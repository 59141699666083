import axios from "axios";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { EnumController, EnumControllerType } from "../../controllers/enum/EnumController";
import { FieldController, FieldControllerType } from "../../controllers/field/FieldController";
import { FormController, FormControllerType } from "../../controllers/form/FormController";
import { RoleService, RoleServiceType } from "../../controllers/role/RoleService";
import { UserRightController, UserRightControllerType } from "../../controllers/user-right/UserRightController";
import EnumComponentType from "../../infrastructure/system/EnumComponentType";
import EnumFormStatus from "../../infrastructure/system/EnumFormStatus";
import EnumRight from "../../infrastructure/system/EnumRight";
import LabelsContext from "../../infrastructure/system/LabelsContext";
import { DATE_FORMAT, FORM_CCF, FORM_DEATH_REPORT, handleAxiosCallError, useEffectOnce } from "../../infrastructure/system/Utils";
import FieldReadDto from "../../model/FieldReadDto";
import FieldValueDto from "../../model/FieldValueDto";
import { AppContext } from "../../Store";

interface MDProfileLogicalType {
  formStatuses: any;
  formDataList: any;
  setSelectedRow: any;
  selectedRow: any;
  selectedFormFilled: any;
  setSelectedFormFilled: any;
  fieldList: Array<FieldReadDto>;
  searchDataOne: any;
  setSearchDataOne: any;
  searchData: any;
  setSearchData: any;
  addSearchData: any;
  removeSearchData: any;
  onChange: any;
  search: any;
  searchFormStatus: any;
  setSearchFormStatus: any;
  breadCrumbItems: any;
  exportData: any;
  formList: any;
  searchFormType: any;
  changeFormType: any;
  hasDeleteRight: boolean;
  hasReadRight: boolean;
  hasUpdateRight: boolean;
  pmtHasCreateRight: boolean;
  pulHasCreateRight: boolean;
  onSearchValueChange: any;
  dt: any;
  selectedStatus: any;
  setSelectedStatus: any;
  history: any;
  labelList: any;
  locale: any;
}

let searchNumber = 0;

interface MDParams {
  searchFormStatus: any;
  searchFormType: any;
}

export default function MDPageLogical(): MDProfileLogicalType {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const dt = useRef<any>(null);
  const [formDataList, setFormDataList] = useState([]) as any;
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [formStatuses, setFormStatuses] = useState([]) as any;
  const [formList, setFormList] = useState([]) as any;
  const [fieldList, setFieldList] = useState<Array<FieldReadDto>>([]);
  const { isBed, isEd, isMd }: RoleServiceType = RoleService();
  const [searchDataOne, setSearchDataOne] = useState<any>({
    fieldId: "",
    fieldName: "",
    operator: "",
    operatorName: "",
    value: "",
    valueName: "",
  });

  const [searchData, setSearchData] = useState<any>([]);
  const location = useLocation();
  const locationNew = useLocation<MDParams>();
  const { authData, showMessage, setShowBlockUI, filterSearch, setFilterSearch, labelList, locale } = useContext(AppContext);
  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedFormFilled, setSelectedFormFilled] = useState<any | null>();
  const [searchFormStatus, setSearchFormStatus] = useState(
    locationNew.state && locationNew.state.searchFormStatus !== null && locationNew.state.searchFormStatus !== undefined ? locationNew.state.searchFormStatus : null
  );
  const [searchFormType, setSearchFormType] = useState<any>(
    locationNew.state && locationNew.state.searchFormType !== null && locationNew.state.searchFormType !== undefined ? locationNew.state.searchFormType : undefined
  );
  const { axiosFormFilledListSearch, axiosGetFormList }: FormControllerType = FormController();
  const { axiosGetFieldListByForm }: FieldControllerType = FieldController();
  const { axiosGetFormStatusList }: EnumControllerType = EnumController();
  const { axiosGetAllUserRightListByForm }: UserRightControllerType = UserRightController();
  const [hasDeleteRight, setHasDeleteRight] = useState<boolean>(false);
  const [hasReadRight, setHasReadRight] = useState<boolean>(false);
  const [hasUpdateRight, setHasUpdateRight] = useState<boolean>(false);
  const [pmtHasCreateRight, setPmtHasCreateRight] = useState<boolean>(false);
  const [pulHasCreateRight, setPulHasCreateRight] = useState<boolean>(false);
  const [formUserRightRules, setFormUserRightRules] = useState<any>({});
  const { Labels } = LabelsContext();
  const breadCrumbItems = [
    {
      label: Labels.FORM_LIST,
      command: () => {
        window.location.reload();
      },
    },
  ];

  const getAllUserRightListByForm = () => {
    axiosGetAllUserRightListByForm()
      .then((res: any) => {
        let formRuleObject: any = {};
        res.data.data.forEach((rule: any) => {
          for (const [key, value] of Object.entries(rule)) {
            formRuleObject[key] = value;
          }
        });
        setFormUserRightRules(formRuleObject);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  useEffectOnce(() => {
    getAllUserRightListByForm();
    fetchData();
  });

  const userRoleBasedPMTandPULRihts = () => {
    let hasCCFCreate = 0;
    let hasDeathReportCreate = 0;

    let form_ccf_rights = formUserRightRules[FORM_CCF];
    form_ccf_rights && form_ccf_rights.indexOf(EnumRight.CREATE) !== -1 && form_ccf_rights.indexOf(EnumRight.VISIBILITY) !== -1 ? hasCCFCreate++ : (hasCCFCreate += 0);
    hasCCFCreate > 0 ? setPmtHasCreateRight(true) : setPmtHasCreateRight(false);

    let form_report_death_rights = formUserRightRules[FORM_DEATH_REPORT];
    form_report_death_rights && form_report_death_rights.indexOf(EnumRight.CREATE) !== -1 && form_report_death_rights.indexOf(EnumRight.VISIBILITY) !== -1
      ? hasDeathReportCreate++
      : (hasDeathReportCreate += 0);
    hasDeathReportCreate ? setPulHasCreateRight(true) : setPulHasCreateRight(false);
  };
  useEffect(() => {
    userRoleBasedPMTandPULRihts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formUserRightRules]);

  const userRoleBasedFormRights = () => {
    let deleteRight = 0;
    let updateRight = 0;
    let readRight = 0;
    let visibilityRight = 0;
    let createRight = 0;
    let rightList = [];

    if (selectedRow.length) rightList = formUserRightRules[selectedRow[0].formCode];
    rightList.forEach((right: any) => {
      right === EnumRight.DELETE ? deleteRight++ : (deleteRight += 0);
      right === EnumRight.READ ? readRight++ : (readRight += 0);
      right === EnumRight.CREATE ? createRight++ : (createRight += 0);
      right === EnumRight.UPDATE ? updateRight++ : (updateRight += 0);
      right === EnumRight.VISIBILITY ? visibilityRight++ : (visibilityRight += 0);
    });
    deleteRight > 0 ? setHasDeleteRight(true) : setHasDeleteRight(false);
    readRight > 0 ? setHasReadRight(true) : setHasReadRight(false);
    updateRight > 0 ? setHasUpdateRight(true) : setHasUpdateRight(false);
  };

  useEffect(() => {
    userRoleBasedFormRights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, formList]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (searchDataOne.fieldId) {
      const field = fieldList.find((f) => f.id === searchDataOne.fieldId);

      if (field?.fieldType?.componentEnumType === EnumComponentType.BOOLEAN) {
        setSearchDataOne({
          ...searchDataOne,
          operator: "",
          operatorName: "",
          value: false,
          valueName: "",
        });
      } else {
        setSearchDataOne({
          ...searchDataOne,
          operator: "",
          operatorName: "",
          value: "",
          valueName: "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDataOne.fieldId]);

  const fetchData = () => {
    setShowBlockUI(true);
    setSearchData(filterSearch);

    const requestFieldList = axiosGetFieldListByForm(FORM_CCF);
    const requestFormStatusList = axiosGetFormStatusList();
    const requestFormList = axiosGetFormList();
    axios
      .all([requestFieldList, requestFormStatusList, requestFormList])
      .then(
        axios.spread((responseFieldList: any, responseFormStatusList: any, responseFormList: any) => {
          setFieldList(responseFieldList.data.data);
          let listFormStatus = new Array<any>();
          responseFormStatusList.data.forEach((element: any) => {
            let formStatus;
            if (element.code === EnumFormStatus.SUBMITTED) {
              formStatus = { label: Labels.LABEL_SUBMITTED, value: Labels.LABEL_SUBMITTED };
            }
            if (element.code === EnumFormStatus.APPROVED) {
              formStatus = { label: Labels.LABEL_APPROVED, value: Labels.LABEL_APPROVED };
            }
            if (element.code === EnumFormStatus.FOR_REVISION) {
              formStatus = { label: Labels.LABEL_FOR_REVISION, value: Labels.LABEL_FOR_REVISION };
            }
            if (element.code === EnumFormStatus.UNFINISHED) {
              formStatus = { label: Labels.LABEL_UNFINISHED, value: Labels.LABEL_UNFINISHED };
            }
            if (!(isEd() || isBed()) && element.code === EnumFormStatus.UNFINISHED) {
              listFormStatus.push(formStatus);
            }
            if (element.code !== EnumFormStatus.UNFINISHED) {
              listFormStatus.push(formStatus);
            }
          });
          setFormStatuses(listFormStatus);
          setFormList(responseFormList.data.data);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
    if (location.pathname === "/forRevisionFormList") {
      if (isBed() || isEd()) {
        setSearchFormStatus(EnumFormStatus.SUBMITTED);
      } else if (isMd()) {
        setSearchFormStatus(EnumFormStatus.FOR_REVISION);
      }
    }
    const data = {
      institutionID: authData!.currentUser.institution?.id,
      fieldFilledSearchDtoList: filterSearch.length > 0 ? filterSearch : [],
      formStatus: location.pathname === "/forRevisionFormList" ? (isBed() || isEd() ? EnumFormStatus.SUBMITTED : EnumFormStatus.FOR_REVISION) : null,
      formType: searchFormType !== undefined ? searchFormType?.code : null,
    };
    axiosFormFilledListSearch(data)
      .then((res: any) => {
        const listForm: any = res.data.data.map((a: any) => ({
          id: a.id,
          formCode: a.code,
          institution: a.institution,
          status:
            a.formStatus === EnumFormStatus.SUBMITTED
              ? Labels.LABEL_SUBMITTED
              : a.formStatus === EnumFormStatus.APPROVED
              ? Labels.LABEL_APPROVED
              : a.formStatus === EnumFormStatus.FOR_REVISION
              ? Labels.LABEL_FOR_REVISION
              : Labels.LABEL_UNFINISHED,
          patientName: a.patientName,
          dateOfForm: moment(a.date).format(DATE_FORMAT),
          patientSurname: a.patientSurname,
        }));
        let exportList = new Array<any>();
        listForm.forEach((formFilled: any) => {
          exportList.push({
            [Labels.INSTITUTION]: formFilled.institution,
            [Labels.PATIENT_FIRST_NAME]: formFilled.patientName,
            [Labels.PATIENT_LAST_NAME]: formFilled.patientSurname,
            [Labels.FORM_STATUS]: formFilled.status,
            [Labels.INSERT_DATE]: formFilled.dateOfForm,
          });
        });
        setExportData(exportList);
        setFormDataList(listForm);
        setShowBlockUI(false);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const search = () => {
    const status =
      searchFormStatus === Labels.LABEL_SUBMITTED
        ? EnumFormStatus.SUBMITTED
        : searchFormStatus === Labels.LABEL_APPROVED
        ? EnumFormStatus.APPROVED
        : searchFormStatus === Labels.LABEL_FOR_REVISION
        ? EnumFormStatus.FOR_REVISION
        : EnumFormStatus.UNFINISHED;
    const data = {
      institutionID: authData!.currentUser.institution?.id,
      fieldFilledSearchDtoList: searchData.length > 0 ? searchData : [],
      formStatus: searchFormStatus !== null && searchFormStatus !== undefined ? status : null,
      formType: searchFormType !== undefined ? searchFormType.code : null,
    };

    setShowBlockUI(true);
    axiosFormFilledListSearch(data)
      .then((response: any) => {
        const listForm: any = response.data.data.map((a: any) => ({
          id: a.id,
          formCode: a.code,
          institution: a.institution,
          status:
            a.formStatus === EnumFormStatus.SUBMITTED
              ? Labels.LABEL_SUBMITTED
              : a.formStatus === EnumFormStatus.APPROVED
              ? Labels.LABEL_APPROVED
              : a.formStatus === EnumFormStatus.FOR_REVISION
              ? Labels.LABEL_FOR_REVISION
              : Labels.LABEL_UNFINISHED,
          patientName: a.patientName,
          dateOfForm: moment(a.date).format(DATE_FORMAT),
          patientSurname: a.patientSurname,
        }));
        let exportList = new Array<any>();
        listForm.forEach((formFilled: any) => {
          exportList.push({
            [Labels.INSTITUTION]: formFilled.institution,
            [Labels.PATIENT_FIRST_NAME]: formFilled.patientName,
            [Labels.PATIENT_LAST_NAME]: formFilled.patientSurname,
            [Labels.FORM_STATUS]: formFilled.status,
            [Labels.INSERT_DATE]: formFilled.dateOfForm,
          });
        });
        setExportData(exportList);
        setFormDataList(listForm);
        setShowBlockUI(false);
        history.push("/registry");
      })
      .catch((error: any) => {
        setShowBlockUI(false);
        handleAxiosCallError(showMessage, error);
      });
  };

  const addSearchData = () => {
    const params = [...searchData, { ...searchDataOne, id: searchNumber }];
    setSearchData(params);
    setFilterSearch(params);
    searchNumber++;
    setSearchDataOne({
      fieldId: "",
      fieldName: "",
      operator: "",
      operatorName: "",
      value: "",
      valueName: "",
    });
  };

  const removeSearchData = (data: any) => {
    const searchDataNew = searchData.filter((s: any) => s.id !== data.id);
    setSearchData(searchDataNew);
    setFilterSearch(searchDataNew);
  };

  const onChange = (fieldValue: FieldValueDto) => {
    let value = fieldValue.value;
    let valueName = undefined;
    if (fieldValue.field.fieldType?.componentEnumType === EnumComponentType.SET) {
      valueName = fieldValue.field?.fieldType.fieldTypeEnumeratorItems.find((e: any) => e.id === fieldValue.value)?.name;
    } else if (fieldValue.field.fieldType?.componentEnumType === EnumComponentType.DATE_TIME) {
      valueName = moment(fieldValue.value).format(DATE_FORMAT);
    }
    setSearchDataOne({
      ...searchDataOne,
      value: value,
      valueName: valueName,
    });
  };

  const changeFormType = (value: any) => {
    setSearchFormType(value);
    setSearchData([]);
    setSearchDataOne({});
    setSearchFormStatus(null);
    if (value !== undefined) {
      axiosGetFieldListByForm(value.code)
        .then((response: any) => {
          setFieldList(response.data.data.filter((field: FieldReadDto) => field.fieldType.componentEnumType !== EnumComponentType.PANEL));
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    } else {
      setFieldList([]);
    }
  };

  const onSearchValueChange = (sortedData: Array<any>) => {
    const exportList = new Array<any>();
    sortedData?.forEach((formFilled: any) => {
      exportList.push({
        [Labels.INSTITUTION]: formFilled.institution,
        [Labels.PATIENT_FIRST_NAME]: formFilled.patientName,
        [Labels.PATIENT_LAST_NAME]: formFilled.patientSurname,
        [Labels.FORM_STATUS]: formFilled.status,
        [Labels.INSERT_DATE]: formFilled.dateOfForm,
      });
    });
    setExportData(exportList);
  };

  return {
    formStatuses,
    formDataList,
    fieldList,
    searchDataOne,
    setSearchDataOne,
    searchData,
    setSearchData,
    setSelectedRow,
    selectedRow,
    selectedFormFilled,
    setSelectedFormFilled,
    addSearchData,
    removeSearchData,
    onChange,
    search,
    searchFormStatus,
    setSearchFormStatus,
    breadCrumbItems,
    exportData,
    formList,
    searchFormType,
    changeFormType,
    hasDeleteRight,
    hasReadRight,
    hasUpdateRight,
    pmtHasCreateRight,
    pulHasCreateRight,
    onSearchValueChange,
    dt,
    selectedStatus,
    setSelectedStatus,
    history,
    labelList,
    locale,
  };
}

export type { MDProfileLogicalType };
