import React from "react";
import { Panel } from "primereact/panel";
import EnumComponentType from "../../../../infrastructure/system/EnumComponentType";
import { isFormDisabled } from "../../../../infrastructure/system/Utils";
import FieldReadDto from "../../../../model/FieldReadDto";
import FormLogical, { FormLogicalType } from "../../FormLogical";
import Title from "../../title/Title";
import Field from "../Field";

interface PanelType {
  fieldList?: any;
  field: any;
  value: any;
  onChange?: any;
  onBlur?: any;
  errorMessage?: any;
  isDisabled?: boolean;
  isSearch?: boolean;
  codebookLogicalControlValidation?: any;
  setFieldCodebookListValidation?: any;
  validate?: any;
  showField?: any;
}

export default function PanelField(props: PanelType) {
  const { showField, field, onBlur, errorMessage, codebookLogicalControlValidation, setFieldCodebookListValidation, onChange, value, validate } = props;

  const { formOperation, locale, labelList }: FormLogicalType = FormLogical();
  const getName = (fieldName: any) => {
    const elementName = labelList.find((label: any) => label.original === fieldName && label.language === locale);
    return elementName !== undefined ? elementName.translate : fieldName;
  };

  return (
    <div>
      <Panel header={field.name}>
        {field.children &&
          field.children.map((f: FieldReadDto) => {
            if (showField(f)) {
              return (
                <div key={f.code} className="p-grid p-fluid p-align-center">
                  {f.fieldType?.componentEnumType !== EnumComponentType.LABEL && f.fieldType?.componentEnumType !== EnumComponentType.PANEL && (
                    <Title name={getName(f.name)} isRequired={f.isRequired} />
                  )}
                  <div className={f.fieldType?.componentEnumType === EnumComponentType.PANEL ? "p-col-12" : "p-col-8"}>
                    <Field
                      fieldList={f.children}
                      isDisabled={isFormDisabled(formOperation) || f.isDisabled}
                      field={f}
                      value={value[f.id]}
                      onChange={onChange}
                      validate={validate}
                      onBlur={onBlur}
                      errorMessage={errorMessage[f.id]}
                      isSearch={false}
                      codebookLogicalControlValidation={f.children ? codebookLogicalControlValidation : codebookLogicalControlValidation[f.id]}
                      setFieldCodebookListValidation={setFieldCodebookListValidation}
                    />
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
      </Panel>
    </div>
  );
}
