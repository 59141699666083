import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { useContext } from "react";
import LabelsContext from "../../../../infrastructure/system/LabelsContext";
import { AppContext } from "../../../../Store";
import { ReportController, ReportControllerType } from "../../../../controllers/report/ReportController";
import { DATE_TIME_FORMAT, handleAxiosCallError } from "../../../../infrastructure/system/Utils";
import { Checkbox } from "primereact/checkbox";
import moment from "moment";

interface ReportTableViewProp {
  reportGeneratedList: any;
  setReportGenerateList?: any;
  first: any;
  tableRows: any;
  onPageGeneratedReport: any;
  isPublic: boolean;
}

export default function ReportTableView(prop: ReportTableViewProp) {
  const { reportGeneratedList, first, tableRows, onPageGeneratedReport, isPublic, setReportGenerateList } = prop;
  const { Labels } = LabelsContext();
  const { showMessage } = useContext(AppContext);
  const { axiosGetGeneratedReport, axiosUpdateIsPublicReport }: ReportControllerType = ReportController();

  const renderButtons = (rowData: any) => {
    return rowData.report?.reportFileFormatList.map((element: any, index: any) => {
      return (
        <React.Fragment key={index}>
          {element.isGenerated !== undefined && element.isGenerated === false && <>{Labels.LABEL_GENERATE_REPORT}</>}
          {element.isGenerated === undefined && (
            <>
              <Button
                onClick={() => {
                  axiosGetGeneratedReport({ format: element.fileFormat.toLowerCase(), generatedReportID: rowData.id })
                    .then((response: any) => {
                      const url = window.URL.createObjectURL(new Blob([response.data]));
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", `${rowData.title}.${element.fileFormat.toLowerCase()}`);
                      document.body.appendChild(link);
                      link.click();
                    })
                    .catch((error: any) => {
                      handleAxiosCallError(showMessage, error);
                    });
                }}
                label={element?.fileFormat?.toLowerCase()}
                className="p-button-success margin-right-bottom-style"
                tooltip={Labels.LABEL_DOWNLOAD_IN_FORMAT}
              />
            </>
          )}
        </React.Fragment>
      );
    });
  };

  const isPublicHeaderBody = (rowData: any) => {
    return (
      <React.Fragment>
        <Checkbox
          readOnly={rowData.id === undefined}
          onChange={() => {
            changeIsPublicReport(rowData.id, !rowData.isPublic);
          }}
          checked={rowData.isPublic}
        />
      </React.Fragment>
    );
  };

  const changeIsPublicReport = (reportGenerateID: number, isPublic: boolean) => {
    axiosUpdateIsPublicReport(reportGenerateID, isPublic)
      .then((response: any) => {
        setReportGenerateList(
          reportGeneratedList.map((reportGenerated: any) => {
            if (reportGenerated.id === reportGenerateID) {
              return { ...reportGenerated, isPublic: isPublic };
            } else {
              return reportGenerated;
            }
          })
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const reportParameterBody = (rowData: any) => {
    return (
      <React.Fragment>
        <div>
          {rowData?.description?.split(";").map((param: any) => {
            return <div>{param}</div>;
          })}
        </div>
      </React.Fragment>
    );
  };

  const reportInsertTimestampBody = (rowData: any) => {
    return (
      <React.Fragment>
        <div>
          {rowData?.insertTimestamp && !rowData?.insertTimestamp.includes(".")
            ? moment(rowData?.insertTimestamp.toString()).format(DATE_TIME_FORMAT)
            : rowData?.insertTimestamp.includes(".")
            ? rowData?.insertTimestamp
            : null}
        </div>
      </React.Fragment>
    );
  };

  return (
    <DataTable
      value={reportGeneratedList}
      emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
      paginator
      first={first}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      rows={tableRows}
      rowsPerPageOptions={[5, 10, 20]}
      onPage={(e) => {
        onPageGeneratedReport(e.rows, e.first);
      }}
    >
      {!isPublic && <Column header={Labels.HEADER_PUBLIC} body={isPublicHeaderBody} className="width-100px"></Column>}
      <Column field="title" header={Labels.LABEL_NAME} className="width-200px"></Column>
      <Column field="description" header={Labels.REPORT_PARAMETERS} body={reportParameterBody}></Column>
      <Column field="insertTimestamp" header={Labels.REPORT_GENERATE_TIME} body={reportInsertTimestampBody}></Column>
      <Column header={Labels.LABEL_ACTIONS} body={renderButtons}></Column>
    </DataTable>
  );
}
