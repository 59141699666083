import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { CodebookController, CodebookControllerType } from "../../../controllers/codebook/CodebookController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import CodebookDto from "../../../model/CodebookDto";
import CodebookLogicalControlDto from "../../../model/CodebookLogicalControlDto";
import { AppContext } from "../../../Store";
import { getOperatorName } from "../../../infrastructure/system/Utils";

interface CrudCodebookLogicalControlListLogicalType {
  first: number;
  tableRows: number;
  selectedRow: any;
  setSelectedRow: any;
  breadCrumbItems: any;
  codebookLogicalControlList: Array<CodebookLogicalControlDto>;
  selectedCodebookLogicalControl: any;
  setSelectedCodebookLogicalControl: any;
  dialogHeader: any;
  entityOperation: any;
  openDialog: any;
  closeDialog: any;
  displayDialog: boolean;
  dialogRef: any;
  codebookLogicalControlToChange: any;
  setCodebookLogicalControlToChange: any;
  index: any;
  setIndex: any;
  deleteCodebookLogicalControl: any;
  createCodebookLogicalControl: any;
  updateCodebookLogicalControl: any;
  onPageCodebookLogicalControl: any;
  codebookList: Array<CodebookDto>;
  codebookOptionListPrimary: any;
  codebookOptionListSecondary: any;
  exportData: any;
  filterOperatorFirst: any;
  onOperatorFilterChangeFirst: any;
  filterOperatorSecond: any;
  onOperatorFilterChangeSecond: any;
  onSearchValueChange: any;
}
// eslint-disable-next-line
export default function CrudCodebookLogicalControlListLogical(): CrudCodebookLogicalControlListLogicalType {
  const { Labels } = LabelsContext();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const {
    axiosGetCodebookLogicalControlList,
    axiosCreateCodebookLogicalControl,
    axiosUpdateCodebookLogicalControl,
    axiosDeleteCodebookLogicalControl,
    axiosGetCodebookAllList,
    axiosGetCodebookListWithCode,
  }: CodebookControllerType = CodebookController();
  const [codebookLogicalControlList, setCodebookLogicalControlList] = useState<Array<CodebookLogicalControlDto>>([]);
  const [codebookList, setCodebookList] = useState<Array<CodebookDto>>([]);
  const [selectedCodebookLogicalControl, setSelectedCodebookLogicalControl] =
    useState<CodebookLogicalControlDto | null>();
  const [entityOperation, setEntityOperation] = useState<any>();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const [codebookLogicalControlToChange, setCodebookLogicalControlToChange] = useState<CodebookLogicalControlDto>();
  const dialogRef = useRef<any>();
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [codebookOptionListPrimary, setCodebookOptionListPrimary] = useState([]);
  const [codebookOptionListSecondary, setCodebookOptionListSecondary] = useState([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [filterOperatorFirst, setFilterOperatorFirst] = useState();
  const [filterOperatorSecond, setFilterOperatorSecond] = useState();

  const history = useHistory();
  const breadCrumbItems = [
    {
      label: Labels.LABEL_COODEBOOK_TYPE_LIST,
      command: () => {
        history.push("/codebookTypeList");
      },
    },
    {
      label: Labels.LABEL_LOGICAL_CONTROL,
      command: () => {
        window.location.reload();
      },
    },
  ];

  const fetchData = (idSelectedCodebookLogicalControl?: number) => {
    setShowBlockUI(true);
    const codebookLogicalControlList = axiosGetCodebookLogicalControlList();
    const codebookList = axiosGetCodebookAllList();
    axios
      .all([codebookLogicalControlList, codebookList])
      .then(
        axios.spread((responseCodebookLogicalControlList: any, responseCodebookList: any) => {
          setShowBlockUI(false);
          setCodebookLogicalControlList(responseCodebookLogicalControlList.data.data);
          setCodebookList(responseCodebookList.data.data);
          let exportList = new Array<any>();
          responseCodebookLogicalControlList.data.data.forEach((logicalControl: any) => {
            exportList.push({
              [Labels.CODEBOOK_TYPE]: getCodebookTypeName(
                responseCodebookList.data.data,
                logicalControl.primaryCodebookTypeEnum
              ),
              [Labels.HEADER_OPERATOR]: getOperatorName(logicalControl.primaryEnumOperator, Labels),
              [Labels.HEADER_FIRST_VALUE]: logicalControl.primaryFirstValue?.name,
              [Labels.HEADER_SECOND_VALUE]: logicalControl.primarySecondValue?.name,
              [Labels.CODEBOOK_TYPE]: getCodebookTypeName(
                responseCodebookList.data.data,
                logicalControl.secondaryCodebookTypeEnum
              ),
              [Labels.HEADER_OPERATOR + Labels.SPACE]: getOperatorName(logicalControl.secondaryEnumOperator, Labels),
              [Labels.HEADER_FIRST_VALUE + Labels.SPACE]: logicalControl.secondaryFirstValue?.name,
              [Labels.HEADER_SECOND_VALUE + Labels.SPACE]: logicalControl.secondarySecondValue?.name,
            });
          });
          setExportData(exportList);
          if (idSelectedCodebookLogicalControl) {
            responseCodebookLogicalControlList.data.data.forEach((codebookLogicalControlList: any) => {
              if (idSelectedCodebookLogicalControl === codebookLogicalControlList.id) {
                setSelectedRow(codebookLogicalControlList);
                setSelectedCodebookLogicalControl(codebookLogicalControlList);
              }
            });
          }
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  useEffectOnce(() => {
    fetchData();
  });

  useEffect(() => {
    let selectedCodebookType =
      codebookLogicalControlToChange?.primaryCodebookTypeEnum ||
      selectedCodebookLogicalControl?.primaryCodebookTypeEnum;
    if (selectedCodebookType) {
      axiosGetCodebookListWithCode(selectedCodebookType)
        .then((res: any) => {
          const codebookOptionListWithDropdownLabel = res.data.data.map((coodebook: any) => {
            return {
              ...coodebook,
              dropdownValue: `${coodebook.code} - ${coodebook.name}`,
            };
          });
          setCodebookOptionListPrimary(codebookOptionListWithDropdownLabel);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
    // eslint-disable-next-line
  }, [selectedCodebookLogicalControl, codebookLogicalControlToChange]);

  useEffect(() => {
    let selectedCodebookType =
      codebookLogicalControlToChange?.secondaryCodebookTypeEnum ||
      selectedCodebookLogicalControl?.secondaryCodebookTypeEnum;
    if (selectedCodebookType) {
      axiosGetCodebookListWithCode(selectedCodebookType)
        .then((res: any) => {
          const codebookOptionListWithDropdownLabel = res.data.data.map((coodebook: any) => {
            return {
              ...coodebook,
              dropdownValue: `${coodebook.code} - ${coodebook.name}`,
            };
          });
          setCodebookOptionListSecondary(codebookOptionListWithDropdownLabel);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
    // eslint-disable-next-line
  }, [selectedCodebookLogicalControl, codebookLogicalControlToChange]);

  const dialogHeader = (entityOperation: string) => {
    const display = selectedCodebookLogicalControl
      ? codebookList!.find((x: any) => x.code === selectedCodebookLogicalControl.primaryCodebookTypeEnum)?.name! +
      " - " +
      codebookList!.find((x: any) => x.code === selectedCodebookLogicalControl.secondaryCodebookTypeEnum)?.name!
      : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_CREATE;
      case EntityOperation.DELETE:
        return Labels.CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DELETE + " " + display;
      case EntityOperation.UPDATE:
        return Labels.CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_UPDATE + " " + display;
      case EntityOperation.READ:
        return Labels.CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DETAILS + " " + display;
      default:
        return "";
    }
  };
  const getCodebookTypeName = (codebookList: any, column: any) => {
    return codebookList!.find((x: any) => x.code === column)?.name!;
  };

  const onOperatorFilterChangeFirst = (event: any, dt: any) => {
    dt.current.filter(event.value, "primaryEnumOperator", "equals");
    setFilterOperatorFirst(event.value);
  };

  const onOperatorFilterChangeSecond = (event: any, dt: any) => {
    dt.current.filter(event.value, "secondaryEnumOperator", "equals");
    setFilterOperatorSecond(event.value);
  };

  const openDialog = (entityOperation: String, selectedCodebookLogicalControl?: CodebookLogicalControlDto) => {
    setIndex(0);
    let codebookLogicalControl = undefined;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        codebookLogicalControl = selectedCodebookLogicalControl;
        break;
    }

    setEntityOperation(entityOperation);
    const data: any = {
      ...codebookLogicalControl,
      primaryFirstValue: codebookLogicalControl?.primaryFirstValue?.code!,
      primarySecondValue: codebookLogicalControl?.primarySecondValue?.code!,
      secondaryFirstValue: codebookLogicalControl?.secondaryFirstValue?.code!,
      secondarySecondValue: codebookLogicalControl?.secondarySecondValue?.code!,
    };
    setCodebookLogicalControlToChange(data);
    setDisplayDialog(true);
  };

  const closeDialog = () => {
    setDisplayDialog(false);
    setIndex(0);
  };

  const createCodebookLogicalControl = (codebookLogicalControl: CodebookLogicalControlDto) => {
    return new Promise((resolve, reject) => {
      setShowBlockUI(true);
      axiosCreateCodebookLogicalControl(codebookLogicalControl)
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_CREATE_SUCCESS, "");
          fetchData();
          setShowBlockUI(false);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        });
    });
  };

  const updateCodebookLogicalControl = (codebookLogicalControl: CodebookLogicalControlDto) => {
    return new Promise((resolve, reject) => {
      setShowBlockUI(true);
      axiosUpdateCodebookLogicalControl(codebookLogicalControl, codebookLogicalControl.id!)
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_UPDATE_SUCCESS, "");
          fetchData(response.data.data.id);
          setShowBlockUI(false);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        });
    });
  };
  const deleteCodebookLogicalControl = (codebookLogicalControlId: number) => {
    return new Promise((resolve, reject) => {
      axiosDeleteCodebookLogicalControl(codebookLogicalControlId)
        .then(() => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DELETE_SUCCESS, "");
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const onPageCodebookLogicalControl = (rows: any, first: any) => {
    setTableRows(rows);
    setFirst(first);
  };

  const onSearchValueChange = (sortedData: Array<any>) => {
    let exportList = new Array<any>();
    sortedData?.forEach((logicalControl: any) => {
      exportList.push({
        [Labels.CODEBOOK_TYPE]: getCodebookTypeName(
          codebookList,
          logicalControl.primaryCodebookTypeEnum
        ),
        [Labels.HEADER_OPERATOR]: getOperatorName(logicalControl.primaryEnumOperator, Labels),
        [Labels.HEADER_FIRST_VALUE]: logicalControl.primaryFirstValue?.name,
        [Labels.HEADER_SECOND_VALUE]: logicalControl.primarySecondValue?.name,
        [Labels.CODEBOOK_TYPE]: getCodebookTypeName(
          codebookList,
          logicalControl.secondaryCodebookTypeEnum
        ),
        [Labels.HEADER_OPERATOR + Labels.SPACE]: getOperatorName(logicalControl.secondaryEnumOperator, Labels),
        [Labels.HEADER_FIRST_VALUE + Labels.SPACE]: logicalControl.secondaryFirstValue?.name,
        [Labels.HEADER_SECOND_VALUE + Labels.SPACE]: logicalControl.secondarySecondValue?.name,
      });
    });
    setExportData(exportList);
  }

  return {
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    breadCrumbItems,
    codebookLogicalControlList,
    selectedCodebookLogicalControl,
    setSelectedCodebookLogicalControl,
    dialogHeader,
    entityOperation,
    openDialog,
    closeDialog,
    displayDialog,
    dialogRef,
    codebookLogicalControlToChange,
    index,
    setIndex,
    deleteCodebookLogicalControl,
    createCodebookLogicalControl,
    updateCodebookLogicalControl,
    onPageCodebookLogicalControl,
    codebookList,
    codebookOptionListPrimary,
    codebookOptionListSecondary,
    setCodebookLogicalControlToChange,
    exportData,
    filterOperatorFirst,
    onOperatorFilterChangeFirst,
    filterOperatorSecond,
    onOperatorFilterChangeSecond,
    onSearchValueChange
  };
}

export type { CrudCodebookLogicalControlListLogicalType };
