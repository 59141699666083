import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../../infrastructure/system/LabelsContext";
import { getOperatorName, isFormDisabled, operatorSemanticValidation } from "../../../../infrastructure/system/Utils";
import ExportData from "../../../app/export/ExportData";
import CrudFormSemanticValidation from "./CrudFormSemanticValidation";
import FormSemanticValidationLogical, { FormSemanticValidationLogicalType } from "./FormSemanticValidationLogical";
import React from "react";

interface FormSemanticValidationListProp {
  formOperation: string;
}

export default function FormSematicValidationList(prop: FormSemanticValidationListProp) {
  const {
    formSemanticValidationList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    setSelectedFormSemanticValidation,
    selectedFormSemanticValidation,
    onPageForm,
    exportData,
    entityOperation,
    dialogHeader,
    closeDialog,
    dialogRef,
    index,
    displayDialog,
    openDialog,
    setDisplayDialog,
    formSemanticValidationToChange,
    setFormSemanticValidationToChange,
    onCreate,
    onUpdate,
    onDelete,
    filterOperator,
    onOperatorFilterChange,
    onSearchValueChange,
    deleteFormSemanticValidation,
  }: FormSemanticValidationLogicalType = FormSemanticValidationLogical();

  const { formOperation } = prop;

  const dt = useRef<any>(null);

  const { Labels } = LabelsContext();

  const leftContents = () => (
    <>
      <Button
        icon="pi pi-plus"
        className="p-button-success btn-margin-right"
        label={Labels.BUTTON_CREATE}
        tooltip={Labels.LABEL_CREATE_FORM_SEMANTIC_VALIDATION}
        disabled={formOperation !== EntityOperation.UPDATE}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          openDialog(EntityOperation.CREATE);
        }}
      />

      <Button
        icon="pi pi-pencil"
        className="p-button-warning btn-margin-right"
        disabled={!selectedFormSemanticValidation || formOperation !== EntityOperation.UPDATE}
        label={Labels.BUTTON_UPDATE}
        tooltip={Labels.LABEL_UPDATE_FORM_SEMANTIC_VALIDATION}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          selectedFormSemanticValidation && openDialog(EntityOperation.UPDATE);
        }}
      />
      <Button
        icon="pi pi-info-circle"
        label={Labels.BUTTON_DETAILS}
        tooltip={Labels.LABEL_DETAILS_FORM_SEMANTIC_VALIDATION}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedFormSemanticValidation}
        onClick={() => {
          selectedFormSemanticValidation && openDialog(EntityOperation.READ);
        }}
      />
    </>
  );

  const rightContents = () => (
    <>
      <ExportData data={exportData} name={Labels.SEMANTIC_VALIDATION} dt={dt} />
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        label={Labels.BUTTON_DELETE}
        tooltip={Labels.LABEL_DELETE_FORM_SEMANTIC_VALIDATION}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedFormSemanticValidation || formOperation !== EntityOperation.UPDATE}
        onClick={() => {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_SEMANTIC_VALIDATION,
            icon: "pi pi-power-off",
            accept: () => {
              deleteFormSemanticValidation(selectedFormSemanticValidation.id);
            },
          });
        }}
      />
    </>
  );

  const getOperator = (column: any) => {
    return getOperatorName(column.operator, Labels);
  };

  const leftContentsDialog = () => (
    <>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.LABEL_CREATE_FORM_SEMANTIC_VALIDATION}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && index === 0 && (
        <Button
          label={Labels.BUTTON_UPDATE}
          tooltip={Labels.LABEL_UPDATE_FORM_SEMANTIC_VALIDATION}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            dialogRef.current.onUpdate();
          }}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          tooltip={Labels.LABEL_DELETE_FORM_SEMANTIC_VALIDATION}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </>
  );

  const rightContentsDialog = () => <Button label={index === 0 ? Labels.BUTTON_CANCEL : Labels.BUTTON_CLOSE} icon="pi pi-times" onClick={closeDialog} />;

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  const renderSourceFilter = () => {
    return (
      <Dropdown
        value={filterOperator}
        options={operatorSemanticValidation(Labels)}
        onChange={(e) => onOperatorFilterChange(e, dt)}
        showClear
        optionValue="id"
        optionLabel="name"
        placeholder={Labels.PLACEHOLDER_CHOOSE_OPERATOR}
        className="p-column-filter"
      />
    );
  };

  const sourceFilter = renderSourceFilter();

  return (
    <div className="layout-crud-form">
      <Toolbar left={leftContents} right={rightContents} />
      <div>
        <DataTable
          ref={dt}
          value={formSemanticValidationList}
          paginator
          first={first}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={tableRows}
          rowsPerPageOptions={[5, 10, 20]}
          onPage={(e) => {
            onPageForm(e.rows, e.first);
          }}
          selectionMode="single"
          selection={selectedRow}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          onRowSelect={(e) => setSelectedFormSemanticValidation(e.data)}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          exportFilename={Labels.FORM_SEMANTIC_VALIDATION_LIST}
          onValueChange={onSearchValueChange}
        >
          <Column field={"field.name"} header={Labels.HEADER_FIELD} filter filterMatchMode="contains" sortable />
          <Column field={"operator"} header={Labels.HEADER_OPERATOR} filter filterElement={sourceFilter} filterMatchMode="contains" body={getOperator} sortable />
          <Column field={"fieldTarget.name"} header={Labels.HEADER_FIELD_TARGET} filter filterMatchMode="contains" sortable />
          <Column field={"customFormula"} header={Labels.HEADER_CUSTOM_FORMULA} filter filterMatchMode="contains" sortable />
        </DataTable>
      </div>
      <Dialog header={dialogHeader(entityOperation)} visible={displayDialog} onHide={closeDialog} style={{ width: "1000px" }} footer={dialogFooter()}>
        <CrudFormSemanticValidation
          dialogRef={dialogRef}
          formSemanticValidationToChange={formSemanticValidationToChange}
          setFormSemanticValidationToChange={setFormSemanticValidationToChange}
          isDisabled={isFormDisabled(entityOperation)}
          setDisplayDialog={setDisplayDialog}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      </Dialog>
    </div>
  );
}
