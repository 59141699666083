import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface LogTypeControllerType {
  axiosGetLogTypeList: any;
}

export function LogTypeController(): LogTypeControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetLogTypeList = () => {
    return axios.get(Endpoint.LOG_TYPE_LIST, axiosConfig(authData!.token));
  };

  return { axiosGetLogTypeList };
}

export type { LogTypeControllerType };
