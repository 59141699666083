import axios from "axios";
import { useContext, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FieldTypeController, FieldTypeControllerType } from "../../../controllers/field-type/FieldTypeController";
import { FormController, FormControllerType } from "../../../controllers/form/FormController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, useEffectOnce } from "../../../infrastructure/system/Utils";
import FieldReadDto from "../../../model/FieldReadDto";
import FormReadDto from "../../../model/FormReadDto";
import { AppContext } from "../../../Store";

interface CrudFormLogicalType {
  formCode: any;
  formOperation: string;
  isDisabled: any;
  onCreate: any;
  onDelete: any;
  onUpdate: any;
  onCancel: any;
  formChange: FormReadDto | undefined;
  location: any;
  setFormChange: any;
  breadCrumbItems: any;
  formFieldList: any;
  first: any;
  tableRows: any;
  selectedFormField: any;
  setSelectedFormField: any;
  selectedRow: any;
  setSelectedRow: any;
  fieldToChange: any;
  setFieldToChange: any;
  index: number;
  setIndex: any;
  onPageFormTable: any;
  exportData: any;
  history: any;
  deleteFormField: any;
  displayDialog: boolean;
  setDisplayDialog: any;
  selectedForm: any;
}

interface LocationType {
  formOperation: string;
  tabIndex?: any;
  selectedForm?: any;
}

interface UseParamsType {
  formCode?: string;
}

export default function CrudFormLogical() {
  const { showMessage } = useContext(AppContext);
  const { Labels } = LabelsContext();
  const [formChange, setFormChange] = useState<FormReadDto | undefined>();
  const history = useHistory();
  const { formCode } = useParams<UseParamsType>();
  const [exportData, setExportData] = useState<Array<any>>([]);
  const location = useLocation<LocationType>();
  const [formOperation, setFormOperation] = useState(location.state && location.state.formOperation !== undefined ? location.state.formOperation : EntityOperation.UPDATE);
  const [selectedForm, setSelectedForm] = useState(location.state && location.state.selectedForm ? location.state.selectedForm : null);
  const [tabIndex] = useState(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const [isDisabled] = useState(isFormDisabled(formOperation));
  const [formFieldList, setFormFieldList] = useState<Array<FieldReadDto>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [selectedFormField, setSelectedFormField] = useState<FieldReadDto | null>();
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [fieldToChange, setFieldToChange] = useState<FieldReadDto | undefined>();
  const [index, setIndex] = useState(tabIndex);
  const [newForm, setNewForm] = useState<FormReadDto | undefined>();
  const [breadCrumbItems, setBreadCrumbItems] = useState<any>([
    {
      label: Labels.FORM_LIST,
      command: () => {
        history.push("/crudFormList");
      },
    },
  ]);
  const [displayDialog, setDisplayDialog] = useState(false);
  const { axiosCreateForm, axiosUpdateForm, axiosDeleteForm, axiosGetForm, axiosGetFormFieldsList, axiosDeleteFormField }: FormControllerType = FormController();
  const { axiosGetFieldTypeList }: FieldTypeControllerType = FieldTypeController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (fCode?: any) => {
    let newFormCode = fCode ? fCode : newForm ? newForm.code : formCode;
    if (newFormCode) {
      const requestForm = axiosGetForm(newFormCode);
      const requestFormFileds = axiosGetFormFieldsList(newFormCode);
      const requestFieldTypes = axiosGetFieldTypeList();
      axios
        .all([requestForm, requestFormFileds, requestFieldTypes])
        .then(
          axios.spread((responseForm: any, responseFormFileds: any, responseFieldTypes: any) => {
            setFormChange(responseForm.data.data);
            setFormFieldList(responseFormFileds.data.data);
            let exportList = new Array<any>();
            responseFormFileds.data.data.forEach((formField: any) => {
              exportList.push({ [Labels.LABEL_CODE]: formField.code, [Labels.LABEL_NAME]: formField.name, [Labels.LABEL_DESCRIPTION]: formField.description, [Labels.LABEL_NOTE]: formField.note });
            });
            setExportData(exportList);
            if (breadCrumbItems.length < 2) {
              setBreadCrumbItems([
                ...breadCrumbItems,
                {
                  label:
                    formOperation === EntityOperation.UPDATE
                      ? Labels.LABEL_UPDATE_FORM + responseForm.data.data.name
                      : formOperation === EntityOperation.READ
                        ? Labels.LABEL_DETAILS_FORM + responseForm.data.data.name
                        : Labels.LABEL_DELETE_FORM + responseForm.data.data.name,
                },
              ]);
            }
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    } else {
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.LABEL_CREATE_FORM,
        },
      ]);
    }
  };

  const onCreate = () => {
    axiosCreateForm(formChange)
      .then((res: any) => {
        setNewForm(res.data.data);
        setIndex(1);
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_CREATE_FORM_SUCCESS, "");
        setFormOperation(EntityOperation.UPDATE);
        fetchData(res.data.data.code);
        history.push(`/crudForm/${res.data.data.code}`);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onUpdate = () => {
    axiosUpdateForm(formChange, newForm ? newForm.code : formCode)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_UPDATE_FORM_SUCCESS, "");
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onDelete = () => {
    axiosDeleteForm(formCode)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_DELETE_FORM_SUCCESS, "");
        history.push(`/crudFormList`);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onCancel = () => {
    history.push({
      pathname: `/crudFormList`,
    });
  };

  const onPageFormTable = (rows: any, first: any) => {
    setTableRows(rows);
    setFirst(first);
  };

  const deleteFormField = (formFieldId: any) => {
    axiosDeleteFormField(formCode, formFieldId)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_DELETE_FIELD_SUCCESS, "");
        setSelectedFormField(undefined);
        fetchData();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });

  };

  return {
    formCode,
    formOperation,
    isDisabled,
    formChange,
    location,
    breadCrumbItems,
    setFormChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    formFieldList,
    first,
    tableRows,
    selectedFormField,
    setSelectedFormField,
    setSelectedRow,
    selectedRow,
    fieldToChange,
    setFieldToChange,
    setIndex,
    index,
    onPageFormTable,
    exportData,
    history,
    deleteFormField,
    displayDialog,
    setDisplayDialog,
    selectedForm
  };
}

export type { CrudFormLogicalType };
