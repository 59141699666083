import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { Redirect } from "react-router-dom";
import EnumComponentType from "../../../infrastructure/system/EnumComponentType";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import CrudFieldTypeEnumeratorItem from "./CrudFieldtypeEnumeratorItem";
import CrudFieldTypeLogical, { CrudFieldTypeLogicalType } from "./CrudFieldTypeLogical";
import EnumDateFormat from "../../../infrastructure/system/EnumDateFormat";
import { confirmDialog } from "primereact/confirmdialog";
export default function CrudFieldType() {
  const {
    fieldTypeId,
    fieldTypeOperation,
    isDisabled,
    fieldTypeChange,
    setFieldTypeChange,
    breadCrumbItems,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    location,
    fieldTypeEnumeratorItemList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    selectedFieldTypeEnumeratorItem,
    setSelectedFieldTypeEnumeratorItem,
    dialogHeader,
    displayDialog,
    closeDialog,
    dialogRef,
    fieldTypeEnumeratorItemToChange,
    createFieldTypeEnumeratorItem,
    updateFieldTypeEnumeratorItem,
    deleteFieldTypeEnumeratorItem,
    fieldTypeEnumeratorItemOperation,
    openDialog,
    index,
    setIndex,
    newFieldTypeId,
    deleteFormat,
    onPageFieldTypeTable,
    codebookTypeList,
    deleteCodebookType,
  }: CrudFieldTypeLogicalType = CrudFieldTypeLogical();

  const { Labels } = LabelsContext();

  const componentTypeOptions = [
    { label: Labels.LABEL_TEXT, value: EnumComponentType.TEXT },
    { label: Labels.LABEL_LONG_TEXT, value: EnumComponentType.LONG_TEXT },
    { label: Labels.LABEL_LABEL, value: EnumComponentType.LABEL },
    { label: Labels.LABEL_BOOLEAN, value: EnumComponentType.BOOLEAN },
    { label: Labels.LABEL_INTEGER, value: EnumComponentType.NUMBER_INTEGER },
    { label: Labels.LABEL_FLOAT, value: EnumComponentType.NUMBER_DECIMAL },
    { label: Labels.LABEL_SET, value: EnumComponentType.SET },
    { label: Labels.LABEL_DATE_TIME, value: EnumComponentType.DATE_TIME },
    { label: Labels.LABEL_PANEL, value: EnumComponentType.PANEL },
    { label: Labels.LABEL_CODEBOOK, value: EnumComponentType.CODEBOOK },
    { label: Labels.CODEBOOK_AUTOCOMPLETE, value: EnumComponentType.CODEBOOK_AUTOCOMPLETE },
  ];

  const formatOptions = [{ label: EnumDateFormat.DATE, value: EnumDateFormat.DATE }];

  if (!fieldTypeId && !location.state) {
    return <Redirect to="/fieldTypeList" />;
  }

  const leftContentsToolbar = () => (
    <>
      {fieldTypeOperation === EntityOperation.CREATE && (
        <Button label={Labels.BUTTON_CREATE} tooltip={Labels.LABEL_CREATE_FIELD_TYPE} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
      )}
      {fieldTypeOperation === EntityOperation.UPDATE && (
        <Button label={Labels.BUTTON_UPDATE} tooltip={Labels.LABEL_UPDATE_FIELD_TYPE} icon="pi pi-pencil" className="p-button-warning" onClick={onUpdate} />
      )}
      {fieldTypeOperation === EntityOperation.DELETE && (
        <Button label={Labels.BUTTON_DELETE} tooltip={Labels.LABEL_DELETE_FIELD_TYPE} icon="pi pi-trash" className="p-button-danger" onClick={onDelete} />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-danger" onClick={onCancel} />
    </>
  );

  const leftContentsEnum = () => (
    <>
      <Button
        icon="pi pi-plus"
        className="p-button-success btn-margin-right"
        label={Labels.LABEL_CREATE}
        tooltip={Labels.FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_CREATE}
        tooltipOptions={{ position: "bottom" }}
        disabled={fieldTypeOperation === EntityOperation.READ}
        onClick={() => {
          openDialog(EntityOperation.CREATE, selectedFieldTypeEnumeratorItem);
        }}
      />
      <Button
        icon="pi pi-pencil"
        className="p-button-warning btn-margin-right"
        tooltip={Labels.FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_UPDATE}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedFieldTypeEnumeratorItem || fieldTypeOperation === EntityOperation.READ}
        label={Labels.LABEL_EDIT}
        onClick={() => {
          selectedFieldTypeEnumeratorItem && openDialog(EntityOperation.UPDATE, selectedFieldTypeEnumeratorItem);
        }}
      />
      <Button
        icon="pi pi-info-circle"
        label={Labels.LABEL_DETAILS}
        tooltip={Labels.FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_DETAILS}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedFieldTypeEnumeratorItem}
        onClick={() => {
          selectedFieldTypeEnumeratorItem && openDialog(EntityOperation.READ, selectedFieldTypeEnumeratorItem);
        }}
      />
    </>
  );

  const rightContentsEnum = () => (
    <>
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        label={Labels.LABEL_DELETE}
        tooltip={Labels.FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_DELETE}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedFieldTypeEnumeratorItem || fieldTypeOperation === EntityOperation.READ}
        onClick={() => {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_TYPE_ENUMERATOR_ITEM,
            icon: "pi pi-power-off",
            accept: () => {
              deleteFieldTypeEnumeratorItem(selectedFieldTypeEnumeratorItem.id);
            },
          });
        }}
      />
    </>
  );

  const leftContentsDialog = () => (
    <>
      {fieldTypeEnumeratorItemOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_CREATE}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
        />
      )}
      {fieldTypeEnumeratorItemOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          tooltip={Labels.FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_UPDATE}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            dialogRef.current.onUpdate();
          }}
        />
      )}
      {fieldTypeEnumeratorItemOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          tooltip={Labels.FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_DELETE}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </>
  );

  const rightContentsDialog = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" onClick={closeDialog} />
    </>
  );

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  return (
    <div className="layout-crud-form">
      <BreadCrumb model={breadCrumbItems} />
      <TabView
        className="tabview-margin-top"
        renderActiveOnly={false}
        activeIndex={index}
        onTabChange={(e) => {
          setIndex(e.index);
        }}
      >
        <TabPanel header={Labels.FIELD_TYPE}>
          <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
            <div className="p-grid p-align-center p-nogutter">
              <div className="p-col-4">
                <div>
                  {Labels.LABEL_CODE}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid">
                <InputText
                  disabled={isDisabled}
                  value={fieldTypeChange && fieldTypeChange.code}
                  onChange={(e: any) => {
                    setFieldTypeChange({
                      ...fieldTypeChange!,
                      code: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>
                  {Labels.LABEL_NAME}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputText
                  disabled={isDisabled}
                  value={fieldTypeChange && fieldTypeChange.name}
                  onChange={(e: any) => {
                    setFieldTypeChange({
                      ...fieldTypeChange!,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>
                  {Labels.FIELD_TYPE_COMPONENT_TYPE}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <Dropdown
                  disabled={isDisabled}
                  options={componentTypeOptions}
                  filter
                  showClear
                  value={fieldTypeChange && fieldTypeChange.componentEnumType}
                  onChange={(e: any) => {
                    deleteCodebookType(e.target.value);
                    deleteFormat(e.target.value);
                    setFieldTypeChange({
                      ...fieldTypeChange!,
                      componentEnumType: e.target.value,
                    });
                  }}
                />
              </div>
              {(fieldTypeChange?.componentEnumType === EnumComponentType.CODEBOOK || fieldTypeChange?.componentEnumType === EnumComponentType.CODEBOOK_AUTOCOMPLETE) && (
                <>
                  <div className="p-col-4">
                    <div>
                      {Labels.CODEBOOK_TYPE}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="p-col-8 p-fluid margin-top-bottom-5">
                    <Dropdown
                      disabled={isDisabled}
                      options={codebookTypeList}
                      optionValue="code"
                      optionLabel="name"
                      filter
                      showClear
                      value={fieldTypeChange && fieldTypeChange.codebookEnumType}
                      onChange={(e: any) => {
                        setFieldTypeChange({
                          ...fieldTypeChange!,
                          codebookEnumType: e.target.value,
                        });
                      }}
                    />
                  </div>
                </>
              )}

              <div className="p-col-4">
                <div>{Labels.LABEL_DESCRIPTION}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputTextarea
                  rows={3}
                  cols={5}
                  disabled={isDisabled}
                  value={fieldTypeChange && fieldTypeChange.description}
                  onChange={(e: any) => {
                    setFieldTypeChange({
                      ...fieldTypeChange!,
                      description: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="p-col-4">
                <div>{Labels.LABEL_NOTE}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputTextarea
                  rows={3}
                  cols={5}
                  disabled={isDisabled}
                  value={fieldTypeChange && fieldTypeChange.note}
                  onChange={(e: any) => {
                    setFieldTypeChange({
                      ...fieldTypeChange!,
                      note: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>{Labels.FIELD_TYPE_MIN}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputNumber
                  disabled={isDisabled}
                  value={fieldTypeChange && fieldTypeChange.min}
                  onValueChange={(e: any) => {
                    setFieldTypeChange({
                      ...fieldTypeChange!,
                      min: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>{Labels.FIELD_TYPE_MAX}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputNumber
                  disabled={isDisabled}
                  value={fieldTypeChange && fieldTypeChange.max}
                  onValueChange={(e: any) => {
                    setFieldTypeChange({
                      ...fieldTypeChange!,
                      max: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>{Labels.FIELD_TYPE_DECIMALS}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputNumber
                  disabled={isDisabled}
                  value={fieldTypeChange && fieldTypeChange.decimals}
                  onValueChange={(e: any) => {
                    setFieldTypeChange({
                      ...fieldTypeChange!,
                      decimals: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>{Labels.FIELD_TYPE_FORMAT}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                {fieldTypeChange?.componentEnumType === EnumComponentType.DATE_TIME ? (
                  <Dropdown
                    disabled={isDisabled}
                    options={formatOptions}
                    filter
                    showClear
                    value={fieldTypeChange && fieldTypeChange.format}
                    onChange={(e: any) => {
                      setFieldTypeChange({
                        ...fieldTypeChange!,
                        format: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <InputText
                    disabled={isDisabled}
                    value={fieldTypeChange && fieldTypeChange.format}
                    onChange={(e: any) => {
                      setFieldTypeChange({
                        ...fieldTypeChange!,
                        format: e.target.value,
                      });
                    }}
                  />
                )}
              </div>
              <div className="p-col-4">
                <div>{Labels.LABEL_UNIT}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputText
                  disabled={isDisabled}
                  value={fieldTypeChange && fieldTypeChange.unit}
                  onChange={(e: any) => {
                    setFieldTypeChange({
                      ...fieldTypeChange!,
                      unit: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
        </TabPanel>
        <TabPanel header={Labels.FIELD_TYPE_ENUM} disabled={fieldTypeChange?.componentEnumType !== EnumComponentType.SET || newFieldTypeId === undefined}>
          <Toolbar left={leftContentsEnum} right={rightContentsEnum} />
          <div>
            <DataTable
              value={fieldTypeEnumeratorItemList}
              paginator
              first={first}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              rows={tableRows}
              rowsPerPageOptions={[5, 10, 20]}
              onPage={(e) => {
                onPageFieldTypeTable(e.rows, e.first);
              }}
              selectionMode="single"
              selection={selectedRow}
              onSelectionChange={(e) => setSelectedRow(e.value)}
              onRowSelect={(e) => setSelectedFieldTypeEnumeratorItem(e.data)}
              emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
              exportFilename={Labels.FIELD_TYPES}
            >
              <Column field={"name"} header={Labels.HEADER_NAME} filter filterMatchMode="contains" sortable />
              <Column field={"code"} header={Labels.HEADER_CODE} filter filterMatchMode="contains" className="word-wrap-anywhere" sortable />
              <Column field={"note"} header={Labels.HEADER_NOTE} filter filterMatchMode="contains" className="word-wrap-anywhere" sortable />
              <Column field={"displayOrder"} header={Labels.HEADER_DISPLAY_ORDER} filter filterMatchMode="equals" sortable />
            </DataTable>
          </div>
          <Dialog header={dialogHeader(fieldTypeEnumeratorItemOperation)} visible={displayDialog} onHide={closeDialog} style={{ width: "1000px" }} footer={dialogFooter()}>
            <CrudFieldTypeEnumeratorItem
              fieldTypeEnumeratorItemOperation={fieldTypeEnumeratorItemOperation}
              fieldTypeEnumeratorItem={fieldTypeEnumeratorItemToChange}
              onCreateFieldTypeEnumeratorItem={createFieldTypeEnumeratorItem}
              dialogRef={dialogRef}
              onUpdateFieldTypeEnumeratorItem={updateFieldTypeEnumeratorItem}
              onDeleteFieldTypeEnumeratorItem={deleteFieldTypeEnumeratorItem}
            />
          </Dialog>
        </TabPanel>
      </TabView>
    </div>
  );
}
