import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import ExportData from "../../app/export/ExportData";
import ReportLogical, { ReportLogicalType } from "./ReportLogical";

export default function ReportList() {
  const {
    reportList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    selectedReport,
    breadCrumbItems,
    exportData,
    onPage,
    setSelectedReport,
    history,
    getCategoryName,
    getFileFormatList,
    deleteReport,
  }: ReportLogicalType = ReportLogical();

  const { Labels } = LabelsContext();

  const dt = useRef<any>(null);

  const openReport = (entityOperation: string) => {
    const reportID = entityOperation !== EntityOperation.CREATE ? selectedReport.id : "";
    history.push({
      pathname: `/crudReport/${reportID}`,
      state: { reportOperation: entityOperation, selectedReport: selectedReport },
    });
  };

  const leftContents = () => (
    <>
      <Button
        icon="pi pi-plus"
        className="p-button-success btn-margin-right"
        label={Labels.BUTTON_CREATE}
        tooltip={Labels.LABEL_CREATE_REPORT}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          openReport(EntityOperation.CREATE);
        }}
      />

      <Button
        icon="pi pi-pencil"
        className="p-button-warning btn-margin-right"
        disabled={!selectedReport}
        label={Labels.BUTTON_UPDATE}
        tooltip={Labels.LABEL_UPDATE_REPORT}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          selectedReport && openReport(EntityOperation.UPDATE);
        }}
      />
      <Button
        icon="pi pi-info-circle"
        label={Labels.BUTTON_DETAILS}
        tooltip={Labels.LABEL_DETAILS_REPORT}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedReport}
        onClick={() => {
          selectedReport && openReport(EntityOperation.READ);
        }}
      />
    </>
  );

  const rightContents = () => (
    <>
      <ExportData data={exportData} name={Labels.MENU_REPORT} dt={dt} />
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        label={Labels.BUTTON_DELETE}
        disabled={!selectedReport}
        onClick={() => {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPORT,
            icon: "pi pi-power-off",
            accept: () => {
              deleteReport(selectedReport.id);
            },
          });
        }}
      />
    </>
  );

  return (
    <div className="layout-crud-form">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
        <Toolbar left={leftContents} right={rightContents} />
        <div>
          <DataTable
            ref={dt}
            value={reportList}
            paginator
            first={first}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            rows={tableRows}
            rowsPerPageOptions={[5, 10, 20]}
            onPage={(e) => {
              onPage(e.rows, e.first);
            }}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedReport(e.data)}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            exportFilename={Labels.REPORT_LIST}
          >
            <Column field={"category"} header={Labels.HEADER_CATEGORY} filter filterMatchMode="contains" sortable body={getCategoryName} />
            <Column field={"name"} header={Labels.HEADER_NAME} filter filterMatchMode="contains" sortable />
            <Column field={"jasperLocation"} header={Labels.HEADER_JASPER_LOCATION} filter filterMatchMode="contains" sortable />
            <Column field={"reportFileFormatList"} header={Labels.HEADER_FILE_FORMAT} filter filterMatchMode="contains" sortable body={getFileFormatList} />
          </DataTable>
        </div>
      </Panel>
    </div>
  );
}
