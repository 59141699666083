import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import ExportData from "../../app/export/ExportData";
import CodebookTypeListLogical, { CodebookListTypeLogicalType } from "./CodebookTypeListLogical";

export default function CodebookTypeList() {
  const {
    codebookTypeList,
    first,
    tableRows,
    selectedRow,
    selectedCodebook,
    onPage,
    setSelectedRow,
    setSelectedCodebook,
    breadCrumbItems,
    exportData,
    openCodebook,
    onSearchValueChange,
  }: CodebookListTypeLogicalType = CodebookTypeListLogical();

  const { Labels } = LabelsContext();
  const dt = useRef<any>(null);

  const leftContents = () => (
    <>
      <Button
        icon="pi pi-info-circle"
        label={Labels.LABEL_DETAILS}
        tooltip={Labels.CODEBOOK_TITLE_DETAILS}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedCodebook}
        onClick={openCodebook}
      />
    </>
  );

  const rightContents = () => <ExportData data={exportData} name={Labels.LABEL_COODEBOOK_TYPE_LIST} dt={dt} />;

  return (
    <div className="layout-institution-list">
      <Panel>
        <div className="p-grid">
          <div className="p-col-12">
            <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
            <Toolbar left={leftContents} right={rightContents} />
            <div>
              <DataTable
                ref={dt}
                value={codebookTypeList}
                paginator
                first={first}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rows={tableRows}
                rowsPerPageOptions={[5, 10, 20]}
                onPage={onPage}
                selectionMode="single"
                selection={selectedRow}
                onSelectionChange={(e) => setSelectedRow(e.value)}
                onRowSelect={(e) => setSelectedCodebook(e.data)}
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                exportFilename={Labels.LABEL_COODEBOOK_TYPE_LIST}
                onValueChange={onSearchValueChange}
              >
                <Column field={"name"} header={Labels.HEADER_NAME} filter filterMatchMode="contains" sortable />
                <Column field={"code"} header={Labels.HEADER_CODE} filter filterMatchMode="contains" sortable />
              </DataTable>
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
}
