import { ChangeEvent, useContext, useState } from "react";
import { useHistory } from "react-router";
import AuthData from "../../AuthData";
import { LoginController, LoginControllerType } from "../../controllers/login/LoginController";
import LabelsContext from "../../infrastructure/system/LabelsContext";
import MessageType from "../../infrastructure/system/MessageType";
import { handleAxiosCallError } from "../../infrastructure/system/Utils";
import LoginDataDto from "../../model/LoginDataDto";
import { AppContext } from "../../Store";

interface LoginPageLocicalType {
  loginData: LoginDataDto;
  changeLoginData: any;
  postLogin: any;
}

export default function LoginPageLogical(): LoginPageLocicalType {
  const { updateAuthData, showMessage } = useContext(AppContext);
  const { Labels } = LabelsContext();
  const history = useHistory();
  const { axiosAuthenticate }: LoginControllerType = LoginController();

  const [loginData, setLoginData] = useState<LoginDataDto>({
    username: "",
    password: "",
  });

  const changeLoginData = (e: ChangeEvent<HTMLInputElement>) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const postLogin = () => {
    if (loginData.username.trim() === "") {
      showMessage(MessageType.ERROR, Labels.MESSAGES_USERNAME_FIELD, "");
      return;
    }
    if (loginData.password.trim() === "") {
      showMessage(MessageType.ERROR, Labels.MESSAGES_PASSWORD_FIELD, "");
      return;
    }
    axiosAuthenticate(loginData)
      .then((result: any) => {
        const { jwt, user } = result.data.data;
        const authData = { token: jwt, currentUser: user } as AuthData;
        updateAuthData(JSON.stringify(authData));
        history.push("/registry");
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };
  return { loginData, changeLoginData, postLogin };
}

export type { LoginPageLocicalType };
