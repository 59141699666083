import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { PickList } from "primereact/picklist";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext, useImperativeHandle, useState } from "react";
import EnumEntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { isFormDisabled } from "../../../infrastructure/system/Utils";
import UserGroupReadDto from "../../../model/UserGroupReadDto";
import { AppContext } from "../../../Store";
import { confirmDialog } from "primereact/confirmdialog";
import React from "react";

interface CrudUserGroupProp {
  userGroupOperation: string;
  userGroup?: UserGroupReadDto;
  onCreateUserGroup: any;
  dialogRef?: any;
  onUpdateUserGroup: any;
  onDeleteUserGroup: any;
  setIndex: Function;
  index: number;
  userGroupRoleList: any;
  roleList: any;
  onChangeRole: any;
  userGroupJurisdictionList: any;
  jurisdictionList: any;
  onChangeJurisdiction: any;
  selectedUserGroupUserRow: any;
  setSelectedUserGroupUserRow: any;
  deleteUserGroupUser: any;
  openDialogCreateUserGroupUser: any;
  displayDialogCreateUserUserGroup: any;
  closeDialogCreateUserUserGroup: any;
  usersNotInUserGroup: any;
  createUserGroupUser: any;
  selectedUserRow: any;
  setSelectedUserRow: any;
  userDataTableFirst: number;
  userTableRows: number;
  userGroupUserTableFirst: any;
  userGroupUserTableRows: any;
  onPageUserGroupUser: any;
  onPageUserTable: any;
  searchUserData: any;
  setSearchUserData: any;
  searchUserList: any;
}

export default function CrudUserGroup(prop: CrudUserGroupProp) {
  const {
    userGroupOperation,
    userGroup,
    onCreateUserGroup,
    dialogRef,
    onUpdateUserGroup,
    onDeleteUserGroup,
    setIndex,
    index,
    userGroupRoleList,
    roleList,
    onChangeRole,
    userGroupJurisdictionList,
    jurisdictionList,
    onChangeJurisdiction,
    setSelectedUserGroupUserRow,
    selectedUserGroupUserRow,
    deleteUserGroupUser,
    openDialogCreateUserGroupUser,
    displayDialogCreateUserUserGroup,
    closeDialogCreateUserUserGroup,
    usersNotInUserGroup,
    createUserGroupUser,
    selectedUserRow,
    setSelectedUserRow,
    userDataTableFirst,
    userTableRows,
    userGroupUserTableFirst,
    userGroupUserTableRows,
    onPageUserGroupUser,
    onPageUserTable,
    searchUserData,
    setSearchUserData,
    searchUserList,
  } = prop;
  const { showMessage } = useContext(AppContext);
  const [isDisabled] = useState(isFormDisabled(userGroupOperation));
  const [userGroupChange, setUserGroupChange] = useState<UserGroupReadDto | undefined>(userGroup);

  const { Labels } = LabelsContext();

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      setIndex(0);
      onCreateUserGroup(userGroupChange)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
    onUpdate: () => {
      onUpdateUserGroup(userGroupChange)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
    onDelete: () => {
      onDeleteUserGroup(userGroupChange!.id)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
  }));
  const jurisdictionTemplate = (item: any) => {
    return <h6 className="p-mb-2">{item.jurisdiction.name}</h6>;
  };
  const itemTemplat = (item: any) => {
    return <h6 className="p-mb-2">{item.role}</h6>;
  };

  const leftContentButton = () => (
    <>
      <Button
        label={Labels.BUTTON_ADD_USER}
        icon="pi pi-plus"
        className="p-button-success"
        disabled={userGroupOperation === EnumEntityOperation.READ}
        onClick={() => {
          openDialogCreateUserGroupUser();
        }}
      ></Button>
    </>
  );

  const rightContentButton = () => (
    <>
      <Button
        label={Labels.BUTTON_DELETE}
        icon="pi pi-trash"
        className="p-button-danger"
        disabled={!selectedUserGroupUserRow || userGroupOperation === EnumEntityOperation.READ}
        onClick={() => {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.LABEL_DIALOG_MESSAGE_DELETE_USER_FROM_USER_GROUP,
            icon: "pi pi-trash",
            accept: () => {
              deleteUserGroupUser(selectedUserGroupUserRow?.id);
            },
          });
        }}
      ></Button>
    </>
  );

  const leftButtonCreate = () => (
    <>
      <Button
        label={Labels.BUTTON_ADD_USER}
        icon="pi pi-plus"
        className="p-button-success"
        disabled={!selectedUserRow}
        onClick={() => {
          createUserGroupUser(selectedUserRow);
        }}
      ></Button>
    </>
  );

  const rightSearchContent = () => (
    <>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText placeholder={Labels.SEARCH_USER} value={searchUserData} onChange={(e) => setSearchUserData(e.target.value)} onKeyPress={(e) => searchUserList(e)} />
      </span>
    </>
  );

  return (
    <div className="layout-user-group">
      <TabView
        className="tabview-margin-top"
        renderActiveOnly={false}
        activeIndex={index}
        onTabChange={(e) => {
          setIndex(e.index);
        }}
      >
        <TabPanel header={Labels.USER_GROUP}>
          <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
            <div className="p-grid p-align-center p-nogutter">
              <div className="p-col-4">
                <div>
                  {Labels.USER_GROUP_LIST_CODE}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputText
                  disabled={isDisabled}
                  value={userGroupChange && userGroupChange.code}
                  onChange={(e: any) => {
                    setUserGroupChange({
                      ...userGroupChange!,
                      code: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>
                  {Labels.USER_GROUP_LIST_NAME}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputText
                  disabled={isDisabled}
                  value={userGroupChange && userGroupChange.name}
                  onChange={(e: any) => {
                    setUserGroupChange({
                      ...userGroupChange!,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>{Labels.USER_GROUP_LIST_DESCRIPTION}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputTextarea
                  rows={3}
                  cols={5}
                  disabled={isDisabled}
                  value={userGroupChange && userGroupChange.description}
                  onChange={(e: any) => {
                    setUserGroupChange({
                      ...userGroupChange!,
                      description: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="p-col-4">
                <div>{Labels.USER_GROUP_LIST_NOTE}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputTextarea
                  rows={3}
                  cols={5}
                  disabled={isDisabled}
                  value={userGroupChange && userGroupChange.note}
                  onChange={(e: any) => {
                    setUserGroupChange({
                      ...userGroupChange!,
                      note: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header={Labels.USER_GROUP_ROLES} disabled={userGroupOperation === EnumEntityOperation.CREATE}>
          <div className="layout-user-group">
            <div className={userGroupOperation === EnumEntityOperation.READ ? "read-only" : "non-read-only"}>
              <PickList
                sourceHeader={Labels.ROLES}
                targetHeader={Labels.USER_GROUP_ROLES}
                source={roleList}
                target={userGroupRoleList}
                onChange={onChangeRole}
                itemTemplate={itemTemplat}
                showSourceControls={false}
                showTargetControls={false}
              ></PickList>
            </div>
          </div>
        </TabPanel>
        <TabPanel header={Labels.USER_GROUP_JURISDICTION} disabled={userGroupOperation === EnumEntityOperation.CREATE}>
          <div className="layout-user-group">
            <div className={userGroupOperation === EnumEntityOperation.READ ? "read-only" : "non-read-only"}>
              <PickList
                sourceHeader={Labels.JURISDICTIONS}
                targetHeader={Labels.USER_GROUP_JURISDICTION}
                source={jurisdictionList}
                target={userGroupJurisdictionList}
                itemTemplate={jurisdictionTemplate}
                onChange={onChangeJurisdiction}
                showSourceControls={false}
                showTargetControls={false}
              ></PickList>
            </div>
          </div>
        </TabPanel>
        <TabPanel header={Labels.USER_LIST} disabled={userGroupOperation === EnumEntityOperation.CREATE}>
          <Toolbar left={leftContentButton} right={rightContentButton} />
          <DataTable
            paginator
            first={userGroupUserTableFirst}
            value={userGroup?.userGroupUserList}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            selectionMode="single"
            rowsPerPageOptions={[5, 10, 20]}
            selection={selectedUserGroupUserRow}
            onPage={(e) => {
              onPageUserGroupUser(e.rows, e.first);
            }}
            rows={userGroupUserTableRows}
            onSelectionChange={(e) => setSelectedUserGroupUserRow(e.value)}
            onRowSelect={(e) => setSelectedUserGroupUserRow(e.data)}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          >
            <Column field={"user.firstname"} header={Labels.USER_FIRST_NAME} />
            <Column field={"user.lastname"} header={Labels.USER_LAST_NAME} />
            <Column field={"user.username"} header={Labels.USER_USER_NAME} />
          </DataTable>
          <Dialog visible={displayDialogCreateUserUserGroup} onHide={closeDialogCreateUserUserGroup} style={{ width: "1000px" }}>
            <div>
              <Toolbar left={leftButtonCreate} right={rightSearchContent} />
              <DataTable
                paginator
                value={usersNotInUserGroup}
                first={userDataTableFirst}
                onPage={(e) => {
                  onPageUserTable(e.rows, e.first);
                }}
                rows={userTableRows}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[5, 10, 20]}
                selectionMode="single"
                selection={selectedUserRow}
                onSelectionChange={(e) => setSelectedUserRow(e.value)}
                onRowSelect={(e) => setSelectedUserRow(e.data)}
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
              >
                <Column field={"user.firstname"} header={Labels.USER_FIRST_NAME} />
                <Column field={"user.lastname"} header={Labels.USER_LAST_NAME} />
                <Column field={"user.username"} header={Labels.USER_USER_NAME} />
              </DataTable>
            </div>
          </Dialog>
        </TabPanel>
      </TabView>
    </div>
  );
}
