import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import EnumComponentType from "../../../infrastructure/system/EnumComponentType";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import { CALENDAR_DATE_FORMAT } from "../../../infrastructure/system/Utils";
import CrudLabel from "../../label/CrudLabel";
import CrudFormFieldLogical, { CrudFormFieldLogicalType } from "./CrudFormFieldLogical";

export default function CrudFormField() {
  const {
    formFieldOperation,
    selectedFormField,
    setSelectedFormField,
    isFieldDisabled,
    fieldTypeList,
    getFieldType,
    enumeratorItemList,
    fieldType,
    onCancel,
    onDeleteField,
    onUpdateField,
    onCreateField,
    breadCrumbItems,
    displayDialog,
    setDisplayDialog,
    fieldTypePanelList,
  }: CrudFormFieldLogicalType = CrudFormFieldLogical();

  const { Labels } = LabelsContext();

  const rightContents = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-danger" onClick={onCancel} />
    </>
  );

  const leftContents = () => (
    <>
      {formFieldOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.FIELD_TITLE_DIALOG_CREATE}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => {
            onCreateField();
          }}
        />
      )}
      {formFieldOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          tooltip={Labels.FIELD_TITLE_DIALOG_UPDATE}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            onUpdateField();
          }}
        />
      )}
      {formFieldOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          tooltip={Labels.FIELD_TITLE_DIALOG_DELETE}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            onDeleteField();
          }}
        />
      )}
    </>
  );

  return (
    <div className="layout-crud-form-field">
      <BreadCrumb model={breadCrumbItems} />
      <CrudLabel
        nameOriginal={selectedFormField?.name}
        displayDialog={displayDialog}
        dialogClose={() => {
          setDisplayDialog(false);
        }}
        isDisabled={isFieldDisabled}
        entityOperation={formFieldOperation}
      />
      <Panel>
        <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
          <div className="p-grid p-align-center p-nogutter">
            <div className="p-col-4">
              <div>
                {Labels.LABEL_CODE} {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="p-col-8 p-fluid">
              <InputText
                disabled={isFieldDisabled}
                value={selectedFormField && selectedFormField.code}
                onChange={(e: any) => {
                  setSelectedFormField({
                    ...selectedFormField!,
                    code: e.target.value,
                  });
                }}
              />
            </div>
            <div className="p-col-4">
              <div>
                {Labels.LABEL_NAME} {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="p-col-8">
              <div className="p-grid p-align-center p-nogutter p-jc-between">
                <div className="p-col-11 p-fluid p-align-center margin-top-bottom-5">
                  <InputText
                    disabled={isFieldDisabled}
                    value={selectedFormField && selectedFormField.name}
                    onChange={(e: any) => {
                      setSelectedFormField({
                        ...selectedFormField!,
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="p-col-1 justify-items-end">
                  <Button
                    icon="pi pi-external-link"
                    onClick={() => {
                      setDisplayDialog(true);
                    }}
                    className="margin-top-bottom-5"
                    disabled={selectedFormField?.name === undefined || selectedFormField?.name === null}
                    tooltip={Labels.LABEL_TRANSLATE_LABEL}
                    tooltipOptions={{ position: "bottom" }}
                  />
                </div>
              </div>
            </div>
            <div className="p-col-4">
              <div>{Labels.LABEL_DESCRIPTION}</div>
            </div>
            <div className="p-col-8 p-fluid margin-top-bottom-5">
              <InputTextarea
                rows={3}
                cols={5}
                disabled={isFieldDisabled}
                value={selectedFormField && selectedFormField.description}
                onChange={(e: any) => {
                  setSelectedFormField({
                    ...selectedFormField!,
                    description: e.target.value,
                  });
                }}
              />
            </div>
            <div className="p-col-4">
              <div>{Labels.LABEL_NOTE}</div>
            </div>
            <div className="p-col-8 p-fluid margin-top-bottom-5">
              <InputTextarea
                rows={3}
                cols={5}
                disabled={isFieldDisabled}
                value={selectedFormField && selectedFormField.note}
                onChange={(e: any) => {
                  setSelectedFormField({
                    ...selectedFormField!,
                    note: e.target.value,
                  });
                }}
              />
            </div>
            <div className="p-col-4">
              <div>
                {Labels.FIELD_TYPE}
                {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="p-col-8 p-fluid margin-top-bottom-5">
              <Dropdown
                disabled={isFieldDisabled}
                optionLabel="name"
                optionValue="id"
                options={fieldTypeList}
                optionGroupLabel="label"
                optionGroupChildren="list"
                value={selectedFormField && selectedFormField?.fieldType && selectedFormField?.fieldType?.id}
                filter
                filterBy="name"
                onChange={(e) => {
                  getFieldType(e.value);
                  setSelectedFormField({
                    ...selectedFormField!,
                    fieldType: { id: e.value },
                  });
                }}
              />
            </div>
            {(fieldType ? fieldType.componentEnumType !== EnumComponentType.PANEL : selectedFormField?.fieldType?.componentEnumType !== EnumComponentType.PANEL) && (
              <>
                <div className="p-col-4">
                  <div>{Labels.LABEL_PARENT_FIELD}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <Dropdown
                    showClear
                    disabled={isFieldDisabled}
                    optionLabel="name"
                    optionValue="id"
                    options={fieldTypePanelList.filter((field: any) => field.id !== selectedFormField.id)}
                    value={selectedFormField && selectedFormField?.parentField && selectedFormField?.parentField?.id}
                    filter
                    filterBy="name"
                    onChange={(e) => {
                      setSelectedFormField({
                        ...selectedFormField!,
                        parentField: fieldTypePanelList.find((field: any) => field.id === e.value),
                      });
                    }}
                  />
                </div>
              </>
            )}
            <div className="p-col-4">
              <div>
                {Labels.LABEL_DISPLAY_ORDER} {Labels.SPECIAL_CHAR_REQUIRED}
              </div>
            </div>
            <div className="p-col-8 p-fluid margin-top-bottom-5">
              <InputNumber
                disabled={isFieldDisabled}
                showButtons
                buttonLayout="horizontal"
                decrementButtonClassName="p-button-danger"
                incrementButtonClassName="p-button-success"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
                value={selectedFormField && selectedFormField.displayOrder}
                onValueChange={(e: any) => {
                  setSelectedFormField({
                    ...selectedFormField!,
                    displayOrder: e.target.value,
                  });
                }}
              />
            </div>

            <div className="p-col-4">
              <div>{Labels.IS_REQUIRED_FIELD}</div>
            </div>
            <div className="p-col-8 p-fluid margin-top-bottom-5">
              <InputSwitch
                disabled={isFieldDisabled}
                checked={selectedFormField && selectedFormField.isRequired ? selectedFormField.isRequired : false}
                onChange={(e: any) => {
                  setSelectedFormField({
                    ...selectedFormField!,
                    isRequired: e.target.value,
                  });
                }}
              />
            </div>
            <div className="p-col-4">
              <div>{Labels.IS_RELEVANT_FIELD}</div>
            </div>
            <div className="p-col-8 p-fluid margin-top-bottom-5">
              <InputSwitch
                disabled={isFieldDisabled}
                checked={selectedFormField && selectedFormField.isRelevant ? selectedFormField.isRelevant : false}
                onChange={(e: any) => {
                  setSelectedFormField({
                    ...selectedFormField!,
                    isRelevant: e.target.value,
                  });
                }}
              />
            </div>
            <div className="p-col-4">
              <div>{Labels.IS_MULTISELECT_FIELD}</div>
            </div>
            <div className="p-col-8 p-fluid margin-top-bottom-5">
              <InputSwitch
                disabled={isFieldDisabled}
                checked={selectedFormField && selectedFormField.isMultiselect ? selectedFormField.isMultiselect : false}
                onChange={(e: any) => {
                  setSelectedFormField({
                    ...selectedFormField!,
                    isMultiselect: e.target.value,
                  });
                }}
              />
            </div>
            <div className="p-col-4">
              <div>{Labels.IS_DISABLED_FIELD}</div>
            </div>
            <div className="p-col-8 p-fluid margin-top-bottom-5">
              <InputSwitch
                disabled={isFieldDisabled}
                checked={selectedFormField && selectedFormField.isDisabled ? selectedFormField.isDisabled : false}
                onChange={(e: any) => {
                  setSelectedFormField({
                    ...selectedFormField!,
                    isDisabled: e.target.value,
                  });
                }}
              />
            </div>
            <div className="p-col-4">
              <div>{Labels.EQUATION_SHOW_LABEL_ON_FORM}</div>
            </div>
            <div className="p-col-8 p-fluid margin-top-bottom-5">
              <InputTextarea
                rows={3}
                cols={5}
                disabled={isFieldDisabled}
                value={selectedFormField && selectedFormField.equationShowLabelOnForm}
                onChange={(e: any) => {
                  setSelectedFormField({
                    ...selectedFormField!,
                    equationShowLabelOnForm: e.target.value,
                  });
                }}
              />
            </div>
            <div className="p-col-4">
              <div>{Labels.EQUATION_SHOW_FIELD_ON_FORM}</div>
            </div>
            <div className="p-col-8 p-fluid margin-top-bottom-5">
              <InputTextarea
                rows={3}
                cols={5}
                disabled={isFieldDisabled}
                value={selectedFormField && selectedFormField.equationShowFieldOnForm}
                onChange={(e: any) => {
                  setSelectedFormField({
                    ...selectedFormField!,
                    equationShowFieldOnForm: e.target.value,
                  });
                }}
              />
            </div>
            <div className="p-col-4">
              <div>{Labels.EQUATION_CALCULATED_VALUE}</div>
            </div>
            <div className="p-col-8 p-fluid margin-top-bottom-5">
              <InputTextarea
                rows={3}
                cols={5}
                disabled={isFieldDisabled}
                value={selectedFormField && selectedFormField.equationCalculatedValue}
                onChange={(e: any) => {
                  setSelectedFormField({
                    ...selectedFormField!,
                    equationCalculatedValue: e.target.value,
                  });
                }}
              />
            </div>
            <div className="p-col-4">
              <div>{Labels.HTML_INFO}</div>
            </div>
            <div className="p-col-8 p-fluid margin-top-bottom-5">
              <InputTextarea
                rows={3}
                cols={5}
                disabled={isFieldDisabled}
                value={selectedFormField && selectedFormField.htmlInfo}
                onChange={(e: any) => {
                  setSelectedFormField({
                    ...selectedFormField!,
                    htmlInfo: e.target.value,
                  });
                }}
              />
            </div>
            {(fieldType?.componentEnumType === EnumComponentType.TEXT || fieldType?.componentEnumType === EnumComponentType.LONG_TEXT) && (
              <>
                <div className="p-col-4">
                  <div>{Labels.INITIAL_VALUE}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputTextarea
                    rows={3}
                    cols={5}
                    disabled={isFieldDisabled}
                    value={selectedFormField && selectedFormField.initialValueString}
                    onChange={(e: any) => {
                      setSelectedFormField({
                        ...selectedFormField!,
                        initialValueString: e.target.value,
                      });
                    }}
                  />
                </div>
              </>
            )}
            {fieldType?.componentEnumType === EnumComponentType.BOOLEAN && (
              <>
                <div className="p-col-4">
                  <div>{Labels.INITIAL_VALUE}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputSwitch
                    disabled={isFieldDisabled}
                    checked={selectedFormField && selectedFormField.initialValueBoolean ? selectedFormField.initialValueBoolean : false}
                    onChange={(e: any) => {
                      setSelectedFormField({
                        ...selectedFormField!,
                        initialValueBoolean: e.target.value,
                      });
                    }}
                  />
                </div>
              </>
            )}
            {(fieldType?.componentEnumType === EnumComponentType.NUMBER_DECIMAL || fieldType?.componentEnumType === EnumComponentType.NUMBER_INTEGER) && (
              <>
                <div className="p-col-4">
                  <div>{Labels.INITIAL_VALUE}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputNumber
                    disabled={isFieldDisabled}
                    value={selectedFormField && selectedFormField.initialValueNumber}
                    onValueChange={(e: any) => {
                      setSelectedFormField({
                        ...selectedFormField!,
                        initialValueNumber: e.target.value,
                      });
                    }}
                  />
                </div>
              </>
            )}
            {fieldType?.componentEnumType === EnumComponentType.SET && (
              <>
                <div className="p-col-4">
                  <div>{Labels.INITIAL_VALUE}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <Dropdown
                    disabled={isFieldDisabled}
                    optionLabel="name"
                    optionValue="id"
                    options={enumeratorItemList}
                    value={selectedFormField && selectedFormField.initialValueEnumeratorItem}
                    onChange={(e) => {
                      setSelectedFormField({
                        ...selectedFormField!,
                        initialValueEnumeratorItem: e.value,
                      });
                    }}
                  />
                </div>
              </>
            )}
            {fieldType?.componentEnumType === EnumComponentType.DATE_TIME && (
              <>
                <div className="p-col-4">
                  <div>{Labels.INITIAL_VALUE}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <Calendar
                    disabled={isFieldDisabled}
                    monthNavigator
                    yearNavigator
                    yearRange="1900:2030"
                    dateFormat={CALENDAR_DATE_FORMAT}
                    showButtonBar
                    readOnlyInput
                    value={selectedFormField && selectedFormField.initialValueTimestamp ? new Date(selectedFormField.initialValueTimestamp) : undefined}
                    onChange={(e) => {
                      setSelectedFormField({
                        ...selectedFormField!,
                        initialValueTimestamp: e.value ? e.value : null,
                      });
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <Toolbar left={leftContents} right={rightContents}></Toolbar>
      </Panel>
    </div>
  );
}
