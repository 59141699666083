import React from "react";
import { Calendar } from "primereact/calendar";
import { CALENDAR_DATE_FORMAT, DATE_TIME_FORMAT_3 } from "../../../../infrastructure/system/Utils";
import FieldReadDto from "../../../../model/FieldReadDto";
import moment from "moment";
import { addSrLocal } from "../../../../infrastructure/system/Utils";
import { useContext } from "react";
import { AppContext } from "../../../../Store";
import EnumLanguage from "../../../../infrastructure/system/EnumLanguage";

interface DatetimeType {
  field: FieldReadDto;
  onChange?: any;
  value: any;
  isDisabled?: boolean;
  onBlur?: any;
  errorMessage?: any;
  validate?: any;
}

export default function Datetime(props: DatetimeType) {
  const { field, onChange, value, onBlur, errorMessage, isDisabled, validate } = props;
  const { locale } = useContext(AppContext);

  addSrLocal();

  return (
    <div>
      <Calendar
        disabled={isDisabled}
        monthNavigator
        yearNavigator
        yearRange="1900:2030"
        key={field.code}
        dateFormat={field.fieldType.format ? field.fieldType.format : CALENDAR_DATE_FORMAT}
        showButtonBar
        readOnlyInput
        locale={locale === EnumLanguage.CYRILLIC ? "sr-cyrilic" : "sr"}
        value={value ? new Date(value) : undefined}
        onBlur={(e) => {
          if (window.location.href.includes("/form")) {
            validate(field, value);
          }
        }}
        onChange={(e) => {
          onChange({ field: field, value: e.value ? moment(e.value.toString()).format(DATE_TIME_FORMAT_3) : null });
          if (onBlur) onBlur({ field: field, value: e.value ? moment(e.value.toString()).format(DATE_TIME_FORMAT_3) : null });
        }}
        className={errorMessage && errorMessage !== "" ? "p-invalid" : field.isDisabled === true ? "p-disabled-component" : ""}
        aria-describedby="data-time-required-help"
      />
      <small id="data-time-required-help" className="p-error">
        {errorMessage && errorMessage !== "" ? errorMessage : ""}
      </small>
    </div>
  );
}
