import React from "react";
import { Dropdown } from "primereact/dropdown";
import FieldReadDto from "../../../../model/FieldReadDto";
import CodebookLogical, { CodebookLogicalType } from "./CodebookLogical";
import LabelsContext from "../../../../infrastructure/system/LabelsContext";
interface CodebookType {
  field: FieldReadDto;
  onChange?: any;
  value: any;
  isDisabled?: boolean;
  onBlur?: any;
  errorMessage?: any;
  isSearch?: boolean;
  codebookLogicalControlValidation?: any;
  setFieldCodebookListValidation?: any;
  validate?: any;
}

export default function Codebook(props: CodebookType) {
  const { field, onChange, value, errorMessage, isDisabled, codebookLogicalControlValidation, setFieldCodebookListValidation, validate } = props;
  const { codebookOptionList }: CodebookLogicalType = CodebookLogical({ id: field.id, code: field?.fieldType?.codebookEnumType, codebookLogicalControlValidation, setFieldCodebookListValidation });
  const { Labels } = LabelsContext();
  return (
    <div>
      <Dropdown
        filter
        showClear
        emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
        disabled={isDisabled}
        optionLabel="dropdownValue"
        optionValue="code"
        options={codebookOptionList}
        filterPlaceholder={isDisabled ? Labels.SPACE : Labels.PLACEHOLDER_INSERT_CODE_OR_NAME}
        value={value ? value : null}
        onChange={(e) => {
          onChange({ field: field, value: e.value });
        }}
        onBlur={(e) => {
          if (window.location.href.includes("/form")) {
            validate(field, value);
          }
        }}
        className={errorMessage && errorMessage !== "" ? "p-invalid" : field.isDisabled === true ? "p-disabled-component" : ""}
        aria-describedby="set-required-help"
      />
      <small id="set-required-help" className="p-error">
        {errorMessage && errorMessage !== "" ? errorMessage : ""}
      </small>
    </div>
  );
}
