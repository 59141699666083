import React from "react";
import { Dropdown } from "primereact/dropdown";
import FieldReadDto from "../../../../model/FieldReadDto";

interface SetType {
  field: FieldReadDto;
  onChange?: any;
  value: any;
  isDisabled?: boolean;
  onBlur?: any;
  errorMessage?: any;
  validate?: any;
}

export default function Set(props: SetType) {
  const { field, onChange, value, errorMessage, isDisabled, validate } = props;

  return (
    <div>
      <Dropdown
        disabled={isDisabled}
        optionLabel="name"
        optionValue="id"
        options={field.fieldType.fieldTypeEnumeratorItems}
        value={value ? value : null}
        onChange={(e) => {
          onChange({ field: field, value: e.value });
        }}
        onBlur={(e) => {
          if (window.location.href.includes("/form")) {
            validate(field, value);
          }
        }}
        className={errorMessage && errorMessage !== "" ? "p-invalid" : field.isDisabled === true ? "p-disabled-component" : ""}
        aria-describedby="set-required-help"
      />
      <small id="set-required-help" className="p-error">
        {errorMessage && errorMessage !== "" ? errorMessage : ""}
      </small>
    </div>
  );
}
