/*eslint-disable no-eval */
import axios from "axios";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { CodebookController, CodebookControllerType } from "../../controllers/codebook/CodebookController";
import { CommentController, CommentControllerType } from "../../controllers/comment/CommentController";
import { FileController, FileControllerType } from "../../controllers/file/FileController";
import { FormController, FormControllerType } from "../../controllers/form/FormController";
import { RoleService, RoleServiceType } from "../../controllers/role/RoleService";
import CodebookLogicalControlOperator from "../../infrastructure/system/CodebookLogicalControlOperator";
import Endpoint from "../../infrastructure/system/Endpoint";
import EnumComponentType from "../../infrastructure/system/EnumComponentType";
import EntityOperation from "../../infrastructure/system/EnumEntityOperation";
import EnumFormStatus from "../../infrastructure/system/EnumFormStatus";
import LabelsContext from "../../infrastructure/system/LabelsContext";
import MessageType from "../../infrastructure/system/MessageType";
import { axiosConfig, COMMENT_PANEL_WIDTH, DATE_TIME_FORMAT_4, FORM_CCF, handleAxiosCallError, MAX_FILE_UPLOAD, useEffectOnce } from "../../infrastructure/system/Utils";
import CodebookLogicalControlDto from "../../model/CodebookLogicalControlDto";
import FieldReadDto from "../../model/FieldReadDto";
import FieldValueDto from "../../model/FieldValueDto";
import FormFilledReadDto from "../../model/FormFilledReadDto";
import FormReadDto from "../../model/FormReadDto";
import { AppContext } from "../../Store";

interface FormLogicalType {
  fieldList: Array<FieldReadDto>;
  form: FormReadDto | undefined;
  save: any;
  saveAsDraft: any;
  clearForm: any;
  onChange: any;
  onBlur: any;
  errorMessages: any;
  fileList: any;
  fetchFileList: any;
  uploadHandler: any;
  deleteFile: any;
  formFilledId: any;
  fieldValuesList: any;
  panelHeader: any;
  commentList: any;
  deleteFormFilled: any;
  formOperation: any;
  comment: any;
  setComment: any;
  roleList: any;
  createComment: any;
  formFilled: any;
  onApprove: any;
  breadCrumbItems: any;
  formCode: any;
  codebookLogicalControlValidation: any;
  setFieldCodebookListValidation: any;
  locale: any;
  labelList: any;
  searchFormType: any;
  searchFormStatus: any;
  validate: any;
  semanticValidationFieldList: any;
  mainPanelDiv: any;
  showCommentPanel: any;
  setShowCommentPanel: any;
  handleScreenSizeChange: any;
  stopChange: any;
  setStopChange: any;
  showField: any;
}
interface FormFilledParams {
  entityOperation: string;
  searchFormStatus: any;
  searchFormType: any;
}
const hiddenFieldList: any = [];

export default function FormLogical(): FormLogicalType {
  const { showMessage, authData, setShowBlockUI, locale, labelList } = useContext(AppContext);
  const { isBed, isEd }: RoleServiceType = RoleService();
  const { Labels } = LabelsContext();
  const [fieldOriginalList, setFieldOriginalList] = useState<Array<FieldReadDto>>([]);
  const [fieldList, setFieldList] = useState<Array<FieldReadDto>>([]);
  const [semanticValidationFieldList, setSemanticValidationFieldList] = useState<Array<FieldReadDto>>([]);
  const [form, setForm] = useState<FormReadDto>();
  const [errorMessages, setErrorMessages] = useState<any>({});
  const history = useHistory();
  const { formCode, formFilledID }: any = useParams();
  const [formFilledId, setFormFilledId] = useState(formFilledID);
  const [fileList, setFileList] = useState([]);
  const location = useLocation<FormFilledParams>();
  const { roleList } = authData!.currentUser;
  const [formOperation, setFormOperation] = useState(location.state ? location.state.entityOperation : isEd() || isBed() ? EntityOperation.READ : EntityOperation.UPDATE);
  const [searchFormStatus] = useState(location.state && location.state.searchFormStatus !== null && location.state.searchFormStatus !== undefined ? location.state.searchFormStatus : null);
  const [searchFormType] = useState(location.state && location.state.searchFormType !== null && location.state.searchFormType !== undefined ? location.state.searchFormType : undefined);
  const [fieldValuesList, setFieldValuesList] = useState<any>({});
  const [commentList, setCommentList] = useState([]);
  const [formFilled, setFormFilled] = useState<FormFilledReadDto>();
  const [fieldCodebookList, setFieldCodebookList] = useState<any>({});
  const [comment, setComment] = useState("");
  const [codebookLogicalControlList, setCodebookLogicalControlList] = useState([]);
  const [codebookLogicalControlValidation, setCodebookLogicalControlValidation] = useState<any>({});
  const mainPanelDiv: any = useRef();
  const [showCommentPanel, setShowCommentPanel] = useState(true);
  const [stopChange, setStopChange] = useState(true);
  const [breadCrumbItems, setBreadCrumbItems] = useState<any>([
    {
      label: Labels.FORM_LIST,
      command: () => {
        history.push({
          pathname: `/registry`,
          state: { searchFormStatus: searchFormStatus, searchFormType: searchFormType },
        });
      },
    },
  ]);

  const {
    axiosSaveUnfinishedForm,
    axiosGetForm,
    axiosGetFormFieldsList,
    axiosUpdateFormFilled,
    axiosGetFormFilled,
    axiosGetFieldFilledList,
    axiosDeleteFormFilled,
    axiosCreateFormFilled,
  }: FormControllerType = FormController();

  const { axiosCreateFileList, axiosGetFileList, axiosDeleteFile }: FileControllerType = FileController();

  const { axiosCreateComment, axiosGetCommentList }: CommentControllerType = CommentController();

  const { axiosGetCodebookLogicalControlList }: CodebookControllerType = CodebookController();

  const parseChildren = (fieldList: any) => {
    const root: Array<FieldReadDto> = [];
    const map: any = {};
    fieldList.forEach((node: FieldReadDto) => {
      if (!node.parentField) return root.push(node);
      let parentIndex = map[node.parentField.id];
      if (typeof parentIndex !== "number") {
        parentIndex = fieldList.findIndex((el: any) => el.id === node.parentField.id);
        map[node.parentField.id] = parentIndex;
      }
      if (!fieldList[parentIndex].children) {
        return (fieldList[parentIndex].children = [node]);
      }
      fieldList[parentIndex].children.push(node);
    });

    return root;
  };

  const fetchForm = () => {
    setShowBlockUI(true);
    const requestFieldList = axiosGetFormFieldsList(formCode);
    const requestForm = axiosGetForm(formCode);
    const requestCodebookLogicalControl = axiosGetCodebookLogicalControlList();

    axios
      .all([requestFieldList, requestForm, requestCodebookLogicalControl])
      .then(
        axios.spread((responseFieldList, responseForm, responseCodebookLogicalControl) => {
          setFieldList(parseChildren(responseFieldList.data.data));
          setFieldOriginalList(responseFieldList.data.data);
          setSemanticValidationFieldList(responseFieldList.data.data);
          setForm(responseForm.data.data);
          setCodebookLogicalControlList(responseCodebookLogicalControl.data.data);
          setBreadCrumbItems([
            ...breadCrumbItems,
            {
              label: Labels.BUTTON_NEW_FORM,
            },
          ]);
          setShowBlockUI(false);
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const fetchFormFilled = () => {
    setShowBlockUI(true);
    let fieldValues: any = {};
    const requestFormFilled = axiosGetFormFilled(formFilledID);
    const requestFieldList = axiosGetFormFieldsList(formCode);
    const requestFieldFilledList = axiosGetFieldFilledList(formFilledID);
    const requestFileList = axiosGetFileList(formFilledID);
    const requestCommentList = axiosGetCommentList(formFilledID);
    axios
      .all([requestFormFilled, requestFieldList, requestFieldFilledList, requestFileList, requestCommentList])
      .then(
        axios.spread((responseFormFilled, responseFieldList, responseFieldFilledList, responseFileList, responseCommentList) => {
          setFileList(responseFileList.data.data);
          setCommentList(responseCommentList.data.data);
          if (responseFormFilled.data.data.formStatusEnum === EnumFormStatus.SUBMITTED || (responseFormFilled.data.data.formStatusEnum === EnumFormStatus.APPROVED && formCode === FORM_CCF)) {
            if (location.state.entityOperation === EntityOperation.READ) {
              setFormOperation(EntityOperation.READ);
            } else {
              setFormOperation(EntityOperation.EDUPDATE);
            }
          }
          setFormFilled(responseFormFilled.data.data);
          setFieldList(parseChildren(responseFieldList.data.data));
          setSemanticValidationFieldList(responseFieldList.data.data);
          setFieldOriginalList(responseFieldList.data.data);
          responseFieldFilledList.data.data.forEach((fieldFilled: any) => {
            if (fieldFilled.field.fieldType?.componentEnumType === EnumComponentType.TEXT || fieldFilled.field.fieldType?.componentEnumType === EnumComponentType.LONG_TEXT) {
              fieldValues[fieldFilled.field.id] = fieldFilled.valueString;
            } else if (fieldFilled.field.fieldType?.componentEnumType === EnumComponentType.NUMBER_DECIMAL || fieldFilled.field.fieldType?.componentEnumType === EnumComponentType.NUMBER_INTEGER) {
              fieldValues[fieldFilled.field.id] = fieldFilled.valueNumber;
            } else if (fieldFilled.field.fieldType?.componentEnumType === EnumComponentType.DATE_TIME) {
              fieldValues[fieldFilled.field.id] = moment(fieldFilled.valueTimestamp).format(DATE_TIME_FORMAT_4);
            } else if (fieldFilled.field.fieldType?.componentEnumType === EnumComponentType.BOOLEAN) {
              fieldValues[fieldFilled.field.id] = fieldFilled.valueBoolean;
            } else if (fieldFilled.field.fieldType?.componentEnumType === EnumComponentType.SET) {
              fieldValues[fieldFilled.field.id] = fieldFilled.valueEnumeratorItem.id;
            } else if (fieldFilled.field.fieldType?.componentEnumType === EnumComponentType.CODEBOOK || fieldFilled.field.fieldType?.componentEnumType === EnumComponentType.CODEBOOK_AUTOCOMPLETE) {
              fieldValues[fieldFilled.field.id] = fieldFilled.valueCodebookCode;
            }
          });
          setFieldValuesList(fieldValues);
          if (breadCrumbItems.length < 2) {
            setBreadCrumbItems([
              ...breadCrumbItems,
              {
                label:
                  formOperation === EntityOperation.UPDATE
                    ? `${Labels.LABEL_UPDATE_FORM} ${responseFormFilled.data.data.form.name}`
                    : formOperation === EntityOperation.READ
                    ? `${Labels.LABEL_DETAILS_FORM} ${responseFormFilled.data.data.form.name}`
                    : formOperation === EntityOperation.DELETE
                    ? `${Labels.LABEL_DELETE_FORM} ${responseFormFilled.data.data.form.name}`
                    : `${Labels.HEADER_FORM_REVISION} ${responseFormFilled.data.data.form.name}`,
              },
            ]);
          }
          setShowBlockUI(false);
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const showField = (field: FieldReadDto) => {
    let value = true;
    if (!field.equationShowFieldOnForm) {
      return value;
    } else if (fieldOriginalList.length !== 0) {
      let equationShowOnForm = field.equationShowFieldOnForm;
      let equationShowOnFormList = equationShowOnForm.split(" ");
      equationShowOnFormList.forEach((elem: string) => {
        if (elem.includes("$(")) {
          elem = elem.split("$(")[1].split(")")[0];
          let fieldFromEquation = fieldOriginalList.filter((x: FieldReadDto) => x.code === elem)[0];
          value = fieldFromEquation && fieldValuesList[fieldFromEquation.id] !== "" && fieldValuesList[fieldFromEquation.id] ? fieldValuesList[fieldFromEquation.id] : "";
          if (fieldFromEquation.fieldType.componentEnumType === EnumComponentType.BOOLEAN && typeof value === "boolean") {
            equationShowOnForm = equationShowOnForm.replace("$(" + elem + ")", value.toString());
          } else if (fieldFromEquation.fieldType.componentEnumType === EnumComponentType.SET) {
            fieldFromEquation.fieldType.fieldTypeEnumeratorItems.forEach((enumStavka: any) => {
              if (enumStavka.id === value) {
                equationShowOnForm = equationShowOnForm.replace("$(" + elem + ")", "'" + enumStavka.code + "'");
              }
            });
          } else if (fieldFromEquation.fieldType.componentEnumType === EnumComponentType.CODEBOOK) {
            equationShowOnForm = equationShowOnForm.replace("$(" + elem + ")", "'" + value + "'");
          } else if (fieldFromEquation.fieldType.componentEnumType === EnumComponentType.CODEBOOK_AUTOCOMPLETE) {
            equationShowOnForm = equationShowOnForm.replace("$(" + elem + ")", "'" + value + "'");
          } else if (fieldFromEquation.fieldType.componentEnumType === EnumComponentType.DATE_TIME) {
            equationShowOnForm = equationShowOnForm.replace("$(" + elem + ")", "'" + value + "'");
          } else if (fieldFromEquation.fieldType.componentEnumType === EnumComponentType.LABEL) {
            equationShowOnForm = equationShowOnForm.replace("$(" + elem + ")", "'" + value + "'");
          }
        }
      });
      if (!equationShowOnForm.includes("$")) {
        value = eval(equationShowOnForm);
      }
      if (!value) {
        if (hiddenFieldList.indexOf(field.id) === -1) hiddenFieldList.push(field.id);
      } else {
        const index = hiddenFieldList.indexOf(field.id);
        if (index > -1) {
          hiddenFieldList.splice(index, 1);
        }
      }
      return value;
    }
  };

  useEffectOnce(() => {
    if (formFilledID !== undefined && formFilledID !== null) {
      fetchFormFilled();
    } else {
      fetchForm();
    }
  });

  const onApprove = () => {
    let approvedFormFilled = { id: formFilledID, formStatus: EnumFormStatus.APPROVED };
    axiosUpdateFormFilled(formFilledID, approvedFormFilled)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.MESSAGES_APPROVE_DOCUMENT_SUCCESS, "");
        history.push({ pathname: "/registry" });
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const createComment = () => {
    if (comment.trim() !== "") {
      let commentCreateDto = { formFilled: { id: formFilledID }, text: comment };
      axiosCreateComment(commentCreateDto)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.MESSAGES_FOR_REVISION_DOCUMENT_SUCCESS, "");
          history.push({ pathname: "/registry" });
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    } else {
      showMessage(MessageType.ERROR, Labels.COMMENT_IS_REQUIRED, "");
    }
  };

  const fetchFileList = () => {
    axiosGetFileList(formFilledId)
      .then((response: any) => {
        setFileList(response.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };
  const uploadHandler = (e: any) => {
    e.files.forEach((file: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (formFilledId !== null && formFilledId !== undefined) {
          if (file.size > MAX_FILE_UPLOAD) {
            showMessage(MessageType.ERROR, file.name + Labels.DASH + Labels.MESSAGES_INVALID_FILE_SIZE, "");
            return;
          }
          axiosCreateFileList({
            formFilled: { id: formFilledId },
            name: file.name,
            type: file.type,
            attachment: reader.result?.toString().substr(reader.result?.toString().indexOf(",") + 1),
          })
            .then((res: any) => {
              fetchFileList();
            })
            .catch((err: any) => {
              handleAxiosCallError(showMessage, err);
            });
        }
      };
    });
  };

  const deleteFile = (fileID: number) => {
    axiosDeleteFile(fileID)
      .then((response: any) => {
        fetchFileList();
        showMessage(MessageType.SUCCESS, Labels.MESSAGES_DELETE_DOCUMENT_SUCCESS, "");
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  useEffect(() => {
    // changeCalculatedFields(values);
    changeCalculatedFields(fieldValuesList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldList]);

  const onChange = (fieldValue: FieldValueDto) => {
    if (fieldValue.field.fieldType.componentEnumType === EnumComponentType.CODEBOOK || fieldValue.field.fieldType.componentEnumType === EnumComponentType.SET) {
      onBlur(fieldValue);
    }
    validate(fieldValue.field, fieldValue.value);
    const newValue = {
      ...fieldValuesList,
      [fieldValue.field.id]: fieldValue.value,
    };
    changeCalculatedFields(newValue);
    validationCodebook(fieldValue);
    setFieldValuesList(newValue);
  };

  const validationCodebook = (fieldValue: FieldValueDto) => {
    const { field, value } = fieldValue;
    if (field?.fieldType?.codebookEnumType !== null || field?.fieldType?.codebookEnumType !== undefined) {
      const { codebookEnumType }: any = field.fieldType;

      const codebookLogical = codebookLogicalControlList.filter((codebook: CodebookLogicalControlDto) =>
        [codebook.primaryCodebookTypeEnum, codebook.secondaryCodebookTypeEnum].includes(codebookEnumType)
      );

      const data: any = {};

      codebookLogical.forEach((c: CodebookLogicalControlDto) => {
        if (c.primaryCodebookTypeEnum === codebookEnumType) {
          if (
            (c.primaryEnumOperator === CodebookLogicalControlOperator.EQUAL && c.primaryFirstValue.code === value) ||
            (c.primaryEnumOperator === CodebookLogicalControlOperator.NOT_EQUAL && c.primaryFirstValue.code !== value)
          ) {
            const fields = fieldOriginalList.filter((f) => f.fieldType.codebookEnumType === c.secondaryCodebookTypeEnum);
            fields.forEach((fv: any) => {
              const id = fv.id;
              data[id] = [
                ...(data[id] ? data[id] : []),
                {
                  operator: c.secondaryEnumOperator,
                  firstValue: c.secondaryFirstValue?.code,
                  secondValue: c.secondarySecondValue?.code,
                },
              ];
            });
          } else if (c.primaryEnumOperator === CodebookLogicalControlOperator.RANGE) {
            const codebookData = fieldCodebookList[field.id];
            if (codebookData.length > 0) {
              const codebookFilter =
                codebookData && codebookData.length > 0 && codebookData[0].code.toString() >= c.primaryFirstValue.code && codebookData[0].code.toString() <= c.primarySecondValue.code;
              if (codebookFilter) {
                const fields = fieldOriginalList.filter((f) => f.fieldType.codebookEnumType === c.secondaryCodebookTypeEnum);
                fields.forEach((fv: any) => {
                  const id = fv.id;
                  data[id] = [
                    ...(data[id] ? data[id] : []),
                    {
                      operator: c.secondaryEnumOperator,
                      firstValue: c.secondaryFirstValue?.code,
                      secondValue: c.secondarySecondValue?.code,
                    },
                  ];
                });
              }
            }
          }
        } else if (c.secondaryCodebookTypeEnum === codebookEnumType) {
          if (
            (c.secondaryEnumOperator === CodebookLogicalControlOperator.EQUAL && c.secondaryFirstValue.code === value) ||
            (c.secondaryEnumOperator === CodebookLogicalControlOperator.NOT_EQUAL && c.secondaryFirstValue.code !== value)
          ) {
            const fields = fieldOriginalList.filter((f) => f.fieldType.codebookEnumType === c.primaryCodebookTypeEnum);
            fields.forEach((fv: any) => {
              const id = fv.id;
              data[id] = [
                ...(data[id] ? data[id] : []),
                {
                  operator: c.primaryEnumOperator,
                  firstValue: c.primaryFirstValue?.code,
                  secondValue: c.primarySecondValue?.code,
                },
              ];
            });
          } else if (c.secondaryEnumOperator === CodebookLogicalControlOperator.RANGE) {
            const codebookData = fieldCodebookList[field.id];
            if (codebookData.length > 0) {
              const codebookFilter =
                codebookData && codebookData.length > 0 && codebookData[0].code.toString() >= c.primaryFirstValue.code && codebookData[0].code.toString() <= c.primarySecondValue.code;
              if (codebookFilter) {
                const fields = fieldOriginalList.filter((f) => f.fieldType.codebookEnumType === c.primaryCodebookTypeEnum);
                fields.forEach((fv: any) => {
                  const id = fv.id;
                  data[id] = [
                    ...(data[id] ? data[id] : []),
                    {
                      operator: c.primaryEnumOperator,
                      firstValue: c.primaryFirstValue?.code,
                      secondValue: c.primarySecondValue?.code,
                    },
                  ];
                });
              }
            }
          }
        }
      });
      setCodebookLogicalControlValidation(data);
    }
  };

  const changeCalculatedFields = (newValue: any) => {
    let fieldWithCalculatedValue: Array<any> = [];
    fieldList.forEach((field: FieldReadDto) => {
      if (field.children && field.children.length) {
        field.children.forEach((child: FieldReadDto) => {
          if (child.equationCalculatedValue && child.equationCalculatedValue !== "") fieldWithCalculatedValue.push(child);
        });
      } else {
        if (field.equationCalculatedValue && field.equationCalculatedValue !== "") {
          fieldWithCalculatedValue.push(field);
        }
      }
    });
    if (fieldWithCalculatedValue.length > 0) {
      fieldWithCalculatedValue.forEach((field: FieldReadDto) => {
        const value = calculatedInitFields(field, newValue);
        newValue[field.id] = value;
      });
    }
  };

  const calculatedInitFields = (field: FieldReadDto, newValue: any) => {
    let value = undefined;
    if (field.equationCalculatedValue !== null && field.equationCalculatedValue !== "") {
      let equationCalculatedValue = field.equationCalculatedValue;
      const equationCalculatedValueList = equationCalculatedValue.split(" ");
      equationCalculatedValueList.forEach((elem: string) => {
        if (elem.includes("$P(")) {
          elem = elem.split("$P(")[1].split(")")[0];
          const fieldCodeId = fieldOriginalList.find((x) => x.code === elem)?.id;
          value = fieldCodeId ? newValue[fieldCodeId] : undefined;
          if (value && typeof value != "number") {
            while (value.includes("\n")) {
              value = value.replace("\n", "\\n");
            }
            equationCalculatedValue = equationCalculatedValue.replace("$P(" + elem + ")", "'" + value + "'");
            if (field.fieldType?.componentEnumType === EnumComponentType.DATE_TIME && field.fieldType?.format) {
              value = moment(value.toString()).format(DATE_TIME_FORMAT_4);
            }
          }
        }
      });
      if (!equationCalculatedValue.includes("$P")) {
        value = eval(equationCalculatedValue);
      }
    }
    if (value && field.fieldType?.componentEnumType === EnumComponentType.DATE_TIME && field.fieldType?.format) {
      value = moment(value.toString()).format(DATE_TIME_FORMAT_4);
    }
    return value;
  };

  const validate = async (field: FieldReadDto, value: any, finallyErrorMessages?: any) => {
    if (field && showField(field) && field.parentField !== null && showField(field.parentField) === true) {
      setErrorMessages({
        ...errorMessages,
        [field.id]: "",
      });
      if (field.isRequired) {
        if (value === undefined || value === "" || value === null) {
          setErrorMessages({
            ...errorMessages,
            [field.id]: Labels.FIELD_IS_REQUIRED,
          });
          if (finallyErrorMessages !== undefined) finallyErrorMessages[field.id] = Labels.FIELD_IS_REQUIRED;
        }
      }
      validateMinMaxValues(field, value, finallyErrorMessages);
    } else {
      delete fieldValuesList[field.id];
    }
  };

  const validateMinMaxValues = (field: any, value: any, finallyErrorMessages?: any) => {
    const type = field.fieldType?.componentEnumType;
    switch (type) {
      case EnumComponentType.NUMBER_DECIMAL:
      case EnumComponentType.NUMBER_INTEGER:
        if (value !== null && value !== "" && value !== undefined) {
          if (field.fieldType?.min !== null && field.fieldType?.max !== null && (value < field.fieldType?.min! || value > field.fieldType?.max!)) {
            setErrorMessages({
              ...errorMessages,
              [field.id]: `${Labels.VALUE_SHOULD_BE_BETWEEN}${field.fieldType?.min}-${field.fieldType?.max}`,
            });
            if (finallyErrorMessages !== undefined) finallyErrorMessages[field.id] = `${Labels.VALUE_SHOULD_BE_BETWEEN}${field.fieldType.min}-${field.fieldType.max}`;
          } else if (field.fieldType?.min !== null && field.fieldType?.min! > value) {
            setErrorMessages({
              ...errorMessages,
              [field.id]: `${Labels.VALUE_SHOULD_BE_GREATER_THEN} ${field.fieldType?.min}`,
            });
            if (finallyErrorMessages !== undefined) finallyErrorMessages[field.id] = `${Labels.VALUE_SHOULD_BE_LESS_THEN} ${field.fieldType.min}`;
          } else if (field.fieldType?.max !== null && field.fieldType?.max! < value) {
            setErrorMessages({
              ...errorMessages,
              [field.id]: `${Labels.VALUE_SHOULD_BE_LESS_THEN} ${field.fieldType?.max}`,
            });
            if (finallyErrorMessages !== undefined) finallyErrorMessages[field.id] = `${Labels.VALUE_SHOULD_BE_GREATER_THEN} ${field.fieldType?.max}`;
          }
        }
        break;
      case EnumComponentType.TEXT:
      case EnumComponentType.LONG_TEXT:
        if (value !== null && value !== "" && value !== undefined) {
          if (field.fieldType?.min !== null && field.fieldType?.max !== null && field.fieldType?.min === field.fieldType?.max && value.length !== field.fieldType?.min!) {
            setErrorMessages({
              ...errorMessages,
              [field.id]: `${Labels.FIELD_SHOULD_HAVE} ${field.fieldType?.min} ${Labels.CHARACTERS}`,
            });
            if (finallyErrorMessages !== undefined) finallyErrorMessages[field.id] = `${Labels.FIELD_SHOULD_HAVE} ${field.fieldType?.min} ${Labels.CHARACTERS}`;
          } else if (field.fieldType?.min !== null && field.fieldType?.max !== null && (value.length < field.fieldType?.min! || value.length > field.fieldType?.max!)) {
            setErrorMessages({
              ...errorMessages,
              [field.id]: `${Labels.IT_SHOULD_BE_BETWEEN}${field.fieldType?.min}-${field.fieldType?.max} ${Labels.CHARACTERS}`,
            });
            if (finallyErrorMessages !== undefined) finallyErrorMessages[field.id] = `${Labels.IT_SHOULD_BE_BETWEEN}${field.fieldType?.min}-${field.fieldType?.max} ${Labels.CHARACTERS}`;
          } else if (field.fieldType?.min !== null && value !== null && value !== "" && value !== undefined && field.fieldType?.min! > value.length) {
            setErrorMessages({
              ...errorMessages,
              [field.id]: `${Labels.IT_SHOULD_BE_LESS_THEN} ${field.fieldType?.min} ${Labels.CHARACTERS}`,
            });
            if (finallyErrorMessages !== undefined) finallyErrorMessages[field.id] = `${Labels.IT_SHOULD_BE_LESS_THEN} ${field.fieldType?.min} ${Labels.CHARACTERS}`;
          } else if (field.fieldType?.max !== null && value !== null && value !== "" && value !== undefined && field.fieldType?.max! < value.length) {
            setErrorMessages({
              ...errorMessages,
              [field.id]: `${Labels.IT_SHOULD_BE_MORE_THEN} ${field.fieldType?.max} ${Labels.CHARACTERS}`,
            });
            if (finallyErrorMessages !== undefined) finallyErrorMessages[field.id] = `${Labels.IT_SHOULD_BE_MORE_THEN} ${field.fieldType?.max} ${Labels.CHARACTERS}`;
          }
        }
        break;

      default:
        break;
    }
  };

  const onBlur = (fieldValue: FieldValueDto) => {
    validate(fieldValue.field, fieldValue.value);
    var valuesWithoutNull = {};
    const newValues = fieldValue.field.id ? { ...fieldValuesList, [fieldValue.field.id]: fieldValue.value } : fieldValuesList;
    for (const id in newValues) {
      if (newValues[id] !== null && newValues[id] !== "") {
        valuesWithoutNull = { ...valuesWithoutNull, [id]: newValues[id] };
      }
    }
    const params =
      formFilledId !== undefined
        ? {
            formFilledId: formFilledId,
            formCode: formCode,
            fields: valuesWithoutNull,
          }
        : { formCode: formCode, fields: valuesWithoutNull };
    axiosSaveUnfinishedForm(params)
      .then((response: any) => {
        if (formFilledId === undefined) {
          setFormFilledId(response.data.data.id);
        }
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const saveAsDraft = () => {
    setShowBlockUI(true);
    const params = {
      formCode: formCode,
      fields: fieldValuesList,
      formFilledId: formFilledId,
    };
    axios
      .post(Endpoint.UNFINISHED_FORM_FILLED_LIST, params, axiosConfig(authData!.token))
      .then((response: any) => {
        setShowBlockUI(false);
        showMessage(MessageType.SUCCESS, Labels.FORM_SUCCESS_SAVE);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const validateAllFields = async () => {
    setStopChange(true);
    let isValid = true;
    let finallyErrorMessages = [] as any;
    for (let field of fieldList) {
      if (field.children) {
        for (let child of field.children) {
          finallyErrorMessages[child.id] = "";
        }
      } else {
        finallyErrorMessages[field.id] = "";
      }
    }
    await Promise.all(
      fieldList.map(async (field) => {
        if (field.children) {
          field.children.map(async (child) => {
            await validate(child, fieldValuesList[child.id], finallyErrorMessages);
          });
        } else {
          await validate(field, fieldValuesList[field.id], finallyErrorMessages);
        }
      })
    );
    setErrorMessages(finallyErrorMessages);
    finallyErrorMessages.forEach((error: any) => {
      if (error !== "") {
        isValid = false;
        return;
      }
    });
    return isValid;
  };

  const deleteFormFilled = () => {
    setShowBlockUI(true);
    axiosDeleteFormFilled(formFilledID)
      .then((response: any) => {
        setShowBlockUI(false);
        showMessage(MessageType.SUCCESS, Labels.FORM_FILLED_SUCCESS_DELETED);
        history.push({ pathname: "/registry" });
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const save = async () => {
    const isValidForm = await validateAllFields();
    if (isValidForm === true) {
      setShowBlockUI(true);
      const params = {
        formCode: formCode,
        fields: fieldValuesList,
        formFilledId: formFilledId,
        hiddenFieldList: hiddenFieldList,
      };
      axiosCreateFormFilled(params)
        .then((response: any) => {
          setShowBlockUI(false);
          showMessage(MessageType.SUCCESS, Labels.FORM_SUCCESS_SAVE);
          setStopChange(false);
          history.push({ pathname: "/registry" });
          setStopChange(true);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        });
    } else {
      showMessage(MessageType.ERROR, Labels.FORM_FAILED_SAVE);
    }
  };

  const setFieldCodebookListValidation = (data: any) => {
    const dataNew = fieldCodebookList;
    dataNew[data.id] = data.value;
    setFieldCodebookList(dataNew);
  };

  const panelHeader = () => {
    switch (formOperation) {
      case EntityOperation.CREATE:
        return Labels.HEADER_CREATE_FORM;
      case EntityOperation.DELETE:
        return Labels.HEADER_DELETE_FORM_FILLED;
      case EntityOperation.UPDATE:
        return Labels.HEADER_UPDATE_FORM_FILLED;
      case EntityOperation.READ:
        return Labels.HEADER_READ_FORM_FILLED;
      case EntityOperation.EDUPDATE:
        return Labels.HEADER_FORM_REVISION;
      default:
        return "";
    }
  };

  const clearForm = () => {
    setFieldValuesList({});
    setErrorMessages({});
  };

  const handleScreenSizeChange = (panelWidth: number) => {
    setShowCommentPanel(mainPanelDiv?.current?.clientWidth! - 828 < panelWidth);
    return mainPanelDiv?.current?.clientWidth! - 828 < panelWidth;
  };
  useEffect(() => {
    handleScreenSizeChange(COMMENT_PANEL_WIDTH);
  }, []);

  return {
    fieldList,
    form,
    save,
    saveAsDraft,
    clearForm,
    panelHeader,
    deleteFormFilled,
    formOperation,
    commentList,
    onChange,
    onBlur,
    fieldValuesList,
    errorMessages,
    formFilledId,
    uploadHandler,
    roleList,
    onApprove,
    comment,
    setComment,
    fetchFileList,
    deleteFile,
    fileList,
    createComment,
    formFilled,
    breadCrumbItems,
    formCode,
    codebookLogicalControlValidation,
    setFieldCodebookListValidation,
    locale,
    labelList,
    searchFormStatus,
    searchFormType,
    validate,
    semanticValidationFieldList,
    mainPanelDiv,
    showCommentPanel,
    setShowCommentPanel,
    handleScreenSizeChange,
    stopChange,
    setStopChange,
    showField,
  };
}

export type { FormLogicalType };
