import moment from "moment";
import { EffectCallback, useEffect } from "react";
import CodebookLogicalControlOperator from "./CodebookLogicalControlOperator";
import EnumCategory from "./EnumCategory";
import EnumComponentType from "./EnumComponentType";
import EntityOperation from "./EnumEntityOperation";
import EnumFileFormat from "./EnumFileFormat";
import EnumLanguage from "./EnumLanguage";
import EnumReportParameterType from "./EnumReportParameterType";
import FormSemanticValidationOperator from "./FormSemanticValidationOperator";
import MessageType from "./MessageType";
import { addLocale, locale } from "primereact/api";

const useEffectOnce = (effect: EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};

const isPublicRoute = (route: string) => {
  return route.startsWith("/public");
};

const stringChecker = (s: string | undefined | null) => {
  return s ? s : "";
};

const axiosConfig = (token: string, params?: Object) => {
  return {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: params ? params : {},
  };
};

const hrefForDownloadAttachment = (fileType: string, base64Attachment: string) => {
  return "data:" + fileType + ";base64," + base64Attachment;
};

const isFormDisabled = (operation: string) => {
  let returnValue = true;

  if (!operation) return true;

  switch (operation) {
    case EntityOperation.CREATE:
    case EntityOperation.UPDATE:
      returnValue = false;
      break;
    case EntityOperation.READ:
    case EntityOperation.DELETE:
      returnValue = true;
      break;
    default:
      returnValue = true;
      break;
  }

  return returnValue;
};

const dateAndTime = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format(DATE_TIME_FORMAT);
  } else {
    return "Lose zadat datum";
  }
};

const date = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format(DATE_FORMAT);
  } else {
    return "Lose zadat datum";
  }
};

const time = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format("h:mm");
  } else {
    return "Lose zadat datum";
  }
};

const dateTimeBefore = (date: any, dateNow: any, Labels: any) => {
  const now = moment(dateNow);
  const durationAsMinutes = moment.duration(now.diff(date)).asMinutes();
  if (durationAsMinutes < 60) {
    return Labels.LABEL_BEFORE + " " + Math.floor(durationAsMinutes + 1) + " " + Labels.LABEL_MINUTES;
  } else if (durationAsMinutes < 24 * 60) {
    return Labels.LABEL_BEFORE + " " + Math.floor(durationAsMinutes / 60) + " " + Labels.LABEL_HOURS;
  } else {
    return moment(date).format(DATE_TIME_FORMAT);
  }
};

const imageExtention = (base64: string) => {
  if (base64) {
    switch (base64.charAt(0)) {
      case "/":
        return "jpg";
      case "i":
        return "png";
      case "R":
        return "gif";
      case "U":
        return "webp";
    }
  }
};

const getImage = (needUri: boolean, source: string, replaceImage: any) => {
  return source
    ? needUri
      ? { uri: `data:image/${imageExtention(source)};base64,${source}` }
      : `data:image/${imageExtention(source)};base64,${source}`
    : replaceImage;
};

const showActivityDuration = (seconds: number) => {
  return seconds ? new Date(seconds * 1000).toISOString().substr(11, 8) : "";
};

const handleAxiosCallSuccess = (showMessage: Function, response: any) => {
  if (response.data && response.data.data.successes) {
    showMessage(
      MessageType.SUCCESS,
      undefined,
      response.data.data.successes.map((e: any) => e.message + "\n")
    );
  } else {
    showMessage(MessageType.SUCCESS, undefined, response.toString());
  }
};

const handleAxiosCallError = (showMessage: Function, error: any) => {
  if (error.response && error.response.data.errors) {
    error.response.data.errors.length = 5;
    showMessage(
      MessageType.ERROR,
      undefined,
      error.response.data.errors.map((e: any) => e.message + "\n")
    );
  } else {
    showMessage(MessageType.ERROR, undefined, error.toString());
  }
};

const languageList = (Labels?: any) => [
  { id: EnumLanguage.LATIN, name: Labels.LATIN },
  { id: EnumLanguage.CYRILLIC, name: Labels.CYRILLIC },
];

const translateLanguageList = (Labels?: any) => [{ id: EnumLanguage.CYRILLIC, name: Labels.CYRILLIC }];

const operatorSemanticValidation = (Labels: any) => [
  { id: FormSemanticValidationOperator.GREATER, name: Labels.GREATER },
  { id: FormSemanticValidationOperator.LESS, name: Labels.LESS },
  { id: FormSemanticValidationOperator.EQUAL, name: Labels.EQUAL },
  { id: FormSemanticValidationOperator.NOT_EQUAL, name: Labels.NOT_EQUAL }
];

const operatorCodebookLogicalControl = (Labels: any) => [
  { code: CodebookLogicalControlOperator.EQUAL, name: Labels.EQUAL },
  { code: CodebookLogicalControlOperator.NOT_EQUAL, name: Labels.NOT_EQUAL },
  { code: CodebookLogicalControlOperator.RANGE, name: Labels.RANGE },
];

const categoryList = [
  { label: "Individualni izveštaji", labelCyrillic: "Индивидуални извештаји", value: EnumCategory.INDIVIDUAL },
  { label: "Statistički izveštaji", labelCyrillic: "Статистички извештаји", value: EnumCategory.STATISTIC }
];

const fileFormatList = [
  { label: EnumFileFormat.CSV, value: EnumFileFormat.CSV },
  { label: EnumFileFormat.PDF, value: EnumFileFormat.PDF },
  { label: EnumFileFormat.TXT, value: EnumFileFormat.TXT },
  { label: EnumFileFormat.XLS, value: EnumFileFormat.XLS },
  { label: EnumFileFormat.XLSX, value: EnumFileFormat.XLSX },
];
const getComponentTypeName = (componentType: any, Labels?: any) => {
  switch (componentType) {
    case EnumComponentType.TEXT:
      return Labels.LABEL_TEXT;
    case EnumComponentType.LONG_TEXT:
      return Labels.LABEL_LONG_TEXT;
    case EnumComponentType.NUMBER_INTEGER:
      return Labels.LABEL_INTEGER;
    case EnumComponentType.NUMBER_DECIMAL:
      return Labels.LABEL_FLOAT;
    case EnumComponentType.LABEL:
      return Labels.LABEL_LABEL;
    case EnumComponentType.SET:
      return Labels.LABEL_SET;
    case EnumComponentType.BOOLEAN:
      return Labels.LABEL_BOOLEAN;
    case EnumComponentType.PANEL:
      return Labels.LABEL_PANEL;
    case EnumComponentType.DATE_TIME:
      return Labels.LABEL_DATE_TIME;
    case EnumComponentType.CODEBOOK:
      return Labels.LABEL_CODEBOOK;
    case EnumComponentType.CODEBOOK_AUTOCOMPLETE:
      return Labels.CODEBOOK_AUTOCOMPLETE;
    default:
      return;
  }
};

const parameterType = (Labels: any) => [
  { label: Labels.LABEL_LABEL, value: EnumReportParameterType.TEXT },
  { label: Labels.LABEL_DATE_TIME, value: EnumReportParameterType.DATE },
  { label: Labels.LABEL_SET, value: EnumReportParameterType.SET },
  { label: Labels.MULTISELECT, value: EnumReportParameterType.MULTISELECT },
  { label: Labels.LABEL_BOOLEAN, value: EnumReportParameterType.BOOLEAN },
  { label: Labels.LABEL_NUMBER, value: EnumReportParameterType.NUMBER },
];

const getOperatorName = (operator: any, Labels?: any) => {
  switch (operator) {
    case FormSemanticValidationOperator.GREATER:
      return Labels.GREATER;
    case FormSemanticValidationOperator.LESS:
      return Labels.LESS;
    case FormSemanticValidationOperator.EQUAL:
      return Labels.EQUAL;
    case FormSemanticValidationOperator.NOT_EQUAL:
      return Labels.NOT_EQUAL;
    case CodebookLogicalControlOperator.RANGE:
      return Labels.RANGE;
    default:
      return;
  }
};

const hasRole = (roleList: any, code: any) => {
  const role = roleList?.find((x: any) => x.code === code);
  if (role === undefined || role === null) return false;
  return true;
};
const meterToKilometer = (value: number, decimal: number) => {
  return Number((value / 1000).toFixed(decimal));
};

const operatorNumber = [
  { id: 1, name: ">" },
  { id: 2, name: "<" },
  { id: 3, name: "=" },
  { id: 4, name: "!=" },
  { id: 5, name: "<=" },
  { id: 6, name: ">=" },
];

const operatorText = (Labels: any) => [
  { id: 1, name: "=" },
  { id: 2, name: "!=" },
  { id: 3, name: Labels.CONTAIS },
  { id: 4, name: Labels.NOT_CONTAIS },
  { id: 5, name: Labels.START_WITH },
  { id: 6, name: Labels.END_WITH },
];

const operatorSet = (Labels: any) => [
  { id: 1, name: Labels.CHOOSE },
  { id: 2, name: Labels.NOT_CHOOSE },
];

const getOperator = (componentType: string, Labels: any) => {
  switch (componentType) {
    case EnumComponentType.LABEL:
    case EnumComponentType.TEXT:
    case EnumComponentType.LONG_TEXT:
      return operatorText(Labels);
    case EnumComponentType.NUMBER_INTEGER:
    case EnumComponentType.NUMBER_DECIMAL:
    case EnumComponentType.DATE_TIME:
      return operatorNumber;
    case EnumComponentType.SET:
    case EnumComponentType.BOOLEAN:
      return operatorSet(Labels);
    case EnumComponentType.CODEBOOK:
      return operatorSet(Labels);
    case EnumComponentType.CODEBOOK_AUTOCOMPLETE:
      return operatorSet(Labels);
    default:
      break;
  }
};

const DATE_TIME_FORMAT = "DD.MM.YYYY. HH:mm";
const DATE_TIME_FORMAT_FULL = "DD.MM.YYYY. HH:mm:ss";
const DATE_FORMAT = "DD.MM.YYYY.";
const DATE_FORMAT2 = "YYYY-MM-DD";
const CALENDAR_DATE_FORMAT = "dd.mm.yy.";
const DATE_TIME_FORMAT_2 = "YYYY-MM-DD HH:mm:ss";
const DATE_TIME_FORMAT_3 = "YYYY-MM-DDTHH:mm:ss";
const DATE_TIME_FORMAT_4 = "YYYY-MM-DDT00:00:00";
const FORM_CCF = "pmt";
const FORM_DEATH_REPORT = "pul";
const MAX_FILE_UPLOAD = 5242880;
const SUCCESS_MESSAGE_LIFE = 5000;
const COMMENT_PANEL_WIDTH = 300;
const LIMIT = 100000;
const OUTPUT_FIELDS = ["sif_id", "sif_vrednost", "sif_kod", "sif_vazi_od", "sif_vazi_do", "sif_roditelj_id"];
const FIELD = "sif_order";
const TYPE = "ASC";
const DISTRICT = "DISTRICT";
const MUNICIPALITY = "MUNICIPALITY";
const INSTITUTION = "INSTITUTION";

const addSrLocal = () => {
  addLocale("sr", {
    firstDayOfWeek: 1,
    dayNames: ["Nedelja", "Ponedeljak", "Utorak", "Sreda", "Četvrtak", "Petak", "Subota"],
    dayNamesShort: ["Ned", "Pon", "Uto", "Sre", "Čet", "Pet", "Sub"],
    dayNamesMin: ["N", "P", "U", "S ", "Č", "P ", "S"],
    monthNames: [
      "Januar",
      "Februar",
      "Mart",
      "April",
      "Maj",
      "Juni",
      "Juli",
      "Avgust",
      "Septembar",
      "Oktobar",
      "Novembar",
      "Decembar",
    ],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"],
    weekHeader: "Sedmica",
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: "",
    timeOnlyTitle: "Samo Vreme",
    timeText: "Vreme",
    hourText: "Sat",
    minuteText: "Minuta",
    secondText: "Sekunda",
    currentText: "Danas",
    ampm: false,
    month: "Mesec",
    week: "sedmica",
    day: "Dan",
    allDayText: "Celi Dan",
    clear: "Poništi",
    today: "Danas",
  });
  addLocale("sr-cyrilic", {
    firstDayOfWeek: 1,
    dayNames: ["Недеља", "Понедељак", "Уторак", "Среда", "Четвртак", "Петак", "Субота"],
    dayNamesShort: ["Нед", "Пон", "Уто", "Сре", "Чет", "Пет", "Суб"],
    dayNamesMin: ["Н", "П", "У", "С ", "Ч", "П ", "С"],
    monthNames: [
      "Јануар",
      "Фебруар",
      "Март",
      "Април",
      "Мај",
      "Јуни",
      "Јули",
      "Август",
      "Септембар",
      "Октобар",
      "Новембар",
      "Децембар",
    ],
    monthNamesShort: ["Јан", "Феб", "Мар", "Апр", "Мај", "Јун", "Јул", "Авг", "Сеп", "Окт", "Нов", "Дец"],
    weekHeader: "Седмица",
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: "",
    timeOnlyTitle: "Само време",
    timeText: "Време",
    hourText: "Сат",
    minuteText: "Минута",
    secondText: "Секунда",
    currentText: "Данас",
    ampm: false,
    month: "Месец",
    week: "седмица",
    day: "Дан",
    allDayText: "Цели Дан",
    clear: "Поништи",
    today: "Данас",
  });
  locale("sr");
  locale("sr-cyrilic");
};

export {
  useEffectOnce,
  isPublicRoute,
  getImage,
  showActivityDuration,
  imageExtention,
  dateTimeBefore,
  stringChecker,
  isFormDisabled,
  dateAndTime,
  date,
  time,
  axiosConfig,
  hrefForDownloadAttachment,
  getOperator,
  operatorNumber,
  operatorText,
  operatorSet,
  getOperatorName,
  CALENDAR_DATE_FORMAT,
  DATE_FORMAT,
  DATE_TIME_FORMAT_FULL,
  DATE_FORMAT2,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_2,
  DATE_TIME_FORMAT_3,
  DATE_TIME_FORMAT_4,
  FORM_CCF,
  FORM_DEATH_REPORT,
  MAX_FILE_UPLOAD,
  SUCCESS_MESSAGE_LIFE,
  handleAxiosCallError,
  handleAxiosCallSuccess,
  meterToKilometer,
  hasRole,
  getComponentTypeName,
  languageList,
  operatorSemanticValidation,
  operatorCodebookLogicalControl,
  categoryList,
  fileFormatList,
  parameterType,
  translateLanguageList,
  addSrLocal,
  COMMENT_PANEL_WIDTH,
  LIMIT,
  OUTPUT_FIELDS,
  FIELD,
  TYPE,
  DISTRICT,
  MUNICIPALITY,
  INSTITUTION
};
