import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { FieldTypeController, FieldTypeControllerType } from "../../../controllers/field-type/FieldTypeController";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import FieldTypeReadDto from "../../../model/FieldTypeReadDto";
import { AppContext } from "../../../Store";

interface FieldTypeLogicalType {
  fieldTypeList: Array<FieldTypeReadDto>;
  first: number;
  tableRows: number;
  selectedRow: any;
  setSelectedRow: any;
  setSelectedFieldType: any;
  selectedFieldType: any;
  history: any;
  breadCrumbItems: any;
  onPageFieldType: any;
  exportData: any;
  onSearchValueChange: any;
  deleteFieldType: any;
}

export default function FieldTypeLogical(): FieldTypeLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const { Labels } = LabelsContext();
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedFieldType, setSelectedFieldType] = useState<FieldTypeReadDto | null>();
  const [fieldTypeList, setFieldTypeList] = useState<Array<FieldTypeReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const history = useHistory();
  const [breadCrumbItems] = useState([
    {
      label: Labels.FIELD_TYPES,
      command: () => {
        window.location.reload();
      },
    },
  ]);

  const { axiosGetFieldTypeList, axiosDeleteFieldType }: FieldTypeControllerType = FieldTypeController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (idSelectedFieldType?: number) => {
    setShowBlockUI(true);
    axiosGetFieldTypeList()
      .then((res: any) => {
        setFieldTypeList(res.data.data);
        let exportList = new Array<any>();
        res.data.data.forEach((fieldType: any) => {
          exportList.push({ [Labels.LABEL_CODE]: fieldType.code, [Labels.LABEL_NAME]: fieldType.name, [Labels.LABEL_DESCRIPTION]: fieldType.description, [Labels.LABEL_NOTE]: fieldType.note });
        });
        setExportData(exportList);
        setShowBlockUI(false);
        if (idSelectedFieldType) {
          res.data.data.forEach((fieldType: any) => {
            if (idSelectedFieldType === fieldType.id) {
              setSelectedRow(fieldType);
              setSelectedFieldType(fieldType);
            }
          });
        }
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const onPageFieldType = (rows: any, first: any) => {
    setTableRows(rows);
    setFirst(first);
  };

  const onSearchValueChange = (sortedData: Array<any>) => {
    const exportList = new Array<any>();
    sortedData?.forEach((fieldType: any) => {
      exportList.push({ [Labels.LABEL_CODE]: fieldType.code, [Labels.LABEL_NAME]: fieldType.name, [Labels.LABEL_DESCRIPTION]: fieldType.description, [Labels.LABEL_NOTE]: fieldType.note });
    });
    setExportData(exportList);
  }

  const deleteFieldType = (fieldTypeId: any) => {
    axiosDeleteFieldType(fieldTypeId)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_DELETE_FIELD_TYPE_SUCCESS, "");
        fetchData();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  return {
    fieldTypeList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    setSelectedFieldType,
    selectedFieldType,
    history,
    breadCrumbItems,
    onPageFieldType,
    exportData,
    onSearchValueChange,
    deleteFieldType
  };
}

export type { FieldTypeLogicalType };