import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { FormController, FormControllerType } from "../../../../controllers/form/FormController";
import { RoleController, RoleControllerType } from "../../../../controllers/role/RoleController";
import { UserRightController, UserRightControllerType } from "../../../../controllers/user-right/UserRightController";
import EnumRight from "../../../../infrastructure/system/EnumRight";
import LabelsContext from "../../../../infrastructure/system/LabelsContext";
import { handleAxiosCallError } from "../../../../infrastructure/system/Utils";
import { AppContext } from "../../../../Store";

interface CrudUserRightLogicalType {
  roleList: any;
  userRightList: any;
  hasRight: any;
  onCreate: any;
  onDelete: any;
  userRightLabel: any;
}

interface UseParamsType {
  formCode?: string;
}

export default function CrudUserRightLogical(): CrudUserRightLogicalType {
  const { showMessage } = useContext(AppContext);
  const { Labels } = LabelsContext();
  const [roleList, setRoleList] = useState<any>([]);
  const [userRightList, setUserRightList] = useState<any>([]);
  const [formUserRight, setFormUserRight] = useState<any>([]);

  const { formCode } = useParams<UseParamsType>();

  const { axiosGetFormUserRightList, axiosCreateFormUserRightList, axiosDeleteFormUserRightList }: FormControllerType = FormController();

  const { axiosGetRoleList }: RoleControllerType = RoleController();

  const { axiosGetUserRightList }: UserRightControllerType = UserRightController();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formCode]);

  const fetchData = () => {
    if (formCode) {
      axios
        .all([axiosGetRoleList(), axiosGetUserRightList(), axiosGetFormUserRightList(formCode)])
        .then(
          axios.spread((responseRoleList, responseUserRightList, responseFormUserRight) => {
            setRoleList(responseRoleList.data);
            setUserRightList(responseUserRightList.data);
            setFormUserRight(responseFormUserRight.data.data);
          })
        )
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const hasRight = (right: string, role: string) => {
    return formUserRight.some((x: any) => x.roleEnum === role && x.rightEnum === right);
  };

  const userRightLabel = (right: string) => {
    switch (right) {
      case EnumRight.CREATE:
        return Labels.USER_RIGHT_CREATE;
      case EnumRight.READ:
        return Labels.USER_RIGHT_OVERVIEW;
      case EnumRight.UPDATE:
        return Labels.USER_RIGHT_UPDATE;
      case EnumRight.DELETE:
        return Labels.USER_RIGHT_DELETE;
      case EnumRight.VISIBILITY:
        return Labels.USER_RIGHT_VISIBILITY;
      default:
        break;
    }
  };

  const onCreate = (right: string, role: string) => {
    const data = {
      roleEnum: role,
      rightEnum: right,
    };
    axiosCreateFormUserRightList(formCode, data)
      .then((response: any) => {
        fetchData();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onDelete = (right: string, role: string) => {
    const id = formUserRight.find((x: any) => x.roleEnum === role && x.rightEnum === right)?.id;
    axiosDeleteFormUserRightList(formCode, id)
      .then(() => {
        fetchData();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  return { roleList, userRightList, hasRight, onCreate, onDelete, userRightLabel };
}

export type { CrudUserRightLogicalType };
