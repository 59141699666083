import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface FieldTypeControllerType {
    axiosCreateFieldType: any;
    axiosUpdateFieldType: any;
    axiosDeleteFieldType: any;
    axiosGetFieldType: any;
    axiosGetFieldTypeList: any;
    axiosGetFieldTypeEnumeratorItemList: any;
    axiosCreateFieldTypeEnumeratorItem: any;
    axiosUpdateFieldTypeEnumeratorItem: any;
    axiosDeleteFieldTypeEnumeratorItem: any;
}

export function FieldTypeController(): FieldTypeControllerType {
    const { authData } = useContext(AppContext);

    const axiosCreateFieldType = (fieldTypeChange: any) => {
        return axios.post(Endpoint.FIELD_TYPE_LIST, fieldTypeChange ? fieldTypeChange : {}, axiosConfig(authData!.token));
    };

    const axiosUpdateFieldType = (fieldTypeId: any, fieldTypeChange: any) => {
        return axios.put(Endpoint.FIELD_TYPE_LIST + "/" + fieldTypeId, fieldTypeChange, axiosConfig(authData!.token));
    };

    const axiosDeleteFieldType = (fieldTypeId: any) => {
        return axios.delete(Endpoint.FIELD_TYPE_LIST + "/" + fieldTypeId, axiosConfig(authData!.token))
    };

    const axiosGetFieldType = (fieldTypeId: any) => {
        return axios.get(Endpoint.FIELD_TYPE_LIST + "/" + fieldTypeId, axiosConfig(authData!.token))
    };

    const axiosGetFieldTypeList = () => {
        return axios.get(Endpoint.FIELD_TYPE_LIST, axiosConfig(authData!.token));
    };

    const axiosGetFieldTypeEnumeratorItemList = (fieldTypeId: any) => {
        return axios.get(Endpoint.FIELD_TYPE_LIST + "/" + fieldTypeId + "/fieldTypeEnumeratorItemList", axiosConfig(authData!.token));
    };

    const axiosCreateFieldTypeEnumeratorItem = (fieldTypeId: number, fieldTypeEnumeratorItemChange: any) => {
        return axios.post(Endpoint.FIELD_TYPE_LIST + "/" + fieldTypeId + "/fieldTypeEnumeratorItemList", fieldTypeEnumeratorItemChange ? fieldTypeEnumeratorItemChange : {}, axiosConfig(authData!.token));
    };

    const axiosUpdateFieldTypeEnumeratorItem = (fieldTypeId: number, fieldTypeEnumeratorItemId: any, fieldTypeChange: any) => {
        return axios.put(Endpoint.FIELD_TYPE_LIST + "/" + fieldTypeId + "/fieldTypeEnumeratorItemList/" + fieldTypeEnumeratorItemId, fieldTypeChange, axiosConfig(authData!.token));
    };

    const axiosDeleteFieldTypeEnumeratorItem = (fieldTypeId: any, fieldTypeEnumeratorItemId: any) => {
        return axios.delete(Endpoint.FIELD_TYPE_LIST + "/" + fieldTypeId + "/fieldTypeEnumeratorItemList/" + fieldTypeEnumeratorItemId, axiosConfig(authData!.token))
    };


    return { axiosCreateFieldType, axiosUpdateFieldType, axiosDeleteFieldType, axiosGetFieldType, axiosGetFieldTypeList, axiosGetFieldTypeEnumeratorItemList, axiosCreateFieldTypeEnumeratorItem, axiosUpdateFieldTypeEnumeratorItem, axiosDeleteFieldTypeEnumeratorItem };
}

export type { FieldTypeControllerType };
