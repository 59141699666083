import React from "react";
import { Dialog } from "primereact/dialog";
import CrudLabelLogical, { CrudLabelLogicalType } from "./CrudLabelLogical";
import { translateLanguageList } from "../../infrastructure/system/Utils";
import LabelsContext from "../../infrastructure/system/LabelsContext";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import EntityOperation from "../../infrastructure/system/EnumEntityOperation";
interface PropsType {
  nameOriginal: string | undefined;
  displayDialog: boolean;
  dialogClose: any;
  isDisabled: boolean;
  entityOperation: string;
}

export default function CrudLabel(props: PropsType) {
  const { displayDialog, nameOriginal, dialogClose, isDisabled, entityOperation } = props;
  const { onSaveLabel, labelListChange, onChangeLabel }: CrudLabelLogicalType = CrudLabelLogical({ original: nameOriginal });
  const { Labels } = LabelsContext();

  return (
    <Dialog header={`${Labels.LABEL} - ${nameOriginal}`} visible={displayDialog} onHide={dialogClose} style={{ width: "600px" }}>
      <div className="p-col-11 tab-view">
        <div className="p-grid p-fluid p-align-center p-nogutter">
          {translateLanguageList &&
            translateLanguageList(Labels).map((tl: any) => {
              return (
                <div key={tl.id} className="p-col-12">
                  <div className="p-grid p-align-top p-align-center">
                    <div className="p-col-2">
                      <div>{tl.name}</div>
                    </div>
                    <div className="p-col-8">
                      <InputText
                        disabled={isDisabled}
                        value={
                          labelListChange.length !== 0 && labelListChange.filter((label: any) => label.original === nameOriginal && label.language === tl.id)[0]
                            ? labelListChange.filter((label: any) => label.original === nameOriginal && label.language === tl.id)[0].translate
                            : ""
                        }
                        onChange={(e: any) => {
                          onChangeLabel(e.target.value, tl.id);
                        }}
                      />
                    </div>
                    <div className="p-col-2">
                      {entityOperation !== EntityOperation.READ && (
                        <Button
                          tooltip={Labels.LABEL_CREATE_LABEL}
                          tooltipOptions={{ position: "bottom" }}
                          icon="pi pi-save"
                          className="p-button-success"
                          onClick={() => onSaveLabel(labelListChange.filter((label: any) => label.original === nameOriginal && label.language === tl.id)[0], tl.id)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Dialog>
  );
}
