import axios from "axios";
import { useContext, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import { ReportController, ReportControllerType } from "../../../controllers/report/ReportController";
import { handleAxiosCallError, isFormDisabled, useEffectOnce } from "../../../infrastructure/system/Utils";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import { AppContext } from "../../../Store";
import ReportReadDto from "../../../model/ReportReadDto";
import MessageType from "../../../infrastructure/system/MessageType";

interface CrudReportLogicalType {
  reportID: any;
  reportOperation: string;
  onCreate: any;
  onDelete: any;
  onUpdate: any;
  onCancel: any;
  reportChange: ReportReadDto | undefined;
  location: any;
  setReportChange: any;
  breadCrumbItems: any;
  index: number;
  setIndex: any;
  isDisabled: any;
  displayDialog: any;
  setDisplayDialog: any;
  selectedReport: any;
}

interface UseParamsType {
  reportID?: string;
}

interface LocationType {
  reportOperation: string;
  tabIndex?: any;
  selectedReport?: any;
}

export default function CrudReportLogical() {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const { Labels } = LabelsContext();
  const [reportChange, setReportChange] = useState<ReportReadDto | undefined>();
  const history = useHistory();
  const { reportID } = useParams<UseParamsType>();
  const location = useLocation<LocationType>();
  const [reportOperation, setReportOperation] = useState(
    location.state && location.state.reportOperation !== undefined
      ? location.state.reportOperation
      : EntityOperation.UPDATE
  );
  const [tabIndex] = useState(location.state && location.state.tabIndex !== undefined ? location.state.tabIndex : 0);
  const [index, setIndex] = useState(tabIndex);
  const [newReport, setNewReport] = useState<ReportReadDto | undefined>();
  const [selectedReport, setSelectedReport] = useState(location.state && location.state.selectedReport ? location.state.selectedReport : null);
  const [isDisabled] = useState(isFormDisabled(reportOperation));
  const [breadCrumbItems, setBreadCrumbItems] = useState<any>([
    {
      label: Labels.REPORT_LIST,
      command: () => {
        history.push("/crudReport");
      },
    },
  ]);

  const { axiosCreateReport, axiosUpdateReport, axiosDeleteReport, axiosGetReport }: ReportControllerType =
    ReportController();

  useEffectOnce(() => {
    fetchData(reportID);
  });
  const [displayDialog, setDisplayDialog] = useState(false);
  const fetchData = (reportID?: any) => {
    let newReportID = reportID ? reportID : newReport ? newReport.id : reportID;
    if (newReportID) {
      setShowBlockUI(true);
      const requestReport = axiosGetReport(newReportID);
      axios
        .all([requestReport])
        .then(
          axios.spread((responseReport: any) => {
            setReportChange(getFileFormatList(responseReport.data.data));
            setShowBlockUI(false);
            if (breadCrumbItems.length < 2) {
              setBreadCrumbItems([
                ...breadCrumbItems,
                {
                  label:
                    reportOperation === EntityOperation.UPDATE
                      ? Labels.LABEL_UPDATE_REPORT + responseReport.data.data.name
                      : reportOperation === EntityOperation.READ
                        ? Labels.LABEL_DETAILS_REPORT + responseReport.data.data.name
                        : Labels.LABEL_DELETE_REPORT + responseReport.data.data.name,
                },
              ]);
            }
          })
        )
        .catch((error: any) => {
          setShowBlockUI(false);
          handleAxiosCallError(showMessage, error);
        });
    } else {
      setBreadCrumbItems([
        ...breadCrumbItems,
        {
          label: Labels.LABEL_CREATE_REPORT,
        },
      ]);
    }
  };
  const getFileFormatList = (reportChange: any) => {
    let ffList: Array<string> = [];
    reportChange.reportFileFormatList &&
      reportChange.reportFileFormatList.forEach((ff: any) => {
        ffList.push(ff.fileFormat);
      });
    return { ...reportChange, reportFileFormatList: ffList };
  };

  const onCreate = () => {
    axiosCreateReport(reportChange)
      .then((res: any) => {
        setNewReport(res.data.data);
        setIndex(1);
        setReportOperation(EntityOperation.UPDATE);
        history.push(`/crudReport/${res.data.data.id}`);
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_CREATE_REPORT_SUCCESS, "");
        setReportOperation(EntityOperation.CREATE);
        fetchData(res.data.data.code);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onUpdate = () => {
    axiosUpdateReport(newReport ? newReport.id : reportID, reportChange)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_UPDATE_REPORT_SUCCESS, "");
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onDelete = () => {
    axiosDeleteReport(reportID)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_DELETE_REPORT_SUCCESS, "");
        history.push(`/report`);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onCancel = () => {
    history.push({
      pathname: `/report`,
    });
  };

  return {
    reportID,
    reportOperation,
    reportChange,
    location,
    breadCrumbItems,
    setReportChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    setIndex,
    index,
    isDisabled,
    displayDialog,
    setDisplayDialog,
    selectedReport
  };
}

export type { CrudReportLogicalType };
