import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../../infrastructure/system/LabelsContext";
import ReportAccessLogical, { ReportAccessLogicalType } from "./ReportAccessLogical";

interface ReportAccessListProps {
  reportOperation: string;
}
export default function ReportAccessList(props: ReportAccessListProps) {
  const { reportOperation } = props;
  const {
    accessList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    openDialog,
    displayDialog,
    closeDialog,
    setSelectedReportAccess,
    selectedReportAccess,
    dialogHeader,
    reportAccessOperation,
    onCreate,
    onDelete,
    roleList,
    firstRole,
    tableRoleRows,
    selectedRoleRow,
    setSelectedRole,
    setSelectedRoleRow,
    selectedRole,
    onPage,
    onPageRole,
    deleteReportAccess,
  }: ReportAccessLogicalType = ReportAccessLogical();
  const dt = useRef<any>(null);
  const dtRole = useRef<any>(null);
  const { Labels } = LabelsContext();
  const leftContents = () => (
    <>
      <Button
        icon="pi pi-plus"
        className="p-button-success btn-margin-right"
        label={Labels.BUTTON_ADD}
        tooltip={Labels.LABEL_ADD_REPORT_ACCESS}
        disabled={reportOperation !== EntityOperation.UPDATE}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          openDialog(EntityOperation.CREATE);
        }}
      />
    </>
  );

  const rightContents = () => (
    <>
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        label={Labels.BUTTON_DELETE}
        tooltip={Labels.LABEL_DELETE_REPORT_ACCESS}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedReportAccess || reportOperation !== EntityOperation.UPDATE}
        onClick={() => {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPORT_ACCESS,
            icon: "pi pi-power-off",
            accept: () => {
              deleteReportAccess(selectedReportAccess.id);
            },
          });
        }}
      />
    </>
  );
  const leftContentsDialog = () => (
    <>
      {reportAccessOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_ADD}
          tooltip={Labels.LABEL_ADD_REPORT_ACCESS}
          disabled={!selectedRole}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => {
            onCreate();
          }}
        />
      )}
      {reportAccessOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          tooltip={Labels.LABEL_DELETE_REPORT_ACCESS}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            onDelete();
          }}
        />
      )}
    </>
  );

  const rightContentsDialog = () => <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" onClick={closeDialog} />;
  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };
  return (
    <div className="layout-crud-form">
      <Toolbar left={leftContents} right={rightContents} />
      <div>
        <DataTable
          ref={dt}
          value={accessList}
          paginator
          first={first}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={tableRows}
          rowsPerPageOptions={[5, 10, 20]}
          onPage={(e) => {
            onPage(e.rows, e.first);
          }}
          selectionMode="single"
          selection={selectedRow}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          onRowSelect={(e) => setSelectedReportAccess(e.data)}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
        >
          <Column field={"role"} header={Labels.ROLES} filter filterMatchMode="contains" sortable />
        </DataTable>
      </div>
      <Dialog header={dialogHeader(reportAccessOperation)} visible={displayDialog} onHide={closeDialog} style={{ width: "1000px" }} footer={dialogFooter()}>
        {reportAccessOperation === EntityOperation.CREATE && (
          <div>
            <DataTable
              ref={dtRole}
              value={roleList}
              paginator
              first={firstRole}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              rows={tableRoleRows}
              rowsPerPageOptions={[5, 10, 20]}
              onPage={(e) => {
                onPageRole(e.rows, e.first);
              }}
              selectionMode="single"
              selection={selectedRoleRow}
              onSelectionChange={(e) => setSelectedRoleRow(e.value)}
              onRowSelect={(e) => setSelectedRole(e.data)}
              emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            >
              <Column field={"code"} header={Labels.ROLES} filter filterMatchMode="contains" sortable />
            </DataTable>
          </div>
        )}
        {reportAccessOperation === EntityOperation.DELETE && <div>{selectedReportAccess.role}</div>}
      </Dialog>
    </div>
  );
}
