// @ts-ignore
import classNames from "classnames";
import { confirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { useContext } from "react";
import { Badge } from "primereact/badge";
import { NavLink, useHistory } from "react-router-dom";
import Images from "../../../infrastructure/system/Images";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import { getImage, handleAxiosCallError } from "../../../infrastructure/system/Utils";
import { AppContext } from "../../../Store";
import AppBreadcrumb from "../breadcrumb/AppBreadcrumb";
import AppTopbarLogical, { AppTopbarLogicalType } from "./AppTopbarLogical";
import { LoginController, LoginControllerType } from "../../../controllers/login/LoginController";
import { RoleService, RoleServiceType } from "../../../controllers/role/RoleService";
import { Tooltip } from "primereact/tooltip";

export default function AppTopbar(props: any) {
  const { authData, deleteAuthData, showMessage } = useContext(AppContext);
  const { isBed, isEd, isMd }: RoleServiceType = RoleService();
  const { axiosLogout }: LoginControllerType = LoginController();
  const { onChangeIntitution, institutionList, getCurrentUser, badgeValue }: AppTopbarLogicalType = AppTopbarLogical();
  const { Labels } = LabelsContext();
  const history = useHistory();

  const profileItemClassName = classNames("profile-item", { "active-menuitem fadeInDown": props.topbarUserMenuActive });

  if (!authData) {
    return null;
  }

  const goToForRevisionForms = () => {
    history.push("/forRevisionFormList");
  };

  return (
    <div className="layout-topbar">
      <div className="topbar-left">
        <button type="button" className="menu-button p-link" onClick={props.onMenuButtonClick}>
          <i className="pi pi-chevron-left"></i>
        </button>

        <span className="topbar-separator"></span>

        <div className="layout-breadcrumb viewname" style={{ textTransform: "uppercase" }}>
          <AppBreadcrumb routers={props.routers} />
        </div>

        <img id="logo-mobile" className="mobile-logo" src="assets/layout/images/logo-dark.svg" alt="diamond-layout" />
      </div>

      <div className="topbar-right">
        <div className="topbar-menu">
          {isMd() && institutionList.length > 0 && (
            <Dropdown
              filter
              placeholder={Labels.INSTITUTION_DROPDOWN_CHOOSE}
              resetFilterOnHide
              onFocus={getCurrentUser}
              optionLabel="name"
              optionValue="id"
              options={institutionList}
              value={authData.currentUser.institution?.id}
              onChange={(e) => onChangeIntitution(e.value)}
            />
          )}
          {(isMd() || isBed() || isEd()) && (
            <>
              <i className="pi pi-bell p-text-secondary p-overlay-badge badge-style tooltip-badge" onClick={goToForRevisionForms}>
                <Badge value={badgeValue} severity="danger" className={badgeValue > 0 ? "" : "badge-no-severity"}></Badge>
              </i>
              <Tooltip target=".tooltip-badge" position="bottom">
                {Labels.BADGE_TOOLTIP}
              </Tooltip>
            </>
          )}
          <ul className="topbar-menu">
            <li className={profileItemClassName} onClick={props.onTopbarUserMenu}>
              <button type="button" className="p-link">
                <img src={getImage(false, authData.currentUser.profilePicture, Images.PROFILE)} alt="diamond-layout" className="profile-image" />
                <span className="profile-name">{authData.currentUser?.username}</span>
              </button>
              <ul className="profile-menu fade-in-up">
                <li>
                  <NavLink to={`/userProfile/${authData.currentUser.id}`} type="button" className="p-link" onClick={props.onTopbarUserMenuButtonClick}>
                    <i className="pi pi-user" />
                    <span>{Labels.LABEL_USER_PROFILE}</span>
                  </NavLink>
                </li>
                <li>
                  <button
                    type="button"
                    className="p-link"
                    onClick={() => {
                      props.setTopbarUserMenuActive(false);
                      confirmDialog({
                        acceptLabel: Labels.BUTTON_YES,
                        rejectLabel: Labels.BUTTON_CANCEL,
                        message: Labels.LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED,
                        icon: "pi pi-power-off",
                        accept: () => {
                          axiosLogout()
                            .then(() => {
                              deleteAuthData();
                              history.push("/");
                            })
                            .catch((error: any) => {
                              handleAxiosCallError(showMessage, error);
                            });
                        },
                      });
                    }}
                  >
                    <i className="pi pi-power-off"></i>
                    <span>{Labels.BUTTON_LOGOUT}</span>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
