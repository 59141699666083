import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface UserGroupControllerType {
  axiosCreateUserGroup: any;
  axiosUpdateUserGroup: any;
  axiosDeleteUserGroup: any;
  axiosGetUserGroupList: any;
  axiosDeleteUserGroupUser: any;
  axiosCreateUserGroupUser: any;
  axiosCreateUserGroupJurisdiction: any;
  axiosDeleteUserGroupJurisdiction: any;
  axiosDeleteUserGroupRole: any;
  axiosCreateUserGroupRole: any;
}

export function UserGroupController(): UserGroupControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetUserGroupList = () => {
    return axios.get(`${Endpoint.USER_GROUP_LIST}`, axiosConfig(authData!.token));
  };
  const axiosCreateUserGroup = (userGroup: any) => {
    return axios.post(Endpoint.USER_GROUP_LIST, userGroup ? userGroup : {}, axiosConfig(authData!.token));
  };
  const axiosUpdateUserGroup = (userGroup: any) => {
    return axios.put(`${Endpoint.USER_GROUP_LIST}/${userGroup.id}`, userGroup, axiosConfig(authData!.token));
  };
  const axiosDeleteUserGroup = (userGroupId: any) => {
    return axios.delete(`${Endpoint.USER_GROUP_LIST}/${userGroupId}`, axiosConfig(authData!.token));
  };
  const axiosDeleteUserGroupUser = (userGroupId: any, userGroupUserId: any) => {
    return axios.delete(`${Endpoint.USER_GROUP_LIST}/${userGroupId}/userList/${userGroupUserId}`, axiosConfig(authData!.token));
  };
  const axiosCreateUserGroupUser = (userGroupId: any, userGroupUser: any) => {
    return axios.post(`${Endpoint.USER_GROUP_LIST}/${userGroupId}/userList`, userGroupUser, axiosConfig(authData!.token));
  };
  const axiosCreateUserGroupJurisdiction = (userGroupId: any, userGroupJurisdiction: any) => {
    return axios.post(Endpoint.USER_GROUP_LIST + `/${userGroupId}` + Endpoint.USER_GROUP_JURISDICTION_LIST, userGroupJurisdiction, axiosConfig(authData!.token));
  };

  const axiosDeleteUserGroupJurisdiction = (userGroupId: any, userGroupJurisdictionId: any) => {
    return axios.delete(Endpoint.USER_GROUP_LIST + `/${userGroupId}` + Endpoint.USER_GROUP_JURISDICTION_LIST + `/${userGroupJurisdictionId}`, axiosConfig(authData!.token));
  };

  const axiosDeleteUserGroupRole = (userGroupId: any, userGroupRoleId: any) => {
    return axios.delete(Endpoint.USER_GROUP_LIST + `/${userGroupId}` + Endpoint.USER_GROUP_ROLE_LIST + `/${userGroupRoleId}`, axiosConfig(authData!.token));
  };

  const axiosCreateUserGroupRole = (userGroupId: any, userGroupRole: any) => {
    return axios.post(Endpoint.USER_GROUP_LIST + `/${userGroupId}` + Endpoint.USER_GROUP_ROLE_LIST, userGroupRole, axiosConfig(authData!.token));
  };

  return {
    axiosCreateUserGroup,
    axiosDeleteUserGroup,
    axiosGetUserGroupList,
    axiosUpdateUserGroup,
    axiosCreateUserGroupJurisdiction,
    axiosDeleteUserGroupUser,
    axiosDeleteUserGroupJurisdiction,
    axiosCreateUserGroupUser,
    axiosDeleteUserGroupRole,
    axiosCreateUserGroupRole,
  };
}

export type { UserGroupControllerType };
