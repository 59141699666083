import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { Redirect } from "react-router-dom";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import { categoryList, fileFormatList } from "../../../infrastructure/system/Utils";
import CrudReportLogical, { CrudReportLogicalType } from "./CrudReportLogical";
import ReportAccessList from "./report-access/ReportAccessList";
import ReportParameterList from "./report-parameter/ReportParameterList";
import { MultiSelect } from "primereact/multiselect";
import CrudLabel from "../../label/CrudLabel";

export default function CrudReport() {
  const {
    reportOperation,
    reportChange,
    location,
    breadCrumbItems,
    setReportChange,
    onCreate,
    onDelete,
    onUpdate,
    onCancel,
    setIndex,
    index,
    isDisabled,
    reportID,
    displayDialog,
    setDisplayDialog,
    selectedReport,
  }: CrudReportLogicalType = CrudReportLogical();

  const { Labels } = LabelsContext();

  if (!reportID && !location.state) {
    return <Redirect to="/report" />;
  }
  const leftContentsToolbar = () => (
    <>
      {reportOperation === EntityOperation.CREATE && (
        <Button label={Labels.BUTTON_CREATE} tooltip={Labels.LABEL_CREATE_REPORT} tooltipOptions={{ position: "bottom" }} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
      )}
      {reportOperation === EntityOperation.UPDATE && (
        <Button label={Labels.BUTTON_UPDATE} icon="pi pi-pencil" tooltip={Labels.LABEL_UPDATE_REPORT} tooltipOptions={{ position: "bottom" }} className="p-button-warning" onClick={onUpdate} />
      )}
      {reportOperation === EntityOperation.DELETE && (
        <Button label={Labels.BUTTON_DELETE} icon="pi pi-trash" tooltip={Labels.LABEL_DELETE_REPORT} tooltipOptions={{ position: "bottom" }} className="p-button-danger" onClick={onDelete} />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-danger" onClick={onCancel} />
    </>
  );
  const fileFormatTemplate = (option: any) => {
    return <div className="p-clearfix">{option.label}</div>;
  };

  return (
    <div className="layout-crud-form">
      <BreadCrumb model={breadCrumbItems} />
      <CrudLabel
        nameOriginal={selectedReport?.name}
        displayDialog={displayDialog}
        dialogClose={() => {
          setDisplayDialog(false);
        }}
        isDisabled={isDisabled}
        entityOperation={reportOperation}
      />

      <TabView
        className="tabview-margin-top"
        renderActiveOnly={false}
        activeIndex={index}
        onTabChange={(e) => {
          setIndex(e.index);
        }}
      >
        <TabPanel header={Labels.REPORT}>
          <div className="p-col-12 p-xl-8 p-lg-8 p-md-12 p-sm-12">
            <div className="p-grid p-fluid p-align-center p-nogutter p-justify-between">
              <div className="p-col-12">
                <div className="p-grid p-justify-between p-align-center">
                  <div className="p-col-4">
                    <label>{Labels.HEADER_CATEGORY}</label>
                  </div>
                  <div className="p-col-8">
                    <Dropdown
                      disabled={isDisabled}
                      filter
                      placeholder={Labels.CATEGORY_DROPDOWN_CHOOSE}
                      resetFilterOnHide
                      optionLabel="label"
                      optionValue="value"
                      options={categoryList}
                      value={reportChange && reportChange?.category}
                      onChange={(e) =>
                        setReportChange({
                          ...reportChange,
                          category: categoryList?.find((x: any) => x.value === e.value)?.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="p-col-12">
                <div className="p-grid p-justify-between p-align-center">
                  <div className="p-col-4">
                    <div>
                      {Labels.LABEL_NAME}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="p-col-7">
                    <InputText
                      disabled={isDisabled}
                      value={reportChange && reportChange.name}
                      onChange={(e: any) => {
                        setReportChange({
                          ...reportChange!,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="p-col-1 justify-items-end">
                    <Button
                      icon="pi pi-external-link"
                      onClick={() => {
                        setDisplayDialog(true);
                      }}
                      className="margin-bottom-10 margin-left-10"
                      disabled={reportOperation === EntityOperation.READ}
                      tooltip={Labels.LABEL_TRANSLATE_LABEL}
                      tooltipOptions={{ position: "bottom" }}
                    />
                  </div>
                </div>
              </div>
              <div className="p-col-12">
                <div className="p-grid p-justify-between p-align-center">
                  <div className="p-col-4">
                    <div>
                      {Labels.HEADER_JASPER_LOCATION}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="p-col-8">
                    <InputText
                      disabled={isDisabled}
                      value={reportChange && reportChange.jasperLocation}
                      onChange={(e: any) => {
                        setReportChange({
                          ...reportChange!,
                          jasperLocation: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="p-col-12">
                <div className="p-grid p-justify-between p-align-center">
                  <div className="p-col-4">
                    <div>{Labels.LABEL_FILE_FORMAT}</div>
                  </div>
                  <div className="p-col-8">
                    <MultiSelect
                      optionLabel="label"
                      optionValue="value"
                      dataKey="value"
                      value={reportChange && reportChange?.reportFileFormatList}
                      options={fileFormatList}
                      itemTemplate={fileFormatTemplate}
                      maxSelectedLabels={6}
                      selectedItemsLabel={Labels.PLACEHOLDER_CHOOSEN_FORM_TYPE_LIST}
                      disabled={isDisabled}
                      onChange={(e: any) => {
                        setReportChange({
                          ...reportChange,
                          reportFileFormatList: e.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
        </TabPanel>
        <TabPanel header={Labels.REPORT_PARAMETER} disabled={reportOperation === EntityOperation.CREATE}>
          <ReportParameterList></ReportParameterList>
        </TabPanel>
        <TabPanel header={Labels.REPORT_ACCESS} disabled={reportOperation === EntityOperation.CREATE}>
          <ReportAccessList reportOperation={reportOperation}></ReportAccessList>
        </TabPanel>
      </TabView>
    </div>
  );
}
