import React from "react";
import { InputSwitch } from "primereact/inputswitch";
import FieldReadDto from "../../../../model/FieldReadDto";
interface BooleanType {
  field: FieldReadDto;
  onChange?: any;
  value: any;
  isDisabled?: boolean;
  onBlur?: any;
}

export default function Boolean(props: BooleanType) {
  const { field, onChange, value, onBlur, isDisabled } = props;
  return (
    <InputSwitch
      disabled={isDisabled}
      onBlur={() => {
        if (onBlur) onBlur({ field: field, value: value });
      }}
      checked={value ? value : false}
      className={field.isDisabled === true ? "p-disabled-component" : ""}
      onChange={(e: any) => {
        onChange({ field: field, value: e.value });
      }}
    />
  );
}
