import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface LoginControllerType {
  axiosAuthenticate: any;
  axiosLogout: any;
}

const axiosConfiguration = {
  headers: {
    "Content-Type": "application/json;",
  },
};

export function LoginController(): LoginControllerType {
  const { authData } = useContext(AppContext);

  const axiosAuthenticate = (loginData: any) => {
    return axios.post(Endpoint.AUTHENTICATE, loginData, axiosConfiguration);
  };
  const axiosLogout = () => {
    return axios.delete(Endpoint.LOGOUT, axiosConfig(authData!.token));
  };

  return { axiosAuthenticate, axiosLogout };
}

export type { LoginControllerType };
