import { useState, useContext } from "react";
import { ReportController, ReportControllerType } from "../../../../controllers/report/ReportController";
import LabelsContext from "../../../../infrastructure/system/LabelsContext";
import { handleAxiosCallError, useEffectOnce } from "../../../../infrastructure/system/Utils";
import { AppContext } from "../../../../Store";

interface PublicReportLogicalType {
    publicReportList: any;
    first: any;
    tableRows: any;
    onPageGeneratedReport: any;
    breadCrumbItems: any;
}

export default function PublicReportLogical(): PublicReportLogicalType {
    const { showMessage, setShowBlockUI } = useContext(AppContext);
    const [publicReportList, setPublicReportList] = useState<any>([]);
    const [first, setFirst] = useState(0);
    const [tableRows, setTableRows] = useState(10);
    const { Labels } = LabelsContext();
    const {
        axiosGetPublicReportGenerateList
    }: ReportControllerType = ReportController();
    const breadCrumbItems = [
        {
            label: Labels.LABEL_PUBLIC_REPORTS,
            command: () => {
                window.location.reload();
            },
        },
    ];

    useEffectOnce(() => {
        fetchData();
    });

    const fetchData = () => {
        setShowBlockUI(true);
        axiosGetPublicReportGenerateList()
            .then((response: any) => {
                setPublicReportList(response.data.data);
                setShowBlockUI(false);
            })
            .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
                setShowBlockUI(false);
            });
    };

    const onPageGeneratedReport = (rows: any, first: any) => {
        setTableRows(rows);
        setFirst(first);
    };

    return {
        publicReportList, first, tableRows, onPageGeneratedReport, breadCrumbItems
    };
}

export type { PublicReportLogicalType };


