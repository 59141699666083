import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface FieldControllerType {
  axiosGetFieldListByForm: any;
}

export function FieldController(): FieldControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetFieldListByForm = (formCode: string) => {
    return axios.get(`${Endpoint.FORM_LIST}/${formCode}/fieldList`, axiosConfig(authData!.token));
  };

  return { axiosGetFieldListByForm };
}

export type { FieldControllerType };
