import React from "react";
import FieldReadDto from "../../../../model/FieldReadDto";
import CodebookAutocompleteLogical, { CodebookAutocompleteLogicalType } from "./CodebookAutocompleteLogical";
import { AutoComplete } from "primereact/autocomplete";
import Labels from "../../../../infrastructure/system/Labels_sr_Latn_RS";

interface CodebookType {
  field: FieldReadDto;
  onChange?: any;
  value: any;
  isDisabled?: boolean;
  onBlur?: any;
  errorMessage?: any;
  isSearch?: any;
  codebookLogicalControlValidation?: any;
  setFieldCodebookListValidation?: any;
  validate?: any;
}

export default function Codebook(props: CodebookType) {
  const { field, onChange, value, onBlur, errorMessage, isDisabled, codebookLogicalControlValidation, setFieldCodebookListValidation, validate } = props;
  const { codebookSuggestionsList, searchCodebook, selectedCodebook, onChangeAutoComplete, isEmptyList }: CodebookAutocompleteLogicalType = CodebookAutocompleteLogical({
    codebookTypeCode: field?.fieldType?.codebookEnumType,
    value: value,
    onChange: onChange,
    field: field,
    codebookLogicalControlValidation,
    setFieldCodebookListValidation,
  });
  const searchCountry = (event: { query: string }) => {
    setTimeout(() => {
      searchCodebook(event.query);
    }, 250);
  };
  return (
    <div>
      <AutoComplete
        disabled={isDisabled}
        value={selectedCodebook}
        suggestions={codebookSuggestionsList}
        completeMethod={searchCountry}
        field="dropdownValue"
        placeholder={isDisabled ? Labels.SPACE : Labels.PLACEHOLDER_INSERT_CODE_OR_NAME}
        onChange={(e) => {
          onChangeAutoComplete(e);
        }}
        onBlur={() => {
          if (window.location.href.includes("/form")) {
            validate(field, value);
          }
          if (onBlur && selectedCodebook !== null && selectedCodebook !== undefined) onBlur({ field: field, value: value });
        }}
        className={errorMessage && errorMessage !== "" ? "p-invalid" : field.isDisabled === true ? "p-disabled-component" : ""}
        aria-describedby="set-required-help"
      />
      <small id="set-required-help" className="p-error">
        {errorMessage && errorMessage !== "" ? errorMessage : ""}
      </small>
      {selectedCodebook !== undefined && selectedCodebook !== null && selectedCodebook !== "" && isEmptyList && (
        <div>
          <small id="set-required-help" className="p-error">
            {Labels.SPACE + Labels.MESSAGE_RESULT_LIST_IS_EMPTY}
          </small>
        </div>
      )}
    </div>
  );
}
