import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import ExportData from "../../app/export/ExportData";
import FormListLogical, { FormListLogicalType } from "./CrudFormListLogical";
import React from "react";

export default function CrudFormList() {
  const {
    formList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    setSelectedForm,
    selectedForm,
    history,
    breadCrumbItems,
    onPageForm,
    exportData,
    onSearchValueChange,
    deleteForm,
  }: FormListLogicalType = FormListLogical();
  const { Labels } = LabelsContext();
  const dt = useRef<any>(null);
  const openForm = (entityOperation: string) => {
    const codeForm = entityOperation !== EntityOperation.CREATE ? selectedForm.code : "";
    history.push({
      pathname: `/crudForm/${codeForm}`,
      state: { formOperation: entityOperation, selectedForm: selectedForm },
    });
  };

  const leftContents = () => (
    <>
      <Button
        icon="pi pi-plus"
        className="p-button-success btn-margin-right"
        label={Labels.BUTTON_CREATE}
        tooltip={Labels.LABEL_CREATE_FORM}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          openForm(EntityOperation.CREATE);
        }}
      />

      <Button
        icon="pi pi-pencil"
        className="p-button-warning btn-margin-right"
        disabled={!selectedForm}
        label={Labels.BUTTON_UPDATE}
        tooltip={Labels.LABEL_UPDATE_FORM}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          selectedForm && openForm(EntityOperation.UPDATE);
        }}
      />
      <Button
        icon="pi pi-info-circle"
        label={Labels.BUTTON_DETAILS}
        tooltip={Labels.LABEL_DETAILS_FORM}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedForm}
        onClick={() => {
          selectedForm && openForm(EntityOperation.READ);
        }}
      />
    </>
  );

  const rightContents = () => (
    <>
      <ExportData data={exportData} name={Labels.FORM_LIST} dt={dt} />
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        label={Labels.BUTTON_DELETE}
        disabled={!selectedForm}
        onClick={() => {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FORM,
            icon: "pi pi-power-off",
            accept: () => {
              deleteForm(selectedForm.code);
            },
          });
        }}
      />
    </>
  );

  return (
    <div className="layout-crud-form">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
        <Toolbar left={leftContents} right={rightContents} />
        <div>
          <DataTable
            ref={dt}
            value={formList}
            paginator
            first={first}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            rows={tableRows}
            rowsPerPageOptions={[5, 10, 20]}
            onPage={(e) => {
              onPageForm(e.rows, e.first);
            }}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedForm(e.data)}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            exportFilename={Labels.FORM_LIST}
            onValueChange={onSearchValueChange}
          >
            <Column field={"name"} header={Labels.HEADER_NAME} filter filterMatchMode="contains" sortable />
            <Column field={"code"} header={Labels.HEADER_CODE} filter filterMatchMode="contains" sortable />
            <Column field={"description"} header={Labels.HEADER_DESCRIPTION} filter filterMatchMode="contains" sortable />
          </DataTable>
        </div>
      </Panel>
    </div>
  );
}
