import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface LabelControllerType {
  axiosGetLabelList: any;
  axiosCreateLabel: any;
  axiosUpdateLabel: any;
}

export function LabelController(): LabelControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetLabelList = (token: string) => {
    return axios.get(Endpoint.LABEL, axiosConfig(token));
  };

  const axiosCreateLabel = (body: any) => {
    return axios.post(Endpoint.LABEL, body, axiosConfig(authData!.token));
  };

  const axiosUpdateLabel = (body: any) => {
    return axios.put(`${Endpoint.LABEL}/${body.id}`, body, axiosConfig(authData!.token));
  };

  return {
    axiosGetLabelList,
    axiosCreateLabel,
    axiosUpdateLabel,
  };
}

export type { LabelControllerType };
