import axios from "axios";
// @ts-ignore
import classNames from "classnames";
import { Toast } from "primereact/toast";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Content from "../Content";
import LabelsContext from "../infrastructure/system/LabelsContext";
import { useEffectOnce } from "../infrastructure/system/Utils";
import { AppContext } from "../Store";
import AppFooter from "./app/footer/AppFooter";
import AppMenu from "./app/menu/AppMenu";
import AppTopbar from "./app/topbar/AppTopbar";
import EnumLanguage from "../infrastructure/system/EnumLanguage";
import { RoleService, RoleServiceType } from "../controllers/role/RoleService";

export default function Home() {
  const { deleteAuthData, setMessages, locale, authData } = useContext(AppContext);
  const { isAdmin, isSuperAdmin, isBed, isEd, isMd }: RoleServiceType = RoleService();
  const { Labels } = LabelsContext();
  const history = useHistory();

  axios.interceptors.request.use(function (config) {
    if (locale === EnumLanguage.CYRILLIC) {
      config.params = { ...config.params, language: "sr_RS" };
    }
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      if (response.status === 401) {
        deleteAuthData();
      }
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        deleteAuthData();
        history.push("/");
      }
      return Promise.reject(error);
    }
  );
  const [menu, setMenu] = useState<any>([]);
  useEffectOnce(() => {
    createMenu();
  });
  const [menuActive, setMenuActive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
  const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);

  const menuMode = "slim";
  const colorScheme = "light";
  const menuTheme = "layout-sidebar-blue";

  const containerClassName = classNames(
    "layout-wrapper",
    {
      "layout-overlay": false,
      "layout-static": false,
      "layout-slim": true,
      "layout-sidebar-dim": false,
      "layout-sidebar-dark": false,
      "layout-overlay-active": overlayMenuActive,
      "layout-mobile-active": staticMenuMobileActive,
      "layout-static-inactive": staticMenuDesktopInactive && false,
      "p-input-filled": false,
      "p-ripple-disabled": true,
    },
    colorScheme === "light" ? menuTheme : ""
  );

  useEffect(() => {
    createMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const onDocumentClick = () => {};

  const createMenu = () => {
    let menu = [];
    if (isMd() || isEd() || isBed()) {
      menu.push({
        label: Labels.MENU_FORMS,
        icon: "pi pi-fw pi-book",
        to: "/registry",
      });
      menu.push({ separator: true });
    }
    if (isAdmin() || isSuperAdmin()) {
      menu.push({
        label: Labels.MENU_ADMIN,
        icon: "pi pi-fw pi-shield",
        items: [
          { label: Labels.USER_GROUP_LIST, icon: "pi pi-fw pi-users", to: "/userGroupList" },
          { label: Labels.JURISDICTIONS, icon: "pi pi-fw pi-globe", to: "/jurisdictionList" },
          { label: Labels.USER_LIST, icon: "pi pi-fw pi-user", to: "/userList" },
          { label: Labels.INSTITUTION_LIST, icon: "pi pi-fw pi-home", to: "/institutionList" },
          { label: Labels.LABEL_FORM_LIST, icon: "pi pi-fw pi-book", to: "/crudFormList" },
          { label: Labels.LABEL_FIELD_TYPE, icon: "pi pi-fw pi-table", to: "/fieldTypeList" },
          {
            label: Labels.LABEL_COODEBOOK_TYPE_LIST,
            icon: "pi pi-fw pi-list",
            items: [
              { label: Labels.LABEL_COODEBOOK_TYPE_LIST, icon: "pi pi-fw pi-list", to: "/codebookTypeList" },
              {
                label: Labels.LABEL_LOGICAL_CONTROL,
                icon: "pi pi-fw pi-sitemap",
                to: "/crudCodebookLogicalControlList",
              },
            ],
          },
          { label: Labels.REPORT_LIST, icon: "pi pi-chart-bar", to: "/report" },
          { label: Labels.LABEL_LOG_LIST, icon: "pi pi-eye pi-list", to: "/logActionList" },
        ],
      });
      menu.push({ separator: true });
    }
    menu.push({
      label: Labels.MENU_REPORT,
      icon: "pi pi-fw pi-chart-bar",
      to: "/",
      items: authData?.currentUser.generateReportAccess
        ? [
            { label: Labels.LABEL_GENERATE_REPORT, icon: "pi pi-fw pi-list", to: "/generateReport" },
            {
              label: Labels.LABEL_PUBLIC_REPORTS,
              icon: "pi pi-fw pi-file-o",
              to: "/reportPublic",
            },
          ]
        : [
            {
              label: Labels.LABEL_PUBLIC_REPORTS,
              icon: "pi pi-fw pi-file-o",
              to: "/reportPublic",
            },
          ],
    });
    menu.push({ separator: true });
    setMenu(menu);
  };

  const onMenuClick = () => {};

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
    unblockBodyScroll();
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(new RegExp("(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)", "gi"), " ");
    }
  };

  const onMenuitemClick = (event: any) => {
    if (!event.item?.items) {
      hideOverlayMenu();

      if (isSlim()) {
        setMenuActive(false);
      }
    }
  };

  const isSlim = () => {
    return menuMode === "slim";
  };

  const onRootMenuitemClick = () => {
    setTopbarUserMenuActive(false);
    setMenuActive((prevMenuActive) => !prevMenuActive);
  };

  const onMenuButtonClick = (event: any) => {
    setTopbarUserMenuActive(false);
    setTopbarNotificationMenuActive(false);

    if (isOverlay()) {
      setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
    }

    if (isDesktop()) {
      setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
    } else {
      setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
    }

    event.preventDefault();
  };

  const isOverlay = () => {
    return false;
  };

  const isDesktop = () => {
    return window.innerWidth > 991;
  };

  const onTopbarUserMenuButtonClick = (event: any) => {
    setTopbarUserMenuActive((prevTopbarUserMenuActive) => !prevTopbarUserMenuActive);

    hideOverlayMenu();

    event.preventDefault();
  };

  const routers = [
    { path: "/userGroupList", name: Labels.USER_GROUP },
    { path: "/jurisdictionList", name: Labels.JURISDICTIONS },
    { path: "/userList", name: Labels.USER_LIST },
    { path: "/institutionList", name: Labels.INSTITUTION_LIST },
    { path: "/userProfile", name: Labels.USER_PROFILE_CHANGE },
    { path: "/form", name: Labels.FORM },
    { path: "/crudFormList", name: Labels.FORM_LIST },
    { path: "/codebookTypeList", name: Labels.LABEL_COODEBOOK_TYPE_LIST },
    { path: "/crudCodebookLogicalControlList", name: Labels.LOGICAL_CONTROL_TITLE },
    { path: "/codebookList", name: Labels.LABEL_COODEBOOK_LIST },
    { path: "/crudForm", name: Labels.FORM },
    { path: "/fieldTypeList", name: Labels.FIELD_TYPES },
    { path: "/crudFieldType", name: Labels.FIELD_TYPES },
    { path: "/registry", name: Labels.MENU_FORMS },
    { path: "/forRevisionFormList", name: Labels.MENU_FORMS },
    { path: "/logActionList", name: Labels.LABEL_LOG_LIST },
    { path: "/mergeForms", name: Labels.LABEL_MERGE_FORMS },
    { path: "/report", name: Labels.MENU_REPORT },
    { path: "/crudReport", name: Labels.REPORT },
    { path: "/generateReport", name: Labels.LABEL_GENERATE_REPORT },
    { path: "/reportPublic", name: Labels.LABEL_PUBLIC_REPORTS },
  ];

  return (
    <div className="layout-message">
      <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>
        <div className="layout-content-wrapper">
          <AppTopbar
            routers={routers}
            topbarNotificationMenuActive={topbarNotificationMenuActive}
            topbarUserMenuActive={topbarUserMenuActive}
            onMenuButtonClick={onMenuButtonClick}
            onTopbarUserMenu={onTopbarUserMenuButtonClick}
            setTopbarUserMenuActive={setTopbarUserMenuActive}
          />
          <div>
            <Toast
              ref={(el) => {
                setMessages(el);
              }}
            />
          </div>

          <div className="layout-content">
            <Content />
          </div>
          <AppFooter />
        </div>
        <AppMenu
          model={menu}
          menuMode={menuMode}
          active={menuActive}
          mobileMenuActive={staticMenuMobileActive}
          onMenuClick={onMenuClick}
          onMenuitemClick={onMenuitemClick}
          onRootMenuitemClick={onRootMenuitemClick}
        />
        <div className="layout-mask modal-in"></div>
      </div>
    </div>
  );
}
