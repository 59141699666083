import React from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { Toolbar } from "primereact/toolbar";
import { Tooltip } from "primereact/tooltip";
import AvatarEditor from "react-avatar-editor";
import Images from "../../infrastructure/system/Images";
import LabelsContext from "../../infrastructure/system/LabelsContext";
import { imageExtention, languageList } from "../../infrastructure/system/Utils";
import UserProfileLogical, { UserProfileLogicalType } from "./UserProfileLogical";

export default function UserProfilePage() {
  const {
    changeUserData,
    editUser,
    userChange,
    cancelEdit,
    _handleFileChange,
    handleScale,
    handleSave,
    setEditorRef,
    avatar,
    avatarChangeVisible,
    avatarVisible,
    avatarNotVisible,
    goBack,
  }: UserProfileLogicalType = UserProfileLogical();

  const { Labels } = LabelsContext();

  const leftContents = () => (
    <>
      <Button label={Labels.USER_PROFILE_BUTTON_SAVE} tooltip={Labels.SAVE_USER_DATA} className="p-button-success button-save" onClick={editUser} />
    </>
  );

  const rightContents = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} tooltip={Labels.TOOLTIP_RETURN} className="p-button-danger button-save" onClick={goBack} />
      <Button label={Labels.USER_PROFILE_BUTTON_CANCEL} tooltip={Labels.CANCEL_USER_DATA} className="p-button-danger button-cancel-update" onClick={cancelEdit} />
    </>
  );

  return (
    <div className="layout-user">
      <Panel header={Labels.USER_PROFILE_CHANGE} className="panel-width">
        <div className="p-grid p-justify-between">
          <div className="p-col-6">
            {!avatarChangeVisible && (
              <div>
                <div
                  onClick={avatarVisible}
                  className="profile-picture tooltip-profile"
                  style={{
                    backgroundImage: userChange?.profilePicture ? `url(data:image/${imageExtention(userChange.profilePicture!)};base64,${userChange.profilePicture})` : `url(${Images.NO_PROFILE})`,
                  }}
                />
                <Tooltip target=".tooltip-profile" position="right">
                  {Labels.USER_PROFILE_CHANGE_IMAGE}
                </Tooltip>
              </div>
            )}

            {avatarChangeVisible && (
              <div className="text-center">
                <div className="p-grid p-justify-center ">
                  <AvatarEditor
                    className="margin-bottom-10 avatar-editor-size"
                    ref={setEditorRef}
                    image={avatar.img}
                    width={200}
                    height={200}
                    border={50}
                    color={[255, 255, 255, 0.6]} // RGBA
                    scale={avatar.zoom}
                    rotate={0}
                  />
                </div>
                <br />
                <div className="p-grid p-justify-center">
                  <input hidden id="file" type="file" accept="image/*" onChange={_handleFileChange} />
                  <Button className="button-upload-image btn-small">
                    <label htmlFor="file">{Labels.BUTTON_UPLOAD_IMAGE}</label>
                  </Button>

                  <Button
                    className="button-save-picture btn-small"
                    label={Labels.BUTTON_SAVE}
                    onClick={() => {
                      handleSave();
                    }}
                  ></Button>
                  <Button className="button-cancel-picture btn-small" label={Labels.BUTTON_CANCEL} onClick={avatarNotVisible}></Button>
                </div>
                <br />
                <div className="p-grid p-justify-center">
                  <input name="scale" type="range" onChange={handleScale} min={avatar.allowZoomOut ? "0.1" : "1"} max="2" step="0.01" defaultValue="1" />
                </div>
              </div>
            )}
          </div>
          <div className="p-col-6 user-data">
            <div className="login-input">
              <label className="edit-user-label">{Labels.USER_USER_NAME}</label>
              <InputText name="username" className="p-col-12" value={userChange.username} disabled={true} />
            </div>
            <div className="login-input">
              <label className="edit-user-label">{Labels.USER_FIRST_NAME}</label>
              <InputText name="firstname" className="p-col-12" value={userChange.firstname} onChange={(e) => changeUserData(e)} />
            </div>
            <div className="login-input">
              <label className="edit-user-label">{Labels.USER_LAST_NAME}</label>
              <InputText name="lastname" className="p-col-12" value={userChange.lastname} onChange={(e) => changeUserData(e)} />
            </div>
            <div className="login-input">
              <label className="edit-user-label">{Labels.USER_EMAIL}</label>
              <InputText name="email" className="p-col-12" value={userChange.email} onChange={(e) => changeUserData(e)} />
            </div>
            <div className="login-input">
              <label className="edit-user-label">{Labels.USER_PROFILE_CHANGE_LANGUANGE}</label>
              {languageList(Labels).map((l) => (
                <div key={l.id} className="p-field-radiobutton">
                  <RadioButton inputId={l.id} name="language" value={l.id} checked={userChange.language === l.id} onChange={changeUserData} />
                  <label htmlFor={l.id}>{l.name}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="p-col-12">
            <Toolbar left={leftContents} right={rightContents} />
          </div>
        </div>
      </Panel>
    </div>
  );
}
