import { ChangeEvent, useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import { CodebookController, CodebookControllerType } from "../../../controllers/codebook/CodebookController";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import { FIELD, handleAxiosCallError, LIMIT, OUTPUT_FIELDS, TYPE, useEffectOnce } from "../../../infrastructure/system/Utils";
import { AppContext } from "../../../Store";
import axios from "axios";

interface CodebookLogicalType {
  codebookList: any;
  first: number;
  tableRows: number;
  selectedRow: any;
  selectedCodebook: any;
  onPage: any;
  totalRecords: number;
  setSelectedRow: any;
  setSelectedCodebook: any;
  breadCrumbItems: any;
  exportData: any;
  searchData: any;
  changeSearchData: any;
  searchCodebookByEnter: any;
  searchCodebook: any;
  onSearchValueChange: any;
  codebookType: any;
  getAllCodeBook: any;
  getCodebookSjz: any;
  path: any;
}

export default function CodebookLogical() {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const { path } = useParams<any>();
  const { axiosGetCodebookList, axiosGetCodebookType, axiosCountCodebookList, axiosGetCodebookListWithCode, axiosGetCodebookFromSjz }: CodebookControllerType = CodebookController();
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [totalRecords, setTotalRecords] = useState<any>(0);
  const [codebookList, setCodebookList] = useState([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState<boolean>(false);
  const [selectedCodebook, setSelectedCodebook] = useState<any>();
  const [searchData, setSearchData] = useState<any>({});
  const [codebookType, setCodebookType] = useState<any>({});
  const [codebookCode, setCodebookCode] = useState<any>();
  const [codebookBatutCode, setCodebookBatutCode] = useState<any>();
  const { Labels } = LabelsContext();
  const history = useHistory();
  const [breadCrumbItems, setBreadCrumbItems] = useState<any>([]);


  const fetchData = () => {
    setShowBlockUI(true);
    const params = {
      page: page,
      size: tableRows,
      name: searchData.name ? searchData.name : "",
      code: searchData.code ? searchData.code : "",
    };

    axios
      .all([axiosCountCodebookList(path, params), axiosGetCodebookType(path), axiosGetCodebookList(path, params)])
      .then(
        axios.spread((responseCount, responseCodebookType, responseCodebook) => {
          setTotalRecords(responseCount.data.data);
          setCodebookType(responseCodebookType.data.data.name);
          setCodebookBatutCode(responseCodebookType.data.data.batutCode);
          setCodebookCode(responseCodebookType.data.data.code);
          setBreadCrumbItems([
            {
              label: Labels.LABEL_COODEBOOK_TYPE_LIST,
              command: () => {
                history.push("/codebookTypeList");
              },
            },
            {
              label: responseCodebookType.data.data.name,
              command: () => {
                window.location.reload();
              },
            },
          ]);
          setCodebookList(responseCodebook.data.data);
          const exportList = new Array<any>();
          responseCodebook.data.data.forEach((codebook: any) => {
            exportList.push({ [Labels.LABEL_CODE]: codebook.code, [Labels.LABEL_NAME]: codebook.name });
          });
          setExportData(exportList);
          setShowBlockUI(false);
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  useEffectOnce(() => {
    fetchData();
  });

  const changeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const countCodebook = () => {
    const params = {
      name: searchData.name ? searchData.name : "",
      code: searchData.code ? searchData.code : "",
    };
    axiosCountCodebookList(path, params)
      .then((response: any) => {
        setTotalRecords(response.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const getCodebookSjz = () => {
    setShowBlockUI(true);
    const params = {
      limit: LIMIT,
      output_fields: OUTPUT_FIELDS,
      query: { "sif_ts_id": codebookBatutCode },
      sort: [{
        field: FIELD,
        type: TYPE
      }],
    };
    axiosGetCodebookFromSjz(params)
      .then((response: any) => {
        fetchData();
        setShowBlockUI(false);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const searchCodebook = () => {
    const params = {
      page: page,
      size: tableRows,
      name: searchData.name ? searchData.name : "",
      code: searchData.code ? searchData.code : "",
    };
    axiosGetCodebookList(path, params)
      .then((response: any) => {
        setCodebookList(response.data.data);
        const exportList = new Array<any>();

        response.data.data.forEach((codebook: any) => {
          exportList.push({ [Labels.LABEL_CODE]: codebook.code, [Labels.LABEL_NAME]: codebook.name });
        });
        setExportData(exportList);
        countCodebook();
        setShowBlockUI(false);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const searchCodebookByEnter = (event: any) => {
    if (event.charCode === 13) {
      searchCodebook();
    }
  };

  const onPage = (e: any) => {
    setShowBlockUI(true);
    setTableRows(e.rows);
    setFirst(e.first);
    setPage(e.page);
    const params = {
      page: e.page,
      size: e.rows,
      name: searchData.name ? searchData.name : "",
      code: searchData.code ? searchData.code : "",
    };
    axiosGetCodebookList(path, params)
      .then((response: any) => {
        setCodebookList(response.data.data);
        const exportList = new Array<any>();
        response.data.data.forEach((codebook: any) => {
          exportList.push({ [Labels.LABEL_CODE]: codebook.code, [Labels.LABEL_NAME]: codebook.name });
        });
        setExportData(exportList);
        setShowBlockUI(false);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const onSearchValueChange = (sortedData: Array<any>) => {
    const exportList = new Array<any>();
    sortedData?.forEach((codebook: any) => {
      exportList.push({ [Labels.LABEL_CODE]: codebook.code, [Labels.LABEL_NAME]: codebook.name });
    });
    setExportData(exportList);
  };
  const getAllCodeBook = () => {
    setShowBlockUI(true);
    return axiosGetCodebookListWithCode(codebookCode)
      .then((response: any) => {
        const exportList = new Array<any>();
        response.data.data.forEach((codebook: any) => {
          exportList.push({ [Labels.LABEL_CODE]: codebook.code, [Labels.LABEL_NAME]: codebook.name });
        });
        setExportData(exportList);
        setShowBlockUI(false);
        return exportList;
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  return {
    codebookList,
    first,
    tableRows,
    selectedRow,
    selectedCodebook,
    onPage,
    totalRecords,
    setSelectedRow,
    setSelectedCodebook,
    breadCrumbItems,
    exportData,
    searchData,
    searchCodebookByEnter,
    changeSearchData,
    searchCodebook,
    onSearchValueChange,
    codebookType,
    getAllCodeBook,
    getCodebookSjz,
    path
  };
}

export type { CodebookLogicalType };
