import moment from "moment";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import { CALENDAR_DATE_FORMAT, DATE_TIME_FORMAT_3 } from "../../../infrastructure/system/Utils";
import ExportData from "../../app/export/ExportData";
import LogActionListLogical, { LogActionLogicalType } from "./LogActionListLogical";
export default function LogActionList() {
  const {
    logActionList,
    first,
    tableRows,
    selectedRow,
    onPage,
    breadCrumbItems,
    setSelectedRow,
    totalRecords,
    getTime,
    searchData,
    changeSearchData,
    searchLogs,
    setSearchData,
    changeTextSearchData,
    classNames,
    logTypes,
    exportData,
    searchLogsByEnter,
  }: LogActionLogicalType = LogActionListLogical();
  const { Labels } = LabelsContext();
  const dt = useRef<any>(null);
  const rightContents = () => (
    <>
      <ExportData data={exportData} name={Labels.LABEL_LOG_LIST} dt={dt} />
    </>
  );
  return (
    <div className="layout-jurisdiction">
      <Panel>
        <div className="p-grid">
          <div className="p-col-3">
            <Card className="p-col-12 ">
              <span className="p-float-label">
                <InputNumber
                  className="p-input"
                  inputClassName="p-input"
                  inputId="objectId"
                  name="objectId"
                  value={searchData.objectId}
                  onChange={(e: any) => {
                    changeSearchData("objectId", e);
                  }}
                />
                <label htmlFor="objectId">{Labels.OBJECT}</label>
              </span>
              <span className="p-float-label">
                <InputText className="p-col-12" id="field" name="field" value={searchData.field} onChange={changeTextSearchData} onKeyPress={searchLogsByEnter} />
                <label htmlFor="field">{Labels.FIELD_TYPE}</label>
              </span>
              <span className="p-float-label">
                <Dropdown
                  className="p-col-12"
                  inputId="logType"
                  name="logType"
                  value={searchData.logType}
                  options={logTypes}
                  optionLabel="description"
                  optionValue="code"
                  showClear
                  filter
                  onChange={(e: any) => {
                    changeSearchData("logType", e);
                  }}
                />
                <label htmlFor="logType">{Labels.ACTION_TYPE}</label>
              </span>
              <span className="p-float-label">
                <Dropdown
                  className="p-col-12"
                  inputId="className"
                  name="className"
                  optionLabel="name"
                  optionValue="code"
                  showClear
                  filter
                  value={searchData.className}
                  options={classNames}
                  onChange={(e: any) => {
                    changeSearchData("className", e);
                  }}
                />
                <label htmlFor="className">{Labels.CLASS}</label>
              </span>
              <span className="p-float-label">
                <InputText className="p-col-12" id="oldValue" name="oldValue" value={searchData.oldValue} onChange={changeTextSearchData} onKeyPress={searchLogsByEnter} />
                <label htmlFor="oldValue">{Labels.OLD_VALUE}</label>
              </span>
              <span className="p-float-label">
                <InputText className="p-col-12" id="newValue" name="newValue" value={searchData.newValue} onChange={changeTextSearchData} onKeyPress={searchLogsByEnter} />
                <label htmlFor="newValue">{Labels.NEW_VALUE}</label>
              </span>
              <span className="p-float-label">
                <InputText className="p-col-12" id="username" name="username" value={searchData.username} onChange={changeTextSearchData} onKeyPress={searchLogsByEnter} />
                <label htmlFor="username">{Labels.PLACEHOLDER_USERNAME}</label>
              </span>
              <span className="p-float-label">
                <Calendar
                  id="insertedTimeFrom"
                  name="insertedTimeFrom"
                  className="p-input"
                  inputClassName="p-input"
                  showTime
                  showSeconds
                  dateFormat={CALENDAR_DATE_FORMAT}
                  value={searchData.insertedTimeFrom ? new Date(searchData.insertedTimeFrom) : undefined}
                  onChange={(e: any) => {
                    setSearchData({
                      ...searchData,
                      insertedTimeFrom: e.value ? moment(e.value.toString()).format(DATE_TIME_FORMAT_3) : null,
                    });
                  }}
                />
                <label htmlFor="insertedTimeFrom">{Labels.INSERT_TIME_FROM}</label>
              </span>
              <span className="p-float-label">
                <Calendar
                  id="insertedTimeTo"
                  name="insertedTimeTo"
                  className="p-input"
                  inputClassName="p-input"
                  showTime
                  dateFormat={CALENDAR_DATE_FORMAT}
                  showSeconds
                  value={searchData.insertedTimeTo ? new Date(searchData.insertedTimeTo) : undefined}
                  onChange={(e: any) => {
                    setSearchData({
                      ...searchData,
                      insertedTimeTo: e.value ? moment(e.value.toString()).format(DATE_TIME_FORMAT_3) : null,
                    });
                  }}
                />
                <label htmlFor="insertedTimeTo">{Labels.INSERT_TIME_TO}</label>
              </span>
              <span className="p-float-label">
                <InputText className="p-col-12" id="ipAddress" name="ipAddress" value={searchData.ipAddress} onChange={changeTextSearchData} onKeyPress={searchLogsByEnter} />
                <label htmlFor="ipAddress">{Labels.IP_ADDRESS}</label>
              </span>

              <Button label={Labels.USER_SEARCH} tooltip={Labels.SEARCH_LOGS} onClick={searchLogs} icon="pi pi-search" className="search-button" />
            </Card>
          </div>
          <div className="p-col-9">
            <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
            <Toolbar right={rightContents} />
            <div>
              <DataTable
                ref={dt}
                value={logActionList}
                paginator
                first={first}
                lazy
                totalRecords={totalRecords}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rows={tableRows}
                rowsPerPageOptions={[5, 10, 20, 5000, 10000]}
                onPage={(e) => {
                  onPage(e);
                }}
                selectionMode="single"
                selection={selectedRow}
                onSelectionChange={(e) => setSelectedRow(e.value)}
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                exportFilename={Labels.LABEL_LOG_LIST}
              >
                <Column field={"objectId"} header={Labels.OBJECT} className="word-wrap-anywhere" />
                <Column field={"field"} header={Labels.FIELD_TYPE} className="word-wrap-anywhere" />
                <Column field={"logType.description"} header={Labels.ACTION_TYPE} className="word-wrap-anywhere" />
                <Column field={"className.name"} header={Labels.CLASS} className="word-wrap-anywhere" />
                <Column field={"oldValue"} header={Labels.OLD_VALUE} className="word-wrap-anywhere" />
                <Column field={"newValue"} header={Labels.NEW_VALUE} className="word-wrap-anywhere" />
                <Column field={"user.username"} header={Labels.PLACEHOLDER_USERNAME} className="word-wrap-anywhere" />
                <Column field={"insertTimestamp"} header={Labels.INSERT_TIME} body={(e: any) => getTime(e)} className="word-wrap-anywhere" />
                <Column field={"ipAddress"} header={Labels.IP_ADDRESS} className="word-wrap-anywhere" />
              </DataTable>
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
}
