import { Dropdown } from "primereact/dropdown";
import { useImperativeHandle } from "react";
import LabelsContext from "../../../../infrastructure/system/LabelsContext";
import { operatorSemanticValidation } from "../../../../infrastructure/system/Utils";
import FormLogical, { FormLogicalType } from "../../../form/FormLogical";

interface CrudFormSemanticValidationType {
  dialogRef: any;
  isDisabled: boolean;
  formSemanticValidationToChange: any;
  setFormSemanticValidationToChange: any;
  setDisplayDialog: any;
  onCreate: any;
  onUpdate: any;
  onDelete: any;
}

export default function CrudFormSemanticValidation(props: CrudFormSemanticValidationType) {
  const { Labels } = LabelsContext();

  const {
    dialogRef,
    formSemanticValidationToChange,
    isDisabled,
    setFormSemanticValidationToChange,
    onCreate,
    onUpdate,
    onDelete,
  } = props;
  const { semanticValidationFieldList }: FormLogicalType = FormLogical();
  useImperativeHandle(dialogRef, () => ({
    onCreate: onCreate,
    onUpdate: onUpdate,
    onDelete: onDelete,
  }));

  return (
    <div className="layout-form-semantic-validation">
      <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
        <div className="p-grid p-align-center p-nogutter">
          <div className="p-col-4">
            <div>
              {Labels.HEADER_FIELD}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <Dropdown
              disabled={isDisabled}
              options={semanticValidationFieldList}
              optionValue="id"
              optionLabel="name"
              filter
              showClear
              value={formSemanticValidationToChange && formSemanticValidationToChange.fieldId}
              onChange={(e: any) => {
                setFormSemanticValidationToChange({
                  ...formSemanticValidationToChange!,
                  fieldId: e.target.value,
                });
              }}
            />
          </div>
          <div className="p-col-4">
            <div>
              {Labels.HEADER_OPERATOR}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <Dropdown
              disabled={isDisabled}
              options={operatorSemanticValidation(Labels)}
              optionValue="id"
              optionLabel="name"
              filter
              showClear
              value={formSemanticValidationToChange && formSemanticValidationToChange.operator}
              onChange={(e: any) => {
                setFormSemanticValidationToChange({
                  ...formSemanticValidationToChange!,
                  operator: e.target.value,
                });
              }}
            />
          </div>
          <div className="p-col-4">
            <div>
              {Labels.HEADER_FIELD_TARGET}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <Dropdown
              disabled={isDisabled}
              options={semanticValidationFieldList}
              optionValue="id"
              optionLabel="name"
              filter
              showClear
              value={formSemanticValidationToChange && formSemanticValidationToChange.fieldTargetId}
              onChange={(e: any) => {
                setFormSemanticValidationToChange({
                  ...formSemanticValidationToChange!,
                  fieldTargetId: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
