import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface EnumControllerType {
  axiosGetFormStatusList: any;
}

export function EnumController(): EnumControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetFormStatusList = () => {
    return axios.get(Endpoint.FORM_STATUS_LIST, axiosConfig(authData!.token));
  };

  return { axiosGetFormStatusList };
}

export type { EnumControllerType };
