import { ProgressSpinner } from "primereact/progressspinner";
import { useContext } from "react";
import { AppContext } from "./Store";

export default function BlockUI() {
  const { showBlockUI } = useContext(AppContext);

  if (!showBlockUI) {
    return null;
  }

  return (
    <div className="block-ui">
      <ProgressSpinner />
    </div>
  );
}
