import React from "react";

import { InputTextarea } from "primereact/inputtextarea";
import FieldReadDto from "../../../../model/FieldReadDto";

interface LongTextType {
  field: FieldReadDto;
  onChange?: any;
  value: any;
  onBlur?: any;
  errorMessage?: any;
  isDisabled?: boolean;
}

export default function LongText(props: LongTextType) {
  const { field, onChange, value, onBlur, errorMessage, isDisabled } = props;

  return (
    <div>
      <InputTextarea
        disabled={isDisabled}
        onBlur={() => {
          if (onBlur) onBlur({ field: field, value: value });
        }}
        rows={field.fieldType.min}
        cols={field.fieldType.max}
        value={value ? value : ""}
        onChange={(e: any) => {
          onChange({ field: field, value: e.target.value });
        }}
        className={errorMessage && errorMessage !== "" ? "p-invalid" : field.isDisabled === true ? "p-disabled-component" : ""}
        aria-describedby="longtext-required-help"
      />
      <small id="longtext-required-help" className="p-error">
        {errorMessage && errorMessage !== "" ? errorMessage : ""}
      </small>
    </div>
  );
}
