import React from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { Tooltip } from "primereact/tooltip";
import { RoleService, RoleServiceType } from "../../controllers/role/RoleService";
import EntityOperation from "../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../infrastructure/system/LabelsContext";
import { FORM_CCF, FORM_DEATH_REPORT, getOperator } from "../../infrastructure/system/Utils";
import ExportData from "../app/export/ExportData";
import Field from "../form/field/Field";
import MDProfileLogical, { MDProfileLogicalType } from "./MDPageLogical";

export default function MDPageView() {
  const {
    formStatuses,
    formDataList,
    fieldList,
    searchDataOne,
    setSearchDataOne,
    searchData,
    setSelectedRow,
    selectedRow,
    selectedFormFilled,
    setSelectedFormFilled,
    addSearchData,
    removeSearchData,
    onChange,
    search,
    searchFormStatus,
    setSearchFormStatus,
    breadCrumbItems,
    exportData,
    formList,
    searchFormType,
    changeFormType,
    hasDeleteRight,
    hasReadRight,
    hasUpdateRight,
    pmtHasCreateRight,
    pulHasCreateRight,
    onSearchValueChange,
    dt,
    selectedStatus,
    setSelectedStatus,
    history,
  }: MDProfileLogicalType = MDProfileLogical();
  const { Labels } = LabelsContext();
  const { isBed, isEd, isMd }: RoleServiceType = RoleService();
  const renderHeader = () => {
    return <div className="table-header">{Labels.LABEL_TABLE_OF_FORMS}</div>;
  };
  const renderStatusFilter = () => {
    return <Dropdown showClear onChange={onStatusFilterChange} value={selectedStatus} options={formStatuses} placeholder={Labels.PLACEHOLDER_SEARCH_BY_STATUS} className="p-column-filter" />;
  };
  const onStatusFilterChange = (event: any) => {
    dt.current.filter(event.value, "status", "equals");
    setSelectedStatus(event.value);
    setSelectedRow([]);
  };
  const statusFilterElement = renderStatusFilter();

  const leftContents = () => (
    <>
      {pulHasCreateRight && (
        <Button
          label={Labels.BUTTON_REPORT_OF_DEATH}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-plus"
          tooltip={Labels.CREATE_REPORT_OF_DEATH}
          className="p-mr-2 p-button-danger"
          onClick={() => history.push({ pathname: `/form/${FORM_DEATH_REPORT}`, state: { entityOperation: EntityOperation.CREATE } })}
        />
      )}
      {isMd() && !isEd() && !isBed() && (
        <>
          {pmtHasCreateRight && (
            <Button
              label={Labels.BUTTON_NEW_FORM}
              tooltip={Labels.HEADER_CREATE_FORM}
              tooltipOptions={{ position: "bottom" }}
              icon="pi pi-plus"
              className="p-mr-2 p-button-success"
              onClick={() => history.push({ pathname: `/form/${FORM_CCF}`, state: { entityOperation: EntityOperation.CREATE } })}
            />
          )}
          {hasUpdateRight && (
            <Button
              label={selectedFormFilled !== undefined && selectedFormFilled.status === Labels.LABEL_UNFINISHED ? Labels.BUTTON_EDIT_DRAFT : Labels.BUTTON_EDIT}
              icon="pi pi-pencil"
              tooltip={Labels.HEADER_UPDATE_FORM_FILLED}
              tooltipOptions={{ position: "bottom" }}
              className="p-mr-2 p-button-warning"
              disabled={
                selectedFormFilled === undefined || selectedRow.length !== 1 || (selectedFormFilled.status !== Labels.LABEL_UNFINISHED && selectedFormFilled.status !== Labels.LABEL_FOR_REVISION)
              }
              onClick={() => {
                history.push({
                  pathname: `/form/${selectedFormFilled.formCode}/${selectedFormFilled.id}`,
                  state: { entityOperation: EntityOperation.UPDATE, searchFormStatus: searchFormStatus, searchFormType: searchFormType },
                });
              }}
            />
          )}
        </>
      )}
      {hasReadRight && (
        <Button
          label={Labels.BUTTON_DETAILS}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-info-circle"
          tooltip={Labels.HEADER_READ_FORM_FILLED}
          className="p-mr-2 p-button-info"
          disabled={selectedFormFilled === undefined || selectedRow.length !== 1}
          onClick={() => {
            history.push({
              pathname: `/form/${selectedFormFilled.formCode}/${selectedFormFilled.id}`,
              state: { entityOperation: EntityOperation.READ, searchFormStatus: searchFormStatus, searchFormType: searchFormType },
            });
          }}
        />
      )}
      {(isEd() || isBed()) && hasReadRight && selectedFormFilled.status === Labels.LABEL_SUBMITTED && (
        <Button
          label={Labels.BUTTON_REVISION}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-pencil"
          tooltip={Labels.TOOLTIP_ADD_COMMENT}
          className="p-mr-2 p-button-warning"
          disabled={selectedFormFilled === undefined || selectedRow.length !== 1}
          onClick={() => {
            history.push({
              pathname: `/form/${selectedFormFilled.formCode}/${selectedFormFilled.id}`,
              state: { entityOperation: EntityOperation.EDUPDATE, searchFormStatus: searchFormStatus, searchFormType: searchFormType },
            });
          }}
        />
      )}
    </>
  );

  const rightContents = () => (
    <>
      <Tooltip target=".tooltip-place" position="bottom">
        {selectedRow.length !== 2 ? Labels.LABEL_CHOOSE_TWO_CCF : ""}
      </Tooltip>
      <div className="tooltip-place">
        {isBed() && hasDeleteRight && (
          <Button
            label={Labels.BUTTON_MERGE_CCF}
            tooltip={Labels.TOOLTIP_MERGE_CCF}
            tooltipOptions={{ position: "bottom" }}
            icon="pi pi-clone"
            className="p-mr-2 p-button-warning"
            disabled={selectedRow.length !== 2}
            onClick={() => {
              history.push({ pathname: "/mergeForms", state: { selection: dt.current?.props?.selection, searchFormStatus: searchFormStatus, searchFormType: searchFormType } });
            }}
          />
        )}
      </div>
      <ExportData data={exportData} name={Labels.FORM_LIST} dt={dt} />
      {isMd() && !isEd() && !isBed() && hasDeleteRight && (
        <Button
          label={Labels.BUTTON_DELETE}
          tooltip={Labels.HEADER_DELETE_FORM_FILLED}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-trash"
          className="p-mr-2 p-button-danger"
          disabled={selectedFormFilled === undefined || selectedRow.length !== 1 || (selectedFormFilled.status !== Labels.LABEL_UNFINISHED && selectedFormFilled.formCode === FORM_CCF)}
          onClick={() => {
            history.push({
              pathname: `/form/${selectedFormFilled.formCode}/${selectedFormFilled.id}`,
              state: { entityOperation: EntityOperation.DELETE, searchFormStatus: searchFormStatus, searchFormType: searchFormType },
            });
          }}
        />
      )}
    </>
  );

  const operator = fieldList ? getOperator(fieldList.find((x) => x.id === searchDataOne.fieldId)?.fieldType?.componentEnumType || "", Labels) : [];

  const buttonAddEnable = searchDataOne.fieldId && searchDataOne.operator && searchDataOne.value !== undefined && searchDataOne.value !== null && searchDataOne.value !== "";

  const contentsSearch = (
    <div className="p-formgroup-inline">
      <Dropdown
        filter
        placeholder={Labels.FIELD_DROPDOWN_CHOOSE}
        emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
        emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
        resetFilterOnHide
        className="p-field"
        optionLabel="name"
        optionValue="id"
        options={searchFormType === undefined ? [] : fieldList}
        value={searchDataOne.fieldId}
        onChange={(e) =>
          setSearchDataOne({
            ...searchDataOne,
            fieldId: e.value,
            fieldName: fieldList.find((x) => x.id === e.value)?.name,
          })
        }
      />
      {searchDataOne.fieldId && (
        <Dropdown
          filter
          placeholder={Labels.OPERATOR_FIELD_DROPDOWN_CHOOSE}
          emptyFilterMessage={Labels.MESSAGES_NO_RESULT_FOUND}
          emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
          resetFilterOnHide
          className="p-field"
          optionLabel="name"
          optionValue="id"
          options={operator}
          value={searchDataOne.operator}
          onChange={(e) =>
            setSearchDataOne({
              ...searchDataOne,
              operator: e.value,
              operatorName: operator?.find((x) => x.id === e.value)?.name,
            })
          }
        />
      )}
      {searchDataOne.operator && (
        <div className="p-field">
          <Field field={fieldList.find((x) => x.id === searchDataOne.fieldId)} value={searchDataOne.value} onChange={onChange} isSearch={true} />
        </div>
      )}
      <Button
        icon="pi pi-plus"
        className="p-mr-2"
        tooltip={Labels.ADD_SEARCH_PARAMETER}
        tooltipOptions={{ hideEvent: "mouseleave", position: "bottom" }}
        disabled={!buttonAddEnable}
        onClick={addSearchData}
      />
      <Button label={Labels.USER_SEARCH} icon="pi pi-search" tooltip={Labels.SEARCH_FORM} className="p-mr-2 p-button-success" onClick={search} />
    </div>
  );

  const header = renderHeader();
  return (
    <div className="layout-jurisdiction">
      <div>
        <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
        <Panel header={Labels.USER_SEARCH} toggleable>
          <div className="p-grid">
            <div className="width-full">
              <span className="p-float-label margin-top-style">
                <Dropdown
                  inputId="formType"
                  showClear
                  onChange={(e) => {
                    changeFormType(e.value);
                    setSelectedRow([]);
                  }}
                  value={searchFormType}
                  options={formList}
                  optionLabel="name"
                  className="width-100"
                />
                <label htmlFor="formType">{Labels.LABEL_FORM_TYPE}</label>
              </span>
            </div>
            <div className="width-full">
              <span className="p-float-label margin-top-style">
                <Dropdown
                  inputId="formStatus"
                  showClear
                  onChange={(e) => {
                    setSearchFormStatus(e.value);
                    setSelectedRow([]);
                  }}
                  value={searchFormStatus}
                  options={formStatuses}
                  className="width-100"
                />
                <label htmlFor="formStatus">{Labels.LABEL_STATUS}</label>
              </span>
            </div>
          </div>
          <div>{contentsSearch}</div>
          <div>
            {searchData.map((data: any) => (
              <Chip
                key={data.id}
                className="p-m-2"
                label={`${data.fieldName} ${data.operatorName} ${data.valueName ? data.valueName : data.value}`}
                removable
                onRemove={() => removeSearchData(data)}
              />
            ))}
          </div>
        </Panel>
        <Toolbar className="p-mt-2" left={leftContents} right={rightContents} />
        <Panel>
          <div>
            <DataTable
              ref={dt}
              dataKey="id"
              header={header}
              value={formDataList}
              paginator
              selectionMode="checkbox"
              rows={10}
              selection={selectedRow}
              onSelectionChange={(e) => setSelectedRow(e.value)}
              onRowSelect={(e) => setSelectedFormFilled(e.data)}
              emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
              currentPageReportTemplate={Labels.PAGINATION_SHOWING_TO_OF_ENTRIES}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rowsPerPageOptions={[5, 10, 15]}
              exportFilename={Labels.FORM_LIST}
              onValueChange={onSearchValueChange}
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3em" }}></Column>
              <Column field="institution" filterMatchMode="contains" header={Labels.INSTITUTION} sortable filter filterPlaceholder={Labels.PLACEHOLDER_SEARCH_BY_INSTITUTION} />
              <Column field="patientName" header={Labels.PATIENT_FIRST_NAME} sortable filter filterMatchMode="contains" filterPlaceholder={Labels.PLACEHOLDER_PATIENT_FIRST_NAME} />
              <Column field="patientSurname" header={Labels.PATIENT_LAST_NAME} sortable filter filterMatchMode="contains" filterPlaceholder={Labels.PLACEHOLDER_PATIENT_LAST_NAME} />
              <Column field="dateOfForm" header={Labels.INSERT_DATE} sortable filter filterMatchMode="contains" filterPlaceholder={Labels.PLACEHOLDER_INSERT_DATE} />
              <Column field="status" header={Labels.COLUMN_HEADER_STATUS} sortable filter filterElement={statusFilterElement} />
            </DataTable>
          </div>
        </Panel>
      </div>
    </div>
  );
}
