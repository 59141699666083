import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface UserRightControllerType {
  axiosGetUserRightList: any;
  axioGetFormUserRightList: any;
  axiosGetAllUserRightListByForm: any;
}

export function UserRightController(): UserRightControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetUserRightList = () => {
    return axios.get(Endpoint.USER_RIGHT_LIST, axiosConfig(authData!.token));
  };

  const axioGetFormUserRightList = (formCode: any) => {
    return axios.get(`${Endpoint.FORM_LIST}/${formCode}/formUserRightList`, axiosConfig(authData!.token));
  };

  const axiosGetAllUserRightListByForm = () => {
    return axios.get(`${Endpoint.USER_RIGHT_LIST_BY_FORM}`, axiosConfig(authData!.token));
  };

  return { axiosGetUserRightList, axioGetFormUserRightList, axiosGetAllUserRightListByForm };
}

export type { UserRightControllerType };
