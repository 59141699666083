import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useContext, useImperativeHandle, useState } from "react";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { isFormDisabled } from "../../../infrastructure/system/Utils";
import FieldTypeEnumeratorItemReadDto from "../../../model/FieldTypeEnumeratorItemReadDto";
import { AppContext } from "../../../Store";

interface CrudFieldTypeEnumeratorItemProp {
  fieldTypeEnumeratorItemOperation: string;
  fieldTypeEnumeratorItem?: FieldTypeEnumeratorItemReadDto;
  onCreateFieldTypeEnumeratorItem: any;
  dialogRef?: any;
  onUpdateFieldTypeEnumeratorItem: any;
  onDeleteFieldTypeEnumeratorItem: any;
}

export default function CrudFieldTypeEnumeratorItem(prop: CrudFieldTypeEnumeratorItemProp) {
  const { fieldTypeEnumeratorItemOperation, fieldTypeEnumeratorItem, onCreateFieldTypeEnumeratorItem, dialogRef, onUpdateFieldTypeEnumeratorItem, onDeleteFieldTypeEnumeratorItem } = prop;
  const { showMessage } = useContext(AppContext);
  const [isDisabled] = useState(isFormDisabled(fieldTypeEnumeratorItemOperation));
  const [fieldTypeEnumeratorItemChange, setFieldTypeEnumeratorItemChange] = useState<FieldTypeEnumeratorItemReadDto | undefined>(fieldTypeEnumeratorItem);

  const { Labels } = LabelsContext();

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      onCreateFieldTypeEnumeratorItem(fieldTypeEnumeratorItemChange)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
    onUpdate: () => {
      onUpdateFieldTypeEnumeratorItem(fieldTypeEnumeratorItemChange)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
    onDelete: () => {
      onDeleteFieldTypeEnumeratorItem(fieldTypeEnumeratorItemChange!.id)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
  }));

  return (
    <div className="layout-user-group">
      <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
        <div className="p-grid p-align-center p-nogutter">
          <div className="p-col-4">
            <div>
              {Labels.LABEL_CODE}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <InputText
              disabled={isDisabled}
              value={fieldTypeEnumeratorItemChange && fieldTypeEnumeratorItemChange.code}
              onChange={(e: any) => {
                setFieldTypeEnumeratorItemChange({
                  ...fieldTypeEnumeratorItemChange!,
                  code: e.target.value,
                });
              }}
            />
          </div>
          <div className="p-col-4">
            <div>
              {Labels.LABEL_NAME}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <InputText
              disabled={isDisabled}
              value={fieldTypeEnumeratorItemChange && fieldTypeEnumeratorItemChange.name}
              onChange={(e: any) => {
                setFieldTypeEnumeratorItemChange({
                  ...fieldTypeEnumeratorItemChange!,
                  name: e.target.value,
                });
              }}
            />
          </div>
          <div className="p-col-4">
            <div>{Labels.LABEL_DESCRIPTION}</div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <InputTextarea
              rows={3}
              cols={5}
              disabled={isDisabled}
              value={fieldTypeEnumeratorItemChange && fieldTypeEnumeratorItemChange.description}
              onChange={(e: any) => {
                setFieldTypeEnumeratorItemChange({
                  ...fieldTypeEnumeratorItemChange!,
                  description: e.target.value,
                });
              }}
            />
          </div>

          <div className="p-col-4">
            <div>{Labels.LABEL_NOTE}</div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <InputTextarea
              rows={3}
              cols={5}
              disabled={isDisabled}
              value={fieldTypeEnumeratorItemChange && fieldTypeEnumeratorItemChange.note}
              onChange={(e: any) => {
                setFieldTypeEnumeratorItemChange({
                  ...fieldTypeEnumeratorItemChange!,
                  note: e.target.value,
                });
              }}
            />
          </div>
          <div className="p-col-4">
            <div>
              {Labels.LABEL_DISPLAY_ORDER} {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <InputNumber
              disabled={isDisabled}
              value={fieldTypeEnumeratorItemChange && fieldTypeEnumeratorItemChange.displayOrder}
              showButtons
              buttonLayout="horizontal"
              decrementButtonClassName="p-button-danger"
              incrementButtonClassName="p-button-success"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              onValueChange={(e: any) => {
                setFieldTypeEnumeratorItemChange({
                  ...fieldTypeEnumeratorItemChange!,
                  displayOrder: e.target.value,
                });
              }}
            />
          </div>
          <div className="p-col-4">
            <div>{Labels.LABEL_VALUE_NUMBER}</div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <InputNumber
              disabled={isDisabled}
              value={fieldTypeEnumeratorItemChange && fieldTypeEnumeratorItemChange.valueNumber}
              showButtons
              buttonLayout="horizontal"
              decrementButtonClassName="p-button-danger"
              incrementButtonClassName="p-button-success"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              onValueChange={(e: any) => {
                setFieldTypeEnumeratorItemChange({
                  ...fieldTypeEnumeratorItemChange!,
                  valueNumber: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
