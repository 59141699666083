const EnumReportParameterType = {
  BOOLEAN: "BOOLEAN",
  NUMBER: "NUMBER",
  TEXT: "TEXT",
  DATE: "DATE",
  SET: "SET",
  MULTISELECT: "MULTISELECT",
};

export default EnumReportParameterType;
