import { useContext, useEffect, useState } from "react";
import { CodebookController, CodebookControllerType } from "../../../../controllers/codebook/CodebookController";
import CodebookLogicalControlOperator from "../../../../infrastructure/system/CodebookLogicalControlOperator";
import { handleAxiosCallError } from "../../../../infrastructure/system/Utils";
import { AppContext } from "../../../../Store";

interface CodebookAutocompleteLogicalType {
  codebookSuggestionsList: any;
  searchCodebook: any;
  selectedCodebook: any;
  onChangeAutoComplete: any;
  isEmptyList: any;
}

interface CodebookLogicalPropType {
  codebookTypeCode?: string;
  value?: string;
  onChange: any;
  field: any;
  codebookLogicalControlValidation?: any;
  setFieldCodebookListValidation?: any;
}

export default function CodebookAutocompleteLogical(prop: CodebookLogicalPropType): CodebookAutocompleteLogicalType {
  const [codebookSuggestionsList, setCodebookSuggestionsList] = useState([]);
  const [isEmptyList, setIsEmptyList] = useState<any>();
  const [selectedCodebook, setSelectedCodebook] = useState<any>(null);
  const { axiosSearchCodebook, axiosGetCodebook }: CodebookControllerType = CodebookController();
  const { showMessage } = useContext(AppContext);
  const { codebookTypeCode, field, onChange, value, codebookLogicalControlValidation, setFieldCodebookListValidation } = prop;

  const searchCodebook = (codeOrName: string) => {
    axiosSearchCodebook(codebookTypeCode, { codeOrName: codeOrName, codebookLogicalControlList: codebookLogicalControlValidation })
      .then((res: any) => {
        const codebookOptionListWithDropdownLabel = res.data.data.map((coodebook: any) => {
          return { ...coodebook, dropdownValue: `${coodebook.code} - ${coodebook.name}` };
        });

        if (codebookLogicalControlValidation && !codebookLogicalControlValidation.some((c: any) => c.operator === CodebookLogicalControlOperator.RANGE)) {
          let codebookNew: any = [];
          codebookLogicalControlValidation.forEach((codebookValidation: any) => {
            let codebookFilter: any = [];
            if (codebookValidation.operator === CodebookLogicalControlOperator.EQUAL) {
              codebookFilter = codebookOptionListWithDropdownLabel.filter((c: any) => c.code === codebookValidation.firstValue);
            } else if (codebookValidation.operator === CodebookLogicalControlOperator.NOT_EQUAL) {
              codebookFilter = codebookOptionListWithDropdownLabel.filter((c: any) => c.code !== codebookValidation.firstValue);
            }
            if (codebookNew.length === 0) {
              codebookNew = codebookFilter;
            } else {
              codebookNew = codebookNew.filter((e: any) => {
                return codebookFilter.some((item: any) => item.code === e.code);
              });
            }
          });
          setCodebookSuggestionsList(codebookNew);

          setIsEmptyList(codebookNew.length === 0 ? true : false);
        } else {
          setCodebookSuggestionsList(codebookOptionListWithDropdownLabel);
          setIsEmptyList(codebookOptionListWithDropdownLabel.length === 0 ? true : false);
        }
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  useEffect(() => {
    if (setFieldCodebookListValidation) {
      setFieldCodebookListValidation({ id: field.id, value: codebookSuggestionsList });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codebookSuggestionsList]);

  useEffect(() => {
    if (value !== null && value !== undefined && value !== "") {
      axiosGetCodebook(codebookTypeCode, value)
        .then((res: any) => {
          setSelectedCodebook({ ...res.data.data, dropdownValue: `${res.data.data.code} - ${res.data.data.name}` });
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
    // eslint-disable-next-line
  }, [value]);

  const onChangeAutoComplete = (e: any) => {
    setSelectedCodebook(e.target.value);
    if (e.value?.code || e.value === "") {
      onChange({ field: field, value: e.value.code ? e.value.code : e.value });
    }
  };

  return {
    codebookSuggestionsList,
    searchCodebook,
    selectedCodebook,
    onChangeAutoComplete,
    isEmptyList,
  };
}

export type { CodebookAutocompleteLogicalType };
