import { Tooltip } from "primereact/tooltip";
import { Link } from "react-router-dom";
import Images from "../../../infrastructure/system/Images";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import AppSubmenu from "./submenu/AppSubmenu";

interface AppMenuType {
  onMenuClick: any;
  model: any;
  menuMode: any;
  active: any;
  mobileMenuActive: any;
  onMenuitemClick: any;
  onRootMenuitemClick: any;
}

export default function AppMenu(props: AppMenuType) {
  const { onMenuClick, model, menuMode, active, mobileMenuActive, onMenuitemClick, onRootMenuitemClick } = props;
  const { Labels } = LabelsContext();

  return (
    <div className="layout-sidebar" onClick={onMenuClick}>
      <Link to="/" className="logo ">
        <img id="app-logo" className="logo-image tooltip" src={Images.LOGO_IJZS} alt="diamond layout" />
        <span className="app-name">{Labels.APPLICATION_NAME}</span>
      </Link>
      <Tooltip target=".tooltip" position="right">
        {Labels.HOME_PAGE}
      </Tooltip>

      <div className="layout-menu-container">
        <AppSubmenu
          items={model}
          menuMode={menuMode}
          parentMenuItemActive
          menuActive={active}
          mobileMenuActive={mobileMenuActive}
          root
          onMenuitemClick={onMenuitemClick}
          onRootMenuitemClick={onRootMenuitemClick}
        />
      </div>
    </div>
  );
}
