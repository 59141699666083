import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface FileControllerType {
  axiosCreateFileList: any;
  axiosGetFileList: any;
  axiosDeleteFile: any;
}

export function FileController(): FileControllerType {
  const { authData } = useContext(AppContext);

  const axiosCreateFileList = (attachmentList: any) => {
    return axios.post(Endpoint.FILE_LIST, attachmentList, axiosConfig(authData!.token));
  };

  const axiosGetFileList = (formFilledID: number) => {
    return axios.get(`${Endpoint.FILE_LIST}/${formFilledID}`, axiosConfig(authData!.token));
  };

  const axiosDeleteFile = (fileID: number) => {
    return axios.delete(`${Endpoint.FILE_LIST}/${fileID}`, axiosConfig(authData!.token));
  };

  return {
    axiosCreateFileList,
    axiosGetFileList,
    axiosDeleteFile,
  };
}

export type { FileControllerType };
