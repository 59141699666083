import { Panel } from "primereact/panel";
import PublicReportLogical, { PublicReportLogicalType } from "./PublicReportLogical";
import ReportTableView from "./ReportTableView";
import { BreadCrumb } from "primereact/breadcrumb";

export default function PublicReportView() {
  const { publicReportList, first, tableRows, onPageGeneratedReport, breadCrumbItems }: PublicReportLogicalType = PublicReportLogical();

  return (
    <div className="layout-report">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
        <ReportTableView reportGeneratedList={publicReportList} first={first} tableRows={tableRows} onPageGeneratedReport={onPageGeneratedReport} isPublic={true}></ReportTableView>
      </Panel>
    </div>
  );
}
