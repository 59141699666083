interface TitleType {
  name: string;
  isRequired?: boolean;
}

export default function Title(props: TitleType) {
  const { name, isRequired }: TitleType = props;

  return <div className="p-col-4">{name + (isRequired ? "*" : "")}</div>;
}
