import { createContext, useEffect, useState } from "react";
import { BrowserRouter, useHistory } from "react-router-dom";
import AuthData from "./AuthData";
import { UserController, UserControllerType } from "./controllers/user/UserController";
import { LabelController, LabelControllerType } from "./controllers/label/LabelController";
import { handleAxiosCallError, SUCCESS_MESSAGE_LIFE } from "./infrastructure/system/Utils";
import LATIN from "./infrastructure/system/Labels_sr_Latn_RS";
import CYRILLIC from "./infrastructure/system/Labels_sr_Cyrillic_RS";
import i18n from "i18n-js";
import EnumLanguage from "./infrastructure/system/EnumLanguage";
import MessageType from "./infrastructure/system/MessageType";
import UserLeaveConfirmation from "./UserLeaveConfirmation";

interface AppContextProps {
  authData: AuthData | undefined;
  updateAuthData: (data: string) => void;
  deleteAuthData: () => void;
  showMessage: (summery: string, detail: string, severity?: string) => void;
  setMessages: (messages: any) => void;
  showBlockUI: boolean;
  setShowBlockUI: any;
  filterSearch: any;
  setFilterSearch: any;
  t: (scope: any, options?: any) => string;
  locale: string;
  setLocale: any;
  labelList: any;
  setLabelList: any;
}

i18n.fallbacks = true;
i18n.translations = { LATIN, CYRILLIC };

export const AppContext = createContext({} as AppContextProps);

const Store = (props: any) => {
  const [authData, setAuthData] = useState(localStorage.getItem("cris-app-auth") || "");
  const [locale, setLocale] = useState(EnumLanguage.LATIN);
  const [messages, setMessages] = useState<any>("");
  const [showBlockUI, setShowBlockUI] = useState(false);
  const [filterSearch, setFilterSearch] = useState<any>([]);
  const [labelList, setLabelList] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(true);

  const history = useHistory();
  const { axiosGetUser }: UserControllerType = UserController();
  const { axiosGetLabelList }: LabelControllerType = LabelController();

  useEffect(() => {
    const loggedInUserData = authData ? (JSON.parse(authData) as AuthData) : null;
    if (loggedInUserData) {
      axiosGetLabelList(loggedInUserData.token)
        .then((res: any) => {
          setLabelList(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
      axiosGetUser(loggedInUserData.currentUser.id, loggedInUserData.token)
        .then((res: any) => {
          updateAuthData(
            JSON.stringify({
              token: loggedInUserData.token,
              currentUser: res.data.data,
            })
          );
          if (res.data.data.language) {
            setLocale(res.data.data.language);
          }
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData]);

  const clear = async (messages: any) => {
    try {
      messages.clear();
    } catch {
      history?.push("/error");
    }
  };

  const showMessage = async (severity: string, summary: string, detail?: string) => {
    await clear(messages);
    try {
      const isGreen = severity === MessageType.SUCCESS;
      messages.show({
        severity: severity,
        summary: summary,
        detail: detail ? detail : "",
        closable: true,
        sticky: !isGreen,
        life: isGreen ? SUCCESS_MESSAGE_LIFE : !SUCCESS_MESSAGE_LIFE,
      });
    } catch {
      history?.push("/error");
    }
  };

  const setBlockUI = (value: boolean) => {
    if (value) {
      setShowBlockUI(value);
    } else {
      setTimeout(() => {
        setShowBlockUI(value);
      }, 500);
    }
  };

  const updateAuthData = (data: string) => {
    localStorage.setItem("cris-app-auth", data);
    setAuthData(data);
  };

  const deleteAuthData = () => {
    setAuthData("");
    setFilterSearch([]);
    localStorage.removeItem("cris-app-auth");
  };

  return (
    <BrowserRouter
      getUserConfirmation={(message, callback) => {
        return UserLeaveConfirmation(message, callback, confirmOpen, setConfirmOpen);
      }}
    >
      <AppContext.Provider
        value={{
          authData: authData !== "" ? (JSON.parse(authData) as AuthData) : undefined,
          updateAuthData,
          deleteAuthData,
          showMessage,
          setMessages,
          showBlockUI: showBlockUI,
          setShowBlockUI: setBlockUI,
          filterSearch,
          setFilterSearch,
          t: (scope: any, options: any) => i18n.t(scope, { locale, ...options }),
          locale: locale,
          setLocale,
          labelList,
          setLabelList,
        }}
      >
        {props.children}
      </AppContext.Provider>
    </BrowserRouter>
  );
};

export default Store;
