import { Route, Switch } from "react-router-dom";
import CodebookList from "./components/admin/codebook/CodebookList";
import CodebookTypeList from "./components/admin/codebook/CodebookTypeList";
import CrudFieldType from "./components/admin/field-type/CrudFieldType";
import FieldTypeViewPage from "./components/admin/field-type/FieldTypeView";
import CrudForm from "./components/admin/form/CrudForm";
import CrudFormList from "./components/admin/form/CrudFormList";
import InstitutionListPage from "./components/admin/institution/InstitutionListView";
import JurisdictionListPage from "./components/admin/jurisdiction/JurisdictionListView";
import LogActionList from "./components/admin/log-action/LogActionList";
import UserGroupListPage from "./components/admin/user-group/UserGroupListView";
import UserListPage from "./components/admin/user/UserListView";
import ErrorPageView from "./components/error/ErrorPageView";
import FormView from "./components/form/FormView";
import LoginPage from "./components/login/LoginPageView";
import MDPageView from "./components/md-page/MDPageView";
import UserProfilePage from "./components/user-profile/UserProfileView";
import { RoleService, RoleServiceType } from "./controllers/role/RoleService";
import CrudCodebookLogicalControlList from "./components/admin/codebook/CrudCodebookLogicalControlList";
import CrudFormField from "./components/admin/form/CrudFormField";
import MergeFormsView from "./components/form/merge-forms/MergeFormsView";
import ReportList from "./components/admin/report/ReportList";
import CrudReport from "./components/admin/report/CrudReport";
import NewReport from "./components/admin/report/report-generate/NewReport";
import PublicReportView from "./components/admin/report/public-report/PublicReportView";
export default function Content() {
  const { isAdmin, isSuperAdmin }: RoleServiceType = RoleService();

  return (
    <Switch>
      <Route exact path="/" component={MDPageView} />
      <Route exact path="/registry" component={MDPageView} />
      <Route exact path="/forRevisionFormList" component={MDPageView} />
      <Route exact path="/form/:formCode/:formFilledID?" component={FormView} />
      <Route exact path="/userProfile/:id" component={UserProfilePage} />
      <Route exact path="/report" component={ReportList} />
      <Route exact path="/reportPublic" component={PublicReportView} />
      <Route exact path="/generateReport" component={NewReport} />

      {(isAdmin() || isSuperAdmin()) && (
        <>
          <Route exact path="/userGroupList" component={UserGroupListPage} />
          <Route exact path="/jurisdictionList" component={JurisdictionListPage} />
          <Route exact path="/fieldTypeList" component={FieldTypeViewPage} />
          <Route exact path="/userList" component={UserListPage} />
          <Route exact path="/institutionList" component={InstitutionListPage} />
          <Route exact path="/crudFormList" component={CrudFormList} />
          <Route exact path="/codebookTypeList" component={CodebookTypeList} />
          <Route exact path="/codebookList/:path" component={CodebookList} />
          <Route exact path="/crudCodebookLogicalControlList" component={CrudCodebookLogicalControlList} />
          <Route path="/crudForm/:formCode?" component={CrudForm} />
          <Route path="/crudFormField/:formFieldId?" component={CrudFormField} />
          <Route path="/crudFieldType/:fieldTypeId?" component={CrudFieldType} />
          <Route exact path="/logActionList" component={LogActionList} />
          <Route exact path="/mergeForms" component={MergeFormsView} />
          <Route exact path="/crudReport/:reportID" component={CrudReport} />
          <Route exact path="/crudReport" component={CrudReport} />
        </>
      )}
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/error" component={ErrorPageView} />
      <Route component={ErrorPageView} />
    </Switch>
  );
}
