import React from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Password } from "primereact/password";
import { NavLink } from "react-router-dom";
import LabelsContext from "../../infrastructure/system/LabelsContext";
import Container from "../Container";
import LoginPageLogical, { LoginPageLocicalType } from "./LoginPageLogical";
import Images from "../../infrastructure/system/Images";

export default function LoginPage() {
  const { Labels } = LabelsContext();

  const { loginData, changeLoginData, postLogin }: LoginPageLocicalType = LoginPageLogical();

  return (
    <Container>
      <div className="login-body">
        <div className="login-wrapper">
          <div className="login-panel">
            <div className="login-form">
              <div className="image-wrapper">
                <div>
                  <img id="app-logo" className="logo-image" src={Images.LOGO_IJZS} alt="diamond layout" />
                </div>
                <div className="logo-text">
                  <p className="large-text">{Labels.CANCER_REGISTRY}</p>
                  <span className="medium-text">{Labels.INSTITUTE_OF_PUBLIC_HEALTH}</span>
                  <p className="small-text">{Labels.BATUT_LABEL}</p>
                </div>
              </div>
              <Panel className="width-350" header={Labels.TITLE_LOGIN}>
                <form onSubmit={postLogin}>
                  <div className="p-col-12">
                    <div className="p-col-12 login-input">
                      <InputText name="username" value={loginData.username} onChange={changeLoginData} placeholder={Labels.PLACEHOLDER_USERNAME} autoComplete="username" />
                    </div>
                    <div className="p-col-12 login-input p-nogutter">
                      <Password
                        name="password"
                        feedback={false}
                        toggleMask
                        value={loginData.password}
                        onChange={changeLoginData}
                        placeholder={Labels.PLACEHOLDER_PASSWORD}
                        autoComplete="current-password"
                      />
                    </div>
                    <div className="p-col-12 center-button">
                      <NavLink to="/">
                        <Button label={Labels.BUTTON_LOGIN} onClick={postLogin} className="login-button" />
                      </NavLink>
                    </div>
                  </div>
                </form>
              </Panel>
            </div>
          </div>
        </div>
        <footer className="footer-style">
          {Labels.DELIVERED_BY}{" "}
          <a className="link-style" target={"_blank"} href="https://heliant.rs/">
            {Labels.HELIANT_LABEL}
          </a>
        </footer>
      </div>
    </Container>
  );
}
