import { ChangeEvent, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserController, UserControllerType } from "../../controllers/user/UserController";
import LabelsContext from "../../infrastructure/system/LabelsContext";
import MessageType from "../../infrastructure/system/MessageType";
import { handleAxiosCallError } from "../../infrastructure/system/Utils";
import UserReadDto from "../../model/user/UserReadDto";
import { AppContext } from "../../Store";

interface UserProfileLogicalType {
  changeUserData: any;
  editUser: any;
  userChange: UserReadDto;
  cancelEdit: any;
  dialogClose: any;
  dialogVisible: any;
  displayDialog: boolean;
  _handleFileChange: any;
  handleScale: any;
  handleSave: any;
  setEditorRef: any;
  avatar: any;
  avatarChangeVisible: boolean;
  avatarVisible: any;
  avatarNotVisible: any;
  goBack: any;
}

export default function UserProfileLogical(): UserProfileLogicalType {
  const { updateAuthData, showMessage, authData, setShowBlockUI, setLocale, locale } = useContext(AppContext);
  const { axiosUpdateUserProfile }: UserControllerType = UserController();
  const { Labels } = LabelsContext();
  const [userChange, setUserChange] = useState<UserReadDto>(authData!.currentUser);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [avatarChangeVisible, setAvatarChangeVisible] = useState(false);
  const history = useHistory();
  const [avatar, setAvatar] = useState<any>({
    cropperOpen: false,
    img: null,
    zoom: 1,
    croppedImg: "",
  });
  const [editor, setEditor] = useState<any>();
  const changeUserData = (e: ChangeEvent<HTMLInputElement>) => {
    setUserChange({
      ...userChange!,
      [e.target.name]: e.target.value,
    });
  };

  const _handleFileChange = (e: any) => {
    setAvatar({ ...avatar, img: e.target.files[0] });
  };

  const handleScale = (e: any) => {
    const scale = parseFloat(e.target.value);
    setAvatar({ ...avatar, zoom: scale });
  };

  const handleSave = () => {
    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      editProfilePicture(croppedImg.substr(croppedImg.indexOf(",") + 1));
      setAvatarChangeVisible(false);
    }
  };

  const setEditorRef = (editor: any) => {
    setEditor(editor);
  };

  const goBack = () => {
    history.goBack();
  };

  const editUser = () => {
    setShowBlockUI(true);
    axiosUpdateUserProfile(userChange)
      .then((response: any) => {
        updateAuthData(
          JSON.stringify({
            token: authData?.token,
            currentUser: userChange,
          })
        );
        if (locale !== userChange.language) {
          setLocale(userChange.language);
        }
        showMessage(MessageType.SUCCESS, Labels.USER_PROFILE_EDIT_SUCCESS, "");
        setShowBlockUI(false);
        history.goBack();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const cancelEdit = () => {
    setUserChange(authData!.currentUser);
  };

  const dialogClose = () => {
    setDisplayDialog(false);
  };

  const dialogVisible = () => {
    setDisplayDialog(true);
  };

  const editProfilePicture = (img: any) => {
    setUserChange({ ...userChange!, profilePicture: img });
  };

  const avatarVisible = () => {
    setAvatarChangeVisible(true);
  };

  const avatarNotVisible = () => {
    setAvatarChangeVisible(false);
  };

  return {
    changeUserData,
    editUser,
    userChange,
    cancelEdit,
    dialogClose,
    dialogVisible,
    displayDialog,
    _handleFileChange,
    handleScale,
    handleSave,
    setEditorRef,
    avatar,
    avatarChangeVisible,
    avatarVisible,
    avatarNotVisible,
    goBack
  };
}

export type { UserProfileLogicalType };
