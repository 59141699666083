import moment from "moment";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { confirmDialog } from "primereact/confirmdialog";
import { confirmPopup } from "primereact/confirmpopup";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { Tooltip } from "primereact/tooltip";
import { useRef } from "react";
import { Prompt, useHistory } from "react-router";
import { RoleService, RoleServiceType } from "../../controllers/role/RoleService";
import EnumComponentType from "../../infrastructure/system/EnumComponentType";
import EntityOperation from "../../infrastructure/system/EnumEntityOperation";
import EnumFormStatus from "../../infrastructure/system/EnumFormStatus";
import LabelsContext from "../../infrastructure/system/LabelsContext";
import { COMMENT_PANEL_WIDTH, DATE_TIME_FORMAT, FORM_CCF, hrefForDownloadAttachment, isFormDisabled } from "../../infrastructure/system/Utils";
import FieldReadDto from "../../model/FieldReadDto";
import Field from "./field/Field";
import FormLogical, { FormLogicalType } from "./FormLogical";
import Title from "./title/Title";

export default function FormView() {
  const {
    fieldList,
    save,
    saveAsDraft,
    panelHeader,
    formOperation,
    fieldValuesList,
    onChange,
    onBlur,
    errorMessages,
    uploadHandler,
    formFilledId,
    deleteFile,
    fileList,
    commentList,
    comment,
    setComment,
    createComment,
    formFilled,
    onApprove,
    deleteFormFilled,
    breadCrumbItems,
    formCode,
    codebookLogicalControlValidation,
    setFieldCodebookListValidation,
    locale,
    labelList,
    searchFormStatus,
    searchFormType,
    validate,
    mainPanelDiv,
    showCommentPanel,
    setShowCommentPanel,
    handleScreenSizeChange,
    setStopChange,
    stopChange,
    showField,
  }: FormLogicalType = FormLogical();

  const getName = (fieldName: any) => {
    const elementName = labelList.find((label: any) => label.original === fieldName && label.language === locale);
    return elementName !== undefined ? elementName.translate : fieldName;
  };
  const history = useHistory();
  const { Labels } = LabelsContext();
  const fileUploadRef = useRef<any>();
  const { isBed, isEd }: RoleServiceType = RoleService();

  window.addEventListener("resize", () => {
    setShowCommentPanel(handleScreenSizeChange(COMMENT_PANEL_WIDTH));
  });

  const leftContents = () => (
    <>
      {(formOperation === EntityOperation.CREATE || formOperation === EntityOperation.UPDATE) && (
        <>
          <Button
            label={Labels.NEW_FORM_SAVE}
            className="p-button-success button-save"
            onClick={() => {
              setStopChange(false);
              save();
            }}
            tooltip={Labels.SAVE_FORM}
          />
          {formCode === FORM_CCF && <Button label={Labels.NEW_FORM_SAVE_AS_DRAFT} className="p-button-primary button-save-and-stay" onClick={saveAsDraft} tooltip={Labels.SAVE_FORM_AS_DRAFT} />}
        </>
      )}
      {EntityOperation.EDUPDATE === formOperation && (isEd() || isBed()) && formFilled?.formStatusEnum === EnumFormStatus.SUBMITTED && (
        <>
          <Button
            label={Labels.BUTTON_APPROVE}
            className="p-button-success button-approve"
            tooltip={Labels.APPROVE_FORM}
            onClick={() => {
              confirmDialog({
                acceptLabel: Labels.BUTTON_YES,
                rejectLabel: Labels.BUTTON_NO,
                message: Labels.MESSAGE_APPROVE_FORM,
                icon: "pi pi-power-off",
                accept: () => {
                  onApprove();
                },
              });
            }}
          />
          <Button
            label={Labels.BUTTON_REVISION}
            tooltip={Labels.REVISION_FORM}
            className="p-button-danger"
            onClick={() => {
              confirmDialog({
                acceptLabel: Labels.BUTTON_YES,
                rejectLabel: Labels.BUTTON_NO,
                message: Labels.MESSAGE_SEND_BACK_TO_REVISION,
                icon: "pi pi-power-off",
                accept: () => {
                  createComment();
                },
              });
            }}
          />
        </>
      )}
      {formOperation === EntityOperation.DELETE && <Button label={Labels.BUTTON_DELETE} className="p-button-danger" onClick={deleteFormFilled} />}
    </>
  );

  const rightContents = () => (
    <Button
      label={formOperation === EntityOperation.READ ? Labels.BUTTON_BACK : Labels.FORM_FILLED_CANCEL}
      className="p-button-danger"
      tooltip={formOperation === EntityOperation.READ ? Labels.BUTTON_BACK : Labels.CANCEL_FORM}
      onClick={() => {
        if (formOperation !== EntityOperation.READ) {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.LABEL_ARE_YOU_SURE_YOU_WANT_TO_GIVE_UP,
            icon: "pi pi-power-off",
            accept: () => {
              history.push({
                pathname: `/registry`,
                state: { searchFormStatus: searchFormStatus, searchFormType: searchFormType },
              });
            },
          });
        } else {
          history.push({
            pathname: `/registry`,
            state: { searchFormStatus: searchFormStatus, searchFormType: searchFormType },
          });
        }
      }}
    />
  );
  const chooseOptions = { icon: "pi pi-fw pi-plus", iconOnly: true, className: "custom-choose-btn button-width" };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className: "custom-upload-btn p-button-success button-width",
  };
  const cancelOptions = { className: "class-visibility-hidden" };
  const fieldChildrenErrorMessages = (field: any) => {
    const data: any = {};
    field.children.forEach((f: any) => {
      data[f.id] = errorMessages[f.id];
    });
    return data;
  };
  const fieldChildrenValues = (field: any) => {
    const data: any = {};
    field.children.forEach((f: any) => {
      data[f.id] = fieldValuesList[f.id];
    });
    return data;
  };
  return (
    <div className="layout-form" ref={mainPanelDiv}>
      <div className="p-grid">
        <div className="p-col">
          <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
          <Tooltip target=".custom-choose-btn" content={Labels.LABEL_CHOOSE} position="bottom" />
          <Tooltip target=".custom-upload-btn" content={Labels.LABEL_UPLOAD} position="bottom" />
          <Panel className="panel-width" header={panelHeader()}>
            <Prompt
              when={!(isBed() || isEd()) && stopChange && formOperation != EntityOperation.READ}
              message={JSON.stringify({
                confirmText: Labels.BUTTON_YES,
                messageText: Labels.LABEL_DIALOG_MESSAGE_LEAVE_PAGE,
                cancelText: Labels.BUTTON_NO,
              })}
            />
            {fieldList &&
              fieldList.map((field: FieldReadDto) => {
                if (showField(field)) {
                  return (
                    <div key={field.code} className="p-grid p-fluid p-align-center">
                      {field.fieldType?.componentEnumType !== EnumComponentType.LABEL && field.fieldType?.componentEnumType !== EnumComponentType.PANEL && (
                        <Title name={getName(field.name)} isRequired={field.isRequired} />
                      )}
                      <div className={field.fieldType?.componentEnumType !== EnumComponentType.PANEL ? "p-col-8" : "p-col-12"}>
                        <Field
                          showField={showField}
                          isDisabled={isFormDisabled(formOperation) || field.isDisabled}
                          field={field}
                          value={field.children ? fieldChildrenValues(field) : fieldValuesList[field.id]}
                          onChange={onChange}
                          onBlur={onBlur}
                          validate={validate}
                          errorMessage={field.children ? fieldChildrenErrorMessages(field) : errorMessages[field.id]}
                          isSearch={false}
                          codebookLogicalControlValidation={field.children ? codebookLogicalControlValidation : codebookLogicalControlValidation[field.id]}
                          setFieldCodebookListValidation={setFieldCodebookListValidation}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            <Panel header={Labels.LABEL_ATTACHMENTS}>
              <div className="p-grid p-fluid">
                {(formOperation === EntityOperation.CREATE || formOperation === EntityOperation.UPDATE) && (
                  <div className="p-col-12">
                    <FileUpload
                      ref={fileUploadRef}
                      multiple
                      disabled={formFilledId === undefined || formFilledId === null}
                      chooseOptions={chooseOptions}
                      uploadOptions={uploadOptions}
                      cancelOptions={cancelOptions}
                      customUpload
                      uploadHandler={(e) => {
                        uploadHandler(e);
                        fileUploadRef.current.clear();
                      }}
                    />
                  </div>
                )}
                {!(isBed() || isEd()) ? (
                  <div className="p-col-12">
                    <Panel header={Labels.LABEL_UPLOADED}>
                      <div className="p-grid p-fluid">
                        <div className="p-col-12">
                          {fileList.length === 0 && <div>{Labels.LABEL_NO_UPLOADED_ATTACHMENTS}</div>}
                          {fileList.map((file: any, index: number) => {
                            return (
                              <>
                                <a key={index} href={hrefForDownloadAttachment(file.type, file.attachment)} download={file.name} className="a-word-wrap">
                                  {file.name}
                                </a>
                                {(formOperation === EntityOperation.UPDATE || formOperation === EntityOperation.CREATE) && (
                                  <i
                                    onClick={(e) => {
                                      confirmPopup({
                                        target: e.currentTarget,
                                        message: Labels.LABEL_REMOVE_DOCUMENT,
                                        acceptLabel: Labels.BUTTON_YES,
                                        rejectLabel: Labels.BUTTON_NO,
                                        icon: "pi pi-exclamation-triangle",
                                        accept: () => deleteFile(file.id),
                                        reject: () => {},
                                      });
                                    }}
                                    className="pi pi-times delete-attachment-icon"
                                  ></i>
                                )}
                                <hr />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </Panel>
                  </div>
                ) : (
                  <div className="p-col-12">
                    {fileList.length === 0 && <div>{Labels.LABEL_NO_UPLOADED_ATTACHMENTS}</div>}
                    {fileList.map((file: any, index: number) => {
                      return (
                        <>
                          <a key={index} href={hrefForDownloadAttachment(file.type, file.attachment)} download={file.name} className="a-word-wrap">
                            {file.name}
                          </a>
                          {(formOperation === EntityOperation.UPDATE || formOperation === EntityOperation.CREATE) && (
                            <i
                              onClick={(e) => {
                                confirmPopup({
                                  target: e.currentTarget,
                                  message: Labels.LABEL_REMOVE_DOCUMENT,
                                  acceptLabel: Labels.BUTTON_YES,
                                  rejectLabel: Labels.BUTTON_NO,
                                  icon: "pi pi-exclamation-triangle",
                                  accept: () => deleteFile(file.id),
                                  reject: () => {},
                                });
                              }}
                              className="pi pi-times delete-attachment-icon"
                            ></i>
                          )}
                          <hr />
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </Panel>
            {showCommentPanel && (
              <div className="margin-top">
                {(commentList.length > 0 || (formFilled?.formStatusEnum === EnumFormStatus.SUBMITTED && (isBed() || isEd()) && formOperation === EntityOperation.EDUPDATE)) && (
                  <div>
                    <Panel header={Labels.LABLE_COMMENTS}>
                      {commentList &&
                        commentList.map((comment: any) => {
                          const firstname = comment.insertUser && comment.insertUser?.firstname ? " " + comment.insertUser?.firstname : " ";
                          const lastname = comment.insertUser && comment.insertUser?.lastname ? " " + comment.insertUser?.lastname : " ";
                          return (
                            <div key={comment.id} className="p-grid">
                              <div className="p-col-12">
                                <Card subTitle={moment(comment.insertTimestamp).format(DATE_TIME_FORMAT) + firstname + lastname}>{comment.text}</Card>
                              </div>
                            </div>
                          );
                        })}
                      {EntityOperation.EDUPDATE === formOperation && (isBed() || isEd()) && formFilled?.formStatusEnum === EnumFormStatus.SUBMITTED && (
                        <div className="p-grid">
                          <div className="p-col-12">
                            <InputTextarea
                              value={comment}
                              onChange={(e: any) => {
                                setComment(e.target.value);
                              }}
                              autoResize
                              className="p-col-12"
                              placeholder={Labels.PLACEHOLDER_POST_COMMENT}
                            ></InputTextarea>
                          </div>
                        </div>
                      )}
                    </Panel>
                  </div>
                )}
              </div>
            )}
            <Toolbar left={leftContents} right={rightContents} />
          </Panel>
        </div>
        {!showCommentPanel && (
          <div className="p-col">
            {(commentList.length > 0 || (formFilled?.formStatusEnum === EnumFormStatus.SUBMITTED && (isBed() || isEd()) && formOperation === EntityOperation.EDUPDATE)) && (
              <div className="comment">
                <Panel header={Labels.LABLE_COMMENTS}>
                  {commentList &&
                    commentList.map((comment: any) => {
                      const firstname = comment.insertUser && comment.insertUser?.firstname ? " " + comment.insertUser?.firstname : " ";
                      const lastname = comment.insertUser && comment.insertUser?.lastname ? " " + comment.insertUser?.lastname : " ";
                      return (
                        <div key={comment.id} className="p-grid">
                          <div className="p-col-12">
                            <Card subTitle={moment(comment.insertTimestamp).format(DATE_TIME_FORMAT) + firstname + lastname}>{comment.text}</Card>
                          </div>
                        </div>
                      );
                    })}
                  {EntityOperation.EDUPDATE === formOperation && (isBed() || isEd()) && formFilled?.formStatusEnum === EnumFormStatus.SUBMITTED && (
                    <div className="p-grid">
                      <div className="p-col-12">
                        <InputTextarea
                          value={comment}
                          onChange={(e: any) => {
                            setComment(e.target.value);
                          }}
                          autoResize
                          className="p-col-12"
                          placeholder={Labels.PLACEHOLDER_POST_COMMENT}
                        ></InputTextarea>
                      </div>
                    </div>
                  )}
                </Panel>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
