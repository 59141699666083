import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import ExportData from "../../app/export/ExportData";
import CrudJurisdiction from "./CrudJurisdictionView";
import JurisdictionListLogical, { JurisdictionListLogicalType } from "./JurisdictionListLogical";
import React from "react";

export default function JurisdictionListPage() {
  const {
    jurisdictionList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    setSelectedJurisdiction,
    selectedJurisdiction,
    dialogHeader,
    entityOperation,
    openDialog,
    displayDialog,
    closeDialog,
    dialogRef,
    createJurisdiction,
    updateJurisdiction,
    deleteJurisdiction,
    jurisdictionToChange,
    onPageJurisdiction,
    breadCrumbItems,
    exportData,
    onSearchValueChange,
    districtList,
    selectedDistrict,
    setSelectedDistrict,
  }: JurisdictionListLogicalType = JurisdictionListLogical();

  const { Labels } = LabelsContext();
  const dt = useRef<any>(null);
  const leftContents = () => (
    <>
      <Button
        icon="pi pi-plus"
        className="p-button-success btn-margin-right"
        label={Labels.JURISDICTION_LIST_CREATE}
        tooltip={Labels.JURISDICTION_TITLE_DIALOG_CREATE}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          openDialog(EntityOperation.CREATE);
        }}
      />

      <Button
        icon="pi pi-pencil"
        className="p-button-warning btn-margin-right"
        disabled={!selectedJurisdiction}
        tooltip={Labels.JURISDICTION_TITLE_DIALOG_UPDATE}
        tooltipOptions={{ position: "bottom" }}
        label={Labels.JURISDICTION_LIST_EDIT}
        onClick={() => {
          selectedJurisdiction && openDialog(EntityOperation.UPDATE, selectedJurisdiction);
        }}
      />
      <Button
        icon="pi pi-info-circle"
        label={Labels.JURISDICTION_LIST_DETAILS}
        tooltip={Labels.JURISDICTION_TITLE_DIALOG_DETAILS}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedJurisdiction}
        onClick={() => {
          selectedJurisdiction && openDialog(EntityOperation.READ, selectedJurisdiction);
        }}
      />
    </>
  );

  const rightContents = () => (
    <>
      <ExportData data={exportData} name={Labels.JURISDICTIONS} dt={dt} />
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        tooltip={Labels.JURISDICTION_TITLE_DIALOG_DELETE}
        tooltipOptions={{ position: "bottom" }}
        label={Labels.JURISDICTION_LIST_DELETE}
        disabled={!selectedJurisdiction}
        onClick={() => {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_JURISDICTION,
            icon: "pi pi-power-off",
            accept: () => {
              deleteJurisdiction(selectedJurisdiction.id);
            },
          });
        }}
      />
    </>
  );

  const leftContentsDialog = () => (
    <>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.JURISDICTION_TITLE_DIALOG_CREATE}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          tooltip={Labels.JURISDICTION_TITLE_DIALOG_UPDATE}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            dialogRef.current.onUpdate();
          }}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          tooltip={Labels.JURISDICTION_TITLE_DIALOG_DELETE}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </>
  );

  const rightContentsDialog = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" onClick={closeDialog} />
    </>
  );

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  return (
    <div className="layout-jurisdiction">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
        <Toolbar left={leftContents} right={rightContents} />
        <div>
          <DataTable
            ref={dt}
            value={jurisdictionList}
            paginator
            first={first}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            rows={tableRows}
            rowsPerPageOptions={[5, 10, 20]}
            onPage={(e) => {
              onPageJurisdiction(e.rows, e.first);
            }}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedJurisdiction(e.data)}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            exportFilename={Labels.JURISDICTIONS}
            onValueChange={onSearchValueChange}
          >
            <Column field={"name"} header={Labels.HEADER_NAME} filter filterMatchMode="contains" sortable />
            <Column field={"code"} header={Labels.HEADER_CODE} filter filterMatchMode="contains" sortable />
            <Column field={"description"} header={Labels.HEADER_DESCRIPTION} filter filterMatchMode="contains" sortable />
            <Column field={"displayOrder"} header={Labels.HEADER_DISPLAY_ORDER} sortable />
          </DataTable>
        </div>
        <Dialog header={dialogHeader(entityOperation)} visible={displayDialog} onHide={closeDialog} style={{ width: "1000px" }} footer={dialogFooter()}>
          <CrudJurisdiction
            jurisdictionOperation={entityOperation}
            jurisdiction={jurisdictionToChange}
            onCreateJurisdiction={createJurisdiction}
            dialogRef={dialogRef}
            onUpdateJurisdiction={updateJurisdiction}
            onDeleteJurisdiction={deleteJurisdiction}
            districtList={districtList}
            selectedDistrict={selectedDistrict}
            setSelectedDistrict={setSelectedDistrict}
          />
        </Dialog>
      </Panel>
    </div>
  );
}
