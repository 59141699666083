import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import React, { useContext, useImperativeHandle, useState } from "react";
import CodebookLogicalControlOperator from "../../../infrastructure/system/CodebookLogicalControlOperator";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { isFormDisabled, operatorCodebookLogicalControl } from "../../../infrastructure/system/Utils";
import CodebookDto from "../../../model/CodebookDto";
import { AppContext } from "../../../Store";

interface CrudCodebookLogicalControlProp {
  codebookList: Array<CodebookDto>;
  codebookLogicalControlOperation: any;
  dialogRef: any;
  onDeleteCodebookLogicalControl: any;
  onCreateCodebookLogicalControl: any;
  onUpdateCodebookLogicalControl: any;
  codebookOptionListPrimary: any;
  codebookOptionListSecondary: any;
  codebookLogicalControlToChange: any;
  setCodebookLogicalControlToChange: any;
}
export default function CrudCodebookLogicalControlView(prop: CrudCodebookLogicalControlProp) {
  const {
    codebookList,
    codebookLogicalControlOperation,
    dialogRef,
    onDeleteCodebookLogicalControl,
    onCreateCodebookLogicalControl,
    onUpdateCodebookLogicalControl,
    codebookOptionListPrimary,
    codebookOptionListSecondary,
    codebookLogicalControlToChange,
    setCodebookLogicalControlToChange,
  } = prop;
  const { showMessage } = useContext(AppContext);
  const [isDisabled] = useState(isFormDisabled(codebookLogicalControlOperation));
  const { Labels } = LabelsContext();

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      onCreateCodebookLogicalControl(codebookLogicalControlToChange)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
    onUpdate: () => {
      onUpdateCodebookLogicalControl(codebookLogicalControlToChange)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
    onDelete: () => {
      onDeleteCodebookLogicalControl(codebookLogicalControlToChange?.id)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
  }));

  return (
    <div className="layout-institution-list">
      <div className="p-grid">
        <Panel header={Labels.HEADER_FIRST_COODEBOOK} className="p-col-6">
          <div className="p-col-12 p-xl-12 p-lg-12 p-md-6 p-sm-12 tab-view">
            <div className="p-nogutter">
              <div className="p-col-12 p-grid p-align-center p-nogutter margin-top-bottom-5">
                <div className="p-col-4">
                  <div>
                    {Labels.CODEBOOK_TYPE}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="p-col-8 p-fluid ">
                  <Dropdown
                    filter
                    disabled={isDisabled}
                    placeholder={Labels.CODEBOOK_TYPE}
                    resetFilterOnHide
                    optionLabel="name"
                    optionValue="code"
                    options={codebookList}
                    value={codebookLogicalControlToChange && codebookLogicalControlToChange?.primaryCodebookTypeEnum}
                    onChange={(e) => {
                      setCodebookLogicalControlToChange({
                        ...codebookLogicalControlToChange!,
                        primaryCodebookTypeEnum: codebookList!.find((x) => x.code === e.value)?.code!,
                        primaryFirstValue: null,
                        primarySecondValue: null,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="p-col-12 p-grid p-align-center p-nogutter margin-top-bottom-5">
                <div className="p-col-4">
                  <div>
                    {Labels.HEADER_OPERATOR}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="p-col-8 p-fluid ">
                  <Dropdown
                    disabled={isDisabled}
                    placeholder={Labels.OPERATOR_FIELD_DROPDOWN_CHOOSE}
                    resetFilterOnHide
                    optionLabel="name"
                    optionValue="code"
                    options={operatorCodebookLogicalControl(Labels)}
                    value={codebookLogicalControlToChange && codebookLogicalControlToChange.primaryEnumOperator}
                    onChange={(e: any) => {
                      setCodebookLogicalControlToChange({
                        ...codebookLogicalControlToChange!,
                        primaryEnumOperator: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              {codebookOptionListPrimary && (
                <div className="p-col-12 p-grid p-align-center p-nogutter margin-top-bottom-5">
                  <div className="p-col-4">
                    <div>
                      {Labels.CODEBOOK_OPTION}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="p-col-8 p-fluid">
                    <Dropdown
                      filter
                      disabled={isDisabled}
                      placeholder={Labels.CODEBOOK_OPTION_DROPDOWN_CHOOSE}
                      resetFilterOnHide
                      optionLabel="dropdownValue"
                      optionValue="code"
                      options={codebookLogicalControlToChange?.primaryCodebookTypeEnum ? codebookOptionListPrimary : []}
                      value={codebookLogicalControlToChange && codebookLogicalControlToChange?.primaryFirstValue}
                      onChange={(e) => {
                        setCodebookLogicalControlToChange({
                          ...codebookLogicalControlToChange!,
                          primaryFirstValue: codebookOptionListPrimary!.find((x: any) => x.code === e.value)?.code!,
                        });
                      }}
                    />
                  </div>
                </div>
              )}

              {codebookOptionListPrimary && codebookLogicalControlToChange?.primaryEnumOperator === CodebookLogicalControlOperator.RANGE && (
                <div className="p-col-12 p-grid p-align-center p-nogutter margin-top-bottom-5">
                  <div className="p-col-4">
                    <div>
                      {Labels.CODEBOOK_OPTION} {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="p-col-8 p-fluid">
                    <Dropdown
                      filter
                      disabled={isDisabled}
                      placeholder={Labels.CODEBOOK_OPTION_DROPDOWN_CHOOSE}
                      resetFilterOnHide
                      optionLabel="dropdownValue"
                      optionValue="code"
                      options={codebookLogicalControlToChange?.primaryCodebookTypeEnum ? codebookOptionListPrimary : []}
                      value={codebookLogicalControlToChange && codebookLogicalControlToChange?.primarySecondValue}
                      onChange={(e) => {
                        setCodebookLogicalControlToChange({
                          ...codebookLogicalControlToChange!,
                          primarySecondValue: codebookOptionListPrimary!.find((x: any) => x.code === e.value)?.code!,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Panel>
        <Panel header={Labels.HEADER_SECOND_COODEBOOK} className="p-col-6">
          <div className="p-col-12 p-xl-12 p-lg-12 p-md-6 p-sm-12 tab-view">
            <div className="p-nogutter">
              <div className="p-col-12 p-grid p-align-center p-nogutter margin-top-bottom-5">
                <div className="p-col-4">
                  <div>
                    {Labels.CODEBOOK_TYPE}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="p-col-8 p-fluid ">
                  <Dropdown
                    filter
                    disabled={isDisabled}
                    placeholder={Labels.CODEBOOK_TYPE}
                    resetFilterOnHide
                    optionLabel="name"
                    optionValue="code"
                    options={codebookList}
                    value={codebookLogicalControlToChange && codebookLogicalControlToChange?.secondaryCodebookTypeEnum}
                    onChange={(e) => {
                      setCodebookLogicalControlToChange({
                        ...codebookLogicalControlToChange!,
                        secondaryCodebookTypeEnum: codebookList!.find((x) => x.code === e.value)?.code!,
                        secondaryFirstValue: null,
                        secondarySecondValue: null,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="p-col-12 p-grid p-align-center p-nogutter margin-top-bottom-5">
                <div className="p-col-4">
                  <div>
                    {Labels.HEADER_OPERATOR}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="p-col-8 p-fluid">
                  <Dropdown
                    disabled={isDisabled}
                    placeholder={Labels.OPERATOR_FIELD_DROPDOWN_CHOOSE}
                    resetFilterOnHide
                    optionLabel="name"
                    optionValue="code"
                    options={operatorCodebookLogicalControl(Labels)}
                    value={codebookLogicalControlToChange && codebookLogicalControlToChange.secondaryEnumOperator}
                    onChange={(e: any) => {
                      setCodebookLogicalControlToChange({
                        ...codebookLogicalControlToChange!,
                        secondaryEnumOperator: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              {codebookOptionListSecondary && (
                <div className="p-col-12 p-grid p-align-center p-nogutter margin-top-bottom-5">
                  <div className="p-col-4">
                    <div>
                      {Labels.CODEBOOK_OPTION}
                      {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="p-col-8 p-fluid">
                    <Dropdown
                      filter
                      disabled={isDisabled}
                      placeholder={Labels.CODEBOOK_OPTION_DROPDOWN_CHOOSE}
                      resetFilterOnHide
                      optionLabel="dropdownValue"
                      optionValue="code"
                      options={codebookLogicalControlToChange?.secondaryCodebookTypeEnum ? codebookOptionListSecondary : []}
                      value={codebookLogicalControlToChange && codebookLogicalControlToChange?.secondaryFirstValue}
                      onChange={(e) => {
                        setCodebookLogicalControlToChange({
                          ...codebookLogicalControlToChange!,
                          secondaryFirstValue: codebookOptionListSecondary!.find((x: any) => x.code === e.value)?.code!,
                        });
                      }}
                    />
                  </div>
                </div>
              )}

              {codebookOptionListSecondary && codebookLogicalControlToChange?.secondaryEnumOperator === CodebookLogicalControlOperator.RANGE && (
                <div className="p-col-12 p-grid p-align-center p-nogutter margin-top-bottom-5">
                  <div className="p-col-4">
                    <div>
                      {Labels.CODEBOOK_OPTION} {Labels.SPECIAL_CHAR_REQUIRED}
                    </div>
                  </div>
                  <div className="p-col-8 p-fluid">
                    <Dropdown
                      filter
                      disabled={isDisabled}
                      placeholder={Labels.CODEBOOK_OPTION_DROPDOWN_CHOOSE}
                      resetFilterOnHide
                      optionLabel="dropdownValue"
                      optionValue="code"
                      options={codebookLogicalControlToChange?.secondaryCodebookTypeEnum ? codebookOptionListSecondary : []}
                      value={codebookLogicalControlToChange && codebookLogicalControlToChange?.secondarySecondValue}
                      onChange={(e) => {
                        setCodebookLogicalControlToChange({
                          ...codebookLogicalControlToChange!,
                          secondarySecondValue: codebookOptionListSecondary!.find((x: any) => x.code === e.value)?.code!,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
}
