import React from "react";
import { InputText } from "primereact/inputtext";
import { PickList } from "primereact/picklist";
import { TabPanel, TabView } from "primereact/tabview";
import { useContext, useImperativeHandle, useState } from "react";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { isFormDisabled, useEffectOnce } from "../../../infrastructure/system/Utils";
import UserReadDto from "../../../model/user/UserReadDto";
import { AppContext } from "../../../Store";

interface CrudUserProp {
  userOperation: any;
  user: UserReadDto;
  dialogRef?: any;
  index: any;
  setIndex: Function;
  onDeleteUser: any;
  onCreateUser: any;
  onUpdateUser: any;
  institutionList: any;
  userInstitutionList: any;
  onChangeInstitution: any;
  onChangeUserGroup: any;
  userGroupUserList: any;
  userGroupList: any;
}

export default function CrudUser(prop: CrudUserProp) {
  const {
    userOperation,
    user,
    dialogRef,
    onDeleteUser,
    onCreateUser,
    onUpdateUser,
    institutionList,
    userInstitutionList,
    onChangeInstitution,
    index,
    setIndex,
    userGroupUserList,
    userGroupList,
    onChangeUserGroup,
  } = prop;
  const { showMessage } = useContext(AppContext);
  const [isDisabled] = useState(isFormDisabled(userOperation));
  const [userChange, setUserChange] = useState<UserReadDto | undefined>(user);

  const { Labels } = LabelsContext();

  useEffectOnce(() => {
    if (userOperation === EntityOperation.CREATE) {
      setUserChange({
        ...userChange!,
        password: Labels.USER_PASSWORD_DEFAULT,
        passwordConfirm: Labels.USER_PASSWORD_DEFAULT,
      });
    }
  });

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      onCreateUser(userChange)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
    onUpdate: () => {
      onUpdateUser(userChange)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
    onDelete: () => {
      onDeleteUser(userChange!.id)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
  }));

  const itemTemplat = (item: any) => {
    return <h6 className="p-mb-2">{item.institution.name}</h6>;
  };
  const itemTemplateUserGroup = (item: any) => {
    return <h6 className="p-mb-2">{item.userGroup?.name}</h6>;
  };

  return (
    <div className="layout-user-list">
      <TabView
        className="tabview-margin-top"
        renderActiveOnly={false}
        activeIndex={index}
        onTabChange={(e) => {
          setIndex(e.index);
        }}
      >
        <TabPanel header={Labels.LABEL_USER}>
          <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
            <div className="p-grid p-align-center p-nogutter">
              <div className="p-col-4">
                <div>
                  {Labels.USER_FIRST_NAME}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputText
                  disabled={isDisabled}
                  value={userChange && userChange.firstname}
                  onChange={(e: any) => {
                    setUserChange({
                      ...userChange!,
                      firstname: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>
                  {Labels.USER_LAST_NAME}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputText
                  disabled={isDisabled}
                  value={userChange && userChange.lastname}
                  onChange={(e: any) => {
                    setUserChange({
                      ...userChange!,
                      lastname: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>
                  {Labels.USER_USER_NAME}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputText disabled={true} value={userChange && userChange.username} />
              </div>
              <div className="p-col-4">
                <div>
                  {Labels.USER_EMAIL}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputText
                  disabled={isDisabled}
                  value={userChange && userChange.email}
                  onChange={(e: any) => {
                    setUserChange({
                      ...userChange!,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header={Labels.LABEL_INSTITUTION_LIST} disabled={userOperation === EntityOperation.CREATE}>
          <div className={userOperation === EntityOperation.READ ? "read-only" : "non-read-only"}>
            <PickList
              sourceHeader={Labels.LABEL_INSTITUTION_LIST}
              targetHeader={Labels.LABEL_USER_INSTITUTION_LIST}
              source={institutionList}
              target={userInstitutionList}
              onChange={onChangeInstitution}
              itemTemplate={itemTemplat}
              showSourceControls={false}
              showTargetControls={false}
            ></PickList>
          </div>
        </TabPanel>
        <TabPanel header={Labels.USER_GROUP_LIST} disabled={userOperation === EntityOperation.CREATE}>
          <div className={userOperation === EntityOperation.READ ? "read-only" : "non-read-only"}>
            <PickList
              sourceHeader={Labels.USER_GROUP_LIST}
              targetHeader={Labels.LABEL_USER_GROUP_USER_LIST}
              source={userGroupList}
              target={userGroupUserList}
              onChange={onChangeUserGroup}
              itemTemplate={itemTemplateUserGroup}
              showSourceControls={false}
              showTargetControls={false}
            ></PickList>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
