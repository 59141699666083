import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { useRef, useState } from "react";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import EnumReportParameterType from "../../../../infrastructure/system/EnumReportParameterType";
import LabelsContext from "../../../../infrastructure/system/LabelsContext";
import { isFormDisabled, parameterType } from "../../../../infrastructure/system/Utils";
import ExportData from "../../../app/export/ExportData";
import CrudReportParameter from "./CrudReportParameter";
import ReportLogical, { ReportLogicalType } from "./ReportParameterLogical";

export default function ReportParameterList() {
  const {
    reportParameterList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    selectedParameter,
    setSelectedParameter,
    exportData,
    onPage,
    openDialog,
    closeDialog,
    dialogHeader,
    onCreate,
    onDelete,
    onUpdate,
    entityOperation,
    dialogRef,
    displayDialog,
    setDisplayDialog,
    selectedParameterToChange,
    setSelectedParameterToChange,
    index,
    getResultSet,
    resultList,
    deleteReportParameter,
  }: ReportLogicalType = ReportLogical();

  const { Labels } = LabelsContext();
  const dt = useRef<any>(null);
  const [selectedReportParameter, setSelectedReportParameter] = useState(null);

  const getParameterTypeLabel = (column: any) => {
    switch (column.reportParameterType) {
      case EnumReportParameterType.TEXT:
        return Labels.LABEL_LABEL;
      case EnumReportParameterType.NUMBER:
        return Labels.LABEL_NUMBER;
      case EnumReportParameterType.SET:
        return Labels.LABEL_SET;
      case EnumReportParameterType.BOOLEAN:
        return Labels.LABEL_BOOLEAN;
      case EnumReportParameterType.DATE:
        return Labels.LABEL_DATE_TIME;
      case EnumReportParameterType.MULTISELECT:
        return Labels.MULTISELECT;
      default:
        return;
    }
  };

  const onParameterFilterChange = (event: any) => {
    dt.current.filter(event.value, "reportParameterType", "equals");
    setSelectedReportParameter(event.value);
  };

  const renderParameterFilter = () => {
    return (
      <Dropdown
        showClear
        onChange={onParameterFilterChange}
        value={selectedReportParameter}
        options={parameterType(Labels)}
        placeholder={Labels.PLACEHOLDER_SEARCH_BY_STATUS}
        className="p-column-filter"
      />
    );
  };
  const parmeterFilterElement = renderParameterFilter();

  const leftContents = () => (
    <>
      <Button
        icon="pi pi-plus"
        className="p-button-success btn-margin-right"
        label={Labels.BUTTON_CREATE}
        tooltip={Labels.LABEL_CREATE_REPORT_PARAMETER}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          openDialog(EntityOperation.CREATE);
        }}
      />

      <Button
        icon="pi pi-pencil"
        className="p-button-warning btn-margin-right"
        disabled={!selectedParameter}
        label={Labels.BUTTON_UPDATE}
        tooltip={Labels.LABEL_UPDATE_REPORT_PARAMETER}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          selectedParameter && openDialog(EntityOperation.UPDATE);
        }}
      />
      <Button
        icon="pi pi-info-circle"
        label={Labels.BUTTON_DETAILS}
        tooltip={Labels.LABEL_DETAILS_REPORT_PARAMETER}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedParameter}
        onClick={() => {
          selectedParameter && openDialog(EntityOperation.READ);
        }}
      />
    </>
  );

  const rightContents = () => (
    <>
      <ExportData data={exportData} name={Labels.MENU_REPORT_PARAMETER} dt={dt} />
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        label={Labels.BUTTON_DELETE}
        disabled={!selectedParameter}
        onClick={() => {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPORT_PARAMETER,
            icon: "pi pi-power-off",
            accept: () => {
              deleteReportParameter(selectedParameter.id);
            },
          });
        }}
      />
    </>
  );

  const leftContentsDialog = () => (
    <>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.LABEL_CREATE_REPORT_PARAMETER}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && index === 0 && (
        <Button
          label={Labels.BUTTON_UPDATE}
          tooltip={Labels.LABEL_UPDATE_REPORT_PARAMETER}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            dialogRef.current.onUpdate();
          }}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          tooltip={Labels.LABEL_DELETE_REPORT_PARAMETER}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </>
  );

  const rightContentsDialog = () => <Button label={index === 0 ? Labels.BUTTON_CANCEL : Labels.BUTTON_CLOSE} icon="pi pi-times" onClick={closeDialog} />;
  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  return (
    <div className="layout-crud-form">
      <Toolbar left={leftContents} right={rightContents} />
      <div>
        <DataTable
          ref={dt}
          value={reportParameterList}
          paginator
          first={first}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={tableRows}
          rowsPerPageOptions={[5, 10, 20]}
          onPage={(e) => {
            onPage(e.rows, e.first);
          }}
          selectionMode="single"
          selection={selectedRow}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          onRowSelect={(e) => setSelectedParameter(e.data)}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          exportFilename={Labels.MENU_REPORT_PARAMETER}
        >
          <Column field={"displayOrder"} header={Labels.LABEL_DISPLAY_ORDER} filter filterMatchMode="contains" sortable />
          <Column field={"name"} header={Labels.HEADER_NAME} filter filterMatchMode="contains" sortable />
          <Column field={"requestName"} header={Labels.LABEL_REQUEST_NAME} filter filterMatchMode="contains" sortable />
          <Column field={"reportParameterType"} header={Labels.LABEL_REPORT_PARAMETER_TYPE} sortable filter filterElement={parmeterFilterElement} body={getParameterTypeLabel} />
        </DataTable>
      </div>
      <Dialog header={dialogHeader(entityOperation)} visible={displayDialog} onHide={closeDialog} style={{ width: "1000px" }} footer={dialogFooter()}>
        <CrudReportParameter
          dialogRef={dialogRef}
          reportParameterToChange={selectedParameterToChange}
          setReportParameterToChange={setSelectedParameterToChange}
          isDisabled={isFormDisabled(entityOperation)}
          setDisplayDialog={setDisplayDialog}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onDelete={onDelete}
          getResultSet={getResultSet}
          resultList={resultList}
          entityOperation={entityOperation}
        />
      </Dialog>
    </div>
  );
}
