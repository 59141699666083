import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface CodebookControllerType {
  axiosGetCodebookTypeList: any;
  axiosGetCodebookType: any;
  axiosGetCodebookList: any;
  axiosGetCodebookListWithCode: any;
  axiosCountCodebookList: any;
  axiosSearchCodebook: any;
  axiosGetCodebook: any;
  axiosGetCodebookLogicalControlList: any;
  axiosCreateCodebookLogicalControl: any;
  axiosUpdateCodebookLogicalControl: any;
  axiosDeleteCodebookLogicalControl: any;
  axiosGetCodebookAllList: any;
  axiosGetCodebookFromSjz: any;
}

export function CodebookController(): CodebookControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetCodebookTypeList = () => {
    return axios.get(Endpoint.COODEBOOK_TYPE_LIST, axiosConfig(authData!.token));
  };

  const axiosGetCodebookType = (path: string) => {
    return axios.get(`${Endpoint.COODEBOOK}/${path}`, axiosConfig(authData!.token));
  };

  const axiosGetCodebookList = (codebook: string, params: any) => {
    return axios.get(`${Endpoint.COODEBOOK}/${codebook}/list`, axiosConfig(authData!.token, params));
  };

  const axiosGetCodebookListWithCode = (code: string) => {
    return axios.get(`${Endpoint.COODEBOOK}/enumCodeCodebookType/${code}`, axiosConfig(authData!.token));
  };

  const axiosCountCodebookList = (codebook: string, params: any) => {
    return axios.get(`${Endpoint.COODEBOOK}/${codebook}/count`, axiosConfig(authData!.token, params));
  };

  const axiosSearchCodebook = (codeType: string, body: any) => {
    return axios.post(`${Endpoint.COODEBOOK}/searchCodebook/${codeType}`, body, axiosConfig(authData!.token));
  };

  const axiosGetCodebook = (codebookTypeCode: string, code: string) => {
    return axios.get(`${Endpoint.COODEBOOK}/codebookType/${codebookTypeCode}/${code}`, axiosConfig(authData!.token));
  };

  const axiosGetCodebookAllList = () => {
    return axios.get(`${Endpoint.COODEBOOK}/codebookList`, axiosConfig(authData!.token));
  };

  const axiosGetCodebookLogicalControlList = () => {
    return axios.get(`${Endpoint.CODEBOOK_LOGIC_CONTROL_LIST}`, axiosConfig(authData!.token));
  };

  const axiosCreateCodebookLogicalControl = (codebookLogicalControlChange: any) => {
    return axios.post(Endpoint.CODEBOOK_LOGIC_CONTROL, codebookLogicalControlChange ? codebookLogicalControlChange : {}, axiosConfig(authData!.token));
  };
  const axiosUpdateCodebookLogicalControl = (codebookLogicalControlChange: any) => {
    return axios.put(`${Endpoint.CODEBOOK_LOGIC_CONTROL}`, codebookLogicalControlChange ? codebookLogicalControlChange : {}, axiosConfig(authData!.token));
  };
  const axiosDeleteCodebookLogicalControl = (codebookLogicalControlID: number) => {
    return axios.delete(`${Endpoint.CODEBOOK_LOGIC_CONTROL}/${codebookLogicalControlID}`, axiosConfig(authData!.token));
  };

  const axiosGetCodebookFromSjz = (params: any) => {
    return axios.post(`${Endpoint.CODEBOOK_SJZ}`, params, axiosConfig(authData!.token));
  };


  return {
    axiosGetCodebookTypeList,
    axiosGetCodebookType,
    axiosGetCodebookList,
    axiosGetCodebookListWithCode,
    axiosCountCodebookList,
    axiosSearchCodebook,
    axiosGetCodebook,
    axiosGetCodebookLogicalControlList,
    axiosCreateCodebookLogicalControl,
    axiosUpdateCodebookLogicalControl,
    axiosDeleteCodebookLogicalControl,
    axiosGetCodebookAllList,
    axiosGetCodebookFromSjz
  };
}

export type { CodebookControllerType };
