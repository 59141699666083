import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig, FORM_CCF } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface FormControllerType {
  axiosGetFormList: any;
  axiosCreateForm: any;
  axiosUpdateForm: any;
  axiosDeleteForm: any;
  axiosCreateFormField: any;
  axiosUpdateFormField: any;
  axiosDeleteFormField: any;
  axiosGetForm: any;
  axiosGetFormFieldsList: any;
  axiosSaveUnfinishedForm: any;
  axiosGetFormFilled: any;
  axiosGetFieldFilledList: any;
  axiosDeleteFormFilled: any;
  axiosCreateFormFilled: any;
  axiosGetForRevisionFormList: any;
  axiosCountForRevisionFormList: any;
  axiosUpdateFormFilled: any;
  axiosFormFilledListSearch: any;
  axiosCountSubmittedFormList: any;
  axiosGetFormSemanticValidation: any;
  axiosCreateFormSemanticValidation: any;
  axiosUpdateFormSemanticValidation: any;
  axiosDeleteFormSemanticValidation: any;
  axiosGetFormUserRightList: any;
  axiosCreateFormUserRightList: any;
  axiosDeleteFormUserRightList: any;
}

export function FormController(): FormControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetFormList = () => {
    return axios.get(Endpoint.FORM_LIST, axiosConfig(authData!.token));
  };
  const axiosGetForm = (formCode: any) => {
    return axios.get(Endpoint.FORM_LIST + "/" + FORM_CCF, axiosConfig(authData!.token));
  };
  const axiosGetFormFieldsList = (formCode: any) => {
    return axios.get(Endpoint.FORM_LIST + "/" + FORM_CCF + "/fieldList", axiosConfig(authData!.token));
  };
  const axiosCreateForm = (formChange: any) => {
    return axios.post(Endpoint.FORM_LIST, formChange ? formChange : {}, axiosConfig(authData!.token));
  };
  const axiosUpdateForm = (formChange: any, formCode: any) => {
    return axios.put(Endpoint.FORM_LIST + "/" + formCode, formChange, axiosConfig(authData!.token));
  };
  const axiosDeleteForm = (formCode: any) => {
    return axios.delete(Endpoint.FORM_LIST + "/" + formCode, axiosConfig(authData!.token));
  };
  const axiosCreateFormField = (newfield: any, formCode: any) => {
    return axios.post(Endpoint.FORM_LIST + "/" + formCode + "/fieldList", newfield, axiosConfig(authData!.token));
  };
  const axiosUpdateFormField = (fieldChange: any, formCode: any, fieldId: any) => {
    return axios.put(Endpoint.FORM_LIST + "/" + formCode + "/fieldList/" + fieldId, fieldChange, axiosConfig(authData!.token));
  };
  const axiosDeleteFormField = (formCode: any, fieldId: any) => {
    return axios.delete(Endpoint.FORM_LIST + "/" + formCode + "/fieldList/" + fieldId, axiosConfig(authData!.token));
  };
  const axiosSaveUnfinishedForm = (params: any) => {
    return axios.post(Endpoint.UNFINISHED_FORM_FILLED_LIST, params, axiosConfig(authData!.token));
  };
  const axiosGetFormFilled = (formFilledID: number) => {
    return axios.get(`${Endpoint.FORM_FILLED_LIST}/${formFilledID}`, axiosConfig(authData!.token));
  };
  const axiosGetFieldFilledList = (formFilledID: number) => {
    return axios.get(`${Endpoint.FORM_FILLED_LIST}/${formFilledID}/fieldFilledList`, axiosConfig(authData!.token));
  };
  const axiosDeleteFormFilled = (formFilledID: number) => {
    return axios.delete(`${Endpoint.FORM_FILLED_LIST}/${formFilledID}`, axiosConfig(authData!.token));
  };
  const axiosCreateFormFilled = (params: any) => {
    return axios.post(Endpoint.FORM_FILLED_LIST, params, axiosConfig(authData!.token));
  };
  const axiosGetForRevisionFormList = () => {
    return axios.get(`${Endpoint.FOR_REVISION_FORM_LIST}/${authData?.currentUser.id}`, axiosConfig(authData!.token));
  };

  const axiosCountForRevisionFormList = () => {
    return axios.get(`${Endpoint.COUNT_FOR_REVISION_FORM_LIST}/${authData?.currentUser.id}`, axiosConfig(authData!.token));
  };
  const axiosCountSubmittedFormList = () => {
    return axios.get(`${Endpoint.COUNT_SUBMITTED_FORM_LIST}/${authData?.currentUser.id}`, axiosConfig(authData!.token));
  };
  const axiosUpdateFormFilled = (formFilledID: number, params: any) => {
    return axios.put(`${Endpoint.FORM_FILLED_LIST}/${formFilledID}`, params, axiosConfig(authData!.token));
  };

  const axiosFormFilledListSearch = (data: any) => {
    return axios.post(Endpoint.FORM_FILLED_LIST_SEARCH, data, axiosConfig(authData!.token));
  };

  const axiosGetFormSemanticValidation = (formCode: any) => {
    return axios.get(`${Endpoint.FORM_LIST}/${formCode}/formSemanticValidationList`, axiosConfig(authData!.token));
  };

  const axiosCreateFormSemanticValidation = (formCode: string, data: any) => {
    return axios.post(`${Endpoint.FORM_LIST}/${formCode}/formSemanticValidationList`, data, axiosConfig(authData!.token));
  };

  const axiosUpdateFormSemanticValidation = (formCode: string, formSemanticValidationId: number, data: any) => {
    return axios.put(`${Endpoint.FORM_LIST}/${formCode}/formSemanticValidationList/${formSemanticValidationId}`, data, axiosConfig(authData!.token));
  };

  const axiosDeleteFormSemanticValidation = (formCode: string, formSemanticValidationId: number) => {
    return axios.delete(`${Endpoint.FORM_LIST}/${formCode}/formSemanticValidationList/${formSemanticValidationId}`, axiosConfig(authData!.token));
  };

  const axiosGetFormUserRightList = (formCode: string) => {
    return axios.get(`${Endpoint.FORM_LIST}/${formCode}/formUserRightList`, axiosConfig(authData!.token));
  };

  const axiosCreateFormUserRightList = (formCode: string, data: any) => {
    return axios.post(`${Endpoint.FORM_LIST}/${formCode}/formUserRightList`, data, axiosConfig(authData!.token));
  };
  const axiosDeleteFormUserRightList = (formCode: string, formUserRightId: number) => {
    return axios.delete(`${Endpoint.FORM_LIST}/${formCode}/formUserRightList/${formUserRightId}`, axiosConfig(authData!.token));
  };

  return {
    axiosGetFormList,
    axiosGetForm,
    axiosGetFormFieldsList,
    axiosCreateForm,
    axiosUpdateForm,
    axiosDeleteForm,
    axiosCreateFormField,
    axiosSaveUnfinishedForm,
    axiosUpdateFormField,
    axiosDeleteFormField,
    axiosGetFormFilled,
    axiosGetFieldFilledList,
    axiosDeleteFormFilled,
    axiosCreateFormFilled,
    axiosGetForRevisionFormList,
    axiosCountForRevisionFormList,
    axiosUpdateFormFilled,
    axiosFormFilledListSearch,
    axiosCountSubmittedFormList,
    axiosGetFormSemanticValidation,
    axiosCreateFormSemanticValidation,
    axiosUpdateFormSemanticValidation,
    axiosDeleteFormSemanticValidation,
    axiosGetFormUserRightList,
    axiosCreateFormUserRightList,
    axiosDeleteFormUserRightList,
  };
}

export type { FormControllerType };
