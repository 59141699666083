import React from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useContext, useImperativeHandle, useState } from "react";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { isFormDisabled } from "../../../infrastructure/system/Utils";
import JurisdictionReadDto from "../../../model/JurisdictionReadDto";
import { AppContext } from "../../../Store";
import { Dropdown } from "primereact/dropdown";

interface CrudJurisdictionProp {
  jurisdictionOperation: string;
  jurisdiction?: JurisdictionReadDto;
  onCreateJurisdiction: any;
  dialogRef?: any;
  onUpdateJurisdiction: any;
  onDeleteJurisdiction: any;
  districtList: Array<any>;
  selectedDistrict: any;
  setSelectedDistrict: any;
}

export default function CrudJurisdiction(prop: CrudJurisdictionProp) {
  const { jurisdictionOperation, jurisdiction, onCreateJurisdiction, dialogRef, onUpdateJurisdiction, onDeleteJurisdiction, districtList, selectedDistrict, setSelectedDistrict } = prop;
  const { showMessage } = useContext(AppContext);
  const [isDisabled] = useState(isFormDisabled(jurisdictionOperation));
  const [jurisdictionChange, setJurisdictionChange] = useState<JurisdictionReadDto | undefined>(jurisdiction);
  const { Labels } = LabelsContext();

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      onCreateJurisdiction(jurisdictionChange)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
    onUpdate: () => {
      onUpdateJurisdiction(jurisdictionChange)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
    onDelete: () => {
      onDeleteJurisdiction(jurisdictionChange!.id)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
  }));

  return (
    <div className="layout-user-group">
      <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
        <div className="p-grid p-align-center p-nogutter">
          <div className="p-col-4">
            <div>
              {Labels.JURISDICTION_CODE}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <Dropdown
              disabled={isDisabled}
              filter
              placeholder={Labels.LABEL_CODE}
              resetFilterOnHide
              optionLabel="name"
              optionValue="code"
              options={districtList}
              value={jurisdictionChange && selectedDistrict?.code}
              onChange={(e) => {
                setJurisdictionChange({ ...jurisdictionChange!, code: districtList!.find((x) => x.code === e.target.value)!.code });
                setSelectedDistrict(districtList!.find((x) => x.code === e.target.value)!);
              }}
            />
          </div>
          <div className="p-col-4">
            <div>
              {Labels.JURISDICTION_NAME}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <InputText
              disabled={isDisabled}
              value={jurisdictionChange && jurisdictionChange.name}
              onChange={(e: any) => {
                setJurisdictionChange({
                  ...jurisdictionChange!,
                  name: e.target.value,
                });
              }}
            />
          </div>
          <div className="p-col-4">
            <div>{Labels.JURISDICTION_DESCRIPTION}</div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <InputTextarea
              rows={3}
              cols={5}
              disabled={isDisabled}
              value={jurisdictionChange && jurisdictionChange.description}
              onChange={(e: any) => {
                setJurisdictionChange({
                  ...jurisdictionChange!,
                  description: e.target.value,
                });
              }}
            />
          </div>

          <div className="p-col-4">
            <div>{Labels.JURISDICTION_NOTE}</div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <InputTextarea
              rows={3}
              cols={5}
              disabled={isDisabled}
              value={jurisdictionChange && jurisdictionChange.note}
              onChange={(e: any) => {
                setJurisdictionChange({
                  ...jurisdictionChange!,
                  note: e.target.value,
                });
              }}
            />
          </div>
          <div className="p-col-4">
            <div>
              {Labels.JURISDICTION_DISPLAY_ORDER} {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <InputNumber
              disabled={isDisabled}
              value={jurisdictionChange && jurisdictionChange.displayOrder}
              showButtons
              buttonLayout="horizontal"
              decrementButtonClassName="p-button-danger"
              incrementButtonClassName="p-button-success"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              onValueChange={(e: any) => {
                setJurisdictionChange({
                  ...jurisdictionChange!,
                  displayOrder: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
