import axios from "axios";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { ReportController, ReportControllerType } from "../../../../controllers/report/ReportController";
import { RoleController, RoleControllerType } from "../../../../controllers/role/RoleController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../../infrastructure/system/LabelsContext";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, useEffectOnce } from "../../../../infrastructure/system/Utils";
import { AppContext } from "../../../../Store";

interface ReportAccessLogicalType {
  accessList: any;
  first: any;
  tableRows: any;
  selectedRow: any;
  setSelectedRow: any;
  openDialog: any;
  setDisplayDialog: any;
  displayDialog: any;
  closeDialog: any;
  setSelectedReportAccess: any;
  selectedReportAccess: any;
  dialogHeader: any;
  reportAccessOperation: any;
  onCreate: any;
  onDelete: any;
  roleList: any;
  firstRole: any;
  tableRoleRows: any;
  selectedRoleRow: any;
  setSelectedRole: any;
  setSelectedRoleRow: any;
  selectedRole: any;
  onPage: any;
  onPageRole: any;
  deleteReportAccess: any;
}
interface UseParamsType {
  reportID?: string;
}

export default function ReportAccessLogical(): ReportAccessLogicalType {
  const { Labels } = LabelsContext();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const { reportID } = useParams<UseParamsType>();
  const [accessList, setAccessList] = useState<any>([]);
  const [roleList, setRoleList] = useState<any>([]);
  const [first, setFirst] = useState(0);
  const [firstRole, setFirstRole] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [tableRoleRows, setTableRoleRows] = useState(10);
  const [reportAccessOperation, setReportAccessOperation] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedRoleRow, setSelectedRoleRow] = useState<any>([]);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selectedReportAccess, setSelectedReportAccess] = useState<any | null>();
  const [selectedRole, setSelectedRole] = useState<any | null>();
  const { axiosGetAccessListByReport, axiosDeleteAccessReport, axiosCreateAccessReport }: ReportControllerType = ReportController();
  const { axiosGetRoleList }: RoleControllerType = RoleController();

  const closeDialog = () => {
    setDisplayDialog(false);
  };

  const openDialog = (entityOperation: String) => {
    setReportAccessOperation(entityOperation);
    setDisplayDialog(true);
  };
  useEffectOnce(() => {
    fetchData();
  });
  const dialogHeader = (entityOperation: string) => {
    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.LABEL_ADD_REPORT_ACCESS;
      case EntityOperation.DELETE:
        return Labels.LABEL_DELETE_REPORT_ACCESS;
      default:
        return "";
    }
  };
  const onPage = (rows: any, first: any) => {
    setTableRows(rows);
    setFirst(first);
  };
  const onPageRole = (rows: any, first: any) => {
    setTableRoleRows(rows);
    setFirstRole(first);
  };

  const fetchData = () => {
    if (reportID !== undefined) {
      setShowBlockUI(true);
      axios
        .all([axiosGetAccessListByReport(reportID), axiosGetRoleList()])
        .then(
          axios.spread((responseReport: any, responseRoles: any) => {
            setAccessList(responseReport.data.data);
            getRolesNotHave(responseReport.data.data, responseRoles.data);
            setShowBlockUI(false);
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        });
    }
  };

  const deleteReportAccess = (selectedReportAccessId: any) => {
    setShowBlockUI(true);
    axiosDeleteAccessReport(selectedReportAccessId)
      .then(
        axios.spread((responseReport: any) => {
          setShowBlockUI(false);
          fetchData();
          showMessage(MessageType.SUCCESS, Labels.MESSAGE_DELETE_REPORT_ACCESS_SUCCESS, "");
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const getRolesNotHave = (accessRole: any, roleList: any) => {
    let roleNotHave = [] as any;
    roleList.forEach((role: any) => {
      let notHave = true;
      accessRole.forEach((accessRole: any) => {
        if (accessRole.role === role.code) {
          notHave = false;
        }
      });
      if (notHave === true) {
        roleNotHave.push(role);
      }
    });
    setRoleList(roleNotHave);
  };
  const onCreate = () => {
    const reportAccess = {
      reportId: reportID,
      role: selectedRole.code,
    };
    setShowBlockUI(true);
    axios
      .all([axiosCreateAccessReport(reportAccess)])
      .then(
        axios.spread((responseReport: any) => {
          setShowBlockUI(false);
          fetchData();
          showMessage(MessageType.SUCCESS, Labels.MESSAGE_CREATE_REPORT_ACCESS_SUCCESS, "");
          closeDialog();
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };
  const onDelete = () => {
    setShowBlockUI(true);
    axios
      .all([axiosDeleteAccessReport(selectedReportAccess!.id)])
      .then(
        axios.spread((responseReport: any) => {
          setShowBlockUI(false);
          fetchData();
          showMessage(MessageType.SUCCESS, Labels.MESSAGE_DELETE_REPORT_ACCESS_SUCCESS, "");
          closeDialog();
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  return {
    accessList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    openDialog,
    setDisplayDialog,
    displayDialog,
    closeDialog,
    setSelectedReportAccess,
    selectedReportAccess,
    dialogHeader,
    reportAccessOperation,
    onCreate,
    onDelete,
    roleList,
    firstRole,
    tableRoleRows,
    selectedRoleRow,
    setSelectedRole,
    setSelectedRoleRow,
    selectedRole,
    onPage,
    onPageRole,
    deleteReportAccess
  };
}

export type { ReportAccessLogicalType };
