const Labels = {
  LATIN: "Latinica",
  CYRILLIC: "Ćirilica",
  NEW_LINE: "\n",
  TAB: "\t",
  SPACE: " ",
  DASH: " - ",
  SLASH: "/",
  // Special strings
  SPECIAL_CHAR_REQUIRED: "*",

  // Application
  APPLICATION_NAME: "Nacionalni registar malignih tumora Srbije",

  // Common
  COPYRIGHT: "Developed by   ",
  HELIANT: " HELIANT",
  LOGIN: "Prijava",
  FORGOTTEN_PASSWORD_OR_USERNAME: "Zaboravljena lozinka ili korisničko ime?",

  // Title labels
  TITLE_LOGIN: "Unesite Vaše pristupne parametre",
  TITLE_RESET_PASSWORD: "Postavljanje nove lozinke",

  // Menu labels
  MENU_FORMS: "Formulari",
  MENU_ADMIN: "Administracija",
  MENU_REPORT: "Izveštaji",
  MENU_REPORT_PARAMETER: "Parametri izveštaja",

  // Button labels
  BUTTON_CREATE: "Kreiraj",
  BUTTON_UPDATE: "Izmeni",
  BUTTON_DETAILS: "Detalji",
  BUTTON_LOGIN: "Prijava",
  BUTTON_LOGOUT: "Odjava",
  BUTTON_SAVE: "Sačuvaj",
  BUTTON_CANCEL: "Odustani",
  BUTTON_DELETE: "Obriši",
  BUTTON_UPLOAD_IMAGE: "Učitaj sliku",
  BUTTON_RESET_PASSWORD: "Postavi lozinku",
  BUTTON_NEW_FORM: "Nova prijava",
  BUTTON_EDIT_DRAFT: "Izmeni nacrt",
  BUTTON_EDIT: "Izmeni",
  BUTTON_BACK: "Nazad",
  BUTTON_ADD_USER: "Dodaj korisnika",
  BUTTON_ADD: "Dodaj",
  BUTTON_YES: "Da",
  BUTTON_NO: "Ne",
  BUTTON_CLOSE: "Zatvori",
  BUTTON_APPROVE: "Odobri",
  BUTTON_REVISION: "Revizija",
  BUTTON_REPORT_OF_DEATH: "Prijava smrti",
  BUTTON_MERGE_CCF: "Spajanje prijava",
  BUTTON_GENERATE_REPORT: "Generiši izveštaj",

  // Placeholder labels
  PLACEHOLDER_USERNAME: "Korisničko ime",
  PLACEHOLDER_PASSWORD: "Lozinka",
  PLACEHOLDER_EMAIL_REQUIRED: "Email (korisničko ime) *",
  PLACEHOLDER_PASSWORD_REQUIRED: "Lozinka *",
  PLACEHOLDER_PASSWORD_CONFIRM_REQUIRED: "Potvrda lozinke *",
  PLACEHOLDER_POST_COMMENT: "Dodaj komentar",
  PLACEHOLDER_SEARCH_BY_NAME: "Pretraga po imenu",
  PLACEHOLDER_NEW_PASSWORD: "Nova lozinka",
  PLACEHOLDER_NEW_PASSWORD_CONFIRM: "Potvrda nove lozinke",
  PLACEHOLDER_SEARCH_BY_STATUS: "Pretraga po statusu",
  PLACEHOLDER_INSTITUTION_TYPE: "Tip ustanove",
  PLACEHOLDER_CHOOSE_STATUS: "Izaberite status",
  PLACEHOLDER_CHOOSE_FORM_TYPE: "Izaberite tip formulara",
  PLACEHOLDER_CHOOSE_OPERATOR: "Izaberite operator",
  PLACEHOLDER_CHOOSEN_FORM_TYPE_LIST: "Izabran(o) je {0} format(a)",
  PLACEHOLDER_CHOOSE_PARAMETAR_TYPE: "Izaberite tip parametra...",
  PLACEHOLDER_PATIENT_FIRST_NAME: "Pretraga po imenu pacijenta",
  PLACEHOLDER_SEARCH_BY_INSTITUTION: "Pretraga po imenu ustanove",
  PLACEHOLDER_PATIENT_LAST_NAME: "Pretraga po prezimenu pacijenta",
  PLACEHOLDER_INSERT_DATE: "Pretraga po datumu unosa",
  PLACEHOLDER_INSERT_CODE_OR_NAME: "Unesite deo šifre ili naziva",

  LABEL_MUNICIPALITY: "Opština",
  LABEL_DESCRIPTION: "Opis",
  LABEL_NOTE: "Napomena",
  LABEL_EMAIL: "Email",
  LABEL_FIRSTNAME: "Ime",
  LABEL_LASTNAME: "Prezime",
  LABEL_CHOSEN_NAME: "Izabrano ime",
  LABEL_DELETE: "Obriši",
  LABEL_POST: "Postavi",
  LABEL_BEFORE: "pre",
  LABEL_MINUTES: "minuta",
  LABEL_HOURS: "časova",
  LABEL_EDIT: "Izmeni",
  LABEL_USER_PROFILE: "Korisnički profil",

  LABEL_DISPLAY_ORDER: "Prikazni redosled",
  LABEL_BACK_ON_LOGIN: "Nazad na login stranu",
  LABEL_TABLE_OF_FORMS: "Tabela formulara",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED: "Da li ste sigurni da želite da se odjavite?",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_BACK: "Da li ste sigurni da želite nazad?",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_GIVE_UP: "Da li ste sigurni da želite da odustanete?",
  LABEL_FORM_LIST: "Formulari",
  LABEL_COODEBOOK_TYPE_LIST: "Šifarnici",
  LABEL_COODEBOOK_LIST: "Šifarnik",
  LABEL_CODE: "Šifra",
  LABEL_NAME: "Naziv",
  LABEL_UPDATE_FORM: "Izmena formulara ",
  LABEL_DELETE_FORM: "Brisanje fromulara ",
  LABEL_DETAILS_FORM: "Detalji formulara ",
  LABEL_UPDATE_FORM_FIELD: "Izmena polja formulara ",
  LABEL_DELETE_FORM_FIELD: "Brisanje polja fromulara ",
  LABEL_DETAILS_FORM_FIELD: "Detalji polja formulara ",
  LABEL_CREATE_FORM_FIELD: "Kreiranje polja formulara",
  LABEL_CREATE_FORM: "Kreiranje formulara ",
  LABEL_UPDATE_FORM_SEMANTIC_VALIDATION: "Izmena semantičke validacije ",
  LABEL_DELETE_FORM_SEMANTIC_VALIDATION: "Brisanje semantičke validacije ",
  LABEL_DETAILS_FORM_SEMANTIC_VALIDATION: "Detalji semantičke validacije ",
  LABEL_CREATE_FORM_SEMANTIC_VALIDATION: "Kreiranje semantičke validacije ",
  LABEL_DELETE_REPORT_ACCESS: "Brisanje pristupa izveštaju ",
  LABEL_ADD_REPORT_ACCESS: "Dodavanje pristupa izveštaju",
  LABEL_UPDATE_FIELD_TYPE: "Izmena tipa polja ",
  LABEL_DELETE_FIELD_TYPE: "Brisanje tipa polja ",
  LABEL_DETAILS_FIELD_TYPE: "Detalji tipa polja ",
  LABEL_CREATE_FIELD_TYPE: "Kreiranje tipa polja",
  LABEL_CREATE_REPORT: "Kreiranje izveštaja ",
  LABEL_UPDATE_REPORT: "Izmena izveštaja ",
  LABEL_DELETE_REPORT: "Brisanje izveštaja ",
  LABEL_DETAILS_REPORT: "Detalji izveštaja ",
  LABEL_CREATE_REPORT_PARAMETER: "Kreiranje parametra izveštaja ",
  LABEL_UPDATE_REPORT_PARAMETER: "Izmena parametra izveštaja ",
  LABEL_DELETE_REPORT_PARAMETER: "Brisanje parametra izveštaja ",
  LABEL_DETAILS_REPORT_PARAMETER: "Detalji parametra izveštaja ",
  LABEL_FIELD_TYPE: "Tipovi polja",
  LABEL_CREATE: "Kreiraj",
  LABEL_DETAILS: "Detalji",
  LABEL_NAME_SHORT: "Skraćeni naziv",
  LABEL_DISTRICT: "Okrug",
  LABEL_JURISDICTION: "Jurisdikcija",
  LABEL_ADDRESS: "Adresa",
  LABEL_UNIT: "Jedinica",
  LABEL_USER: "Korisnik",
  LABEL_INSTITUTION_LIST: "Ustanove",
  LABEL_USER_INSTITUTION_LIST: "Ustanove korisnika",
  LABEL_USER_GROUP_USER_LIST: "Korisničke grupe korisnika",
  LABEL_TEXT: "Tekstualni",
  LABEL_LONG_TEXT: "Dugačak tekst",
  LABEL_INTEGER: "Celobrojni ",
  LABEL_NUMBER: "Brojni",
  LABEL_FLOAT: "Decimalni ",
  LABEL_DATE_TIME: "Datumski ",
  LABEL_SET: "Skupovni ",
  LABEL_LABEL: "Tekst ",
  LABEL_BOOLEAN: "Izborni ",
  LABEL_PANEL: "Panel ",
  LABEL_CODEBOOK: "Šifarnički ",
  LABEL_VALUE_NUMBER: "Brojna vrednost ",
  LABEL_UPLOADED_ATTACHMENTS: "Priložena dokumentacija",
  LABEL_NO_UPLOADED_ATTACHMENTS: "Nije priložena dokumentacija",
  LABEL_UPLOADED: "Otpremljeno",
  LABEL_ATTACHMENTS: "Dokumentacija",
  LABEL_UPLOAD: "Otpremi",
  LABEL_CHOOSE: "Pripremi",
  LABEL_REMOVE_DOCUMENT: "Da li ste sigurni da želite da uklonite dokument?",
  LABLE_COMMENTS: "Komentari",
  LOGICAL_CONTROL: "Logička pravila",
  COMMENT_IS_REQUIRED: "Obavezno je uneti komentar.",
  LABEL_DIALOG_MESSAGE_DELETE_USER_FROM_USER_GROUP: "Da li ste sigurni da želite da obrišete korisnika iz korisničke grupe?",
  LABEL_DIALOG_MESSAGE_DELETE_USER_FROM_INSTITUTION: "Da li ste sigurni da želite da obrišete korisnika iz ustanove?",
  LABEL_DIALOG_MESSAGE_LEAVE_PAGE: "Da li ste sigurni da želite da napustite stranicu?",
  LABEL_EXPORT: "Preuzmi",
  LABEL_PDF: "PDF",
  LABEL_XLS: "XLS",
  LABEL_CSV: "CSV",
  LABEL_JSON: "JSON",
  LABEL_STATUS: "Status formulara",
  LABEL_FORM_TYPE: "Tip formulara",
  LABEL_MERGE_FORMS: "Spajanje prijava",
  LABEL_APPROVE: "Prihvati",
  LABEL_APPROVE_FIRST_CCF: "Prihvati prvi formular",
  LABEL_APPROVE_SECOND_CCF: "Prihvati drugi formular",
  LABEL_CHOOSE_TWO_CCF: "Odaberite dve prijave koje želite da spojite",
  LABEL_FILE_FORMAT: "Format fajla",
  LABEL_REQUEST_NAME: "Request name",
  LABEL_REPORT_GENERATED: "Generisani izveštaji",
  LABEL_GENERATE_REPORT: "Generisanje izveštaja",
  LABEL_CHOOSE_REPORT: "Izaberite izveštaj",
  LABEL_PARAMETER: "Parametri",
  LABEL_CUSTOM_QUERY: "SQL upit",
  LABEL_REPORT_PARAMETER_TYPE: "Tip parametra",
  LABEL_ACTIONS: "Akcije",
  HEADER_PUBLIC: "Javan",
  LABEL_DOWNLOAD_IN_FORMAT: "Preuzmite izveštaj u odabranom formatu",
  LABEL_PUBLIC_REPORTS: "Javni izveštaji",
  TAB_ARTICLE_SUMMARY: "Osnovni podaci",
  REPORT_PARAMETERS: "Parametri izveštaja",
  TAB_ARTICLE_CONTENT: "Sadržaj",
  TAB_ARTICLE_PREVIEW_SUMMARY: "Kratak pregled",
  TAB_ARTICLE_PREVIEW_CONTENT: "Pregled sadržaja",
  LABEL_CREATE_LABEL: "Snimanje labele",
  LABEL_TRANSLATE_LABEL: "Prevodi labele",
  LABEL: "Labela",
  LABEL_PARENT_FIELD: "Parent polje",
  HOME_PAGE: "Početna strana",

  TABLE_EMPTY_MESSAGE: "Nema podataka",
  DOWNLOAD_DATA_TABLE: "Preuzmite sadržaj tabele",

  //Column title
  COLUMN_TITLE_DISTANCE: "Pređeno",
  COLUMN_HEADER_NAME: "Ime",
  COLUMN_HEADER_DESCRIPTION: "Opis",
  COLUMN_HEADER_STATUS: "Status",

  //Column header
  HEADER_NAME: "Naziv",
  HEADER_CODE: "Šifra",
  HEADER_DESCRIPTION: "Opis",
  HEADER_CATEGORY: "Kategorija",
  HEADER_JASPER_LOCATION: "Lokacija izveštaja",
  HEADER_FILE_FORMAT: "Format fajla",
  HEADER_DISPLAY_ORDER: "Prikazni redosled",
  HEADER_NOTE: "Napomena",
  HEADER_FIELD: "Polje",
  HEADER_OPERATOR: "Operator",
  HEADER_CUSTOM_FORMULA: "Formula",
  HEADER_FIELD_TARGET: "Ciljano polje",

  HEADER_FIRST_VALUE: "Prva vrednost",
  HEADER_SECOND_VALUE: "Druga vrednost",
  //Panel header
  HEADER_CREATE_FORM: "Kreiranje formulara",
  HEADER_UPDATE_FORM_FILLED: "Izmena formulara",
  HEADER_DELETE_FORM_FILLED: "Brisanje formulara",
  HEADER_READ_FORM_FILLED: "Detalji formulara",
  SEARCH_FORM: "Pretraga formulara",
  SEARCH_USER: "Pretraga korisnika",
  HEADER_FIRST_COODEBOOK: "Prvi šifarnik",
  HEADER_SECOND_COODEBOOK: "Drugi šifarnik",
  SEARCH_LOGS: "Pretraga logova",

  //Messages
  MESSAGES_FIRSTNAME_FIELD: "Polje 'Ime' je obavezno",
  MESSAGES_LASTNAME_FIELD: "Polje 'Prezime' je obavezno",
  MESSAGES_CHOSEN_NAME_FIELD: "Polje 'Izabrano ime' je obavezno",
  MESSAGES_EMAIL_FIELD: "Polje 'Email' je obavezno",
  MESSAGES_USERNAME_FIELD: "Polje 'Korisničko ime' je obavezno",
  MESSAGES_PASSWORD_FIELD: "Polje 'Lozinka' je obavezno",
  MESSAGES_PASSWORD_CONFIRM_FIELD: "Polje 'Potvrda lozinke' je obavezno",
  MESSAGES_PASSWORD_LENGTH: "Lozinka mora sadržati najmanje 8 alfa-numeričkih karaktera",
  MESSAGES_NOT_EQUAL_PASSWORD: "Lozinka i potvrda lozinke moraju biti iste",
  MESSAGES_UPLOAD_SIZE_50KB_ERROR: "Datoteka mora biti manja od 50 Kb.",
  MESSAGES_CHOOSE_IMAGE: "Izaberite",
  MESSAGES_FILE_SIZE_ERROR: "Maksimalna veličina slike je 5MB",
  MESSAGES_UPLOAD_IMAGE_ERROR: "Neuspešno učitvanje slike",
  MESSAGES_REGISTRATION_RESEND: "Ukoliko Vam nije stigao mejl, kliknite na dugme Pošalji opet mejl",
  MESSAGES_NO_RESULT_FOUND: "Nema rezultata",
  MESSAGES_NEW_PASSWORD_FIELD: "Polje 'Nova lozinka' je obavezno!",
  MESSAGES_NEW_PASSWORD_CONFIRM_FIELD: "Polje 'Potvrda nove lozinke' je obavezno!",
  MESSAGES_CODE_REQUIRED: "Šifra je obavezan podatak!",
  MESSAGES_NAME_REQUIRED: "Naziv je obavezan podatak!",
  MESSAGES_INSTITUTION_TYPE_REQUIRED: "Tip ustanove je obavezan podatak!",
  MESSAGES_MUNICIPALITY_REQUIRED: "Opština je obavezan podatak!",
  MESSAGES_DISTRICT_REQUIRED: "Okrug je obavezan podatak!",
  MESSAGES_JURISDICTION_REQUIRED: "Jurisdikcija je obavezan podatak!",
  MESSAGES_DELETE_DOCUMENT_SUCCESS: "Uspešno ste obrisali dokument.",
  MESSAGES_APPROVE_DOCUMENT_SUCCESS: "Prijava je odobrena i prosleđena Batutu.",
  MESSAGES_FOR_REVISION_DOCUMENT_SUCCESS: "Prijava je poslata lekaru na reviziju.",
  MESSAGES_INVALID_FILE_SIZE: "Maksimlana veličina datoteke je 5 MB.",
  MESSAGE_SEND_BACK_TO_REVISION: "Da li ste sigurni da želite da vratite formular na reviziju?",
  MESSAGE_APPROVE_FORM: "Da li ste sigurni da želite da odobrite formular?",
  MESSAGE_RESULT_LIST_IS_EMPTY: "Nema pronađenih rezultata.",
  MESSAGE_CREATE_SEMANTIC_VALIDATION_SUCCESS: "Semantička validacija je uspešno kreirana.",
  MESSAGE_UPDATE_SEMANTIC_VALIDATION_SUCCESS: "Semantička validacija je uspešno izmenjena.",
  MESSAGE_DELETE_SEMANTIC_VALIDATION_SUCCESS: "Semantička validacija je uspešno obrisana.",
  MESSAGE_CREATE_REPORT_PARAMETER_SUCCESS: "Parametar izveštaja je uspešno kreiran.",
  MESSAGE_UPDATE_REPORT_PARAMETER_SUCCESS: "Parametar izveštaja je uspešno izmenjen.",
  MESSAGE_DELETE_REPORT_PARAMETER_SUCCESS: "Parametar izveštaja je uspešno obrisan.",
  MESSAGES_MERGE_DIFFERENT_FORMS: "Nije moguće spajati prijave različitog tipa.",
  MESSAGES_MERGE_SUCCESS: "Uspešno ste spojili prijave.",
  MESSAGES_ARE_YOU_SURE_YOU_WANT_TO_MERGE: "Da li ste sigurni da želite da spojite prijave?",
  MESSAGES_DELETE_REPORT_GENERATED: "Da li ste sigurni da želite da obrišete generisani izveštaj?",
  MESSAGES_REPORT_GENERATE_GENERATE_SUCCESS: "Izveštaj se generiše.",
  MESSAGES_REPORT_GENERATE_DELETE_SUCCESS: "Uspešno ste obrisali generisani izveštaj.",
  MESSAGE_CREATE_REPORT_ACCESS_SUCCESS: "Pristup izveštaju je uspešno kreiran.",
  MESSAGE_DELETE_REPORT_ACCESS_SUCCESS: "Pristup izveštaju je uspešno obrisan.",
  MESSAGE_CREATE_LABEL_SUCCESS: "Uspešno kreirana labela.",
  MESSAGE_UPDATE_LABEL_SUCCESS: "Uspešno izmenjena labela",
  MESSAGES_REPORT_GENERATED_SUCCESS: "Izveštaj je uspešno generisan.",

  //UserProfilePage
  USER_PROFILE_CHANGE: "Izmena korisničkog profila",
  USER_PROFILE_CHANGE_EMAIL: "Email",
  USER_PROFILE_CHANGE_NAME: "Ime",
  USER_PROFILE_CHANGE_SURNAME: "Prezime",
  USER_PROFILE_CHANGE_LANGUANGE: "Pismo",
  USER_PROFILE_BUTTON_SAVE: "Sačuvaj",
  USER_PROFILE_BUTTON_CHANGE_PASSWORD: "Promena lozinke",
  USER_PROFILE_CHANGE_IMAGE: "Promeni sliku",
  USER_PROFILE_BUTTON_CANCEL: "Poništi",
  USER_PROFILE_CHANGE_PASSWORD: "Promena lozinke",
  USER_PROFILE_PASSWORD_CHANGE_ERROR: "Greška prilikom promene lozinke.",
  USER_PROFILE_MESSAGES_NEW_PASSWORD: "Morate uneti novu lozinku.",
  USER_PROFILE_MESSAGES_OLD_PASSWORD: "Morate uneti staru lozinku.",
  USER_PROFILE_MESSAGES_PASSWORD_CONFIRMATION: "Morate uneti potvrdu nove lozinke.",
  USER_PROFILE_MESSAGES_PASSWORDS_NOT_SAME: "Nova lozinka i potvrda lozinke nisu iste.",
  USER_PROFILE_BUTTON_SAVE_NEW_PASSWORD: "Sačuvaj novu lozinku",
  USER_PROFILE_OLD_PASSWORD: "Stara lozinka",
  USER_PROFILE_NEW_PASSWORD: "Nova lozinka",
  USER_PROFILE_CONFIRM_NEW_PASSWORD: "Potvrda nove lozinke",
  USER_PROFILE_EDIT_SUCCESS: "Uspešno sačuvane izmene.",
  USER_PROFILE_CHANGE_PASSWORD_SUCCESS: "Uspešno promenjena lozinka.",
  PATIENT_FIRST_NAME: "Ime pacijenta",
  PATIENT_LAST_NAME: "Prezime pacijenta",
  INSERT_DATE: "Datum unosa",

  PAGINATION_SHOWING_TO_OF_ENTRIES: "Prikazano {first} do {last} od {totalRecords} rezultata",

  //newForm
  NEW_FORM_HEALTH_INSTITUTION: "Zdravstvena ustanova",
  NEW_FORM_TITLE_PANEL_CREATE_FORM: "Popunjavanje formulara: ",
  NEW_FORM_SAVE: "Sačuvaj",
  NEW_FORM_SAVE_AS_DRAFT: "Sačuvaj nacrt",
  NEW_FORM_CANCEL: "Poništi",
  FORM_SUCCESS_SAVE: "Prijava je prosleđena regionalnom zavodu.",
  FORM_FILLED_SUCCESS_DELETED: "Popunjen formular je uspešno obrisan.",
  FORM_FAILED_SAVE: "Formular nije sačuvan. Ispravite greške na formularu!",
  FORM: "Formular",
  FORM_FIELDS: "Polja formulara",
  SEMANTIC_VALIDATION: "Semantička validacija",
  USER_RIGHT: "Prava pristupa",
  FIELD_DROPDOWN_CHOOSE: "Izaberite polje...",
  OPERATOR_FIELD_DROPDOWN_CHOOSE: "Izaberite operator...",
  INSTITUTION_DROPDOWN_CHOOSE: "Izaberite ustanovu...",
  CODEBOOK_OPTION_DROPDOWN_CHOOSE: "Izaberite stavku šifarnika...",
  FILE_FORMAT_DROPDOWN_CHOOSE: "Izaberite format fajla...",
  CATEGORY_DROPDOWN_CHOOSE: "Izaberite kategoriju...",
  FORM_STATUS: "Status",
  CREATE_REPORT_OF_DEATH: "Kreiranje prijave o smrti",

  BADGE_TOOLTIP: "Pristigle prijave",

  // operator
  CONTAIS: "sadrži",
  NOT_CONTAIS: "ne sadrži",
  START_WITH: "počinje sa",
  END_WITH: "završava se",
  CHOOSE: "izabrano",
  NOT_CHOOSE: "nije izabrano",

  //errorPage
  ERROR_PAGE_404: "404",
  ERROR_PAGE_PAGE_NOT_FOUND: "Stranica nije pronađena.",
  ERROR_PAGE_HOME_BUTTON: "Nazad na početnu stranu",

  //userGroup
  USER_GROUP: "Korisnička grupa",
  USER_GROUP_ROLES: "Uloge korisničke grupe",
  USER_GROUP_LIST: "Korisničke grupe",
  USER_GROUP_LIST_CODE: "Šifra",
  USER_GROUP_LIST_NAME: "Naziv",
  USER_GROUP_LIST_DESCRIPTION: "Opis",
  USER_GROUP_LIST_NOTE: "Napomena",
  USER_GROUP_LIST_CREATE: "Kreiraj",
  USER_GROUP_LIST_EDIT: "Izmeni",
  USER_GROUP_LIST_DETAILS: "Detalji",
  USER_GROUP_LIST_DELETE: "Obriši",
  USER_GROUP_TITLE_DIALOG_CREATE: "Kreiranje korisničke grupe",
  USER_GROUP_TITLE_DIALOG_UPDATE: "Izmena korisničke grupe ",
  USER_GROUP_TITLE_DIALOG_DELETE: "Brisanje korisničke grupe ",
  USER_GROUP_TITLE_DIALOG_DETAILS: "Detalji korisničke grupe ",
  USER_GROUP_TITLE_MESSAGE_CREATE_USER_GROUP_SUCCESS: "Korisnička grupa je uspešno kreirana.",
  USER_GROUP_TITLE_MESSAGE_UPDATE_USER_GROUP_SUCCESS: "Korisnička grupa je uspešno izmenjena.",
  USER_GROUP_TITLE_MESSAGE_DELETE_USER_GROUP_SUCCESS: "Korisnička grupa je uspešno obrisana.",
  USER_GROUP_TITLE_MESSAGE_DELETE_USER_GROUP_USER_SUCCESS: "Korisnik je uspešno obrisan iz korisničke grupe.",
  USER_GROUP_TITLE_MESSAGE_CREATE_USER_GROUP_USER_SUCCESS: "Korisnik je uspešno dodat korisničkoj grupi.",

  //form
  FORM_LIST: "Formulari",
  FORM_TITLE_DIALOG_CREATE: "Kreiranje formulara",
  FORM_TITLE_DIALOG_UPDATE: "Izmena formulara: ",
  FORM_TITLE_DIALOG_DELETE: "Brisanje formulara: ",
  FORM_TITLE_DIALOG_DETAILS: "Detalji formulara: ",
  TITLE_MESSAGE_CREATE_FORM_SUCCESS: "Formular je uspešno kreiran.",
  TITLE_MESSAGE_UPDATE_FORM_SUCCESS: "Formular je uspešno izmenjen.",
  TITLE_MESSAGE_DELETE_FORM_SUCCESS: "Formular je uspešno obrisan.",

  //jurisdictions
  JURISDICTIONS: "Jurisdikcije",
  USER_GROUP_JURISDICTION: "Jurisdikcije korisničke grupe",
  JURISDICTION: "Jurisdikcija",
  JURISDICTION_CODE: "Šifra",
  JURISDICTION_NAME: "Naziv",
  JURISDICTION_DESCRIPTION: "Opis",
  JURISDICTION_NOTE: "Napomena",
  JURISDICTION_LIST_CREATE: "Kreiraj",
  JURISDICTION_LIST_EDIT: "Izmeni",
  JURISDICTION_LIST_DETAILS: "Detalji",
  JURISDICTION_LIST_DELETE: "Obriši",
  JURISDICTION_TITLE_DIALOG_CREATE: "Kreiranje jurisdikcije ",
  JURISDICTION_TITLE_DIALOG_UPDATE: "Izmena jurisdikcije ",
  JURISDICTION_TITLE_DIALOG_DELETE: "Brisanje jurisdikcije ",
  JURISDICTION_TITLE_DIALOG_DETAILS: "Detalji jurisdikcije ",
  JURISDICTION_DISPLAY_ORDER: "Prikazni redosled",
  JURISDICTION_TITLE_MESSAGE_CREATE_USER_GROUP_SUCCESS: "Jurisdikcija je uspešno kreirana.",
  JURISDICTION_TITLE_MESSAGE_UPDATE_USER_GROUP_SUCCESS: "Jurisdikcija je uspešno izmenjena.",
  JURISDICTION_TITLE_MESSAGE_DELETE_USER_GROUP_SUCCESS: "Jurisdikacija je uspešno obrisana.",
  JURISDICTION_CODE_IS_REQUIRED: "Obavezno je uneti šifru jurisdikcije.",
  JURISDICTION_NAME_IS_REQUIRED: "Obavezno je uneti naziv jurisdikcije.",

  //users
  USER_LIST: "Korisnici",
  USER_FIRST_NAME: "Ime",
  USER_CODE: "Šifra",
  USER_LAST_NAME: "Prezime",
  USER_USER_NAME: "Korisničko ime",
  USER_EMAIL: "Email",
  USER_INSTITUTION: "Ustanova",
  USER_PASSWORD: "Lozinka",
  USER_PASSWORD_CONFIRM: "Potvrda lozinke",
  USER_SEARCH: "Pretraga",
  USER_LIST_CREATE: "Kreiraj",
  USER_LIST_EDIT: "Izmeni",
  USER_LIST_DETAILS: "Detalji",
  USER_LIST_DELETE: "Obriši",
  USER_TITLE_DIALOG_CREATE: "Kreiranje korisnika ",
  USER_TITLE_DIALOG_UPDATE: "Izmena korisnika ",
  USER_TITLE_DIALOG_DELETE: "Brisanje korisnika ",
  USER_TITLE_DIALOG_DETAILS: "Detalji o korisniku ",
  USER_PICK_INSTITUTION: "Odaberite ustanovu",
  USER_TITLE_MESSAGE_DELETE_USER_SUCCESS: "Korisnik je uspešno obrisan.",
  USER_TITLE_MESSAGE_CREATE_USER_SUCCESS: "Korisnik je uspešno kreiran.",
  USER_TITLE_MESSAGE_UPDATE_USER_SUCCESS: "Korisnik je uspešno izmenjen.",
  USER_USERNAME_IS_REQUIRED: "Obavezno je uneti korisničko ime.",
  USER_EMAIL_IS_REQUIRED: "Obavezno je uneti email.",
  USER_PASSWORD_DEFAULT: "UProcesuKreiranja",
  SAVE_USER_DATA: "Sačuvaj podatke o korisniku",
  CANCEL_USER_DATA: "Poništi promene",

  //institutions
  INSTITUTION_LIST: "Ustanove",
  INSTITUTION_TITLE_DIALOG_CREATE: "Kreiranje ustanove ",
  INSTITUTION_TITLE_DIALOG_UPDATE: "Izmena ustanove ",
  INSTITUTION_TITLE_DIALOG_DETAILS: "Detalji o ustanovi ",
  INSTITUTION_TITLE_DIALOG_DELETE: "Brisanje ustanove ",
  INSTITUTION_TITLE_MESSAGE_DELETE_INSTITUTION_SUCCESS: "Ustanova je uspešno obrisana.",
  INSTITUTION_TITLE_MESSAGE_CREATE_INSTITUTION_SUCCESS: "Ustanova je uspešno kreirana.",
  INSTITUTION_TITLE_MESSAGE_UPDATE_INSTITUTION_SUCCESS: "Ustanova je uspešno izmenjena.",
  INSTITUTION_NAME_IS_REQUIRED: "Obavezno je uneti naziv ustanove.",
  INSTITUTION_CODE_IS_REQUIRED: "Obavezno je uneti šifru ustanove.",
  INSTITUTION_LIST_CREATE: "Kreiraj",
  INSTITUTION_LIST_EDIT: "Izmeni",
  INSTITUTION_LIST_DETAILS: "Detalji",
  INSTITUTION_LIST_DELETE: "Obriši",
  INSTITUTION_SEARCH: "Pretraga",
  INSTITUTION_TYPE: "Tip ustanove",
  INSTITUTION_WS_USERNAME: "WS username",
  INSTITUTION_WS_PASSWORD: "WS password",
  INSTITUTION_GPS_LOCATION: "GPS lokacija",
  //FIELD
  FIELD_TITLE_DIALOG_CREATE: "Kreiranje polja formulara ",
  FIELD_TITLE_DIALOG_UPDATE: "Izmena polja formulara ",
  FIELD_TITLE_DIALOG_DELETE: "Brisanje polja formulara ",
  FIELD_TITLE_DIALOG_DETAILS: "Detalji o polju formulara ",
  IS_REQUIRED_FIELD: "Obavezno polje",
  IS_RELEVANT_FIELD: "Relevantno polje",
  IS_MULTISELECT_FIELD: "Više-opciono polje",
  IS_DISABLED_FIELD: "Onesposobljeno polje",
  MULTISELECT: "Više-opcioni",
  EQUATION_SHOW_LABEL_ON_FORM: "Formula za prikaz labele na formularu",
  EQUATION_SHOW_FIELD_ON_FORM: "Formula za prikaz polja na formularu",
  EQUATION_CALCULATED_VALUE: "Formula za računanje vrednosti polja",
  HTML_INFO: "HTML informacije",
  TITLE_MESSAGE_DELETE_FIELD_SUCCESS: "Uspešno izbrisano polje",
  TITLE_MESSAGE_UPDATE_FIELD_SUCCESS: "Uspešno izmenjeno polje",
  TITLE_MESSAGE_CREATE_FIELD_SUCCESS: "Uspešno kreirano polje",
  FIELD_CODE_IS_REQUIRED: "Obavezno je uneti šifru polja.",
  FIELD_NAME_IS_REQUIRED: "Obavezno je uneti naziv polja.",
  INITIAL_VALUE: "Podrazumevana vrednost",

  //formFilled
  FORM_FILLED_EDIT_DRAFT: "Izmena nacrta formulara",
  FORM_FILLED_SAVE: "Sačuvaj",
  FORM_FILLED_SAVE_AS_DRAFT: "Sačuvaj nacrt",
  SAVE_FORM: "Sačuvaj formular",
  SAVE_FORM_AS_DRAFT: "Sačuvaj nacrt formulara",
  APPROVE_FORM: "Odobri formular",
  REVISION_FORM: "Vrati na reviziju formular",
  CANCEL_FORM: "Odustani od čuvanja formulara",
  FORM_FILLED_CANCEL: "Odustani",
  ADD_SEARCH_PARAMETER: "Dodaj parametar pretrage",

  ROLES: "Uloge",
  FIELD_IS_REQUIRED: "Polje je obavezno",
  VALUE_SHOULD_BE_BETWEEN: "Vrednost treba da bude između ",
  VALUE_SHOULD_BE_GREATER_THEN: "Vrednost treba da bude veca ",
  VALUE_SHOULD_BE_LESS_THEN: "Vrednost treba da bude manja ",
  IT_SHOULD_BE_MORE_THEN: "Treba da bude više od",
  FIELD_SHOULD_HAVE: "Polje treba da ima",
  IT_SHOULD_BE_LESS_THEN: "Treba da bude manje od",
  IT_SHOULD_BE_BETWEEN: "Treba da bude između ",
  CHARACTERS: "karaktera",

  //fieldTypes
  FIELD_TYPES: "Tipovi polja",
  FIELD_TYPE_MIN: "Minimalna vrednost",
  FIELD_TYPE_MAX: "Maksimalna vrednost",
  FIELD_TYPE_FORMAT: "Format",
  FIELD_TYPE_CREATE: "Kreiranje tipa polja",
  TITLE_MESSAGE_CREATE_FIELD_TYPE_SUCCESS: "Tip polja je uspešno kreiran.",
  TITLE_MESSAGE_UPDATE_FIELD_TYPE_SUCCESS: "Tip polja je uspešno izmenjen.",
  TITLE_MESSAGE_DELETE_FIELD_TYPE_SUCCESS: "Tip polja je uspešno obrisan.",
  FIELD_TYPE: "Tip polja",
  FIELD_TYPE_DECIMALS: "Broj decimala",
  FIELD_TYPE_ENUM: "Skupovne stavke",
  FIELD_TYPE_COMPONENT_TYPE: "Tip komponente",
  FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_CREATE: "Kreiranje skupovne stavke ",
  FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_UPDATE: "Izmena skupovne stavke ",
  FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_DELETE: "Brisanje skupovne stavke ",
  FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_DETAILS: "Detalji skupovne stavke ",
  FIELD_TYPE_ENUMERATOR_ITEM_CODE_IS_REQUIRED: "Obavezno je uneti šifru skupovne stavke.",
  FIELD_TYPE_ENUMERATOR_ITEM_NAME_IS_REQUIRED: "Obavezno je uneti naziv skupovne stavke.",
  FIELD_TYPE_ENUMERATOR_ITEM_TITLE_MESSAGE_CREATE_USER_GROUP_SUCCESS: "Skupovna stavka je uspešno kreirana.",
  FIELD_TYPE_ENUMERATOR_ITEM_TITLE_MESSAGE_UPDATE_USER_GROUP_SUCCESS: "Skupovna stavka je uspešno izmenjena.",
  FIELD_TYPE_ENUMERATOR_ITEM_TITLE_MESSAGE_DELETE_USER_GROUP_SUCCESS: "Skupovna stavka je uspešno obrisana.",

  //Institutions
  INSTITUTION: "Ustanova",
  INSTITUTION_TITLE_MESSAGE_DELETE_USER_INSTITUTION_SUCCESS: "Korisnik je uspešno obrisan.",
  INSTITUTION_TITLE_MESSAGE_CREATE_USER_INSTITUTION_SUCCESS: "Korisnik je uspešno dodat ustanovi.",

  //codebook
  CODEBOOK_TITLE_CREATE: "Kreiranje šifarnika ",
  CODEBOOK_TITLE_UPDATE: "Izmena šifarnika ",
  CODEBOOK_TITLE_DETAILS: "Detalji o šifarniku ",
  LOGICAL_CONTROL_TITLE: "Logička kontrola šifarnika",
  CODEBOOK_TITLE_DELETE: "Brisanje šifarnika ",
  CODEBOOK_TYPE: "Tip šifarnika",
  CODEBOOK_OPTION: "Stavka šifarnika",
  CODEBOOK_AUTOCOMPLETE: "Šifarnički sa pretragom",
  CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_CREATE: "Kreiranje logičke kontrole",
  CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_UPDATE: "Izmena logičke kontrole",
  CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DETAILS: "Detalji logičke kontrole",
  CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DELETE: "Brisanje logičke kontrole",
  CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DELETE_SUCCESS: "Logička kontrola je uspešno obrisana.",
  CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_CREATE_SUCCESS: "Logička kontrola je uspešno kreirana.",
  CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_UPDATE_SUCCESS: "Logička kontrola je uspešno izmenjena.",

  LABEL_LOG_LIST: "Log",
  INSERT_TIME_FROM: "Vreme unosa od",
  INSERT_TIME_TO: "Vreme unosa do",
  INSERT_TIME: "Vreme unosa",
  OLD_VALUE: "Stara vrednost",
  NEW_VALUE: "Nova vrednost",
  IP_ADDRESS: "IP adresa",
  ACTION_TYPE: "Tip akcije",
  CLASS: "Klasa",
  OBJECT: "Objekat",
  TOOLTIP_MERGE_CCF: "Spajanje dve prijave",

  //operator
  GREATER: "veće",
  LESS: "manje",
  EQUAL: "jednako",
  NOT_EQUAL: "nije jednako",
  RANGE: "opseg",
  CODEBOOKS: "Šifarnici",
  CODEBOOK_TYPES: "Tipovi šifarnika",
  LOGICAL_CONTROLS: "Logičke kontrole",
  FORM_SEMANTIC_VALIDATION_LIST: "Semantičke validacije",

  // form user right
  USER_RIGHT_CREATE: "Kreiranje",
  USER_RIGHT_READ: "Čitanje",
  USER_RIGHT_UPDATE: "Izmena",
  USER_RIGHT_DELETE: "Brisanje",
  USER_RIGHT_VISIBILITY: "Vidljivost",
  USER_RIGHT_OVERVIEW: "Pregled",

  //report
  REPORT: "Izveštaj",
  REPORT_LIST: "Izveštaji",
  REPORT_TITLE_DIALOG_CREATE: "Kreiranje izveštaja",
  REPORT_TITLE_DIALOG_UPDATE: "Izmena izveštaja: ",
  REPORT_TITLE_DIALOG_DELETE: "Brisanje izveštaja: ",
  REPORT_TITLE_DIALOG_DETAILS: "Detalji izveštaja: ",
  TITLE_MESSAGE_CREATE_REPORT_SUCCESS: "Izveštaj je uspešno kreiran.",
  TITLE_MESSAGE_UPDATE_REPORT_SUCCESS: "Izveštaj je uspešno izmenjen.",
  TITLE_MESSAGE_DELETE_REPORT_SUCCESS: "Izveštaj je uspešno obrisan.",
  REPORT_PARAMETER: "Parametri izveštaja",
  REPORT_ACCESS: "Pristup izveštaju",
  REPORT_PARAMETER_TYPE: "Tip parametra",

  DISPLAY_ORDER_REQUIRED: "Prikazni redosled je obavezno polje.",
  CUSTOM_QUERY_EXAMPLE: "Primer SQL upita: select u.id as id, u.email as name form User u",
  LABEL_TEST_QUERY: "Test upita",
  LABEL_ADD_QUERY: "Unesite upit u odgovarajuće polje",
  LABEL_SUBMITTED: "Podnet",
  LABEL_APPROVED: "Odobren",
  LABEL_FOR_REVISION: "Za reviziju",
  LABEL_UNFINISHED: "Nezavršen",
  TOOLTIP_RETURN: "Povratak na prethodnu stranu",
  TOOLTIP_ADD_COMMENT: "Dodajte komentar ili odobrite formular",
  HEADER_FORM_REVISION: "Revizija formulara",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_USERGROUP: "Da li ste sigurni da želite da obrišete korisničku grupu?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_JURISDICTION: "Da li ste sigurni da želite da obrišete jurisdikciju?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_USER: "Da li ste sigurni da želite da obrišete korisnika?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_INSTITUTION: "Da li ste sigurni da želite da obrišete ustanovu?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FORM: "Da li ste sigurni da želite da obrišete formular?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_SEMANTIC_VALIDATION: "Da li ste sigurni da želite da obrišete semantičku validaciju?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FORM_FIELD: "Da li ste sigurni da želite da obrišete polje formulara?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_TYPE: "Da li ste sigurni da želite da obrišete tip polja?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_TYPE_ENUMERATOR_ITEM: "Da li ste sigurni da želite da obrišete skupovnu stavku?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_TYPE_LOGICAL_CONTROL: "Da li ste sigurni da želite da obrišete logičko pravilo?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPORT: "Da li ste sigurni da želite da obrišete izveštaj?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPORT_PARAMETER: "Da li ste sigurni da želite da obrišete parametar izveštaja?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPORT_ACCESS: "Da li ste sigurni da želite da obrišete pristup izveštaju?",
  LABEL_IMPORT_CODEBOOK: "Ažuriraj",
  TOOLTIP_IMPORT_CODEBOOK: "Ažuriranje šifarnika",
  LABEL_M1_PATH: "m1",
  REPORT_GENERATE_TIME: "Vreme generisanja",
  CANCER_REGISTRY: "Registar malignih tumora",
  INSTITUTE_OF_PUBLIC_HEALTH: "Institut za javno zdravlje Srbije",
  BATUT_LABEL: "Dr Milan Jovanović Batut",
  DELIVERED_BY: "delivered by",
  HELIANT_LABEL: "HELIANT"
};

export default Labels;
