import MergeFormsLogical, { MergeFormsLogicalType } from "./MergeFormsLogical";
import { BreadCrumb } from "primereact/breadcrumb";
import FieldReadDto from "../../../model/FieldReadDto";
import EnumComponentType from "../../../infrastructure/system/EnumComponentType";
import Title from "../title/Title";
import Field from "../field/Field";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import { hrefForDownloadAttachment } from "../../../infrastructure/system/Utils";

export default function MergeFormsView() {
  const { breadCrumbItems, fieldListFirst, fieldValuesListFirst, fieldListSecond, fieldValuesListSecond, mergeCCFs, selection, fileListFirst, fileListSecond }: MergeFormsLogicalType =
    MergeFormsLogical();
  const { Labels } = LabelsContext();

  const leftContentsFirst = () => (
    <Button
      icon="pi pi-check"
      className="p-button-success"
      label={Labels.LABEL_APPROVE}
      tooltip={Labels.LABEL_APPROVE_FIRST_CCF}
      tooltipOptions={{ position: "bottom" }}
      onClick={(e) => mergeCCFs(selection[1].id)}
    />
  );

  const leftContentsSecond = () => (
    <Button
      icon="pi pi-check"
      className="p-button-success"
      label={Labels.LABEL_APPROVE}
      tooltip={Labels.LABEL_APPROVE_SECOND_CCF}
      tooltipOptions={{ position: "bottom" }}
      onClick={(e) => mergeCCFs(selection[0].id)}
    />
  );

  return (
    <div className="layout-form">
      <div className="p-col-12">
        <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
        <div className="p-grid">
          <div className="p-col-6">
            <Toolbar left={leftContentsFirst} />
            <Panel>
              {fieldListFirst &&
                fieldListFirst.map((field: FieldReadDto) => {
                  return (
                    <div key={field.code} className="p-grid p-fluid p-align-center">
                      {field.fieldType?.componentEnumType !== EnumComponentType.LABEL && <Title name={field.name} isRequired={field.isRequired} />}
                      <div className="p-col-8">
                        <Field isDisabled field={field} value={fieldValuesListFirst[field.id]} isSearch={false} />
                      </div>
                    </div>
                  );
                })}
              <Panel header={Labels.LABEL_UPLOADED}>
                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    {fileListFirst.length === 0 && <div>{Labels.LABEL_NO_UPLOADED_ATTACHMENTS}</div>}
                    {fileListFirst.map((file: any, index: number) => {
                      return (
                        <>
                          <a key={index} href={hrefForDownloadAttachment(file.type, file.attachment)} download={file.name} className="a-word-wrap">
                            {file.name}
                          </a>
                        </>
                      );
                    })}
                  </div>
                </div>
              </Panel>
            </Panel>
          </div>
          <div className="p-col-6">
            <Toolbar left={leftContentsSecond} />
            <Panel>
              {fieldListSecond &&
                fieldListSecond.map((field: FieldReadDto) => {
                  return (
                    <div key={field.code} className="p-grid p-fluid p-align-center">
                      {field.fieldType?.componentEnumType !== EnumComponentType.LABEL && <Title name={field.name} isRequired={field.isRequired} />}
                      <div className="p-col-8">
                        <Field isDisabled field={field} value={fieldValuesListSecond[field.id]} isSearch={false} />
                      </div>
                    </div>
                  );
                })}
              <Panel header={Labels.LABEL_UPLOADED}>
                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    {fileListSecond.length === 0 && <div>{Labels.LABEL_NO_UPLOADED_ATTACHMENTS}</div>}
                    {fileListSecond.map((file: any, index: number) => {
                      return (
                        <>
                          <a key={index} href={hrefForDownloadAttachment(file.type, file.attachment)} download={file.name} className="a-word-wrap">
                            {file.name}
                          </a>
                        </>
                      );
                    })}
                  </div>
                </div>
              </Panel>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  );
}
