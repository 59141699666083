import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import ExportData from "../../app/export/ExportData";
import CrudUser from "./CrudUserView";
import UserListLogical, { UserListLogicalType } from "./UserListLogical";

export default function UserListPage() {
  const {
    userList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    selectedUser,
    setSelectedUser,
    changeSearchData,
    searchData,
    searchUsers,
    searchUsersByEnter,
    dialogHeader,
    openDialog,
    displayDialog,
    entityOperation,
    closeDialog,
    dialogRef,
    userToChange,
    index,
    setIndex,
    deleteUser,
    createUser,
    updateUser,
    institutionList,
    userInstitutionList,
    onChangeInstitution,
    onPageUser,
    breadCrumbItems,
    exportData,
    userGroupUserList,
    userGroupList,
    onChangeUserGroup,
  }: UserListLogicalType = UserListLogical();

  const { Labels } = LabelsContext();
  const dt = useRef<any>(null);
  const leftContents = () => (
    <>
      <Button
        icon="pi pi-plus"
        className="p-button-success btn-margin-right"
        label={Labels.USER_LIST_CREATE}
        tooltip={Labels.USER_TITLE_DIALOG_CREATE}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          openDialog(EntityOperation.CREATE);
        }}
      />

      <Button
        icon="pi pi-pencil"
        className="p-button-warning btn-margin-right"
        disabled={!selectedUser}
        label={Labels.USER_LIST_EDIT}
        tooltip={Labels.USER_TITLE_DIALOG_UPDATE}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          selectedUser && openDialog(EntityOperation.UPDATE, selectedUser);
        }}
      />
      <Button
        icon="pi pi-info-circle"
        label={Labels.USER_LIST_DETAILS}
        tooltip={Labels.USER_TITLE_DIALOG_DETAILS}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedUser}
        onClick={() => {
          selectedUser && openDialog(EntityOperation.READ, selectedUser);
        }}
      />
    </>
  );

  const rightContents = () => (
    <>
      <ExportData data={exportData} name={Labels.USER_LIST} dt={dt} />
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        label={Labels.USER_LIST_DELETE}
        tooltip={Labels.USER_TITLE_DIALOG_DELETE}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedUser}
        onClick={() => {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_USER,
            icon: "pi pi-power-off",
            accept: () => {
              deleteUser(selectedUser.id);
            },
          });
        }}
      />
    </>
  );

  const leftContentsDialog = () => (
    <>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.USER_TITLE_DIALOG_CREATE}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && index === 0 && (
        <Button
          label={Labels.BUTTON_UPDATE}
          tooltip={Labels.USER_TITLE_DIALOG_UPDATE}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            dialogRef.current.onUpdate();
          }}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          icon="pi pi-trash"
          className="p-button-danger"
          tooltip={Labels.USER_TITLE_DIALOG_DELETE}
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </>
  );

  const rightContentsDialog = () => (
    <>
      <Button label={index === 0 ? Labels.BUTTON_CANCEL : Labels.BUTTON_CLOSE} icon="pi pi-times" onClick={closeDialog} />
    </>
  );

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  return (
    <div className="layout-jurisdiction">
      <Panel>
        <div className="p-grid">
          <div className="p-col-3">
            <Card className="p-col-12 ">
              <span className="p-float-label">
                <InputText className="p-col-12" id="firstname" name="firstName" value={searchData.firstName} onChange={changeSearchData} onKeyPress={searchUsersByEnter} />
                <label htmlFor="firstname">{Labels.USER_FIRST_NAME}</label>
              </span>
              <span className="p-float-label">
                <InputText className="p-col-12" id="lastname" name="lastName" value={searchData.lastName} onChange={changeSearchData} onKeyPress={searchUsersByEnter} />
                <label htmlFor="lastname">{Labels.USER_LAST_NAME}</label>
              </span>
              <span className="p-float-label">
                <InputText className="p-col-12" id="username" name="username" value={searchData.username} onChange={changeSearchData} onKeyPress={searchUsersByEnter} />
                <label htmlFor="username">{Labels.USER_USER_NAME}</label>
              </span>
              <span className="p-float-label">
                <InputText className="p-col-12" id="email" name="email" value={searchData.email} onChange={changeSearchData} onKeyPress={searchUsersByEnter} />
                <label htmlFor="email">{Labels.USER_EMAIL}</label>
              </span>
              <Button label={Labels.USER_SEARCH} tooltip={Labels.SEARCH_USER} icon="pi pi-search" className="search-button" onClick={searchUsers} />
            </Card>
          </div>
          <div className="p-col-9">
            <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
            <Toolbar left={leftContents} right={rightContents} />
            <div>
              <DataTable
                ref={dt}
                value={userList}
                paginator
                first={first}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rows={tableRows}
                rowsPerPageOptions={[5, 10, 20]}
                onPage={(e) => {
                  onPageUser(e.rows, e.first);
                }}
                selectionMode="single"
                selection={selectedRow}
                onSelectionChange={(e) => setSelectedRow(e.value)}
                onRowSelect={(e) => setSelectedUser(e.data)}
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                exportFilename={Labels.USER_LIST}
              >
                <Column field={"firstname"} header={Labels.USER_FIRST_NAME} filter filterMatchMode="contains" sortable />
                <Column field={"lastname"} header={Labels.USER_LAST_NAME} filter filterMatchMode="contains" sortable />
                <Column field={"username"} header={Labels.USER_USER_NAME} filter filterMatchMode="contains" sortable />
                <Column field={"email"} header={Labels.USER_EMAIL} filter filterMatchMode="contains" className="wrap-line-anywhere" sortable />
              </DataTable>
            </div>
            <Dialog header={dialogHeader(entityOperation)} visible={displayDialog} onHide={closeDialog} style={{ width: "1000px" }} footer={dialogFooter()}>
              <CrudUser
                userOperation={entityOperation}
                user={userToChange}
                dialogRef={dialogRef}
                index={index}
                setIndex={setIndex}
                onDeleteUser={deleteUser}
                onCreateUser={createUser}
                onUpdateUser={updateUser}
                userInstitutionList={userInstitutionList}
                institutionList={institutionList}
                onChangeInstitution={onChangeInstitution}
                userGroupUserList={userGroupUserList}
                userGroupList={userGroupList}
                onChangeUserGroup={onChangeUserGroup}
              />
            </Dialog>
          </div>
        </div>
      </Panel>
    </div>
  );
}
