import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface ReportControllerType {
  axiosGetReportList: any;
  axiosCreateReport: any;
  axiosUpdateReport: any;
  axiosDeleteReport: any;
  axiosGetReport: any;
  axiosGetReportParameterListByReportId: any;
  axiosCreateReportParameter: any;
  axiosUpdateReportParameter: any;
  axiosDeleteReportParameter: any;
  axiosGetAccessListByReport: any;
  axiosDeleteAccessReport: any;
  axiosCreateAccessReport: any;
  axiosGetReportCategoryList: any;
  axiosGenerateReport: any;
  axiosGetReportParameterCustomQueryResultList: any;
  axiosGetReportGeneratedListByReportId: any;
  axiosDeleteGenerateReport: any;
  axiosGetPublicReportGenerateList: any;
  axiosGetReportListByUser: any;
  axiosGetGeneratedReport: any;
  axiosUpdateIsPublicReport: any;
  axiosGetCustomQueryResult: any;
}

export function ReportController(): ReportControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetReportList = () => {
    return axios.get(`${Endpoint.REPORT}/reportList`, axiosConfig(authData!.token));
  };

  const axiosCreateReport = (report: any) => {
    return axios.post(`${Endpoint.REPORT}/reportList`, report, axiosConfig(authData!.token));
  };
  const axiosUpdateReport = (reportID: any, report: any) => {
    return axios.put(`${Endpoint.REPORT}/reportList/${reportID}`, report, axiosConfig(authData!.token));
  };
  const axiosDeleteReport = (reportID: any) => {
    return axios.delete(`${Endpoint.REPORT}/reportList/${reportID}`, axiosConfig(authData!.token));
  };
  const axiosGetReport = (reportID: any) => {
    return axios.get(`${Endpoint.REPORT}/reportList/${reportID}`, axiosConfig(authData!.token));
  };
  const axiosGetAccessListByReport = (reportId: any) => {
    return axios.get(`${Endpoint.REPORT}/reportList/${reportId}/reportAccessList`, axiosConfig(authData!.token));
  };

  const axiosDeleteAccessReport = (accessReportId: any) => {
    return axios.delete(`${Endpoint.REPORT}/reportAccess/reportAccessList/${accessReportId}`, axiosConfig(authData!.token));
  };
  const axiosCreateAccessReport = (accessReport: any) => {
    return axios.post(`${Endpoint.REPORT}/reportAccess/reportAccessList`, accessReport, axiosConfig(authData!.token));
  };

  const axiosGetReportParameterListByReportId = (reportID: any) => {
    return axios.get(`${Endpoint.REPORT}/${reportID}/reportParameterList`, axiosConfig(authData!.token));
  };

  const axiosCreateReportParameter = (reportParameter: any) => {
    return axios.post(`${Endpoint.REPORT}/reportParameter/reportParameterList`, reportParameter, axiosConfig(authData!.token));
  };

  const axiosUpdateReportParameter = (reportParameterID: any, reportParameter: any) => {
    return axios.put(`${Endpoint.REPORT}/reportParameter/reportParameterList/${reportParameterID}`, reportParameter, axiosConfig(authData!.token));
  };

  const axiosGenerateReport = (body: any) => {
    return axios.post(`${Endpoint.REPORT}/addReportForExecution`, body, axiosConfig(authData!.token));
  };

  const axiosDeleteReportParameter = (reportParameterID: any) => {
    return axios.delete(`${Endpoint.REPORT}/reportParameter/reportParameterList/${reportParameterID}`, axiosConfig(authData!.token));
  };

  const axiosGetReportCategoryList = () => {
    return axios.get(`${Endpoint.CODETABLE}/reportCategoryList`, axiosConfig(authData!.token));
  };

  const axiosGetReportParameterCustomQueryResultList = (reportParameterID: any) => {
    return axios.get(`${Endpoint.REPORT}/customQuery/${reportParameterID}`, axiosConfig(authData!.token));
  };

  const axiosGetReportGeneratedListByReportId = (reportID: any) => {
    return axios.get(`${Endpoint.REPORT}/${reportID}/reportGenerateList`, axiosConfig(authData!.token));
  };

  const axiosDeleteGenerateReport = (reportGenerateID: any) => {
    return axios.delete(`${Endpoint.REPORT}/reportGenerateList/${reportGenerateID}`, axiosConfig(authData!.token));
  };

  const axiosGetPublicReportGenerateList = () => {
    return axios.get(`${Endpoint.REPORT}/publicReportGenerateList`, axiosConfig(authData!.token));
  };

  const axiosGetReportListByUser = () => {
    return axios.get(`${Endpoint.REPORT}/reportListByUser`, axiosConfig(authData!.token));
  };
  const axiosGetGeneratedReport = (params: any) => {
    return axios({
      url: `${Endpoint.REPORT}/generatedReport`,
      method: "GET",
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + authData!.token,
        "Content-Type": "application/json",
      },
      params: params,
    });
  };

  const axiosUpdateIsPublicReport = (reportGenerateID: number, isPublic: boolean) => {
    return axios.put(`${Endpoint.REPORT}/generateReport/${reportGenerateID}/${isPublic}`, null, axiosConfig(authData!.token));
  };

  const axiosGetCustomQueryResult = (body: any) => {
    return axios.post(`${Endpoint.REPORT}/executeCustomQuery`, body, axiosConfig(authData!.token));
  };

  return {
    axiosGetReportList,
    axiosCreateReport,
    axiosUpdateReport,
    axiosDeleteReport,
    axiosGetReport,
    axiosGetReportParameterListByReportId,
    axiosCreateReportParameter,
    axiosUpdateReportParameter,
    axiosDeleteReportParameter,
    axiosGetAccessListByReport,
    axiosDeleteAccessReport,
    axiosCreateAccessReport,
    axiosGetReportCategoryList,
    axiosGenerateReport,
    axiosGetReportParameterCustomQueryResultList,
    axiosGetReportGeneratedListByReportId,
    axiosDeleteGenerateReport,
    axiosGetPublicReportGenerateList,
    axiosGetReportListByUser,
    axiosGetGeneratedReport,
    axiosUpdateIsPublicReport,
    axiosGetCustomQueryResult
  };
}

export type { ReportControllerType };
