import Images from "../../../infrastructure/system/Images";
import LabelsContext from "../../../infrastructure/system/LabelsContext";

export default function AppFooter() {
  const { Labels } = LabelsContext();

  return (
    <div className="layout-footer">
      <div className="footer-logo-container">
        <img id="footer-logo" src={Images.LOGO_IJZS} alt="diamond-layout" />
        <span className="app-name">{Labels.APPLICATION_NAME}</span>
      </div>
      <span className="copyright">&#169; {Labels.HELIANT}</span>
    </div>
  );
}
