import LabelsContext from "../../infrastructure/system/LabelsContext";

export default function ErrorPageView() {
  const { Labels } = LabelsContext();

  return (
    <div className="error-layout">
      <div className="error-wrapper">
        <div className="man-icon"></div>
        <p className="title">{Labels.ERROR_PAGE_404}</p>
        <p className="info">{Labels.ERROR_PAGE_PAGE_NOT_FOUND}</p>
      </div>
    </div>
  );
}
