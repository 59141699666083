const EnumRole = {
  ADMIN: "ADMIN",
  SUPERADMIN: "SUPERADMIN",
  MD: "MD",
  BED: "BED",
  ED: "ED",
  USER: "USER",
};

export default EnumRole;
