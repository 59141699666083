import { useContext, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FieldTypeController, FieldTypeControllerType } from "../../../controllers/field-type/FieldTypeController";
import EnumComponentType from "../../../infrastructure/system/EnumComponentType";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, useEffectOnce } from "../../../infrastructure/system/Utils";
import FieldTypeEnumeratorItemReadDto from "../../../model/FieldTypeEnumeratorItemReadDto";
import FieldTypeReadDto from "../../../model/FieldTypeReadDto";
import { AppContext } from "../../../Store";
import { CodebookController, CodebookControllerType } from "../../../controllers/codebook/CodebookController";

interface CrudFieldTypeLogicalType {
  fieldTypeId: any;
  fieldTypeOperation: string;
  isDisabled: any;
  fieldTypeChange: FieldTypeReadDto | undefined;
  setFieldTypeChange: any;
  breadCrumbItems: any;
  onCreate: any;
  onDelete: any;
  onUpdate: any;
  onCancel: any;
  location: any;
  fieldTypeEnumeratorItemList: Array<FieldTypeEnumeratorItemReadDto>;
  first: number;
  tableRows: number;
  selectedRow: any;
  setSelectedRow: any;
  selectedFieldTypeEnumeratorItem: any;
  setSelectedFieldTypeEnumeratorItem: any;
  dialogHeader: any;
  displayDialog: boolean;
  closeDialog: any;
  dialogRef: any;
  fieldTypeEnumeratorItemToChange: any;
  createFieldTypeEnumeratorItem: any;
  updateFieldTypeEnumeratorItem: any;
  deleteFieldTypeEnumeratorItem: any;
  fieldTypeEnumeratorItemOperation: any;
  openDialog: any;
  index: any;
  setIndex: any;
  newFieldTypeId: any;
  deleteFormat: any;
  onPageFieldTypeTable: any;
  codebookTypeList: any;
  deleteCodebookType: any;
}

interface LocationType {
  fieldTypeOperation: string;
}

interface UseParamsType {
  fieldTypeId?: string;
}

export default function CrudFieldTypeLogical() {
  const { showMessage } = useContext(AppContext);
  const { Labels } = LabelsContext();
  const [fieldTypeChange, setFieldTypeChange] = useState<FieldTypeReadDto | undefined>();
  const history = useHistory();
  const { fieldTypeId } = useParams<UseParamsType>();
  const location = useLocation<LocationType>();
  const [first, setFirst] = useState(0);
  const [fieldTypeOperation, setFieldTypeOperation] = useState(location.state ? location.state.fieldTypeOperation : EntityOperation.UPDATE);
  const [isDisabled] = useState(isFormDisabled(fieldTypeOperation));
  const [breadCrumbItems, setBreadCrumbItems] = useState<any>([
    {
      label: Labels.FIELD_TYPES,
      command: () => {
        history.push("/fieldTypeList");
      },
    },
  ]);
  const {
    axiosCreateFieldType,
    axiosUpdateFieldType,
    axiosDeleteFieldType,
    axiosGetFieldType,
    axiosGetFieldTypeEnumeratorItemList,
    axiosCreateFieldTypeEnumeratorItem,
    axiosUpdateFieldTypeEnumeratorItem,
    axiosDeleteFieldTypeEnumeratorItem,
  }: FieldTypeControllerType = FieldTypeController();
  const [fieldTypeEnumeratorItemList, setFieldTypeEnumeratorItemList] = useState<Array<FieldTypeEnumeratorItemReadDto>>([]);
  const [tableRows, setTableRows] = useState(10);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedFieldTypeEnumeratorItem, setSelectedFieldTypeEnumeratorItem] = useState<FieldTypeEnumeratorItemReadDto | null>();
  const [displayDialog, setDisplayDialog] = useState(false);
  const dialogRef = useRef<any>();
  const [fieldTypeEnumeratorItemToChange, setFieldTypeEnumeratorItemToChange] = useState<FieldTypeEnumeratorItemReadDto>();
  const [fieldTypeEnumeratorItemOperation, setFieldTypeEnumeratorItemOperation] = useState<any>();
  const [index, setIndex] = useState<number>(0);
  const [newFieldTypeId, setNewFieldTypeId] = useState<any>(fieldTypeId);
  const [codebookTypeList, setCodebookTypeList] = useState([]);
  const { axiosGetCodebookTypeList }: CodebookControllerType = CodebookController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    axiosGetCodebookTypeList()
      .then((res: any) => {
        setCodebookTypeList(res.data.data);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
    if (fieldTypeId || newFieldTypeId) {
      axiosGetFieldType(fieldTypeId !== undefined ? fieldTypeId : newFieldTypeId)
        .then((response: any) => {
          setFieldTypeChange(response.data.data);
          if (response.data.data.componentEnumType === EnumComponentType.SET) {
            axiosGetFieldTypeEnumeratorItemList(fieldTypeId !== undefined ? fieldTypeId : newFieldTypeId)
              .then((response: any) => {
                setFieldTypeEnumeratorItemList(response.data.data);
              })
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
              });
          }
          if (breadCrumbItems.length < 2) {
            setBreadCrumbItems([
              ...breadCrumbItems,
              {
                label:
                  fieldTypeOperation === EntityOperation.UPDATE
                    ? Labels.LABEL_UPDATE_FIELD_TYPE + response.data.data.name
                    : fieldTypeOperation === EntityOperation.READ
                    ? Labels.LABEL_DETAILS_FIELD_TYPE + response.data.data.name
                    : Labels.LABEL_DELETE_FIELD_TYPE + response.data.data.name,
              },
            ]);
          }
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    } else {
      if (breadCrumbItems.length < 2) {
        setBreadCrumbItems([
          ...breadCrumbItems,
          {
            label: Labels.FIELD_TYPE_CREATE,
          },
        ]);
      }
    }
  };

  const deleteCodebookType = (componentEnumType: string) => {
    if (componentEnumType === EnumComponentType.CODEBOOK || componentEnumType === EnumComponentType.CODEBOOK_AUTOCOMPLETE) {
      delete fieldTypeChange?.codebookEnumType;
    }
  };

  const deleteFormat = (componentEnumType: string) => {
    if (componentEnumType === EnumComponentType.DATE_TIME) {
      delete fieldTypeChange?.format;
    }
  };

  const onCreate = () => {
    axiosCreateFieldType(fieldTypeChange)
      .then((response: any) => {
        setNewFieldTypeId(response.data.data.id);
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_CREATE_FIELD_TYPE_SUCCESS, "");
        if (fieldTypeChange?.componentEnumType === EnumComponentType.SET) {
          setIndex(1);
          setFieldTypeOperation(EntityOperation.UPDATE);
        } else {
          history.push(`/fieldTypeList`);
        }
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onUpdate = () => {
    axiosUpdateFieldType(fieldTypeId !== undefined ? fieldTypeId : newFieldTypeId, fieldTypeChange)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_UPDATE_FIELD_TYPE_SUCCESS, "");
        history.push(`/fieldTypeList`);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onDelete = () => {
    axiosDeleteFieldType(fieldTypeId)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_DELETE_FIELD_TYPE_SUCCESS, "");
        history.push(`/fieldTypeList`);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onCancel = () => {
    history.push({
      pathname: `/fieldTypeList`,
    });
  };

  const dialogHeader = (entityOperation: string) => {
    const display = selectedFieldTypeEnumeratorItem ? selectedFieldTypeEnumeratorItem.name : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_CREATE;
      case EntityOperation.DELETE:
        return Labels.FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_DELETE + display;
      case EntityOperation.UPDATE:
        return Labels.FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_UPDATE + display;
      case EntityOperation.READ:
        return Labels.FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_DETAILS + display;
      default:
        return "";
    }
  };

  const closeDialog = () => {
    setDisplayDialog(false);
  };

  const openDialog = (entityOperation: String, selectedFieldTypeEnumeratorItem?: FieldTypeEnumeratorItemReadDto) => {
    let fieldTypeEnumeratorItem = undefined;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        fieldTypeEnumeratorItem = selectedFieldTypeEnumeratorItem;
        break;
    }

    setFieldTypeEnumeratorItemOperation(entityOperation);
    setFieldTypeEnumeratorItemToChange(fieldTypeEnumeratorItem);
    setDisplayDialog(true);
  };

  const validateInput = (fieldTypeEnumeratorItem: FieldTypeEnumeratorItemReadDto) => {
    if (fieldTypeEnumeratorItem === undefined || fieldTypeEnumeratorItem?.code === "") {
      showMessage(MessageType.ERROR, Labels.FIELD_TYPE_ENUMERATOR_ITEM_CODE_IS_REQUIRED, "");
      return false;
    }
    if (fieldTypeEnumeratorItem?.name === "") {
      showMessage(MessageType.ERROR, Labels.FIELD_TYPE_ENUMERATOR_ITEM_NAME_IS_REQUIRED, "");
      return false;
    }
    if (!fieldTypeEnumeratorItem?.displayOrder) {
      showMessage(MessageType.ERROR, Labels.DISPLAY_ORDER_REQUIRED, "");
      return false;
    }

    return true;
  };

  const createFieldTypeEnumeratorItem = (fieldTypeEnumeratorItem: FieldTypeEnumeratorItemReadDto) => {
    return new Promise((resolve, reject) => {
      if (!validateInput(fieldTypeEnumeratorItem)) {
        return;
      }
      axiosCreateFieldTypeEnumeratorItem(fieldTypeId !== undefined ? fieldTypeId : newFieldTypeId, fieldTypeEnumeratorItem)
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.FIELD_TYPE_ENUMERATOR_ITEM_TITLE_MESSAGE_CREATE_USER_GROUP_SUCCESS, "");
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const updateFieldTypeEnumeratorItem = (fieldTypeEnumeratorItem: FieldTypeEnumeratorItemReadDto) => {
    return new Promise((resolve, reject) => {
      if (!validateInput(fieldTypeEnumeratorItem)) {
        return;
      }
      axiosUpdateFieldTypeEnumeratorItem(fieldTypeId !== undefined ? fieldTypeId : newFieldTypeId, fieldTypeEnumeratorItem.id, fieldTypeEnumeratorItem)
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.FIELD_TYPE_ENUMERATOR_ITEM_TITLE_MESSAGE_UPDATE_USER_GROUP_SUCCESS, "");
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const deleteFieldTypeEnumeratorItem = (fieldTypeEnumeratorItemId: number) => {
    return new Promise((resolve, reject) => {
      axiosDeleteFieldTypeEnumeratorItem(fieldTypeId !== undefined ? fieldTypeId : newFieldTypeId, fieldTypeEnumeratorItemId)
        .then(() => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.FIELD_TYPE_ENUMERATOR_ITEM_TITLE_MESSAGE_DELETE_USER_GROUP_SUCCESS, "");
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const onPageFieldTypeTable = (rows: any, first: any) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    fieldTypeId,
    fieldTypeOperation,
    isDisabled,
    fieldTypeChange,
    setFieldTypeChange,
    breadCrumbItems,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    location,
    fieldTypeEnumeratorItemList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    selectedFieldTypeEnumeratorItem,
    setSelectedFieldTypeEnumeratorItem,
    dialogHeader,
    displayDialog,
    closeDialog,
    dialogRef,
    fieldTypeEnumeratorItemToChange,
    createFieldTypeEnumeratorItem,
    updateFieldTypeEnumeratorItem,
    deleteFieldTypeEnumeratorItem,
    fieldTypeEnumeratorItemOperation,
    openDialog,
    index,
    setIndex,
    newFieldTypeId,
    deleteFormat,
    onPageFieldTypeTable,
    codebookTypeList,
    deleteCodebookType,
  };
}

export type { CrudFieldTypeLogicalType };
