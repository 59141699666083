import axios from "axios";
import { ChangeEvent, useContext, useRef, useState } from "react";
import { InstitutionController, InstitutionControllerType } from "../../../controllers/institution/InstitutionController";
import { UserGroupController, UserGroupControllerType } from "../../../controllers/user-group/UserGroupController";
import { UserController, UserControllerType } from "../../../controllers/user/UserController";
import Endpoint from "../../../infrastructure/system/Endpoint";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { axiosConfig, handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import SearchDataDto from "../../../model/SearchDataDto";
import UserReadDto from "../../../model/user/UserReadDto";
import { AppContext } from "../../../Store";

interface UserListLogicalType {
  userList: Array<UserReadDto>;
  first: number;
  tableRows: number;
  selectedRow: any;
  setSelectedRow: any;
  selectedUser: any;
  setSelectedUser: any;
  changeSearchData: any;
  searchData: SearchDataDto;
  searchUsers: any;
  searchUsersByEnter: any;
  dialogHeader: any;
  openDialog: any;
  displayDialog: boolean;
  entityOperation: any;
  closeDialog: any;
  dialogRef: any;
  userToChange: any;
  index: any;
  setIndex: any;
  deleteUser: any;
  createUser: any;
  updateUser: any;
  userInstitutionList: any;
  institutionList: any;
  onChangeInstitution: any;
  onPageUser: any;
  breadCrumbItems: any;
  exportData: any;
  userGroupUserList: any;
  userGroupList: any;
  onChangeUserGroup: any;
}

export default function UserListLogical(): UserListLogicalType {
  const { showMessage, authData, setShowBlockUI } = useContext(AppContext);
  const { Labels } = LabelsContext();
  const [userList, setUserList] = useState<Array<UserReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<UserReadDto | null>();
  const [searchData, setSearchData] = useState<SearchDataDto>({});
  const [entityOperation, setEntityOperation] = useState<any>();
  const [userToChange, setUserToChange] = useState<UserReadDto>();
  const [displayDialog, setDisplayDialog] = useState(false);
  const dialogRef = useRef<any>();
  const [index, setIndex] = useState<number>(0);
  const [userInstitutionList, setUserInstitutionList] = useState<any>([]);
  const [institutionList, setInstitutionList] = useState<any>([]);
  const [userGroupUserList, setUserGroupUserList] = useState<any>([]);
  const [userGroupList, setUserGroupList] = useState<any>([]);
  const {
    axiosGetUserList,
    axiosCreateUser,
    axiosUpdateUser,
    axiosDeleteUser,
    axiosCreateUserInstitution,
    axiosDeleteUserInstitution,
    axiosCreateUserGroupUser,
    axiosDeleteUserGroupUser,
  }: UserControllerType = UserController();
  const { axiosGetInstitutionList }: InstitutionControllerType = InstitutionController();
  const { axiosGetUserGroupList }: UserGroupControllerType = UserGroupController();
  const breadCrumbItems = [
    {
      label: Labels.USER_LIST,
      command: () => {
        window.location.reload();
      },
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (idSelectedUser?: number) => {
    setShowBlockUI(true);
    axiosGetUserList()
      .then((res: any) => {
        setUserList(res.data.data);
        let exportList = new Array<any>();
        res.data.data.forEach((user: any) => {
          exportList.push({ [Labels.LABEL_FIRSTNAME]: user.firstname, [Labels.LABEL_LASTNAME]: user.lastname, [Labels.USER_USER_NAME]: user.username, [Labels.LABEL_EMAIL]: user.email });
        });
        setExportData(exportList);
        setShowBlockUI(false);
        if (idSelectedUser) {
          res.data.data.forEach((user: any) => {
            if (idSelectedUser === user.id) {
              setSelectedRow(user);
              setSelectedUser(user);
            }
          });
        }
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const changeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const searchUsers = () => {
    setShowBlockUI(true);
    axios
      .get(Endpoint.USER_LIST, axiosConfig(authData!.token, searchData))
      .then((res) => {
        setUserList(res.data.data);
        let exportList = new Array<any>();
        res.data.data.forEach((user: any) => {
          exportList.push({ [Labels.LABEL_FIRSTNAME]: user.firstname, [Labels.LABEL_LASTNAME]: user.lastname, [Labels.USER_USER_NAME]: user.username, [Labels.LABEL_EMAIL]: user.email });
        });
        setExportData(exportList);
        setShowBlockUI(false);
        setSelectedRow(undefined);
        setSelectedUser(undefined);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const dialogHeader = (entityOperation: string) => {
    const display = selectedUser ? selectedUser.firstname : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.USER_TITLE_DIALOG_CREATE;
      case EntityOperation.DELETE:
        return Labels.USER_TITLE_DIALOG_DELETE + display;
      case EntityOperation.UPDATE:
        return Labels.USER_TITLE_DIALOG_UPDATE + display;
      case EntityOperation.READ:
        return Labels.USER_TITLE_DIALOG_DETAILS + display;
      default:
        return "";
    }
  };

  const openDialog = (entityOperation: String, selectedUser?: UserReadDto) => {
    setIndex(0);
    let user = undefined;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        user = selectedUser;
        break;
    }
    setEntityOperation(entityOperation);
    setUserToChange(user);
    setDisplayDialog(true);
    getUserInstitution();
    getUserGroupUser();
  };

  const closeDialog = () => {
    setDisplayDialog(false);
    setIndex(0);
  };

  const searchUsersByEnter = (event: any) => {
    if (event.charCode === 13) {
      searchUsers();
    }
  };

  const validateInput = (user: UserReadDto) => {
    if (user === undefined || user?.username === "") {
      showMessage(MessageType.ERROR, Labels.USER_USERNAME_IS_REQUIRED, "");
      return false;
    }
    if (user?.email === "") {
      showMessage(MessageType.ERROR, Labels.USER_EMAIL_IS_REQUIRED, "");
      return false;
    }
    return true;
  };

  const createUser = (user: UserReadDto) => {
    setUserInstitutionList([]);
    setUserGroupUserList([]);
    getInstitution([]);
    getUserGroup([]);
    return new Promise((resolve, reject) => {
      if (!validateInput(user)) {
        return;
      }
      setShowBlockUI(true);
      axiosCreateUser(user)
        .then((response: any) => {
          setEntityOperation(EntityOperation.UPDATE);
          setSelectedUser(response.data.data);
          showMessage(MessageType.SUCCESS, Labels.USER_TITLE_MESSAGE_CREATE_USER_SUCCESS, "");
          setIndex(1);
          fetchData();
          setShowBlockUI(false);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        });
    });
  };

  const updateUser = (user: UserReadDto) => {
    return new Promise((resolve, reject) => {
      if (!validateInput(user)) {
        return;
      }
      setShowBlockUI(true);
      axiosUpdateUser(user)
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.USER_TITLE_MESSAGE_UPDATE_USER_SUCCESS, "");
          fetchData(response.data.data.id);
          setShowBlockUI(false);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        });
    });
  };

  const deleteUser = (userId: number) => {
    return new Promise((resolve, reject) => {
      axiosDeleteUser(userId)
        .then(() => {
          closeDialog();
          setSelectedRow(undefined);
          setSelectedUser(undefined);
          showMessage(MessageType.SUCCESS, Labels.USER_TITLE_MESSAGE_DELETE_USER_SUCCESS, "");
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const getInstitution = (listInstitutionHave: any) => {
    setShowBlockUI(true);
    axiosGetInstitutionList()
      .then((res: any) => {
        var listNotHave: any = [];
        const allInstitution = res.data.data;
        allInstitution.forEach((institution: any) => {
          var isNotInUserInstitution = false;
          listInstitutionHave.forEach((institutionHave: any) => {
            if (institutionHave.institution.code === institution.code) {
              isNotInUserInstitution = true;
            }
          });
          if (isNotInUserInstitution === false) {
            listNotHave.push({ institution: institution, id: null });
          }
        });
        setInstitutionList(listNotHave);
        setShowBlockUI(false);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const getUserInstitution = () => {
    if (selectedUser !== null && selectedUser !== undefined) {
      var institutionList: any = [];
      const userInstitutionList = selectedUser.userInstitutionList;
      userInstitutionList?.map((userInstitution: any) => {
        return institutionList.push({
          id: userInstitution.id,
          institution: userInstitution.institution,
        });
      });
      setUserInstitutionList(institutionList);
      getInstitution(institutionList);
    }
  };

  const onChangeInstitution = async (event: any) => {
    setShowBlockUI(true);
    if (userInstitutionList.length < event.target.length) {
      var userInstitutionListAfterChange: any = [];
      await Promise.all(
        event.target.map(async (userInstitution: any) => {
          if (!userInstitutionList.includes(userInstitution)) {
            const institution = {
              institution: { id: userInstitution.institution.id },
            };
            await axiosCreateUserInstitution(selectedUser!.id, institution)
              .then(async (res: any) => {
                userInstitutionListAfterChange = res.data.data.userInstitutionList;
              })
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
                setShowBlockUI(false);
              });
          }
        })
      );
      setUserInstitutionList(userInstitutionListAfterChange);
      setInstitutionList(event.source);
      fetchData();
    } else if (institutionList.length < event.source.length) {
      event.source.forEach((institution: any) => {
        if (!institutionList.includes(institution)) {
          axiosDeleteUserInstitution(selectedUser!.id, institution.id)
            .then((res: any) => {
              setUserInstitutionList(event.target);
              setInstitutionList(event.source);
            })
            .catch((error: any) => {
              handleAxiosCallError(showMessage, error);
              setShowBlockUI(false);
            });
        }
      });
      fetchData();
    }
  };

  const getUserGroup = (listUserGroupHave: any) => {
    setShowBlockUI(true);
    axiosGetUserGroupList()
      .then((res: any) => {
        var listNotHave: any = [];
        const allUserGroup = res.data.data;
        allUserGroup.forEach((userGroup: any) => {
          var isNotInUserGroupUser = false;
          listUserGroupHave.forEach((userGroupHave: any) => {
            if (userGroupHave.userGroup.code === userGroup.code) {
              isNotInUserGroupUser = true;
            }
          });
          if (isNotInUserGroupUser === false) {
            listNotHave.push({ userGroup: userGroup, id: null });
          }
        });
        setUserGroupList(listNotHave);
        setShowBlockUI(false);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const getUserGroupUser = () => {
    if (selectedUser !== null && selectedUser !== undefined) {
      var userGroupList: any = [];
      const userGroupUserList = selectedUser.userGroupUserList;
      userGroupUserList?.map((userGroupUser: any) => {
        return userGroupList.push({
          id: userGroupUser.id,
          userGroup: userGroupUser.userGroup,
        });
      });
      setUserGroupUserList(userGroupList);
      getUserGroup(userGroupList);
    }
  };
  const onChangeUserGroup = async (event: any) => {
    setShowBlockUI(true);
    if (userGroupUserList.length < event.target.length) {
      var userGroupUserListAfterChange: any = [];
      await Promise.all(
        event.target?.map(async (userGroupUser: any) => {
          if (!userGroupUserList?.includes(userGroupUser)) {
            const userGroup = {
              userGroup: { id: userGroupUser.userGroup.id },
            };
            await axiosCreateUserGroupUser(selectedUser!.id, userGroup)
              .then(async (res: any) => {
                userGroupUserListAfterChange = res.data.data.userGroupUserList;
              })
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
                setShowBlockUI(false);
              });
          }
        })
      );
      setUserGroupUserList(userGroupUserListAfterChange);
      setUserGroupList(event.source);
      fetchData();
    } else if (userGroupList.length < event.source.length) {
      event.source.forEach((userGroup: any) => {
        if (!userGroupList.includes(userGroup)) {
          axiosDeleteUserGroupUser(selectedUser!.id, userGroup.id)
            .then((res: any) => {
              setUserGroupUserList(event.target);
              setUserGroupList(event.source);
            })
            .catch((error: any) => {
              handleAxiosCallError(showMessage, error);
              setShowBlockUI(false);
            });
        }
      });
      fetchData();
    }
  };

  const onPageUser = (rows: any, first: any) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    userList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    selectedUser,
    setSelectedUser,
    changeSearchData,
    searchData,
    searchUsers,
    searchUsersByEnter,
    dialogHeader,
    openDialog,
    displayDialog,
    entityOperation,
    closeDialog,
    dialogRef,
    userToChange,
    index,
    setIndex,
    deleteUser,
    createUser,
    updateUser,
    userInstitutionList,
    institutionList,
    onChangeInstitution,
    onPageUser,
    breadCrumbItems,
    exportData,
    userGroupUserList,
    userGroupList,
    onChangeUserGroup,
  };
}

export type { UserListLogicalType };
