import { Toast } from "primereact/toast";
import { useContext } from "react";
import { AppContext } from "../Store";

interface ContainerProps {
  children?: JSX.Element;
}

export default function Container(props: ContainerProps) {
  const { children } = props;
  const { setMessages } = useContext(AppContext);

  return (
    <div className="layout-message">
      <div>
        <Toast
          ref={(el) => {
            setMessages(el);
          }}
        />
      </div>
      <div>{children}</div>
    </div>
  );
}
