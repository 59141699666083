import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface ClassNameControllerType {
  axiosGetClassNameList: any;
}

export function ClassNameController(): ClassNameControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetClassNameList = () => {
    return axios.get(Endpoint.CLASS_NAME_LIST, axiosConfig(authData!.token));
  };

  return { axiosGetClassNameList };
}

export type { ClassNameControllerType };
