import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import ExportData from "../../app/export/ExportData";
import CrudInstitution from "./CrudInstitutionView";
import InstitutionListLogical, { InstitutionListLogicalType } from "./InstitutionListLogical";

export default function InstitutionListPage() {
  const {
    institutionList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    selectedInstitution,
    setSelectedInstitution,
    dialogHeader,
    openDialog,
    displayDialog,
    entityOperation,
    closeDialog,
    dialogRef,
    institutionToChange,
    index,
    setIndex,
    deleteInstitution,
    createInstitution,
    updateInstitution,
    jurisdictionList,
    districtList,
    municipalityList,
    institutionTypeList,
    selectedInstitutionUserRow,
    setSelectedInstitutionUserRow,
    displayDialogCreateUserInstitution,
    closeDialogCreateUserInstitution,
    setSelectedUserRow,
    selectedUserRow,
    usersNotInInstitution,
    openDialogCreateUserInstitution,
    deleteUserInstitution,
    createUserInstitution,
    userDataTableFirst,
    userTableRows,
    institutionUserTableFirst,
    institutionUserTableRows,
    onPageInstitutionUser,
    onPageInstitution,
    onPageUserTable,
    breadCrumbItems,
    exportData,
    onSearchValueChange,
    setInstitutionChange,
    institutionChange,
    institutionCodebookList,
    selectedDistrict,
    selectedMunicipality,
    selectedInstitutionCodebook,
    setSelectedDistrict,
    setSelectedMunicipality,
    setSelectedInstitutionCodebook,
    searchUserList,
    searchUserData,
    setSearchUserData,
  }: InstitutionListLogicalType = InstitutionListLogical();

  const { Labels } = LabelsContext();
  const dt = useRef<any>(null);

  const leftContents = () => (
    <>
      <Button
        icon="pi pi-plus"
        className="p-button-success btn-margin-right"
        label={Labels.INSTITUTION_LIST_CREATE}
        tooltip={Labels.INSTITUTION_TITLE_DIALOG_CREATE}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          openDialog(EntityOperation.CREATE);
        }}
      />

      <Button
        icon="pi pi-pencil"
        className="p-button-warning btn-margin-right"
        disabled={!selectedInstitution}
        label={Labels.INSTITUTION_LIST_EDIT}
        tooltip={Labels.INSTITUTION_TITLE_DIALOG_UPDATE}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          selectedInstitution && openDialog(EntityOperation.UPDATE, selectedInstitution);
        }}
      />
      <Button
        icon="pi pi-info-circle"
        label={Labels.INSTITUTION_LIST_DETAILS}
        tooltip={Labels.INSTITUTION_TITLE_DIALOG_DETAILS}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedInstitution}
        onClick={() => {
          selectedInstitution && openDialog(EntityOperation.READ, selectedInstitution);
        }}
      />
    </>
  );

  const rightContents = () => (
    <>
      <ExportData data={exportData} name={Labels.INSTITUTION_LIST} dt={dt} />
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        label={Labels.INSTITUTION_LIST_DELETE}
        tooltip={Labels.INSTITUTION_TITLE_DIALOG_DELETE}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedInstitution}
        onClick={() => {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_INSTITUTION,
            icon: "pi pi-power-off",
            accept: () => {
              deleteInstitution(selectedInstitution.id);
            },
          });
        }}
      />
    </>
  );

  const leftContentsDialog = () => (
    <>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.INSTITUTION_TITLE_DIALOG_CREATE}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && index === 0 && (
        <Button
          label={Labels.BUTTON_UPDATE}
          tooltip={Labels.INSTITUTION_TITLE_DIALOG_UPDATE}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            dialogRef.current.onUpdate();
          }}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          tooltip={Labels.INSTITUTION_TITLE_DIALOG_DELETE}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </>
  );

  const rightContentsDialog = () => (
    <>
      <Button label={index === 0 ? Labels.BUTTON_CANCEL : Labels.BUTTON_CLOSE} icon="pi pi-times" onClick={closeDialog} />
    </>
  );

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  return (
    <div className="layout-institution-list">
      <Panel>
        <div className="p-grid">
          <div className="p-col-12">
            <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
            <Toolbar left={leftContents} right={rightContents} />
            <div>
              <DataTable
                ref={dt}
                value={institutionList}
                paginator
                first={first}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rows={tableRows}
                rowsPerPageOptions={[5, 10, 20]}
                onPage={(e) => {
                  onPageInstitution(e.rows, e.first);
                }}
                selectionMode="single"
                selection={selectedRow}
                onSelectionChange={(e) => setSelectedRow(e.value)}
                onRowSelect={(e) => setSelectedInstitution(e.data)}
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                exportFilename={Labels.INSTITUTION_LIST}
                onValueChange={onSearchValueChange}
              >
                <Column field={"name"} header={Labels.HEADER_NAME} filter filterMatchMode="contains" sortable />
                <Column field={"code"} header={Labels.HEADER_CODE} filter filterMatchMode="contains" sortable />
                <Column field={"institutionType"} header={Labels.INSTITUTION_TYPE} filter filterMatchMode="contains" sortable className="institution-row " />
                <Column field={"displayOrder"} header={Labels.HEADER_DISPLAY_ORDER} filter filterMatchMode="contains" sortable />
              </DataTable>
            </div>
            <Dialog header={dialogHeader(entityOperation)} visible={displayDialog} onHide={closeDialog} style={{ width: "1000px" }} footer={dialogFooter()}>
              <CrudInstitution
                institutionOperation={entityOperation}
                institution={institutionToChange}
                dialogRef={dialogRef}
                index={index}
                setIndex={setIndex}
                onDeleteInstitution={deleteInstitution}
                onCreateInstitution={createInstitution}
                onUpdateInstitution={updateInstitution}
                jurisdictionList={jurisdictionList}
                districtList={districtList}
                municipalityList={municipalityList}
                institutionTypeList={institutionTypeList}
                setSelectedInstitutionUserRow={setSelectedInstitutionUserRow}
                selectedInstitutionUserRow={selectedInstitutionUserRow}
                displayDialogCreateUserInstitution={displayDialogCreateUserInstitution}
                closeDialogCreateUserInstitution={closeDialogCreateUserInstitution}
                selectedUserRow={selectedUserRow}
                setSelectedUserRow={setSelectedUserRow}
                usersNotInInstitution={usersNotInInstitution}
                openDialogCreateUserInstitution={openDialogCreateUserInstitution}
                deleteUserInstitution={deleteUserInstitution}
                createUserInstitution={createUserInstitution}
                userDataTableFirst={userDataTableFirst}
                userTableRows={userTableRows}
                institutionUserTableFirst={institutionUserTableFirst}
                institutionUserTableRows={institutionUserTableRows}
                onPageInstitutionUser={onPageInstitutionUser}
                onPageUserTable={onPageUserTable}
                setInstitutionChange={setInstitutionChange}
                institutionChange={institutionChange}
                institutionCodebookList={institutionCodebookList}
                selectedDistrict={selectedDistrict}
                selectedMunicipality={selectedMunicipality}
                selectedInstitutionCodebook={selectedInstitutionCodebook}
                setSelectedDistrict={setSelectedDistrict}
                setSelectedMunicipality={setSelectedMunicipality}
                setSelectedInstitutionCodebook={setSelectedInstitutionCodebook}
                searchUserList={searchUserList}
                searchUserData={searchUserData}
                setSearchUserData={setSearchUserData}
              />
            </Dialog>
          </div>
        </div>
      </Panel>
    </div>
  );
}
