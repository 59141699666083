import { useContext, useEffect, useState } from "react";
import { CodebookController, CodebookControllerType } from "../../../../controllers/codebook/CodebookController";
import CodebookLogicalControlOperator from "../../../../infrastructure/system/CodebookLogicalControlOperator";
import { handleAxiosCallError, useEffectOnce } from "../../../../infrastructure/system/Utils";
import { AppContext } from "../../../../Store";

interface CodebookLogicalType {
  codebookOptionList: any;
}

interface CodebookLogicalPropType {
  id?: number;
  code?: string;
  codebookLogicalControlValidation?: any;
  setFieldCodebookListValidation?: any;
}

export default function CodebookLogical(prop: CodebookLogicalPropType): CodebookLogicalType {
  const [codebookOptionListAll, setCodebookOptionListAll] = useState([]);
  const [codebookOptionList, setCodebookOptionList] = useState([]);
  const { axiosGetCodebookListWithCode }: CodebookControllerType = CodebookController();

  const { showMessage } = useContext(AppContext);
  const { id, code, codebookLogicalControlValidation, setFieldCodebookListValidation } = prop;

  useEffect(() => {
    if (codebookLogicalControlValidation && codebookLogicalControlValidation.length > 0) {
      let codebookNew: any = [];
      codebookLogicalControlValidation.forEach((codebookValidation: any) => {
        let codebookFilter: any = [];
        if (codebookValidation.operator === CodebookLogicalControlOperator.EQUAL) {
          codebookFilter = codebookOptionListAll.filter((c: any) => c.code === codebookValidation.firstValue);
        } else if (codebookValidation.operator === CodebookLogicalControlOperator.NOT_EQUAL) {
          codebookFilter = codebookOptionListAll.filter((c: any) => c.code !== codebookValidation.firstValue);
        } else if (codebookValidation.operator === CodebookLogicalControlOperator.RANGE) {
          const startIndex = codebookOptionListAll.findIndex((x: any) => x.code === codebookValidation.firstValue);
          const endIndex = codebookOptionListAll.findIndex((x: any) => x.code === codebookValidation.lastValue);
          codebookFilter = codebookOptionListAll.slice(startIndex, endIndex);
        }
        if (codebookNew.length === 0) {
          codebookNew = codebookFilter;
        } else {
          codebookNew = codebookNew.filter((e: any) => {
            return codebookFilter.some((item: any) => item.code === e.code);
          });
        }
      });
      setCodebookOptionList(codebookNew);
    } else {
      setCodebookOptionList(codebookOptionListAll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codebookLogicalControlValidation]);

  useEffect(() => {
    if (setFieldCodebookListValidation) {
      setFieldCodebookListValidation({ id: id, value: codebookOptionList });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codebookOptionList]);

  useEffectOnce(() => {
    axiosGetCodebookListWithCode(code)
      .then((res: any) => {
        const codebookOptionListWithDropdownLabel = res.data.data.map((coodebook: any) => {
          return { ...coodebook, dropdownValue: `${coodebook.code} - ${coodebook.name}` };
        });
        setCodebookOptionList(codebookOptionListWithDropdownLabel);
        setCodebookOptionListAll(codebookOptionListWithDropdownLabel);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  });

  return {
    codebookOptionList,
  };
}

export type { CodebookLogicalType };
