const apiBase = (window as any).REACT_APP_BACKEND_API_BASE;
const wsUrl = (window as any).REACT_APP_WS_API_BASE;
const url = apiBase ? apiBase : "http://" + window.location.hostname + ":8080/cris";
const api = url + "/api";

const Endpoint = {
  URL: url,
  WS_URL: wsUrl,
  AUTHENTICATE: url + "/web/authenticate",
  USER: api + "/user",
  LOGOUT: url + "/logOut",
  UPDATE_PASSWORD: url + "/updatePassword",
  FORM_FILLED_LIST: api + "/registry/formFilledList",
  FORM_FILLED_LIST_SEARCH: api + "/registry/formFilledList/search",
  UNFINISHED_FORM_FILLED_LIST: api + "/registry/unfinishedFormFilledList",
  FORM_LIST: api + "/registry/form/formList",
  FIELD_LIST: api + "/registry/form/fieldList",
  FIELD_TYPE_LIST: api + "/registry/fieldType/fieldTypeList",
  FORM_STATUS_LIST: api + "/codetable/formStatusList",
  ROLE_LIST: api + "/codetable/roleList",
  USER_RIGHT_LIST: api + "/codetable/rightList",
  USER_GROUP_LIST: api + "/userGroup/userGroupList",
  JURISDICTION_LIST: api + "/jurisdiction/jurisdictionList",
  USER_GROUP_ROLE_LIST: "/roleList",
  USER_GROUP_USER_LIST: "/userList",
  USER_GROUP_JURISDICTION_LIST: "/jurisdictionList",
  USER_LIST: api + "/user/userList",
  INSTITUTION_LIST: api + "/institution/institutionList",
  INSTITUTION_TYPE_LIST: api + "/codetable/institutionTypeList",
  FILE_LIST: api + "/file/fileList",
  FOR_REVISION_FORM_LIST: api + "/registry/forRevisionFormFilledList",
  COUNT_SUBMITTED_FORM_LIST: api + "/registry/countSubmittedFormFilledList",
  COUNT_FOR_REVISION_FORM_LIST: api + "/registry/countForRevisionFormFilledList",
  COMMENT_LIST: api + "/comment/commentList",
  COODEBOOK_TYPE_LIST: api + "/codebook/codebookType",
  COODEBOOK: api + "/codebook",
  CODEBOOK_SJZ: api + "/codebook/sjz",
  CODETABLE: api + "/codetable",
  LOG_ACTION_LIST: api + "/logAction/list",
  CODEBOOK_LOGIC_CONTROL_LIST: api + "/codebook/codebookLogicalControlList",
  CODEBOOK_LOGIC_CONTROL: api + "/codebook/codebookLogicalControl",
  USER_RIGHT_LIST_BY_FORM: api + "/registry/allUserRightListByForm",
  REPORT: api + "/report",
  LOG_TYPE_LIST: api + "/codetable/logTypeList",
  CLASS_NAME_LIST: api + "/codetable/classNameList",
  LABEL: api + "/label/labelList",
};

export default Endpoint;
