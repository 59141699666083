import axios from "axios";
import moment from "moment";
import { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FormController, FormControllerType } from "../../../controllers/form/FormController";
import EnumComponentType from "../../../infrastructure/system/EnumComponentType";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { DATE_TIME_FORMAT_3, handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import FieldReadDto from "../../../model/FieldReadDto";
import { AppContext } from "../../../Store";
import { confirmDialog } from "primereact/confirmdialog";
import { FileController, FileControllerType } from "../../../controllers/file/FileController";

interface MergeFormsLogicalType {
  breadCrumbItems: any;
  fieldListFirst: Array<FieldReadDto>;
  fieldValuesListFirst: any;
  fieldListSecond: Array<FieldReadDto>;
  fieldValuesListSecond: any;
  mergeCCFs: any;
  selection: any;
  fileListFirst: any;
  fileListSecond: any;
  searchFormStatus: any;
  searchFormType: any;
}

interface LocationType {
  selection: any;
  searchFormStatus: any;
  searchFormType: any;
}

export default function MergeFormsLogical(): MergeFormsLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const location = useLocation<LocationType>();
  const [selection] = useState(location.state ? location.state.selection : []);
  const { Labels } = LabelsContext();
  const history = useHistory();
  const [fieldValuesListFirst, setFieldValuesListFirst] = useState<any>({});
  const [fieldListFirst, setFieldListFirst] = useState<Array<FieldReadDto>>([]);
  const [fieldValuesListSecond, setFieldValuesListSecond] = useState<any>({});
  const [fieldListSecond, setFieldListSecond] = useState<Array<FieldReadDto>>([]);
  const [fileListFirst, setFileListFirst] = useState([]);
  const [fileListSecond, setFileListSecond] = useState([]);
  const [searchFormStatus] = useState(location.state && location.state.searchFormStatus !== null && location.state.searchFormStatus !== undefined ? location.state.searchFormStatus : null);
  const [searchFormType] = useState(location.state && location.state.searchFormType !== null && location.state.searchFormType !== undefined ? location.state.searchFormType : undefined);
  const [breadCrumbItems, setBreadCrumbItems] = useState<any>([
    {
      label: Labels.FORM_LIST,
      command: () => {
        history.push({
          pathname: `/registry`,
          state: { searchFormStatus: searchFormStatus, searchFormType: searchFormType },
        });
      },
    },
  ]);
  const { axiosGetFormFieldsList, axiosGetFieldFilledList, axiosDeleteFormFilled }: FormControllerType = FormController();
  const { axiosGetFileList }: FileControllerType = FileController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    if (selection[0] === undefined || selection[1] === undefined) {
      history?.push("/error");
      return;
    }
    if (selection[0].formCode !== selection[1].formCode) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_MERGE_DIFFERENT_FORMS, "");
      history.push("/registry");
    }
    setShowBlockUI(true);
    let fieldValuesFirst: any = {};
    let fieldValuesSecond: any = {};
    const requestFieldListFirst = axiosGetFormFieldsList(selection[0].formCode);
    const requestFieldFilledListFirst = axiosGetFieldFilledList(selection[0].id);
    const requestFieldListSecond = axiosGetFormFieldsList(selection[1].formCode);
    const requestFieldFilledListSecond = axiosGetFieldFilledList(selection[1].id);
    const requestFileListFirst = axiosGetFileList(selection[0].id);
    const requestFileListSecond = axiosGetFileList(selection[1].id);
    axios
      .all([requestFieldListFirst, requestFieldFilledListFirst, requestFieldListSecond, requestFieldFilledListSecond, requestFileListFirst, requestFileListSecond])
      .then(
        axios.spread((responseFieldListFirst, responseFieldFilledListFirst, responseFieldListSecond, responseFieldFilledListSecond, responseFileListFirst, responseFileListSecond) => {
          setFieldListFirst(responseFieldListFirst.data.data);
          responseFieldFilledListFirst.data.data.forEach((fieldFilled: any) => {
            if (fieldFilled.field.fieldType.componentEnumType === EnumComponentType.TEXT || fieldFilled.field.fieldType.componentEnumType === EnumComponentType.LONG_TEXT) {
              fieldValuesFirst[fieldFilled.field.id] = fieldFilled.valueString;
            } else if (fieldFilled.field.fieldType.componentEnumType === EnumComponentType.NUMBER_DECIMAL || fieldFilled.field.fieldType.componentEnumType === EnumComponentType.NUMBER_INTEGER) {
              fieldValuesFirst[fieldFilled.field.id] = fieldFilled.valueNumber;
            } else if (fieldFilled.field.fieldType.componentEnumType === EnumComponentType.DATE_TIME) {
              fieldValuesFirst[fieldFilled.field.id] = moment(fieldFilled.valueTimestamp).format(DATE_TIME_FORMAT_3);
            } else if (fieldFilled.field.fieldType.componentEnumType === EnumComponentType.BOOLEAN) {
              fieldValuesFirst[fieldFilled.field.id] = fieldFilled.valueBoolean;
            } else if (fieldFilled.field.fieldType.componentEnumType === EnumComponentType.SET) {
              fieldValuesFirst[fieldFilled.field.id] = fieldFilled.valueEnumeratorItem.id;
            } else if (fieldFilled.field.fieldType.componentEnumType === EnumComponentType.CODEBOOK || fieldFilled.field.fieldType.componentEnumType === EnumComponentType.CODEBOOK_AUTOCOMPLETE) {
              fieldValuesFirst[fieldFilled.field.id] = fieldFilled.valueCodebookCode;
            }
          });
          setFieldValuesListFirst(fieldValuesFirst);
          setFieldListSecond(responseFieldListSecond.data.data);
          responseFieldFilledListSecond.data.data.forEach((fieldFilled: any) => {
            if (fieldFilled.field.fieldType.componentEnumType === EnumComponentType.TEXT || fieldFilled.field.fieldType.componentEnumType === EnumComponentType.LONG_TEXT) {
              fieldValuesSecond[fieldFilled.field.id] = fieldFilled.valueString;
            } else if (fieldFilled.field.fieldType.componentEnumType === EnumComponentType.NUMBER_DECIMAL || fieldFilled.field.fieldType.componentEnumType === EnumComponentType.NUMBER_INTEGER) {
              fieldValuesSecond[fieldFilled.field.id] = fieldFilled.valueNumber;
            } else if (fieldFilled.field.fieldType.componentEnumType === EnumComponentType.DATE_TIME) {
              fieldValuesSecond[fieldFilled.field.id] = moment(fieldFilled.valueTimestamp).format(DATE_TIME_FORMAT_3);
            } else if (fieldFilled.field.fieldType.componentEnumType === EnumComponentType.BOOLEAN) {
              fieldValuesSecond[fieldFilled.field.id] = fieldFilled.valueBoolean;
            } else if (fieldFilled.field.fieldType.componentEnumType === EnumComponentType.SET) {
              fieldValuesSecond[fieldFilled.field.id] = fieldFilled.valueEnumeratorItem.id;
            } else if (fieldFilled.field.fieldType.componentEnumType === EnumComponentType.CODEBOOK || fieldFilled.field.fieldType.componentEnumType === EnumComponentType.CODEBOOK_AUTOCOMPLETE) {
              fieldValuesSecond[fieldFilled.field.id] = fieldFilled.valueCodebookCode;
            }
          });
          setFieldValuesListSecond(fieldValuesSecond);
          setFileListFirst(responseFileListFirst.data.data);
          setFileListSecond(responseFileListSecond.data.data);
          if (breadCrumbItems.length < 2) {
            setBreadCrumbItems([
              ...breadCrumbItems,
              {
                label: Labels.LABEL_MERGE_FORMS,
              },
            ]);
          }
          setShowBlockUI(false);
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const mergeCCFs = (formFilledId: number) => {
    confirmDialog({
      message: Labels.MESSAGES_ARE_YOU_SURE_YOU_WANT_TO_MERGE,
      header: "",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: Labels.BUTTON_YES,
      rejectLabel: Labels.BUTTON_NO,
      accept: () => {
        setShowBlockUI(true);
        axiosDeleteFormFilled(formFilledId)
          .then((res: any) => {
            showMessage(MessageType.SUCCESS, Labels.MESSAGES_MERGE_SUCCESS, "");
            setShowBlockUI(false);
            history.push({
              pathname: `/registry`,
              state: { searchFormStatus: searchFormStatus, searchFormType: searchFormType },
            });
          })
          .catch((error: any) => {
            handleAxiosCallError(showMessage, error);
            setShowBlockUI(false);
          });
      },
      reject: () => {},
    });
  };

  return {
    breadCrumbItems,
    fieldListFirst,
    fieldValuesListFirst,
    fieldListSecond,
    fieldValuesListSecond,
    mergeCCFs,
    selection,
    fileListFirst,
    fileListSecond,
    searchFormStatus,
    searchFormType,
  };
}

export type { MergeFormsLogicalType };
