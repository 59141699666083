import axios from "axios";
import moment from "moment";
import { useState, useContext, ChangeEvent } from "react";
import { useHistory } from "react-router";
import { ClassNameControllerType, ClassNameController } from "../../../controllers/class-name/ClassNameController";
import { LogActionController, LogActionControllerType } from "../../../controllers/log-action/LogActionController";
import { LogTypeControllerType, LogTypeController } from "../../../controllers/log-type/LogTypeController";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import { DATE_TIME_FORMAT, handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import SearchLogDataDto from "../../../model/SearchLogDataDto";
import { AppContext } from "../../../Store";

interface LogActionLogicalType {
  logActionList: any;
  onPage: any;
  first: any;
  tableRows: any;
  page: any;
  breadCrumbItems: any;
  selectedRow: any;
  setSelectedRow: any;
  setSelectedLogAction: any;
  totalRecords: any;
  getTime: any;
  changeSearchData: any;
  searchData: SearchLogDataDto;
  searchLogs: any;
  setSearchData: any;
  changeTextSearchData: any;
  classNames: any;
  logTypes: any;
  exportData: any;
  searchLogsByEnter: any;
}
export default function LogActionListLogical() {
  const [logActionList, setLogActionList] = useState([]);
  const { axiosSearchLogActionList, axiosCountSearchLogActionList }: LogActionControllerType = LogActionController();
  const { axiosGetClassNameList }: ClassNameControllerType = ClassNameController();
  const { axiosGetLogTypeList }: LogTypeControllerType = LogTypeController();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const [first, setFirst] = useState(0);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [tableRows, setTableRows] = useState(10);
  const [page, setPage] = useState(0);
  const [breadCrumbItems, setBreadCrumbItems] = useState<any>([]);
  const [logTypes, setLogTypes] = useState<any>([]);
  const [classNames, setClassNames] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<boolean>(false);
  const [setSelectedLogAction] = useState<any>();
  const { Labels } = LabelsContext();
  const [totalRecords, setTotalRecords] = useState<any>(0);
  const [searchData, setSearchData] = useState<SearchLogDataDto>({});
  const changeSearchData = (name: any, e: any) => {
    setSearchData({
      ...searchData,
      [name]: e.value ? e.value : null,
    });
  };
  const changeTextSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value ? e.target.value.trim() : null,
    });
  };

  const history = useHistory();
  useEffectOnce(() => {
    fetchData();
  });
  const fetchData = () => {
    const params = {
      page: page,
      size: tableRows,
    };
    axios
      .all([axiosCountSearchLogActionList(searchData), axiosSearchLogActionList(params), axiosGetClassNameList(), axiosGetLogTypeList()])
      .then(
        axios.spread((responseCount: any, responseLogActionList: any, responseClassNameList: any, responseLogTypeList: any) => {
          setLogActionList(responseLogActionList.data.data);
          setTotalRecords(responseCount.data.data);
          setClassNames(responseClassNameList.data);
          setLogTypes(responseLogTypeList.data);
          let exportList = new Array<any>();
          responseLogActionList.data.data.forEach((logAction: any) => {
            exportList.push({
              [Labels.OBJECT]: logAction.objectId,
              [Labels.FIELD_TYPE]: logAction.logType.description,
              [Labels.ACTION_TYPE]: logAction.className.name,
              [Labels.OLD_VALUE]: logAction.oldValue,
              [Labels.NEW_VALUE]: logAction.newValue,
              [Labels.PLACEHOLDER_USERNAME]: logAction.user.username,
              [Labels.INSERT_TIME]: getTime(logAction),
              [Labels.IP_ADDRESS]: logAction.ipAddress,
            });
          });
          setExportData(exportList);
          setBreadCrumbItems([
            {
              label: Labels.LABEL_LOG_LIST,
              command: () => {
                history.push("/logActionList");
              },
            },
          ]);
          setShowBlockUI(false);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };
  const getTime = (row: any) => {
    const time = row.insertTimestamp;
    return moment(time.toString()).format(DATE_TIME_FORMAT);
  };
  const onPage = (e: any) => {
    setTableRows(e.rows);
    setFirst(e.first);
    setPage(e.page);
    const params = {
      page: e.page,
      size: e.rows,
      ...searchData,
    };
    setShowBlockUI(true);
    axiosSearchLogActionList(params)
      .then((response: any) => {
        setLogActionList(response.data.data);
        let exportList = new Array<any>();
        response.data.data.forEach((logAction: any) => {
          exportList.push({
            [Labels.OBJECT]: logAction.objectId,
            [Labels.FIELD_TYPE]: logAction.logType.description,
            [Labels.ACTION_TYPE]: logAction.className.name,
            [Labels.OLD_VALUE]: logAction.oldValue,
            [Labels.NEW_VALUE]: logAction.newValue,
            [Labels.PLACEHOLDER_USERNAME]: logAction.user.username,
            [Labels.INSERT_TIME]: getTime(logAction),
            [Labels.IP_ADDRESS]: logAction.ipAddress,
          });
        });
        setExportData(exportList);
        setShowBlockUI(false);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };
  const searchLogs = () => {
    setShowBlockUI(true);
    const params = {
      page: page,
      size: tableRows,
      ...searchData,
    };
    axios
      .all([axiosCountSearchLogActionList(searchData), axiosSearchLogActionList(params)])
      .then(
        axios.spread((responseCount: any, responseSearch: any) => {
          setLogActionList(responseSearch.data.data);
          let exportList = new Array<any>();
          responseSearch.data.data.forEach((logAction: any) => {
            exportList.push({
              [Labels.OBJECT]: logAction.objectId,
              [Labels.FIELD_TYPE]: logAction.logType.description,
              [Labels.ACTION_TYPE]: logAction.className.name,
              [Labels.OLD_VALUE]: logAction.oldValue,
              [Labels.NEW_VALUE]: logAction.newValue,
              [Labels.PLACEHOLDER_USERNAME]: logAction.user.username,
              [Labels.INSERT_TIME]: getTime(logAction),
              [Labels.IP_ADDRESS]: logAction.ipAddress,
            });
          });
          setExportData(exportList);
          setTotalRecords(responseCount.data.data);
          setShowBlockUI(false);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };
  const searchLogsByEnter = (event: any) => {
    if (event.charCode === 13) {
      searchLogs();
    }
  };

  return {
    logActionList,
    onPage,
    first,
    tableRows,
    page,
    breadCrumbItems,
    selectedRow,
    setSelectedRow,
    setSelectedLogAction,
    totalRecords,
    getTime,
    searchData,
    changeSearchData,
    searchLogs,
    setSearchData,
    changeTextSearchData,
    classNames,
    logTypes,
    exportData,
    searchLogsByEnter,
  };
}

export type { LogActionLogicalType };
