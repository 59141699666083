import axios from "axios";
import { useContext, useRef, useState } from "react";
import { CodebookController, CodebookControllerType } from "../../../controllers/codebook/CodebookController";
import { InstitutionController, InstitutionControllerType } from "../../../controllers/institution/InstitutionController";
import { JurisdictionController, JurisdictionControllerType } from "../../../controllers/jurisdiction/JurisdictionController";
import { UserController, UserControllerType } from "../../../controllers/user/UserController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { DISTRICT, handleAxiosCallError, INSTITUTION, MUNICIPALITY, useEffectOnce } from "../../../infrastructure/system/Utils";
import InstitutionReadDto from "../../../model/institution/InstitutionReadDto";
import InstitutionTypeDto from "../../../model/InstitutionTypeDto";
import JurisdictionReadDto from "../../../model/JurisdictionReadDto";
import { AppContext } from "../../../Store";

interface InstitutionListLogicalType {
  institutionList: Array<InstitutionReadDto>;
  first: number;
  tableRows: number;
  selectedRow: any;
  setSelectedRow: any;
  selectedInstitution: any;
  setSelectedInstitution: any;
  dialogHeader: any;
  openDialog: any;
  displayDialog: boolean;
  entityOperation: any;
  closeDialog: any;
  dialogRef: any;
  institutionToChange: any;
  index: any;
  setIndex: any;
  deleteInstitution: any;
  createInstitution: any;
  updateInstitution: any;
  jurisdictionList: Array<JurisdictionReadDto>;
  districtList: Array<any>;
  municipalityList: Array<any>;
  institutionTypeList: Array<InstitutionTypeDto>;
  selectedInstitutionUserRow: any;
  setSelectedInstitutionUserRow: any;
  displayDialogCreateUserInstitution: any;
  closeDialogCreateUserInstitution: any;
  selectedUserRow: any;
  setSelectedUserRow: any;
  usersNotInInstitution: any;
  openDialogCreateUserInstitution: any;
  deleteUserInstitution: any;
  createUserInstitution: any;
  userDataTableFirst: number;
  userTableRows: number;
  institutionUserTableFirst: number;
  institutionUserTableRows: number;
  onPageInstitutionUser: any;
  onPageInstitution: any;
  onPageUserTable: any;
  breadCrumbItems: any;
  exportData: any;
  onSearchValueChange: any;
  institutionChange: any;
  setInstitutionChange: any;
  institutionCodebookList: any;
  selectedDistrict: any;
  selectedMunicipality: any;
  selectedInstitutionCodebook: any;
  setSelectedDistrict: any;
  setSelectedMunicipality: any;
  setSelectedInstitutionCodebook: any;
  searchUserData: any;
  setSearchUserData: any;
  searchUserList: any;
}

export default function InstitutionListLogical(): InstitutionListLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const { Labels } = LabelsContext();
  const [institutionList, setInstitutionList] = useState<Array<InstitutionReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [userDataTableFirst, setUserDataTableFirst] = useState(0);
  const [userTableRows, setUserTableRows] = useState(10);
  const [tableRows, setTableRows] = useState(10);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedInstitution, setSelectedInstitution] = useState<InstitutionReadDto | null>();
  const [entityOperation, setEntityOperation] = useState<any>();
  const [institutionToChange, setInstitutionToChange] = useState<InstitutionReadDto>();

  const [institutionChange, setInstitutionChange] = useState<InstitutionReadDto | undefined>(institutionToChange);
  const [displayDialog, setDisplayDialog] = useState(false);
  const dialogRef = useRef<any>();
  const [index, setIndex] = useState<number>(0);
  const [selectedUserRow, setSelectedUserRow] = useState<any>(null);
  const [jurisdictionList, setJurisdictionList] = useState<Array<JurisdictionReadDto>>([]);
  const [districtList, setDistrictList] = useState<Array<any>>([]);
  const [municipalityList, setMunicipalityList] = useState<Array<any>>([]);
  const [institutionCodebookList, setInstitutionCodebookList] = useState<Array<any>>([]);
  const [institutionTypeList, setInstitutionTypeList] = useState<Array<InstitutionTypeDto>>([]);
  const [institutionUserTableFirst, setInstitutionUserTableFirst] = useState(0);
  const [institutionUserTableRows, setInstitutionUserTableRows] = useState(10);
  const { axiosGetCodebookListWithCode }: CodebookControllerType = CodebookController();
  const {
    axiosCreateInstitution,
    axiosUpdateInstitution,
    axiosDeleteInstitution,
    axiosGetInstitutionList,
    axiosGetInstitutionTypeList,
    axiosDeleteUserInstitution,
    axiosCreateUserInstitution,
  }: InstitutionControllerType = InstitutionController();

  const { axiosGetJurisdictionList }: JurisdictionControllerType = JurisdictionController();
  const [selectedInstitutionUserRow, setSelectedInstitutionUserRow] = useState<any>(null);
  const [selectedDistrict, setSelectedDistrict] = useState<any>(null);
  const [selectedMunicipality, setSelectedMunicipality] = useState<any>(null);
  const [selectedInstitutionCodebook, setSelectedInstitutionCodebook] = useState<any>(null);
  const [displayDialogCreateUserInstitution, setDisplayDialogCreateUserInstitution] = useState(false);
  const [usersNotInInstitution, setUsersNotInInstitution] = useState<any>([]);
  const { axiosGetUserList, axiosGetSearchUserList }: UserControllerType = UserController();
  const [searchUserData, setSearchUserData] = useState<any>();
  const breadCrumbItems = [
    {
      label: Labels.INSTITUTION_LIST,
      command: () => {
        window.location.reload();
      },
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (idSelectedInstitution?: number) => {
    setShowBlockUI(true);
    const institutionList = axiosGetInstitutionList();
    const jurisdictionList = axiosGetJurisdictionList();

    const institutionTypeList = axiosGetInstitutionTypeList();
    const districtList = axiosGetCodebookListWithCode(DISTRICT);
    const municipalityList = axiosGetCodebookListWithCode(MUNICIPALITY);
    const institutionCodebookList = axiosGetCodebookListWithCode(INSTITUTION);
    axios
      .all([institutionList, jurisdictionList, institutionTypeList, districtList, municipalityList, institutionCodebookList])
      .then(
        axios.spread(
          (
            responseInstitutionList: any,
            responseJurisdictionList: any,
            responseInstitutionTypeList: any,
            responseDistrictList: any,
            responseMunicipalityList: any,
            responseInstitutionCodebookList: any
          ) => {
            setInstitutionList(responseInstitutionList.data.data);
            let exportList = new Array<any>();
            responseInstitutionList.data.data.forEach((institution: any) => {
              exportList.push({
                [Labels.LABEL_CODE]: institution.code,
                [Labels.LABEL_NAME]: institution.name,
                [Labels.INSTITUTION_TYPE]: institution.institutionType,
                [Labels.LABEL_DESCRIPTION]: institution.description,
              });
            });
            setExportData(exportList);
            setJurisdictionList(responseJurisdictionList.data.data);
            setMunicipalityList(responseMunicipalityList.data.data);
            setDistrictList(responseDistrictList.data.data);
            setInstitutionCodebookList(responseInstitutionCodebookList.data.data);
            setInstitutionTypeList(responseInstitutionTypeList.data);
            setShowBlockUI(false);
            if (idSelectedInstitution) {
              responseInstitutionList.data.data.forEach((institution: any) => {
                if (idSelectedInstitution === institution.id) {
                  setSelectedRow(institution);
                  setSelectedInstitution(institution);
                }
              });
            }
            getUsersNotExistsInUserInstitution();
          }
        )
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const closeDialogCreateUserInstitution = () => {
    setSearchUserData("");
    setDisplayDialogCreateUserInstitution(false);
  };

  const dialogHeader = (entityOperation: string) => {
    const display = selectedInstitution ? selectedInstitution.name : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.INSTITUTION_TITLE_DIALOG_CREATE;
      case EntityOperation.DELETE:
        return Labels.INSTITUTION_TITLE_DIALOG_DELETE + display;
      case EntityOperation.UPDATE:
        return Labels.INSTITUTION_TITLE_DIALOG_UPDATE + display;
      case EntityOperation.READ:
        return Labels.INSTITUTION_TITLE_DIALOG_DETAILS + display;
      default:
        return "";
    }
  };

  const openDialog = (entityOperation: String, selectedInstitution?: InstitutionReadDto) => {
    setIndex(0);
    let institution = undefined;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        institution = selectedInstitution;
        break;
    }
    municipalityList.forEach((element: any) => {
      if (element.id === selectedInstitution?.codeMunicipality) {
        setSelectedMunicipality(element);
      }
    });

    districtList.forEach((element: any) => {
      if (element.id === selectedInstitution?.codeDistrict) {
        setSelectedDistrict(element);
      }
    });

    institutionCodebookList.forEach((element: any) => {
      if (element.id === selectedInstitution?.code) {
        setSelectedInstitutionCodebook(element);
      }
    });
    setEntityOperation(entityOperation);
    setInstitutionToChange(institution);
    setInstitutionChange(institution);
    setDisplayDialog(true);
  };

  const closeDialog = () => {
    setSelectedMunicipality({});
    setSelectedDistrict({});
    setSelectedInstitutionCodebook({});
    setDisplayDialog(false);
    setIndex(0);
  };

  const validateInput = (institution: InstitutionReadDto) => {
    if (!institution || institution.name === undefined || institution?.name === "") {
      showMessage(MessageType.ERROR, Labels.MESSAGES_NAME_REQUIRED, "");
      return false;
    }
    if (institution.code === undefined || institution?.code === null) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_CODE_REQUIRED, "");
      return false;
    }
    if (institution.institutionType === undefined) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_INSTITUTION_TYPE_REQUIRED, "");
      return false;
    }
    if (institution.codeMunicipality === undefined) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_MUNICIPALITY_REQUIRED, "");
      return false;
    }
    if (institution.codeDistrict === undefined) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_DISTRICT_REQUIRED, "");
      return false;
    }
    if (institution.jurisdiction === undefined) {
      showMessage(MessageType.ERROR, Labels.MESSAGES_JURISDICTION_REQUIRED, "");
      return false;
    }
    if (!institution?.displayOrder) {
      showMessage(MessageType.ERROR, Labels.DISPLAY_ORDER_REQUIRED, "");
      return false;
    }

    return true;
  };

  const createInstitution = (institution: InstitutionReadDto) => {
    return new Promise((resolve, reject) => {
      if (!validateInput(institution)) {
        return;
      }
      setShowBlockUI(true);
      axiosCreateInstitution(institution)
        .then((response: any) => {
          showMessage(MessageType.SUCCESS, Labels.INSTITUTION_TITLE_MESSAGE_CREATE_INSTITUTION_SUCCESS, "");
          fetchData();
          setSelectedInstitution(response.data.data);
          setInstitutionToChange(response.data.data);
          setInstitutionChange(response.data.data);
          setIndex(1);
          setEntityOperation(EntityOperation.UPDATE);
          setShowBlockUI(false);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        });
    });
  };

  const updateInstitution = (institution: InstitutionReadDto) => {
    return new Promise((resolve, reject) => {
      if (!validateInput(institution)) {
        return;
      }
      setShowBlockUI(true);
      axiosUpdateInstitution(institution, institution.id)
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.INSTITUTION_TITLE_MESSAGE_UPDATE_INSTITUTION_SUCCESS, "");
          fetchData(response.data.data.id);
          setShowBlockUI(false);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        });
    });
  };

  const deleteInstitution = (institutionId: number) => {
    return new Promise((resolve, reject) => {
      axiosDeleteInstitution(institutionId)
        .then(() => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.INSTITUTION_TITLE_MESSAGE_DELETE_INSTITUTION_SUCCESS, "");
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const getUsersNotExistsInUserInstitution = () => {
    axiosGetUserList()
      .then((res: any) => {
        var listNotHave: any = [];
        const allUsers = res.data.data;
        allUsers.forEach((user: any) => {
          var isNotInUserInstitution = false;
          var listUsersHave = selectedInstitution?.userInstitutionList;
          listUsersHave?.forEach((userHave: any) => {
            if (userHave.user.id === user.id) {
              isNotInUserInstitution = true;
            }
          });
          if (isNotInUserInstitution === false) {
            listNotHave.push({ user: user, id: null });
          }
        });
        setUsersNotInInstitution(listNotHave);
        setShowBlockUI(false);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const openDialogCreateUserInstitution = () => {
    getUsersNotExistsInUserInstitution();
    setDisplayDialogCreateUserInstitution(true);
  };

  const deleteUserInstitution = (userInstitution: number) => {
    return new Promise((resolve, reject) => {
      axiosDeleteUserInstitution(selectedInstitution!.id, userInstitution)
        .then((res: any) => {
          setInstitutionToChange(res.data.data);
          fetchData(selectedInstitution!.id);
          showMessage(MessageType.SUCCESS, Labels.INSTITUTION_TITLE_MESSAGE_DELETE_USER_INSTITUTION_SUCCESS, "");
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const onPageInstitutionUser = (rows: any, first: any) => {
    setInstitutionUserTableRows(rows);
    setInstitutionUserTableFirst(first);
  };

  const createUserInstitution = (userInstitution: any) => {
    return new Promise((resolve, reject) => {
      axiosCreateUserInstitution(selectedInstitution!.id, userInstitution)
        .then((res: any) => {
          setSelectedInstitution(res.data.data);
          setInstitutionToChange(res.data.data);
          fetchData(selectedInstitution!.id);
          showMessage(MessageType.SUCCESS, Labels.INSTITUTION_TITLE_MESSAGE_CREATE_USER_INSTITUTION_SUCCESS, "");
          closeDialogCreateUserInstitution();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };
  const onPageInstitution = (rows: any, first: any) => {
    setTableRows(rows);
    setFirst(first);
  };

  const onPageUserTable = (rows: any, first: any) => {
    setUserTableRows(rows);
    setUserDataTableFirst(first);
  };

  const onSearchValueChange = (sortedData: Array<any>) => {
    const exportList = new Array<any>();
    sortedData?.forEach((institution: any) => {
      exportList.push({
        [Labels.LABEL_CODE]: institution.code,
        [Labels.LABEL_NAME]: institution.name,
        [Labels.INSTITUTION_TYPE]: institution.institutionType,
        [Labels.LABEL_DESCRIPTION]: institution.description,
      });
    });
    setExportData(exportList);
  };

  const searchUserList = (event: any) => {
    const params = {
      param: searchUserData,
    };
    if (event.charCode === 13) {
      axiosGetSearchUserList(params)
        .then((res: any) => {
          var listNotHave: any = [];
          const allUsers = res.data.data;
          allUsers.forEach((user: any) => {
            var isNotInInstitution = false;
            var listUsersHave = selectedInstitution?.userInstitutionList;
            listUsersHave?.forEach((userHave: any) => {
              if (userHave.user.id === user.id) {
                isNotInInstitution = true;
              }
            });
            if (isNotInInstitution === false) {
              listNotHave.push({ user: user, id: null });
            }
          });
          setUsersNotInInstitution(listNotHave);
          setShowBlockUI(false);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        });
    }
  };

  return {
    institutionList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    selectedInstitution,
    setSelectedInstitution,
    dialogHeader,
    openDialog,
    displayDialog,
    entityOperation,
    closeDialog,
    dialogRef,
    institutionToChange,
    index,
    setIndex,
    deleteInstitution,
    createInstitution,
    updateInstitution,
    jurisdictionList,
    districtList,
    municipalityList,
    institutionTypeList,
    selectedInstitutionUserRow,
    setSelectedInstitutionUserRow,
    displayDialogCreateUserInstitution,
    closeDialogCreateUserInstitution,
    selectedUserRow,
    setSelectedUserRow,
    usersNotInInstitution,
    openDialogCreateUserInstitution,
    deleteUserInstitution,
    createUserInstitution,
    userDataTableFirst,
    userTableRows,
    institutionUserTableFirst,
    institutionUserTableRows,
    onPageInstitutionUser,
    onPageInstitution,
    onPageUserTable,
    breadCrumbItems,
    exportData,
    onSearchValueChange,
    institutionChange,
    setInstitutionChange,
    institutionCodebookList,
    selectedDistrict,
    selectedMunicipality,
    selectedInstitutionCodebook,
    setSelectedDistrict,
    setSelectedMunicipality,
    setSelectedInstitutionCodebook,
    searchUserList,
    searchUserData,
    setSearchUserData,
  };
}

export type { InstitutionListLogicalType };
