import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import CrudUserGroup from "./CrudUserGroupView";
import UserGroupListLogical, { UserGroupListLogicalType } from "./UserGroupListLogical";
import { BreadCrumb } from "primereact/breadcrumb";
import ExportData from "../../app/export/ExportData";
import { useRef } from "react";
import { confirmDialog } from "primereact/confirmdialog";
import React from "react";

export default function UserGroupListPage() {
  const {
    userGroupList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    setSelectedUserGroup,
    dialogHeader,
    entityOperation,
    displayDialog,
    openDialog,
    selectedUserGroup,
    closeDialog,
    userGroupToChange,
    createUserGroup,
    dialogRef,
    updateUserGroup,
    deleteUserGroup,
    index,
    setIndex,
    userGroupRoleList,
    roleList,
    userGroupJurisdictionList,
    jurisdictionList,
    onChangeRole,
    onChangeJurisdiction,
    setSelectedUserGroupUserRow,
    selectedUserGroupUserRow,
    deleteUserGroupUser,
    openDialogCreateUserGroupUser,
    displayDialogCreateUserUserGroup,
    closeDialogCreateUserUserGroup,
    usersNotInUserGroup,
    createUserGroupUser,
    setSelectedUserRow,
    selectedUserRow,
    userDataTableFirst,
    userTableRows,
    userGroupUserTableFirst,
    userGroupUserTableRows,
    onPageUserGroupList,
    onPageUserGroupUser,
    onPageUserTable,
    breadCrumbItems,
    exportData,
    onSearchValueChange,
    searchUserData,
    setSearchUserData,
    searchUserList,
  }: UserGroupListLogicalType = UserGroupListLogical();

  const { Labels } = LabelsContext();
  const dt = useRef<any>(null);

  const leftContents = () => (
    <>
      <Button
        icon="pi pi-plus"
        className="p-button-success btn-margin-right"
        label={Labels.USER_GROUP_LIST_CREATE}
        tooltip={Labels.USER_GROUP_TITLE_DIALOG_CREATE}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          openDialog(EntityOperation.CREATE);
        }}
      />

      <Button
        icon="pi pi-pencil"
        className="p-button-warning btn-margin-right"
        disabled={!selectedUserGroup}
        tooltip={Labels.USER_GROUP_TITLE_DIALOG_UPDATE}
        tooltipOptions={{ position: "bottom" }}
        label={Labels.USER_GROUP_LIST_EDIT}
        onClick={() => {
          selectedUserGroup && openDialog(EntityOperation.UPDATE, selectedUserGroup);
        }}
      />
      <Button
        icon="pi pi-info-circle"
        label={Labels.USER_GROUP_LIST_DETAILS}
        tooltip={Labels.USER_GROUP_TITLE_DIALOG_DETAILS}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedUserGroup}
        onClick={() => {
          selectedUserGroup && openDialog(EntityOperation.READ, selectedUserGroup);
        }}
      />
    </>
  );

  const rightContents = () => (
    <>
      <ExportData data={exportData} name={Labels.USER_GROUP_LIST} dt={dt} />
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        label={Labels.USER_GROUP_LIST_DELETE}
        disabled={!selectedUserGroup}
        onClick={() => {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_USERGROUP,
            icon: "pi pi-power-off",
            accept: () => {
              deleteUserGroup(selectedUserGroup.id);
            },
          });
        }}
      />
    </>
  );

  const leftContentsDialog = () => (
    <>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.USER_GROUP_TITLE_DIALOG_CREATE}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && index === 0 && (
        <Button
          label={Labels.BUTTON_UPDATE}
          tooltip={Labels.USER_GROUP_TITLE_DIALOG_UPDATE}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            dialogRef.current.onUpdate();
          }}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          tooltip={Labels.USER_GROUP_TITLE_DIALOG_DELETE}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </>
  );

  const rightContentsDialog = () => <Button label={index === 0 ? Labels.BUTTON_CANCEL : Labels.BUTTON_CLOSE} icon="pi pi-times" onClick={closeDialog} />;

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  return (
    <div className="layout-user-group">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
        <Toolbar left={leftContents} right={rightContents} />
        <div>
          <DataTable
            ref={dt}
            value={userGroupList}
            paginator
            first={first}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            rows={tableRows}
            rowsPerPageOptions={[5, 10, 20]}
            onPage={(e) => {
              onPageUserGroupList(e.rows, e.first);
            }}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedUserGroup(e.data)}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            exportFilename={Labels.USER_GROUP_LIST}
            onValueChange={onSearchValueChange}
          >
            <Column field={"name"} header={Labels.HEADER_NAME} filter filterMatchMode="contains" sortable />
            <Column field={"code"} header={Labels.HEADER_CODE} filter filterMatchMode="contains" sortable />
            <Column field={"description"} header={Labels.HEADER_DESCRIPTION} filter filterMatchMode="contains" sortable />
          </DataTable>
        </div>
        <Dialog header={dialogHeader(entityOperation)} visible={displayDialog} onHide={closeDialog} style={{ width: "1000px" }} footer={dialogFooter()}>
          <CrudUserGroup
            userGroupOperation={entityOperation}
            userGroup={userGroupToChange}
            onCreateUserGroup={createUserGroup}
            dialogRef={dialogRef}
            onUpdateUserGroup={updateUserGroup}
            onDeleteUserGroup={deleteUserGroup}
            index={index}
            setIndex={setIndex}
            userGroupRoleList={userGroupRoleList}
            roleList={roleList}
            onChangeRole={onChangeRole}
            userGroupJurisdictionList={userGroupJurisdictionList}
            jurisdictionList={jurisdictionList}
            onChangeJurisdiction={onChangeJurisdiction}
            setSelectedUserGroupUserRow={setSelectedUserGroupUserRow}
            selectedUserGroupUserRow={selectedUserGroupUserRow}
            deleteUserGroupUser={deleteUserGroupUser}
            openDialogCreateUserGroupUser={openDialogCreateUserGroupUser}
            displayDialogCreateUserUserGroup={displayDialogCreateUserUserGroup}
            closeDialogCreateUserUserGroup={closeDialogCreateUserUserGroup}
            usersNotInUserGroup={usersNotInUserGroup}
            createUserGroupUser={createUserGroupUser}
            selectedUserRow={selectedUserRow}
            setSelectedUserRow={setSelectedUserRow}
            userDataTableFirst={userDataTableFirst}
            userTableRows={userTableRows}
            userGroupUserTableFirst={userGroupUserTableFirst}
            userGroupUserTableRows={userGroupUserTableRows}
            onPageUserGroupUser={onPageUserGroupUser}
            onPageUserTable={onPageUserTable}
            searchUserData={searchUserData}
            setSearchUserData={setSearchUserData}
            searchUserList={searchUserList}
          />
        </Dialog>
      </Panel>
    </div>
  );
}
