import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import ExportDataLogical, { ExportDataLogicalType } from "../../app/export/ExportDataLogical";
import CodebookLogical, { CodebookLogicalType } from "./CodebookLogical";

export default function CodebookList() {
  const {
    codebookList,
    first,
    tableRows,
    selectedRow,
    onPage,
    totalRecords,
    setSelectedRow,
    setSelectedCodebook,
    breadCrumbItems,
    searchData,
    changeSearchData,
    searchCodebookByEnter,
    searchCodebook,
    onSearchValueChange,
    codebookType,
    getAllCodeBook,
    getCodebookSjz,
    path,
  }: CodebookLogicalType = CodebookLogical();

  const { Labels } = LabelsContext();
  const dt = useRef<any>(null);
  const { exportPdf, exportXls, exportCsv, exportJson }: ExportDataLogicalType = ExportDataLogical();
  const menu = useRef<any>(null);
  const items = [
    {
      label: Labels.LABEL_PDF,
      icon: "pi pi-file-pdf",
      command: async (e: any) => {
        const data = await getAllCodeBook();
        exportPdf(data, codebookType);
      },
    },
    {
      label: Labels.LABEL_CSV,
      icon: "pi pi-file-o",
      command: async (e: any) => {
        const data = await getAllCodeBook();
        exportCsv(data, dt, codebookType);
      },
    },
    {
      label: Labels.LABEL_XLS,
      icon: "pi pi-file-excel",
      command: async (e: any) => {
        const data = await getAllCodeBook();
        exportXls(data, codebookType);
      },
    },
    {
      label: Labels.LABEL_JSON,
      icon: "pi pi-copy",
      command: async (e: any) => {
        const data = await getAllCodeBook();
        exportJson(data, codebookType);
      },
    },
  ];

  const leftContents = () => (
    <>
      {path !== Labels.LABEL_M1_PATH && (
        <Button
          icon="pi pi-upload"
          label={Labels.LABEL_IMPORT_CODEBOOK}
          tooltip={Labels.TOOLTIP_IMPORT_CODEBOOK}
          tooltipOptions={{ position: "bottom" }}
          onClick={getCodebookSjz}
          className="margin-left-10"
        />
      )}
    </>
  );

  const rightContents = () => (
    <>
      <div>
        <Menu model={items} popup ref={menu} id="popup_menu" />
        <Button
          label={Labels.LABEL_EXPORT}
          icon="pi pi-file"
          tooltip={Labels.DOWNLOAD_DATA_TABLE}
          tooltipOptions={{ position: "bottom" }}
          onClick={(event: any) => menu.current.toggle(event)}
          className="btn-margin-right"
          aria-controls="popup_menu"
          aria-haspopup
        />
      </div>
    </>
  );

  return (
    <div className="layout-form">
      <Panel>
        <div className="p-grid">
          <div className="p-col-3">
            <Card className="p-col-12 ">
              <span className="p-float-label">
                <InputText className="p-col-12 margin-bottom-20" id="name" name="name" value={searchData.name ? searchData.name : ""} onChange={changeSearchData} onKeyPress={searchCodebookByEnter} />
                <label htmlFor="firstname">{Labels.LABEL_NAME}</label>
              </span>
              <span className="p-float-label">
                <InputText className="p-col-12 margin-bottom-20" id="code" name="code" value={searchData.code ? searchData.code : ""} onChange={changeSearchData} onKeyPress={searchCodebookByEnter} />
                <label htmlFor="lastname">{Labels.LABEL_CODE}</label>
              </span>
              <Button label={Labels.USER_SEARCH} tooltip={Labels.SEARCH_USER} icon="pi pi-search" className="search-button" onClick={searchCodebook} />
            </Card>
          </div>
          <div className="p-col-9">
            <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
            <Toolbar left={leftContents} right={rightContents} />
            <div>
              <DataTable
                ref={dt}
                value={codebookList}
                paginator
                first={first}
                lazy
                totalRecords={totalRecords}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rows={tableRows}
                rowsPerPageOptions={[5, 10, 20]}
                onPage={(e) => {
                  onPage(e);
                }}
                selectionMode="single"
                selection={selectedRow}
                onSelectionChange={(e) => setSelectedRow(e.value)}
                onRowSelect={(e) => setSelectedCodebook(e.data)}
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                exportFilename={codebookType}
                onValueChange={onSearchValueChange}
              >
                <Column field={"code"} header={Labels.HEADER_CODE} />
                <Column field={"name"} header={Labels.HEADER_NAME} />
              </DataTable>
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
}
