import { useContext, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { FormController, FormControllerType } from "../../../../controllers/form/FormController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../../infrastructure/system/LabelsContext";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, useEffectOnce } from "../../../../infrastructure/system/Utils";
import { AppContext } from "../../../../Store";

interface FormSemanticValidationLogicalType {
  formSemanticValidationList: Array<any>;
  first: number;
  tableRows: number;
  selectedRow: any;
  setSelectedRow: any;
  setSelectedFormSemanticValidation: any;
  selectedFormSemanticValidation: any;
  entityOperation: any;
  history: any;
  onPageForm: any;
  exportData: any;
  dialogHeader: any;
  dialogRef: any;
  index: number;
  closeDialog: any;
  displayDialog: any;
  openDialog: any;
  setDisplayDialog: any;
  formSemanticValidationToChange: any;
  isDisabled: boolean;
  setFormSemanticValidationToChange: any;
  onCreate: any;
  onUpdate: any;
  onDelete: any;
  filterOperator: any;
  onOperatorFilterChange: any;
  onSearchValueChange: any;
  deleteFormSemanticValidation: any;
}

interface UseParamsType {
  formCode?: string;
}

export default function FormSemanticValidationLogical(): FormSemanticValidationLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const { Labels } = LabelsContext();
  const [formSemanticValidationList, setFormSemanticValidationList] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedFormSemanticValidation, setSelectedFormSemanticValidation] = useState<any | null>();
  const [entityOperation, setEntityOperation] = useState<any>();
  const dialogRef = useRef<any>();
  const [index, setIndex] = useState<number>(0);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [formSemanticValidationToChange, setFormSemanticValidationToChange] = useState<any>();
  const [filterOperator, setFilterOperator] = useState();
  const [isDisabled] = useState(false);
  const history = useHistory();
  const [exportData, setExportData] = useState<Array<any>>([]);
  const { formCode } = useParams<UseParamsType>();

  const { axiosGetFormSemanticValidation, axiosCreateFormSemanticValidation, axiosUpdateFormSemanticValidation, axiosDeleteFormSemanticValidation }: FormControllerType = FormController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (idSelectedFormSemanticValidation?: number) => {
    if (formCode) {
      setShowBlockUI(true);
      axiosGetFormSemanticValidation(formCode)
        .then((res: any) => {
          setFormSemanticValidationList(res.data.data);
          let exportList = new Array<any>();
          res.data.data.forEach((formSemanticValidation: any) => {
            exportList.push({
              [Labels.HEADER_FIELD]: formSemanticValidation.field.name,
              [Labels.HEADER_OPERATOR]: formSemanticValidation.operator,
              [Labels.HEADER_FIELD_TARGET]: formSemanticValidation.fieldTarget.name,
              [Labels.HEADER_CUSTOM_FORMULA]: formSemanticValidation.customFormula,
            });
          });
          setExportData(exportList);
          setShowBlockUI(false);
          if (idSelectedFormSemanticValidation) {
            res.data.data.forEach((formSemanticValidation: any) => {
              if (idSelectedFormSemanticValidation === formSemanticValidation.id) {
                setSelectedRow(formSemanticValidation);
                setSelectedFormSemanticValidation(formSemanticValidation);
              }
            });
          }
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        });
    }
  };

  const onCreate = () => {
    axiosCreateFormSemanticValidation(formCode, formSemanticValidationToChange)
      .then((response: any) => {
        setDisplayDialog(false);
        showMessage(MessageType.SUCCESS, Labels.MESSAGE_CREATE_SEMANTIC_VALIDATION_SUCCESS, "");
        fetchData(response.data.data.id);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onUpdate = () => {
    axiosUpdateFormSemanticValidation(formCode, formSemanticValidationToChange.id, formSemanticValidationToChange)
      .then((response: any) => {
        setDisplayDialog(false);
        showMessage(MessageType.SUCCESS, Labels.MESSAGE_UPDATE_SEMANTIC_VALIDATION_SUCCESS, "");
        fetchData(response.data.data.id);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onDelete = () => {
    return new Promise((resolve, reject) => {
      axiosDeleteFormSemanticValidation(formCode, formSemanticValidationToChange.id)
        .then(() => {
          setDisplayDialog(false);
          setSelectedFormSemanticValidation(undefined);
          setSelectedRow(undefined);
          showMessage(MessageType.SUCCESS, Labels.MESSAGE_DELETE_SEMANTIC_VALIDATION_SUCCESS, "");
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const deleteFormSemanticValidation = (formSemanticValidationId: any) => {
    axiosDeleteFormSemanticValidation(formCode, formSemanticValidationId)
      .then(() => {
        setDisplayDialog(false);
        setSelectedFormSemanticValidation(undefined);
        setSelectedRow(undefined);
        showMessage(MessageType.SUCCESS, Labels.MESSAGE_DELETE_SEMANTIC_VALIDATION_SUCCESS, "");
        fetchData();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onPageForm = (rows: any, first: any) => {
    setTableRows(rows);
    setFirst(first);
  };

  const onOperatorFilterChange = (event: any, dt: any) => {
    dt.current.filter(event.value, "operator", "equals");
    setFilterOperator(event.value);
  };

  const dialogHeader = (entityOperation: string) => {
    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.LABEL_CREATE_FORM_SEMANTIC_VALIDATION;
      case EntityOperation.DELETE:
        return Labels.LABEL_DELETE_FORM_SEMANTIC_VALIDATION;
      case EntityOperation.UPDATE:
        return Labels.LABEL_UPDATE_FORM_SEMANTIC_VALIDATION;
      case EntityOperation.READ:
        return Labels.LABEL_DETAILS_FORM_SEMANTIC_VALIDATION;
      default:
        return "";
    }
  };

  const closeDialog = () => {
    setIndex(0);
    setDisplayDialog(false);
  };

  const openDialog = (entityOperation: String) => {
    let sematicValidation = undefined;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        sematicValidation = selectedFormSemanticValidation;
        break;
    }

    setEntityOperation(entityOperation);

    if (sematicValidation) {
      setFormSemanticValidationToChange({
        id: sematicValidation.id,
        fieldId: sematicValidation.field.id,
        operator: sematicValidation.operator,
        fieldTargetId: sematicValidation.fieldTarget.id,
        customFormula: sematicValidation.customFormula,
      });
    } else {
      setFormSemanticValidationToChange(undefined);
    }
    setDisplayDialog(true);
  };

  const onSearchValueChange = (sortedData: Array<any>) => {
    let exportList = new Array<any>();
    sortedData?.forEach((formSemanticValidation: any) => {
      exportList.push({
        [Labels.HEADER_FIELD]: formSemanticValidation.field.name,
        [Labels.HEADER_OPERATOR]: formSemanticValidation.operator,
        [Labels.HEADER_FIELD_TARGET]: formSemanticValidation.fieldTarget.name,
        [Labels.HEADER_CUSTOM_FORMULA]: formSemanticValidation.customFormula,
      });
    });
    setExportData(exportList);
  }

  return {
    formSemanticValidationList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    setSelectedFormSemanticValidation,
    selectedFormSemanticValidation,
    entityOperation,
    history,
    onPageForm,
    dialogHeader,
    exportData,
    index,
    displayDialog,
    closeDialog,
    setDisplayDialog,
    dialogRef,
    openDialog,
    isDisabled,
    formSemanticValidationToChange,
    setFormSemanticValidationToChange,
    onCreate,
    onUpdate,
    onDelete,
    filterOperator,
    onOperatorFilterChange,
    onSearchValueChange,
    deleteFormSemanticValidation
  };
}

export type { FormSemanticValidationLogicalType };
