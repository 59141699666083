import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface JurisdictionControllerType {
  axiosGetJurisdictionList: any;
  axiosCreateJurisdiction: any;
  axiosUpdateJurisdiction: any;
  axiosDeleteJurisdiction: any;
}

export function JurisdictionController(): JurisdictionControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetJurisdictionList = () => {
    return axios.get(Endpoint.JURISDICTION_LIST, axiosConfig(authData!.token));
  };

  const axiosCreateJurisdiction = (jurisdiction: any) => {
    return axios.post(Endpoint.JURISDICTION_LIST, jurisdiction, axiosConfig(authData!.token));
  };

  const axiosUpdateJurisdiction = (jurisdiction: any) => {
    return axios.put(`${Endpoint.JURISDICTION_LIST}/${jurisdiction.id}`, jurisdiction, axiosConfig(authData!.token));
  };

  const axiosDeleteJurisdiction = (jurisdictionId: number) => {
    return axios.delete(`${Endpoint.JURISDICTION_LIST}/${jurisdictionId}`, axiosConfig(authData!.token));
  };

  return { axiosGetJurisdictionList, axiosCreateJurisdiction, axiosUpdateJurisdiction, axiosDeleteJurisdiction };
}

export type { JurisdictionControllerType };
