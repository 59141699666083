import { useContext, useMemo } from "react";
import { AppContext } from "../../Store";

export default function LabelsContext() {
  const { t } = useContext(AppContext);

  const Labels = useMemo(() => {
    return {
      LATIN: t("LATIN"),
      CYRILLIC: t("CYRILLIC"),
      NEW_LINE: t("NEW_LINE"),
      TAB: t("TAB"),
      SPACE: t("SPACE"),
      DASH: t("DASH"),
      SLASH: t("SLASH"),
      SPECIAL_CHAR_REQUIRED: t("SPECIAL_CHAR_REQUIRED"),
      APPLICATION_NAME: t("APPLICATION_NAME"),
      COPYRIGHT: t("COPYRIGHT"),
      HELIANT: t("HELIANT"),
      MEMBERSHIP: t("MEMBERSHIP"),
      LOGIN: t("LOGIN"),
      FORGOTTEN_PASSWORD_OR_USERNAME: t("FORGOTTEN_PASSWORD_OR_USERNAME"),
      TITLE_LOGIN: t("TITLE_LOGIN"),
      TITLE_RESET_PASSWORD: t("TITLE_RESET_PASSWORD"),
      MENU_FORMS: t("MENU_FORMS"),
      MENU_ADMIN: t("MENU_ADMIN"),
      MENU_REPORT: t("MENU_REPORT"),
      MENU_REPORT_PARAMETER: t("MENU_REPORT_PARAMETER"),
      BUTTON_CREATE: t("BUTTON_CREATE"),
      BUTTON_UPDATE: t("BUTTON_UPDATE"),
      BUTTON_DETAILS: t("BUTTON_DETAILS"),
      BUTTON_LOGIN: t("BUTTON_LOGIN"),
      BUTTON_LOGOUT: t("BUTTON_LOGOUT"),
      BUTTON_SAVE: t("BUTTON_SAVE"),
      BUTTON_CANCEL: t("BUTTON_CANCEL"),
      BUTTON_DELETE: t("BUTTON_DELETE"),
      BUTTON_UPLOAD_IMAGE: t("BUTTON_UPLOAD_IMAGE"),
      BUTTON_RESET_PASSWORD: t("BUTTON_RESET_PASSWORD"),
      BUTTON_NEW_FORM: t("BUTTON_NEW_FORM"),
      BUTTON_EDIT_DRAFT: t("BUTTON_EDIT_DRAFT"),
      BUTTON_EDIT: t("BUTTON_EDIT"),
      BUTTON_BACK: t("BUTTON_BACK"),
      BUTTON_ADD_USER: t("BUTTON_ADD_USER"),
      BUTTON_ADD: t("BUTTON_ADD"),
      BUTTON_YES: t("BUTTON_YES"),
      BUTTON_NO: t("BUTTON_NO"),
      BUTTON_CLOSE: t("BUTTON_CLOSE"),
      BUTTON_APPROVE: t("BUTTON_APPROVE"),
      BUTTON_REVISION: t("BUTTON_REVISION"),
      BUTTON_GENERATE_REPORT: t("BUTTON_GENERATE_REPORT"),
      WORNING_PASSWORD: t("WORNING_PASSWORD"),
      BUTTON_MERGE_CCF: t("BUTTON_MERGE_CCF"),
      TOOLTIP_MERGE_CCF: t("TOOLTIP_MERGE_CCF"),
      PLACEHOLDER_FIRST_NAME_REQUIRED: t("PLACEHOLDER_FIRST_NAME_REQUIRED"),
      PLACEHOLDER_LAST_NAME_REQUIRED: t("PLACEHOLDER_LAST_NAME_REQUIRED"),
      PLACEHOLDER_CHOSEN_NAME_REQUIRED: t("PLACEHOLDER_CHOSEN_NAME_REQUIRED"),
      PLACEHOLDER_USERNAME: t("PLACEHOLDER_USERNAME"),
      PLACEHOLDER_PASSWORD: t("PLACEHOLDER_PASSWORD"),
      PLACEHOLDER_EMAIL_REQUIRED: t("PLACEHOLDER_EMAIL_REQUIRED"),
      PLACEHOLDER_PASSWORD_REQUIRED: t("PLACEHOLDER_PASSWORD_REQUIRED"),
      PLACEHOLDER_PASSWORD_CONFIRM_REQUIRED: t("PLACEHOLDER_PASSWORD_CONFIRM_REQUIRED"),
      PLACEHOLDER_POST_COMMENT: t("PLACEHOLDER_POST_COMMENT"),
      PLACEHOLDER_SEARCH_BY_NAME: t("PLACEHOLDER_SEARCH_BY_NAME"),
      PLACEHOLDER_NEW_PASSWORD: t("PLACEHOLDER_NEW_PASSWORD"),
      PLACEHOLDER_NEW_PASSWORD_CONFIRM: t("PLACEHOLDER_NEW_PASSWORD_CONFIRM"),
      PLACEHOLDER_SEARCH_BY_STATUS: t("PLACEHOLDER_SEARCH_BY_STATUS"),
      PLACEHOLDER_INSTITUTION_TYPE: t("PLACEHOLDER_INSTITUTION_TYPE"),
      PLACEHOLDER_CHOOSE_STATUS: t("PLACEHOLDER_CHOOSE_STATUS"),
      PLACEHOLDER_CHOOSE_FORM_TYPE: t("PLACEHOLDER_CHOOSE_FORM_TYPE"),
      PLACEHOLDER_CHOOSEN_FORM_TYPE_LIST: t("PLACEHOLDER_CHOOSEN_FORM_TYPE_LIST"),
      PLACEHOLDER_CHOOSE_OPERATOR: t("PLACEHOLDER_CHOOSE_OPERATOR"),
      PLACEHOLDER_CHOOSE_PARAMETAR_TYPE: t("PLACEHOLDER_CHOOSE_PARAMETAR_TYPE"),
      PLACEHOLDER_INSERT_CODE_OR_NAME: t("PLACEHOLDER_INSERT_CODE_OR_NAME"),
      BUTTON_REPORT_OF_DEATH: t("BUTTON_REPORT_OF_DEATH"),
      CREATE_REPORT_OF_DEATH: t("CREATE_REPORT_OF_DEATH"),
      BADGE_TOOLTIP: t("BADGE_TOOLTIP"),
      CHECKBOX_REMEMBER_PASSWORD: t("CHECKBOX_REMEMBER_PASSWORD"),
      LABEL_MUNICIPALITY: t("LABEL_MUNICIPALITY"),
      LABEL_EVENT_STATUS: t("LABEL_EVENT_STATUS"),
      LABEL_DESCRIPTION: t("LABEL_DESCRIPTION"),
      LABEL_NOTE: t("LABEL_NOTE"),
      LABEL_EMAIL: t("LABEL_EMAIL"),
      LABEL_FIRSTNAME: t("LABEL_FIRSTNAME"),
      LABEL_LASTNAME: t("LABEL_LASTNAME"),
      LABEL_CHOSEN_NAME: t("LABEL_CHOSEN_NAME"),
      LABEL_DELETE: t("LABEL_DELETE"),
      LABEL_POST: t("LABEL_POST"),
      LABEL_BEFORE: t("LABEL_BEFORE"),
      LABEL_MINUTES: t("LABEL_MINUTES"),
      LABEL_HOURS: t("LABEL_HOURS"),
      LABEL_EDIT: t("LABEL_EDIT"),
      LABEL_USER_PROFILE: t("LABEL_USER_PROFILE"),
      LABEL_DISPLAY_ORDER: t("LABEL_DISPLAY_ORDER"),
      LABEL_ARTICLE_INSERT_TIMESTAMP: t("LABEL_ARTICLE_INSERT_TIMESTAMP"),
      LABEL_HEIGHT_CM: t("LABEL_HEIGHT_CM"),
      LABEL_WEIGHT_KG: t("LABEL_WEIGHT_KG"),
      LABEL_BACK_ON_LOGIN: t("LABEL_BACK_ON_LOGIN"),
      LABEL_TABLE_OF_FORMS: t("LABEL_TABLE_OF_FORMS"),
      LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED: t("LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED"),
      LABEL_ARE_YOU_SURE_YOU_WANT_TO_BACK: t("LABEL_ARE_YOU_SURE_YOU_WANT_TO_BACK"),
      LABEL_ARE_YOU_SURE_YOU_WANT_TO_GIVE_UP: t("LABEL_ARE_YOU_SURE_YOU_WANT_TO_GIVE_UP"),
      LABEL_FORM_LIST: t("LABEL_FORM_LIST"),
      LABEL_CODE: t("LABEL_CODE"),
      LABEL_NAME: t("LABEL_NAME"),
      LABEL_UPDATE_FORM: t("LABEL_UPDATE_FORM"),
      LABEL_DELETE_FORM: t("LABEL_DELETE_FORM"),
      LABEL_DETAILS_FORM: t("LABEL_DETAILS_FORM"),
      LABEL_CREATE_FORM: t("LABEL_CREATE_FORM"),
      LABEL_UPDATE_FIELD_TYPE: t("LABEL_UPDATE_FIELD_TYPE"),
      LABEL_DELETE_FIELD_TYPE: t("LABEL_DELETE_FIELD_TYPE"),
      LABEL_DETAILS_FIELD_TYPE: t("LABEL_DETAILS_FIELD_TYPE"),
      LABEL_CREATE_FIELD_TYPE: t("LABEL_CREATE_FIELD_TYPE"),
      LABEL: t("LABEL"),
      LABEL_CREATE_LABEL: t("LABEL_CREATE_LABEL"),
      LABEL_TRANSLATE_LABEL: t("LABEL_TRANSLATE_LABEL"),
      LABEL_CREATE_REPORT: t("LABEL_CREATE_REPORT"),
      LABEL_UPDATE_REPORT: t("LABEL_UPDATE_REPORT"),
      LABEL_DELETE_REPORT: t("LABEL_DELETE_REPORT"),
      LABEL_DETAILS_REPORT: t("LABEL_DETAILS_REPORT"),
      LABEL_CREATE_REPORT_PARAMETER: t("LABEL_CREATE_REPORT_PARAMETER"),
      LABEL_UPDATE_REPORT_PARAMETER: t("LABEL_UPDATE_REPORT_PARAMETER"),
      LABEL_DELETE_REPORT_PARAMETER: t("LABEL_DELETE_REPORT_PARAMETER"),
      LABEL_DETAILS_REPORT_PARAMETER: t("LABEL_DETAILS_REPORT_PARAMETER"),
      LABEL_FIELD_TYPE: t("LABEL_FIELD_TYPE"),
      LABEL_CREATE: t("LABEL_CREATE"),
      LABEL_DETAILS: t("LABEL_DETAILS"),
      LABEL_NAME_SHORT: t("LABEL_NAME_SHORT"),
      LABEL_DISTRICT: t("LABEL_DISTRICT"),
      LABEL_JURISDICTION: t("LABEL_JURISDICTION"),
      LABEL_ADDRESS: t("LABEL_ADDRESS"),
      LABEL_UNIT: t("LABEL_UNIT"),
      LABEL_USER: t("LABEL_USER"),
      LABEL_INSTITUTION_LIST: t("LABEL_INSTITUTION_LIST"),
      LABEL_USER_INSTITUTION_LIST: t("LABEL_USER_INSTITUTION_LIST"),
      LABEL_USER_GROUP_USER_LIST: t("LABEL_USER_GROUP_USER_LIST"),
      LABEL_TEXT: t("LABEL_TEXT"),
      LABEL_LONG_TEXT: t("LABEL_LONG_TEXT"),
      LABEL_INTEGER: t("LABEL_INTEGER"),
      LABEL_FLOAT: t("LABEL_FLOAT"),
      LABEL_DATE_TIME: t("LABEL_DATE_TIME"),
      LABEL_SET: t("LABEL_SET"),
      LABEL_NUMBER: t("LABEL_NUMBER"),
      LABEL_LABEL: t("LABEL_LABEL"),
      LABEL_BOOLEAN: t("LABEL_BOOLEAN"),
      LABEL_PANEL: t("LABEL_PANEL"),
      LABEL_CODEBOOK: t("LABEL_CODEBOOK"),
      LABEL_VALUE_NUMBER: t("LABEL_VALUE_NUMBER"),
      LABEL_UPLOADED_ATTACHMENTS: t("LABEL_UPLOADED_ATTACHMENTS"),
      LABEL_NO_UPLOADED_ATTACHMENTS: t("LABEL_NO_UPLOADED_ATTACHMENTS"),
      LABEL_UPLOADED: t("LABEL_UPLOADED"),
      LABEL_ATTACHMENTS: t("LABEL_ATTACHMENTS"),
      LABEL_UPLOAD: t("LABEL_UPLOAD"),
      LABEL_CHOOSE: t("LABEL_CHOOSE"),
      LABEL_REMOVE_DOCUMENT: t("LABEL_REMOVE_DOCUMENT"),
      LABEL_APPROVE: t("LABEL_APPROVE"),
      LABEL_APPROVE_FIRST_CCF: t("LABEL_APPROVE_FIRST_CCF"),
      LABEL_APPROVE_SECOND_CCF: t("LABEL_APPROVE_SECOND_CCF"),
      LABLE_COMMENTS: t("LABLE_COMMENTS"),
      LABEL_LOGICAL_CONTROL: t("LOGICAL_CONTROL"),
      LABEL_MERGE_FORMS: t("LABEL_MERGE_FORMS"),
      LABEL_REQUEST_NAME: t("LABEL_REQUEST_NAME"),
      LABEL_FILE_FORMAT: t("LABEL_FILE_FORMAT"),
      LABEL_REPORT_GENERATED: t("LABEL_REPORT_GENERATED"),
      LABEL_GENERATE_REPORT: t("LABEL_GENERATE_REPORT"),
      LABEL_CHOOSE_REPORT: t("LABEL_CHOOSE_REPORT"),
      LABEL_PARAMETER: t("LABEL_PARAMETER"),
      LABEL_CUSTOM_QUERY: t("LABEL_CUSTOM_QUERY"),
      LABEL_REPORT_PARAMETER_TYPE: t("LABEL_REPORT_PARAMETER_TYPE"),
      LABEL_PARENT_FIELD: t("LABEL_PARENT_FIELD"),
      HOME_PAGE: t("HOME_PAGE"),
      LABEL_ACTIONS: t("LABEL_ACTIONS"),
      HEADER_PUBLIC: t("HEADER_PUBLIC"),
      LABEL_PUBLIC_REPORTS: t("LABEL_PUBLIC_REPORTS"),
      LABEL_DOWNLOAD_IN_FORMAT: t("LABEL_DOWNLOAD_IN_FORMAT"),
      REPORT_PARAMETERS: t("REPORT_PARAMETERS"),
      COMMENT_IS_REQUIRED: t("COMMENT_IS_REQUIRED"),
      TAB_ARTICLE_SUMMARY: t("TAB_ARTICLE_SUMMARY"),
      TAB_ARTICLE_CONTENT: t("TAB_ARTICLE_CONTENT"),
      TAB_ARTICLE_PREVIEW_SUMMARY: t("TAB_ARTICLE_PREVIEW_SUMMARY"),
      TAB_ARTICLE_PREVIEW_CONTENT: t("TAB_ARTICLE_PREVIEW_CONTENT"),
      TABLE_EMPTY_MESSAGE: t("TABLE_EMPTY_MESSAGE"),
      COLUMN_TITLE_DISTANCE: t("COLUMN_TITLE_DISTANCE"),
      COLUMN_HEADER_NAME: t("COLUMN_HEADER_NAME"),
      COLUMN_HEADER_DESCRIPTION: t("COLUMN_HEADER_DESCRIPTION"),
      COLUMN_HEADER_STATUS: t("COLUMN_HEADER_STATUS"),
      HEADER_NAME: t("HEADER_NAME"),
      HEADER_CODE: t("HEADER_CODE"),
      HEADER_DESCRIPTION: t("HEADER_DESCRIPTION"),
      HEADER_CATEGORY: t("HEADER_CATEGORY"),
      HEADER_JASPER_LOCATION: t("HEADER_JASPER_LOCATION"),
      HEADER_FILE_FORMAT: t("HEADER_FILE_FORMAT"),
      HEADER_DISPLAY_ORDER: t("HEADER_DISPLAY_ORDER"),
      HEADER_NOTE: t("HEADER_NOTE"),
      HEADER_FIELD: t("HEADER_FIELD"),
      HEADER_OPERATOR: t("HEADER_OPERATOR"),
      HEADER_CUSTOM_FORMULA: t("HEADER_CUSTOM_FORMULA"),
      HEADER_FIRST_VALUE: t("HEADER_FIRST_VALUE"),
      HEADER_SECOND_VALUE: t("HEADER_SECOND_VALUE"),
      HEADER_FIRST_COODEBOOK: t("HEADER_FIRST_COODEBOOK"),
      HEADER_SECOND_COODEBOOK: t("HEADER_SECOND_COODEBOOK"),
      MESSAGES_FIRSTNAME_FIELD: t("MESSAGES_FIRSTNAME_FIELD"),
      MESSAGES_LASTNAME_FIELD: t("MESSAGES_LASTNAME_FIELD"),
      MESSAGES_CHOSEN_NAME_FIELD: t("MESSAGES_CHOSEN_NAME_FIELD"),
      MESSAGES_EMAIL_FIELD: t("MESSAGES_EMAIL_FIELD"),
      MESSAGES_USERNAME_FIELD: t("MESSAGES_USERNAME_FIELD"),
      MESSAGES_PASSWORD_FIELD: t("MESSAGES_PASSWORD_FIELD"),
      MESSAGES_PASSWORD_CONFIRM_FIELD: t("MESSAGES_PASSWORD_CONFIRM_FIELD"),
      MESSAGES_PASSWORD_LENGTH: t("MESSAGES_PASSWORD_LENGTH"),
      MESSAGES_NOT_EQUAL_PASSWORD: t("MESSAGES_NOT_EQUAL_PASSWORD"),
      MESSAGES_UPLOAD_SIZE_50KB_ERROR: t("MESSAGES_UPLOAD_SIZE_50KB_ERROR"),
      MESSAGES_CHOOSE_IMAGE: t("MESSAGES_CHOOSE_IMAGE"),
      MESSAGES_FILE_SIZE_ERROR: t("MESSAGES_FILE_SIZE_ERROR"),
      MESSAGES_UPLOAD_IMAGE_ERROR: t("MESSAGES_UPLOAD_IMAGE_ERROR"),
      MESSAGES_REGISTRATION_RESEND: t("MESSAGES_REGISTRATION_RESEND"),
      MESSAGES_MERGE_DIFFERENT_FORMS: t("MESSAGES_MERGE_DIFFERENT_FORMS"),
      MESSAGES_MERGE_SUCCESS: t("MESSAGES_MERGE_SUCCESS"),
      MESSAGES_ARE_YOU_SURE_YOU_WANT_TO_MERGE: t("MESSAGES_ARE_YOU_SURE_YOU_WANT_TO_MERGE"),
      MESSAGES_DELETE_REPORT_GENERATED: t("MESSAGES_DELETE_REPORT_GENERATED"),
      MESSAGES_REPORT_GENERATE_GENERATE_SUCCESS: t("MESSAGES_REPORT_GENERATE_GENERATE_SUCCESS"),
      MESSAGES_REPORT_GENERATE_DELETE_SUCCESS: t("MESSAGES_REPORT_GENERATE_DELETE_SUCCESS"),
      LABEL_EXPORT: t("LABEL_EXPORT"),
      LABEL_PDF: t("LABEL_PDF"),
      LABEL_XLS: t("LABEL_XLS"),
      LABEL_CSV: t("LABEL_CSV"),
      LABEL_JSON: t("LABEL_JSON"),
      LABEL_STATUS: t("LABEL_STATUS"),
      LABEL_FORM_TYPE: t("LABEL_FORM_TYPE"),
      FORM_STATUS: t("FORM_STATUS"),
      DOWNLOAD_DATA_TABLE: t("DOWNLOAD_DATA_TABLE"),
      MESSAGES_NO_RESULT_FOUND: t("MESSAGES_NO_RESULT_FOUND"),
      MESSAGES_NEW_PASSWORD_FIELD: t("MESSAGES_NEW_PASSWORD_FIELD"),
      MESSAGES_NEW_PASSWORD_CONFIRM_FIELD: t("MESSAGES_NEW_PASSWORD_CONFIRM_FIELD"),
      MESSAGES_CODE_REQUIRED: t("MESSAGES_CODE_REQUIRED"),
      MESSAGES_NAME_REQUIRED: t("MESSAGES_NAME_REQUIRED"),
      MESSAGES_INSTITUTION_TYPE_REQUIRED: t("MESSAGES_INSTITUTION_TYPE_REQUIRED"),
      MESSAGES_MUNICIPALITY_REQUIRED: t("MESSAGES_MUNICIPALITY_REQUIRED"),
      MESSAGES_DISTRICT_REQUIRED: t("MESSAGES_DISTRICT_REQUIRED"),
      MESSAGES_JURISDICTION_REQUIRED: t("MESSAGES_JURISDICTION_REQUIRED"),
      MESSAGES_DELETE_DOCUMENT_SUCCESS: t("MESSAGES_DELETE_DOCUMENT_SUCCESS"),
      MESSAGES_APPROVE_DOCUMENT_SUCCESS: t("MESSAGES_APPROVE_DOCUMENT_SUCCESS"),
      MESSAGES_FOR_REVISION_DOCUMENT_SUCCESS: t("MESSAGES_FOR_REVISION_DOCUMENT_SUCCESS"),
      MESSAGES_INVALID_FILE_SIZE: t("MESSAGES_INVALID_FILE_SIZE"),
      MESSAGE_SEND_BACK_TO_REVISION: t("MESSAGE_SEND_BACK_TO_REVISION"),
      MESSAGE_APPROVE_FORM: t("MESSAGE_APPROVE_FORM"),
      MESSAGE_RESULT_LIST_IS_EMPTY: t("MESSAGE_RESULT_LIST_IS_EMPTY"),
      USER_PROFILE_CHANGE: t("USER_PROFILE_CHANGE"),
      USER_PROFILE_CHANGE_EMAIL: t("USER_PROFILE_CHANGE_EMAIL"),
      USER_PROFILE_CHANGE_NAME: t("USER_PROFILE_CHANGE_NAME"),
      USER_PROFILE_CHANGE_SURNAME: t("USER_PROFILE_CHANGE_SURNAME"),
      USER_PROFILE_CHANGE_LANGUANGE: t("USER_PROFILE_CHANGE_LANGUANGE"),
      USER_PROFILE_BUTTON_SAVE: t("USER_PROFILE_BUTTON_SAVE"),
      USER_PROFILE_BUTTON_CHANGE_PASSWORD: t("USER_PROFILE_BUTTON_CHANGE_PASSWORD"),
      USER_PROFILE_CHANGE_IMAGE: t("USER_PROFILE_CHANGE_IMAGE"),
      USER_PROFILE_BUTTON_CANCEL: t("USER_PROFILE_BUTTON_CANCEL"),
      USER_PROFILE_CHANGE_PASSWORD: t("USER_PROFILE_CHANGE_PASSWORD"),
      USER_PROFILE_PASSWORD_CHANGE_ERROR: t("USER_PROFILE_PASSWORD_CHANGE_ERROR"),
      USER_PROFILE_MESSAGES_NEW_PASSWORD: t("USER_PROFILE_MESSAGES_NEW_PASSWORD"),
      USER_PROFILE_MESSAGES_OLD_PASSWORD: t("USER_PROFILE_MESSAGES_OLD_PASSWORD"),
      USER_PROFILE_MESSAGES_PASSWORD_CONFIRMATION: t("USER_PROFILE_MESSAGES_PASSWORD_CONFIRMATION"),
      USER_PROFILE_MESSAGES_PASSWORDS_NOT_SAME: t("USER_PROFILE_MESSAGES_PASSWORDS_NOT_SAME"),
      USER_PROFILE_BUTTON_SAVE_NEW_PASSWORD: t("USER_PROFILE_BUTTON_SAVE_NEW_PASSWORD"),
      USER_PROFILE_OLD_PASSWORD: t("USER_PROFILE_OLD_PASSWORD"),
      USER_PROFILE_NEW_PASSWORD: t("USER_PROFILE_NEW_PASSWORD"),
      USER_PROFILE_CONFIRM_NEW_PASSWORD: t("USER_PROFILE_CONFIRM_NEW_PASSWORD"),
      USER_PROFILE_EDIT_SUCCESS: t("USER_PROFILE_EDIT_SUCCESS"),
      USER_PROFILE_CHANGE_PASSWORD_SUCCESS: t("USER_PROFILE_CHANGE_PASSWORD_SUCCESS"),
      PAGINATION_SHOWING_TO_OF_ENTRIES: t("PAGINATION_SHOWING_TO_OF_ENTRIES"),
      NEW_FORM_HEALTH_INSTITUTION: t("NEW_FORM_HEALTH_INSTITUTION"),
      NEW_FORM_TITLE_PANEL_CREATE_FORM: t("NEW_FORM_TITLE_PANEL_CREATE_FORM"),
      NEW_FORM_SAVE: t("NEW_FORM_SAVE"),
      NEW_FORM_SAVE_AS_DRAFT: t("NEW_FORM_SAVE_AS_DRAFT"),
      NEW_FORM_CANCEL: t("NEW_FORM_CANCEL"),
      FORM_SUCCESS_SAVE: t("FORM_SUCCESS_SAVE"),
      FORM_FILLED_SUCCESS_DELETED: t("FORM_FILLED_SUCCESS_DELETED"),
      FORM_FAILED_SAVE: t("FORM_FAILED_SAVE"),
      FORM: t("FORM"),
      FORM_FIELDS: t("FORM_FIELDS"),
      SEMANTIC_VALIDATION: t("SEMANTIC_VALIDATION"),
      FIELD_DROPDOWN_CHOOSE: t("FIELD_DROPDOWN_CHOOSE"),
      OPERATOR_FIELD_DROPDOWN_CHOOSE: t("OPERATOR_FIELD_DROPDOWN_CHOOSE"),
      INSTITUTION_DROPDOWN_CHOOSE: t("INSTITUTION_DROPDOWN_CHOOSE"),
      CODEBOOK_OPTION_DROPDOWN_CHOOSE: t("CODEBOOK_OPTION_DROPDOWN_CHOOSE"),
      FILE_FORMAT_DROPDOWN_CHOOSE: t("FILE_FORMAT_DROPDOWN_CHOOSE"),
      CATEGORY_DROPDOWN_CHOOSE: t("CATEGORY_DROPDOWN_CHOOSE"),
      CONTAIS: t("CONTAIS"),
      NOT_CONTAIS: t("NOT_CONTAIS"),
      START_WITH: t("START_WITH"),
      END_WITH: t("END_WITH"),
      CHOOSE: t("CHOOSE"),
      NOT_CHOOSE: t("NOT_CHOOSE"),
      ERROR_PAGE_404: t("ERROR_PAGE_404"),
      ERROR_PAGE_PAGE_NOT_FOUND: t("ERROR_PAGE_PAGE_NOT_FOUND"),
      ERROR_PAGE_HOME_BUTTON: t("ERROR_PAGE_HOME_BUTTON"),
      USER_GROUP: t("USER_GROUP"),
      USER_GROUP_ROLES: t("USER_GROUP_ROLES"),
      USER_GROUP_LIST: t("USER_GROUP_LIST"),
      USER_GROUP_LIST_CODE: t("USER_GROUP_LIST_CODE"),
      USER_GROUP_LIST_NAME: t("USER_GROUP_LIST_NAME"),
      USER_GROUP_LIST_DESCRIPTION: t("USER_GROUP_LIST_DESCRIPTION"),
      USER_GROUP_LIST_NOTE: t("USER_GROUP_LIST_NOTE"),
      USER_GROUP_LIST_CREATE: t("USER_GROUP_LIST_CREATE"),
      USER_GROUP_LIST_EDIT: t("USER_GROUP_LIST_EDIT"),
      USER_GROUP_LIST_DETAILS: t("USER_GROUP_LIST_DETAILS"),
      USER_GROUP_LIST_DELETE: t("USER_GROUP_LIST_DELETE"),
      USER_GROUP_TITLE_DIALOG_CREATE: t("USER_GROUP_TITLE_DIALOG_CREATE"),
      USER_GROUP_TITLE_DIALOG_UPDATE: t("USER_GROUP_TITLE_DIALOG_UPDATE"),
      USER_GROUP_TITLE_DIALOG_DELETE: t("USER_GROUP_TITLE_DIALOG_DELETE"),
      USER_GROUP_TITLE_DIALOG_DETAILS: t("USER_GROUP_TITLE_DIALOG_DETAILS"),
      USER_GROUP_TITLE_MESSAGE_CREATE_USER_GROUP_SUCCESS: t("USER_GROUP_TITLE_MESSAGE_CREATE_USER_GROUP_SUCCESS"),
      USER_GROUP_TITLE_MESSAGE_UPDATE_USER_GROUP_SUCCESS: t("USER_GROUP_TITLE_MESSAGE_UPDATE_USER_GROUP_SUCCESS"),
      USER_GROUP_TITLE_MESSAGE_DELETE_USER_GROUP_SUCCESS: t("USER_GROUP_TITLE_MESSAGE_DELETE_USER_GROUP_SUCCESS"),
      USER_GROUP_TITLE_MESSAGE_DELETE_USER_GROUP_USER_SUCCESS: t("USER_GROUP_TITLE_MESSAGE_DELETE_USER_GROUP_USER_SUCCESS"),
      USER_GROUP_TITLE_MESSAGE_CREATE_USER_GROUP_USER_SUCCESS: t("USER_GROUP_TITLE_MESSAGE_CREATE_USER_GROUP_USER_SUCCESS"),
      FORM_LIST: t("FORM_LIST"),
      REPORT_LIST: t("REPORT_LIST"),
      LABEL_COODEBOOK_TYPE_LIST: t("LABEL_COODEBOOK_TYPE_LIST"),
      LABEL_COODEBOOK_LIST: t("LABEL_COODEBOOK_LIST"),
      FORM_TITLE_DIALOG_CREATE: t("FORM_TITLE_DIALOG_CREATE"),
      FORM_TITLE_DIALOG_UPDATE: t("FORM_TITLE_DIALOG_UPDATE"),
      FORM_TITLE_DIALOG_DELETE: t("FORM_TITLE_DIALOG_DELETE"),
      FORM_TITLE_DIALOG_DETAILS: t("FORM_TITLE_DIALOG_DETAILS"),
      TITLE_MESSAGE_CREATE_FORM_SUCCESS: t("TITLE_MESSAGE_CREATE_FORM_SUCCESS"),
      TITLE_MESSAGE_UPDATE_FORM_SUCCESS: t("TITLE_MESSAGE_UPDATE_FORM_SUCCESS"),
      TITLE_MESSAGE_DELETE_FORM_SUCCESS: t("TITLE_MESSAGE_DELETE_FORM_SUCCESS"),
      JURISDICTIONS: t("JURISDICTIONS"),
      USER_GROUP_JURISDICTION: t("USER_GROUP_JURISDICTION"),
      JURISDICTION: t("JURISDICTION"),
      JURISDICTION_CODE: t("JURISDICTION_CODE"),
      JURISDICTION_NAME: t("JURISDICTION_NAME"),
      JURISDICTION_DESCRIPTION: t("JURISDICTION_DESCRIPTION"),
      JURISDICTION_NOTE: t("JURISDICTION_NOTE"),
      JURISDICTION_LIST_CREATE: t("JURISDICTION_LIST_CREATE"),
      JURISDICTION_LIST_EDIT: t("JURISDICTION_LIST_EDIT"),
      JURISDICTION_LIST_DETAILS: t("JURISDICTION_LIST_DETAILS"),
      JURISDICTION_LIST_DELETE: t("JURISDICTION_LIST_DELETE"),
      JURISDICTION_TITLE_DIALOG_CREATE: t("JURISDICTION_TITLE_DIALOG_CREATE"),
      JURISDICTION_TITLE_DIALOG_UPDATE: t("JURISDICTION_TITLE_DIALOG_UPDATE"),
      JURISDICTION_TITLE_DIALOG_DELETE: t("JURISDICTION_TITLE_DIALOG_DELETE"),
      JURISDICTION_TITLE_DIALOG_DETAILS: t("JURISDICTION_TITLE_DIALOG_DETAILS"),
      JURISDICTION_DISPLAY_ORDER: t("JURISDICTION_DISPLAY_ORDER"),
      JURISDICTION_TITLE_MESSAGE_CREATE_USER_GROUP_SUCCESS: t("JURISDICTION_TITLE_MESSAGE_CREATE_USER_GROUP_SUCCESS"),
      JURISDICTION_TITLE_MESSAGE_UPDATE_USER_GROUP_SUCCESS: t("JURISDICTION_TITLE_MESSAGE_UPDATE_USER_GROUP_SUCCESS"),
      JURISDICTION_TITLE_MESSAGE_DELETE_USER_GROUP_SUCCESS: t("JURISDICTION_TITLE_MESSAGE_DELETE_USER_GROUP_SUCCESS"),
      JURISDICTION_CODE_IS_REQUIRED: t("JURISDICTION_CODE_IS_REQUIRED"),
      JURISDICTION_NAME_IS_REQUIRED: t("JURISDICTION_NAME_IS_REQUIRED"),
      USER_LIST: t("USER_LIST"),
      USER_FIRST_NAME: t("USER_FIRST_NAME"),
      USER_CODE: t("USER_CODE"),
      USER_LAST_NAME: t("USER_LAST_NAME"),
      USER_USER_NAME: t("USER_USER_NAME"),
      USER_EMAIL: t("USER_EMAIL"),
      USER_INSTITUTION: t("USER_INSTITUTION"),
      USER_PASSWORD: t("USER_PASSWORD"),
      USER_PASSWORD_CONFIRM: t("USER_PASSWORD_CONFIRM"),
      USER_SEARCH: t("USER_SEARCH"),
      USER_LIST_CREATE: t("USER_LIST_CREATE"),
      USER_LIST_EDIT: t("USER_LIST_EDIT"),
      USER_LIST_DETAILS: t("USER_LIST_DETAILS"),
      USER_LIST_DELETE: t("USER_LIST_DELETE"),
      USER_TITLE_DIALOG_CREATE: t("USER_TITLE_DIALOG_CREATE"),
      USER_TITLE_DIALOG_UPDATE: t("USER_TITLE_DIALOG_UPDATE"),
      USER_TITLE_DIALOG_DELETE: t("USER_TITLE_DIALOG_DELETE"),
      USER_TITLE_DIALOG_DETAILS: t("USER_TITLE_DIALOG_DETAILS"),
      USER_PICK_INSTITUTION: t("USER_PICK_INSTITUTION"),
      USER_TITLE_MESSAGE_DELETE_USER_SUCCESS: t("USER_TITLE_MESSAGE_DELETE_USER_SUCCESS"),
      USER_TITLE_MESSAGE_CREATE_USER_SUCCESS: t("USER_TITLE_MESSAGE_CREATE_USER_SUCCESS"),
      USER_TITLE_MESSAGE_UPDATE_USER_SUCCESS: t("USER_TITLE_MESSAGE_UPDATE_USER_SUCCESS"),
      USER_USERNAME_IS_REQUIRED: t("USER_USERNAME_IS_REQUIRED"),
      USER_EMAIL_IS_REQUIRED: t("USER_EMAIL_IS_REQUIRED"),
      USER_PASSWORD_DEFAULT: t("USER_PASSWORD_DEFAULT"),
      INSTITUTION_LIST: t("INSTITUTION_LIST"),
      INSTITUTION_TITLE_DIALOG_CREATE: t("INSTITUTION_TITLE_DIALOG_CREATE"),
      INSTITUTION_TITLE_DIALOG_UPDATE: t("INSTITUTION_TITLE_DIALOG_UPDATE"),
      INSTITUTION_TITLE_DIALOG_DETAILS: t("INSTITUTION_TITLE_DIALOG_DETAILS"),
      INSTITUTION_TITLE_DIALOG_DELETE: t("INSTITUTION_TITLE_DIALOG_DELETE"),
      INSTITUTION_TITLE_MESSAGE_DELETE_INSTITUTION_SUCCESS: t("INSTITUTION_TITLE_MESSAGE_DELETE_INSTITUTION_SUCCESS"),
      INSTITUTION_TITLE_MESSAGE_CREATE_INSTITUTION_SUCCESS: t("INSTITUTION_TITLE_MESSAGE_CREATE_INSTITUTION_SUCCESS"),
      INSTITUTION_TITLE_MESSAGE_UPDATE_INSTITUTION_SUCCESS: t("INSTITUTION_TITLE_MESSAGE_UPDATE_INSTITUTION_SUCCESS"),
      INSTITUTION_NAME_IS_REQUIRED: t("INSTITUTION_NAME_IS_REQUIRED"),
      INSTITUTION_CODE_IS_REQUIRED: t("INSTITUTION_CODE_IS_REQUIRED"),
      INSTITUTION_LIST_CREATE: t("INSTITUTION_LIST_CREATE"),
      INSTITUTION_LIST_EDIT: t("INSTITUTION_LIST_EDIT"),
      INSTITUTION_LIST_DETAILS: t("INSTITUTION_LIST_DETAILS"),
      INSTITUTION_LIST_DELETE: t("INSTITUTION_LIST_DELETE"),
      INSTITUTION_SEARCH: t("INSTITUTION_SEARCH"),
      INSTITUTION_TYPE: t("INSTITUTION_TYPE"),
      INSTITUTION_WS_USERNAME: t("INSTITUTION_WS_USERNAME"),
      INSTITUTION_WS_PASSWORD: t("INSTITUTION_WS_PASSWORD"),
      INSTITUTION_GPS_LOCATION: t("INSTITUTION_GPS_LOCATION"),
      FIELD_TITLE_DIALOG_CREATE: t("FIELD_TITLE_DIALOG_CREATE"),
      FIELD_TITLE_DIALOG_UPDATE: t("FIELD_TITLE_DIALOG_UPDATE"),
      FIELD_TITLE_DIALOG_DELETE: t("FIELD_TITLE_DIALOG_DELETE"),
      FIELD_TITLE_DIALOG_DETAILS: t("FIELD_TITLE_DIALOG_DETAILS"),
      CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_CREATE: t("CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_CREATE"),
      CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_UPDATE: t("CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_UPDATE"),
      CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DETAILS: t("CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DETAILS"),
      CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DELETE: t("CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DELETE"),
      CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DELETE_SUCCESS: t("CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DELETE_SUCCESS"),
      CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_CREATE_SUCCESS: t("CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_CREATE_SUCCESS"),
      CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_UPDATE_SUCCESS: t("CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_UPDATE_SUCCESS"),
      LABEL_UPDATE_FORM_SEMANTIC_VALIDATION: t("LABEL_UPDATE_FORM_SEMANTIC_VALIDATION"),
      LABEL_DELETE_FORM_SEMANTIC_VALIDATION: t("LABEL_DELETE_FORM_SEMANTIC_VALIDATION"),
      LABEL_DETAILS_FORM_SEMANTIC_VALIDATION: t("LABEL_DETAILS_FORM_SEMANTIC_VALIDATION"),
      LABEL_CREATE_FORM_SEMANTIC_VALIDATION: t("LABEL_CREATE_FORM_SEMANTIC_VALIDATION"),
      LABEL_DELETE_REPORT_ACCESS: t("LABEL_DELETE_REPORT_ACCESS"),
      LABEL_ADD_REPORT_ACCESS: t("LABEL_ADD_REPORT_ACCESS"),
      LABEL_UPDATE_FORM_FIELD: t("LABEL_UPDATE_FORM_FIELD"),
      LABEL_DELETE_FORM_FIELD: t("LABEL_DELETE_FORM_FIELD"),
      LABEL_DETAILS_FORM_FIELD: t("LABEL_DETAILS_FORM_FIELD"),
      LABEL_CHOOSE_TWO_CCF: t("LABEL_CHOOSE_TWO_CCF"),
      IS_REQUIRED_FIELD: t("IS_REQUIRED_FIELD"),
      IS_RELEVANT_FIELD: t("IS_RELEVANT_FIELD"),
      IS_MULTISELECT_FIELD: t("IS_MULTISELECT_FIELD"),
      IS_DISABLED_FIELD: t("IS_DISABLED_FIELD"),
      MULTISELECT: t("MULTISELECT"),
      EQUATION_SHOW_LABEL_ON_FORM: t("EQUATION_SHOW_LABEL_ON_FORM"),
      EQUATION_SHOW_FIELD_ON_FORM: t("EQUATION_SHOW_FIELD_ON_FORM"),
      EQUATION_CALCULATED_VALUE: t("EQUATION_CALCULATED_VALUE"),
      HTML_INFO: t("HTML_INFO"),
      TITLE_MESSAGE_DELETE_FIELD_SUCCESS: t("TITLE_MESSAGE_DELETE_FIELD_SUCCESS"),
      TITLE_MESSAGE_UPDATE_FIELD_SUCCESS: t("TITLE_MESSAGE_UPDATE_FIELD_SUCCESS"),
      TITLE_MESSAGE_CREATE_FIELD_SUCCESS: t("TITLE_MESSAGE_CREATE_FIELD_SUCCESS"),
      FIELD_CODE_IS_REQUIRED: t("FIELD_CODE_IS_REQUIRED"),
      FIELD_NAME_IS_REQUIRED: t("FIELD_NAME_IS_REQUIRED"),
      INITIAL_VALUE: t("INITIAL_VALUE"),
      FORM_FILLED_EDIT_DRAFT: t("FORM_FILLED_EDIT_DRAFT"),
      FORM_FILLED_SAVE: t("FORM_FILLED_SAVE"),
      FORM_FILLED_SAVE_AS_DRAFT: t("FORM_FILLED_SAVE_AS_DRAFT"),
      FORM_FILLED_CANCEL: t("FORM_FILLED_CANCEL"),
      ROLES: t("ROLES"),
      FIELD_IS_REQUIRED: t("FIELD_IS_REQUIRED"),
      VALUE_SHOULD_BE_BETWEEN: t("VALUE_SHOULD_BE_BETWEEN"),
      VALUE_SHOULD_BE_GREATER_THEN: t("VALUE_SHOULD_BE_GREATER_THEN"),
      VALUE_SHOULD_BE_LESS_THEN: t("VALUE_SHOULD_BE_LESS_THEN"),
      IT_SHOULD_BE_MORE_THEN: t("IT_SHOULD_BE_MORE_THEN"),
      FIELD_SHOULD_HAVE: t("FIELD_SHOULD_HAVE"),
      IT_SHOULD_BE_LESS_THEN: t("IT_SHOULD_BE_LESS_THEN"),
      IT_SHOULD_BE_BETWEEN: t("IT_SHOULD_BE_BETWEEN"),
      CHARACTERS: t("CHARACTERS"),
      FIELD_TYPES: t("FIELD_TYPES"),
      FIELD_TYPE_MIN: t("FIELD_TYPE_MIN"),
      FIELD_TYPE_MAX: t("FIELD_TYPE_MAX"),
      FIELD_TYPE_FORMAT: t("FIELD_TYPE_FORMAT"),
      FIELD_TYPE_CREATE: t("FIELD_TYPE_CREATE"),
      TITLE_MESSAGE_CREATE_FIELD_TYPE_SUCCESS: t("TITLE_MESSAGE_CREATE_FIELD_TYPE_SUCCESS"),
      TITLE_MESSAGE_UPDATE_FIELD_TYPE_SUCCESS: t("TITLE_MESSAGE_UPDATE_FIELD_TYPE_SUCCESS"),
      TITLE_MESSAGE_DELETE_FIELD_TYPE_SUCCESS: t("TITLE_MESSAGE_DELETE_FIELD_TYPE_SUCCESS"),
      FIELD_TYPE: t("FIELD_TYPE"),
      FIELD_TYPE_DECIMALS: t("FIELD_TYPE_DECIMALS"),
      FIELD_TYPE_ENUM: t("FIELD_TYPE_ENUM"),
      FIELD_TYPE_COMPONENT_TYPE: t("FIELD_TYPE_COMPONENT_TYPE"),
      FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_CREATE: t("FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_CREATE"),
      FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_UPDATE: t("FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_UPDATE"),
      FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_DELETE: t("FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_DELETE"),
      FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_DETAILS: t("FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_DETAILS"),
      FIELD_TYPE_ENUMERATOR_ITEM_CODE_IS_REQUIRED: t("FIELD_TYPE_ENUMERATOR_ITEM_CODE_IS_REQUIRED"),
      FIELD_TYPE_ENUMERATOR_ITEM_NAME_IS_REQUIRED: t("FIELD_TYPE_ENUMERATOR_ITEM_NAME_IS_REQUIRED"),
      FIELD_TYPE_ENUMERATOR_ITEM_TITLE_MESSAGE_CREATE_USER_GROUP_SUCCESS: t("FIELD_TYPE_ENUMERATOR_ITEM_TITLE_MESSAGE_CREATE_USER_GROUP_SUCCESS"),
      FIELD_TYPE_ENUMERATOR_ITEM_TITLE_MESSAGE_UPDATE_USER_GROUP_SUCCESS: t("FIELD_TYPE_ENUMERATOR_ITEM_TITLE_MESSAGE_UPDATE_USER_GROUP_SUCCESS"),
      FIELD_TYPE_ENUMERATOR_ITEM_TITLE_MESSAGE_DELETE_USER_GROUP_SUCCESS: t("FIELD_TYPE_ENUMERATOR_ITEM_TITLE_MESSAGE_DELETE_USER_GROUP_SUCCESS"),
      INSTITUTION: t("INSTITUTION"),
      INSTITUTION_TITLE_MESSAGE_DELETE_USER_INSTITUTION_SUCCESS: t("INSTITUTION_TITLE_MESSAGE_DELETE_USER_INSTITUTION_SUCCESS"),
      INSTITUTION_TITLE_MESSAGE_CREATE_USER_INSTITUTION_SUCCESS: t("INSTITUTION_TITLE_MESSAGE_CREATE_USER_INSTITUTION_SUCCESS"),
      HEADER_CREATE_FORM: t("HEADER_CREATE_FORM"),
      HEADER_UPDATE_FORM_FILLED: t("HEADER_UPDATE_FORM_FILLED"),
      HEADER_DELETE_FORM_FILLED: t("HEADER_DELETE_FORM_FILLED"),
      HEADER_READ_FORM_FILLED: t("HEADER_READ_FORM_FILLED"),
      SEARCH_FORM: t("SEARCH_FORM"),
      SEARCH_USER: t("SEARCH_USER"),
      ADD_SEARCH_PARAMETER: t("ADD_SEARCH_PARAMETER"),
      SAVE_FORM: t("SAVE_FORM"),
      SAVE_FORM_AS_DRAFT: t("SAVE_FORM_AS_DRAFT"),
      APPROVE_FORM: t("APPROVE_FORM"),
      REVISION_FORM: t("REVISION_FORM"),
      CANCEL_FORM: t("CANCEL_FORM"),
      LABEL_DIALOG_MESSAGE_DELETE_USER_FROM_USER_GROUP: t("LABEL_DIALOG_MESSAGE_DELETE_USER_FROM_USER_GROUP"),
      LABEL_DIALOG_MESSAGE_DELETE_USER_FROM_INSTITUTION: t("LABEL_DIALOG_MESSAGE_DELETE_USER_FROM_INSTITUTION"),
      LABEL_DIALOG_MESSAGE_LEAVE_PAGE: t("LABEL_DIALOG_MESSAGE_LEAVE_PAGE"),
      SAVE_USER_DATA: t("SAVE_USER_DATA"),
      CANCEL_USER_DATA: t("CANCEL_USER_DATA"),
      CODEBOOK_TITLE_CREATE: t("CODEBOOK_TITLE_CREATE"),
      CODEBOOK_TITLE_UPDATE: t("CODEBOOK_TITLE_UPDATE"),
      CODEBOOK_TITLE_DETAILS: t("CODEBOOK_TITLE_DETAILS"),
      LOGICAL_CONTROL_TITLE: t("LOGICAL_CONTROL_TITLE"),
      CODEBOOK_TITLE_DELETE: t("CODEBOOK_TITLE_DELETE"),
      CODEBOOK_TYPE: t("CODEBOOK_TYPE"),
      CODEBOOK_OPTION: t("CODEBOOK_OPTION"),
      CODEBOOK_AUTOCOMPLETE: t("CODEBOOK_AUTOCOMPLETE"),
      LABEL_LOG_LIST: t("LABEL_LOG_LIST"),
      INSERT_TIME: t("INSERT_TIME"),
      IP_ADDRESS: t("IP_ADDRESS"),
      OLD_VALUE: t("OLD_VALUE"),
      NEW_VALUE: t("NEW_VALUE"),
      ACTION_TYPE: t("ACTION_TYPE"),
      CLASS: t("CLASS"),
      OBJECT: t("OBJECT"),
      GREATER: t("GREATER"),
      LESS: t("LESS"),
      EQUAL: t("EQUAL"),
      NOT_EQUAL: t("NOT_EQUAL"),
      RANGE: t("RANGE"),
      HEADER_FIELD_TARGET: t("HEADER_FIELD_TARGET"),
      SEARCH_LOGS: t("SEARCH_LOGS"),
      MESSAGE_CREATE_SEMANTIC_VALIDATION_SUCCESS: t("MESSAGE_CREATE_SEMANTIC_VALIDATION_SUCCESS"),
      MESSAGE_UPDATE_SEMANTIC_VALIDATION_SUCCESS: t("MESSAGE_UPDATE_SEMANTIC_VALIDATION_SUCCESS"),
      MESSAGE_DELETE_SEMANTIC_VALIDATION_SUCCESS: t("MESSAGE_DELETE_SEMANTIC_VALIDATION_SUCCESS"),
      MESSAGE_CREATE_REPORT_PARAMETER_SUCCESS: t("MESSAGE_CREATE_REPORT_PARAMETER_SUCCESS"),
      MESSAGE_CREATE_LABEL_SUCCESS: t("MESSAGE_CREATE_LABEL_SUCCESS"),
      MESSAGE_UPDATE_LABEL_SUCCESS: t("MESSAGE_UPDATE_LABEL_SUCCESS"),
      MESSAGE_UPDATE_REPORT_PARAMETER_SUCCESS: t("MESSAGE_UPDATE_REPORT_PARAMETER_SUCCESS"),
      MESSAGE_DELETE_REPORT_PARAMETER_SUCCESS: t("MESSAGE_DELETE_REPORT_PARAMETER_SUCCESS"),
      LABEL_CREATE_FORM_FIELD: t("LABEL_CREATE_FORM_FIELD"),
      INSERT_TIME_FROM: t("INSERT_TIME_FROM"),
      INSERT_TIME_TO: t("INSERT_TIME_TO"),
      USER_RIGHT: t("USER_RIGHT"),
      MESSAGE_CREATE_REPORT_ACCESS_SUCCESS: t("MESSAGE_CREATE_REPORT_ACCESS_SUCCESS"),
      MESSAGE_DELETE_REPORT_ACCESS_SUCCESS: t("MESSAGE_DELETE_REPORT_ACCESS_SUCCESS"),

      USER_RIGHT_CREATE: t("USER_RIGHT_CREATE"),
      USER_RIGHT_READ: t("USER_RIGHT_READ"),
      USER_RIGHT_UPDATE: t("USER_RIGHT_UPDATE"),
      USER_RIGHT_DELETE: t("USER_RIGHT_DELETE"),
      USER_RIGHT_VISIBILITY: t("USER_RIGHT_VISIBILITY"),
      USER_RIGHT_OVERVIEW: t("USER_RIGHT_OVERVIEW"),
      CODEBOOKS: t("CODEBOOKS"),
      CODEBOOK_TYPES: t("CODEBOOK_TYPES"),
      LOGICAL_CONTROLS: t("LOGICAL_CONTROLS"),
      FORM_SEMANTIC_VALIDATION_LIST: t("FORM_SEMANTIC_VALIDATION_LIST"),

      REPORT: t("REPORT"),
      REPORT_TITLE_DIALOG_CREATE: t("REPORT_TITLE_DIALOG_CREATE"),
      REPORT_TITLE_DIALOG_UPDATE: t("REPORT_TITLE_DIALOG_UPDATE"),
      REPORT_TITLE_DIALOG_DELETE: t("REPORT_TITLE_DIALOG_DELETE"),
      REPORT_TITLE_DIALOG_DETAILS: t("REPORT_TITLE_DIALOG_DETAILS"),
      TITLE_MESSAGE_CREATE_REPORT_SUCCESS: t("TITLE_MESSAGE_CREATE_REPORT_SUCCESS"),
      TITLE_MESSAGE_UPDATE_REPORT_SUCCESS: t("TITLE_MESSAGE_UPDATE_REPORT_SUCCESS"),
      TITLE_MESSAGE_DELETE_REPORT_SUCCESS: t("TITLE_MESSAGE_DELETE_REPORT_SUCCESS"),
      REPORT_PARAMETER: t("REPORT_PARAMETER"),
      REPORT_ACCESS: t("REPORT_ACCESS"),
      REPORT_PARAMETER_TYPE: t("REPORT_PARAMETER_TYPE"),
      DISPLAY_ORDER_REQUIRED: t("DISPLAY_ORDER_REQUIRED"),
      CUSTOM_QUERY_EXAMPLE: t("CUSTOM_QUERY_EXAMPLE"),
      LABEL_TEST_QUERY: t("LABEL_TEST_QUERY"),
      LABEL_ADD_QUERY: t("LABEL_ADD_QUERY"),
      LABEL_SUBMITTED: t("LABEL_SUBMITTED"),
      LABEL_APPROVED: t("LABEL_APPROVED"),
      LABEL_FOR_REVISION: t("LABEL_FOR_REVISION"),
      LABEL_UNFINISHED: t("LABEL_UNFINISHED"),
      TOOLTIP_RETURN: t("TOOLTIP_RETURN"),
      TOOLTIP_ADD_COMMENT: t("TOOLTIP_ADD_COMMENT"),
      HEADER_FORM_REVISION: t("HEADER_FORM_REVISION"),
      MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_USERGROUP: t("MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_USERGROUP"),
      MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_JURISDICTION: t("MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_JURISDICTION"),
      MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_USER: t("MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_USER"),
      MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_INSTITUTION: t("MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_INSTITUTION"),
      MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FORM: t("MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FORM"),
      MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_SEMANTIC_VALIDATION: t("MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_SEMANTIC_VALIDATION"),
      MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FORM_FIELD: t("MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FORM_FIELD"),
      MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_TYPE: t("MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_TYPE"),
      MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_TYPE_ENUMERATOR_ITEM: t("MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_TYPE_ENUMERATOR_ITEM"),
      MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_TYPE_LOGICAL_CONTROL: t("MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_TYPE_LOGICAL_CONTROL"),
      MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPORT: t("MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPORT"),
      MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPORT_PARAMETER: t("MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPORT_PARAMETER"),
      MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPORT_ACCESS: t("MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPORT_ACCESS"),
      LABEL_IMPORT_CODEBOOK: t("LABEL_IMPORT_CODEBOOK"),
      TOOLTIP_IMPORT_CODEBOOK: t("TOOLTIP_IMPORT_CODEBOOK"),
      LABEL_M1_PATH: t("LABEL_M1_PATH"),
      REPORT_GENERATE_TIME: t("REPORT_GENERATE_TIME"),
      PATIENT_FIRST_NAME: t("PATIENT_FIRST_NAME"),
      PATIENT_LAST_NAME: t("PATIENT_LAST_NAME"),
      INSERT_DATE: t("INSERT_DATE"),
      PLACEHOLDER_PATIENT_FIRST_NAME: t("PLACEHOLDER_PATIENT_FIRST_NAME"),
      PLACEHOLDER_SEARCH_BY_INSTITUTION: t("PLACEHOLDER_SEARCH_BY_INSTITUTION"),
      PLACEHOLDER_PATIENT_LAST_NAME: t("PLACEHOLDER_PATIENT_LAST_NAME"),
      PLACEHOLDER_INSERT_DATE: t("PLACEHOLDER_INSERT_DATE"),
      CANCER_REGISTRY: t("CANCER_REGISTRY"),
      INSTITUTE_OF_PUBLIC_HEALTH: t("INSTITUTE_OF_PUBLIC_HEALTH"),
      BATUT_LABEL: t("BATUT_LABEL"),
      DELIVERED_BY: t("DELIVERED_BY"),
      HELIANT_LABEL: t("HELIANT_LABEL"),
      MESSAGES_REPORT_GENERATED_SUCCESS: t("MESSAGES_REPORT_GENERATED_SUCCESS")
    };
  }, [t]);

  return { Labels };
}
