import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface InstitutionControllerType {
  axiosCreateInstitution: any;
  axiosUpdateInstitution: any;
  axiosDeleteInstitution: any;
  axiosGetInstitutionList: any;
  axiosGetInstitutionTypeList: any;
  axiosDeleteUserInstitution: any;
  axiosCreateUserInstitution: any;
}

export function InstitutionController(): InstitutionControllerType {
  const { authData } = useContext(AppContext);

  const axiosCreateInstitution = (institutionChange: any) => {
    return axios.post(Endpoint.INSTITUTION_LIST, institutionChange ? institutionChange : {}, axiosConfig(authData!.token));
  };
  const axiosUpdateInstitution = (institutionChange: any, institutionID: string) => {
    return axios.put(`${Endpoint.INSTITUTION_LIST}/${institutionID}`, institutionChange ? institutionChange : {}, axiosConfig(authData!.token));
  };
  const axiosDeleteInstitution = (institutionID: string) => {
    return axios.delete(`${Endpoint.INSTITUTION_LIST}/${institutionID}`, axiosConfig(authData!.token));
  };
  const axiosGetInstitutionList = () => {
    return axios.get(Endpoint.INSTITUTION_LIST, axiosConfig(authData!.token));
  };

  const axiosGetInstitutionTypeList = () => {
    return axios.get(Endpoint.INSTITUTION_TYPE_LIST, axiosConfig(authData!.token));
  };

  const axiosDeleteUserInstitution = (institutionId: number, userInstitutionId: number) => {
    return axios.delete(Endpoint.INSTITUTION_LIST + "/" + institutionId + "/userList/" + userInstitutionId, axiosConfig(authData!.token));
  };

  const axiosCreateUserInstitution = (institutionId: number, userInstitution: any) => {
    return axios.post(Endpoint.INSTITUTION_LIST + "/" + institutionId + "/userList", userInstitution ? userInstitution : {}, axiosConfig(authData!.token));
  };

  return { axiosCreateInstitution, axiosUpdateInstitution, axiosDeleteInstitution, axiosGetInstitutionList, axiosGetInstitutionTypeList, axiosDeleteUserInstitution, axiosCreateUserInstitution };
}

export type { InstitutionControllerType };
