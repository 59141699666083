import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useImperativeHandle, useState } from "react";
import EnumEntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { isFormDisabled } from "../../../infrastructure/system/Utils";
import InstitutionReadDto from "../../../model/institution/InstitutionReadDto";
import InstitutionTypeDto from "../../../model/InstitutionTypeDto";
import JurisdictionReadDto from "../../../model/JurisdictionReadDto";
import { AppContext } from "../../../Store";

interface CrudInstitutionProp {
  institutionOperation: any;
  institution: InstitutionReadDto;
  dialogRef?: any;
  index: any;
  setIndex: Function;
  onDeleteInstitution: any;
  onCreateInstitution: any;
  onUpdateInstitution: any;
  jurisdictionList: Array<JurisdictionReadDto>;
  districtList: Array<any>;
  municipalityList: Array<any>;
  institutionTypeList: Array<InstitutionTypeDto>;
  selectedInstitutionUserRow: any;
  setSelectedInstitutionUserRow: any;
  displayDialogCreateUserInstitution: any;
  closeDialogCreateUserInstitution: any;
  selectedUserRow: any;
  setSelectedUserRow: any;
  usersNotInInstitution: any;
  openDialogCreateUserInstitution: any;
  deleteUserInstitution: any;
  createUserInstitution: any;
  userDataTableFirst: number;
  userTableRows: number;
  institutionUserTableFirst: any;
  institutionUserTableRows: any;
  onPageInstitutionUser: any;
  onPageUserTable: any;
  setInstitutionChange: any;
  institutionChange: any;
  institutionCodebookList: Array<any>;
  selectedDistrict: any;
  selectedMunicipality: any;
  selectedInstitutionCodebook: any;
  setSelectedDistrict: any;
  setSelectedMunicipality: any;
  setSelectedInstitutionCodebook: any;
  searchUserList: any;
  searchUserData: any;
  setSearchUserData: any;
}

export default function CrudInstitution(prop: CrudInstitutionProp) {
  const {
    institutionOperation,
    institution,
    dialogRef,
    onDeleteInstitution,
    onCreateInstitution,
    onUpdateInstitution,
    jurisdictionList,
    districtList,
    municipalityList,
    institutionTypeList,
    index,
    setIndex,
    selectedInstitutionUserRow,
    setSelectedInstitutionUserRow,
    displayDialogCreateUserInstitution,
    closeDialogCreateUserInstitution,
    selectedUserRow,
    setSelectedUserRow,
    usersNotInInstitution,
    openDialogCreateUserInstitution,
    deleteUserInstitution,
    createUserInstitution,
    userDataTableFirst,
    userTableRows,
    institutionUserTableFirst,
    institutionUserTableRows,
    onPageInstitutionUser,
    onPageUserTable,
    institutionChange,
    setInstitutionChange,
    institutionCodebookList,
    selectedDistrict,
    selectedMunicipality,
    selectedInstitutionCodebook,
    setSelectedDistrict,
    setSelectedMunicipality,
    setSelectedInstitutionCodebook,
    searchUserList,
    searchUserData,
    setSearchUserData,
  } = prop;
  const { showMessage } = useContext(AppContext);
  const [isDisabled] = useState(isFormDisabled(institutionOperation));

  const { Labels } = LabelsContext();
  const rightSearchContent = () => (
    <>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText placeholder={Labels.SEARCH_USER} value={searchUserData} onChange={(e) => setSearchUserData(e.target.value)} onKeyPress={(e) => searchUserList(e)} />
      </span>
    </>
  );
  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      onCreateInstitution(institutionChange)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
    onUpdate: () => {
      onUpdateInstitution(institutionChange)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
    onDelete: () => {
      onDeleteInstitution(institutionChange!.id)
        .then(() => {})
        .catch((error: any) => {
          showMessage(MessageType.ERROR, error, "");
        });
    },
  }));

  const leftContentButton = () => (
    <>
      <Button
        label={Labels.BUTTON_ADD_USER}
        icon="pi pi-plus"
        className="p-button-success"
        disabled={institutionOperation === EnumEntityOperation.READ}
        onClick={() => {
          openDialogCreateUserInstitution();
        }}
      ></Button>
    </>
  );

  const rightContentButton = () => (
    <>
      <Button
        label={Labels.BUTTON_DELETE}
        icon="pi pi-trash"
        className="p-button-danger"
        disabled={!selectedInstitutionUserRow || institutionOperation === EnumEntityOperation.READ}
        onClick={() => {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.LABEL_DIALOG_MESSAGE_DELETE_USER_FROM_INSTITUTION,
            icon: "pi pi-trash",
            accept: () => {
              deleteUserInstitution(selectedInstitutionUserRow?.id);
            },
          });
        }}
      ></Button>
    </>
  );

  const leftButtonCreate = () => (
    <>
      <Button
        label={Labels.BUTTON_ADD_USER}
        icon="pi pi-plus"
        className="p-button-success"
        disabled={!selectedUserRow}
        onClick={() => {
          createUserInstitution(selectedUserRow);
        }}
      ></Button>
    </>
  );

  return (
    <div className="layout-institution-list">
      <TabView
        className="tabview-margin-top"
        renderActiveOnly={false}
        activeIndex={index}
        onTabChange={(e) => {
          setIndex(e.index);
        }}
      >
        <TabPanel header={Labels.INSTITUTION}>
          <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
            <div className="p-grid p-align-center p-nogutter">
              <div className="p-col-4">
                <div>
                  {Labels.LABEL_NAME}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputText
                  disabled={isDisabled}
                  value={institutionChange && institutionChange.name}
                  onChange={(e: any) => {
                    setInstitutionChange({
                      ...institutionChange!,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>{Labels.LABEL_NAME_SHORT}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputText
                  disabled={isDisabled}
                  value={institutionChange && institutionChange.nameShort}
                  onChange={(e: any) => {
                    setInstitutionChange({
                      ...institutionChange!,
                      nameShort: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>
                  {Labels.LABEL_CODE}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <Dropdown
                  disabled={isDisabled}
                  filter
                  placeholder={Labels.LABEL_CODE}
                  resetFilterOnHide
                  optionLabel="name"
                  optionValue="id"
                  options={institutionCodebookList}
                  value={institutionChange && selectedInstitutionCodebook?.id}
                  onChange={(e) => {
                    setInstitutionChange({ ...institutionChange!, code: institutionCodebookList!.find((x) => x.id === e.target.value)!.id });
                    setSelectedInstitutionCodebook(institutionCodebookList!.find((x) => x.id === e.target.value)!);
                  }}
                />
              </div>

              <div className="p-col-4">
                <div>
                  {Labels.INSTITUTION_TYPE}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <Dropdown
                  disabled={isDisabled}
                  placeholder={Labels.PLACEHOLDER_INSTITUTION_TYPE}
                  resetFilterOnHide
                  optionLabel="name"
                  optionValue="code"
                  options={institutionTypeList}
                  value={institutionChange && institutionChange?.institutionType}
                  onChange={(e) => {
                    setInstitutionChange({
                      ...institutionChange!,
                      institutionType: institutionTypeList!.find((x) => x.code === e.value)?.code!,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>{Labels.LABEL_ADDRESS}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputText
                  disabled={isDisabled}
                  value={institutionChange && institutionChange.address}
                  onChange={(e: any) => {
                    setInstitutionChange({
                      ...institutionChange!,
                      address: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>
                  {Labels.HEADER_DISPLAY_ORDER} {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputNumber
                  disabled={isDisabled}
                  value={institutionChange && institutionChange.displayOrder}
                  showButtons
                  buttonLayout="horizontal"
                  decrementButtonClassName="p-button-danger"
                  incrementButtonClassName="p-button-success"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  onValueChange={(e: any) => {
                    setInstitutionChange({
                      ...institutionChange!,
                      displayOrder: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>
                  {Labels.LABEL_MUNICIPALITY}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <Dropdown
                  disabled={isDisabled}
                  filter
                  placeholder={Labels.LABEL_MUNICIPALITY}
                  resetFilterOnHide
                  optionLabel="name"
                  optionValue="id"
                  options={municipalityList}
                  value={institutionChange && selectedMunicipality?.id}
                  onChange={(e) => {
                    setInstitutionChange({ ...institutionChange!, codeMunicipality: municipalityList!.find((x) => x.id === e.target.value)!.id });
                    setSelectedMunicipality(municipalityList!.find((x) => x.id === e.target.value)!);
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>
                  {Labels.LABEL_DISTRICT}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <Dropdown
                  disabled={isDisabled}
                  filter
                  placeholder={Labels.LABEL_DISTRICT}
                  resetFilterOnHide
                  optionLabel="name"
                  optionValue="id"
                  options={districtList}
                  value={institutionChange && selectedDistrict?.id}
                  onChange={(e) => {
                    setInstitutionChange({ ...institutionChange!, codeDistrict: districtList!.find((x) => x.id === e.target.value)!.id });
                    setSelectedDistrict(districtList!.find((x) => x.id === e.target.value)!);
                  }}
                />
              </div>

              <div className="p-col-4">
                <div>
                  {Labels.LABEL_JURISDICTION}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <Dropdown
                  disabled={isDisabled}
                  filter
                  placeholder={Labels.LABEL_JURISDICTION}
                  resetFilterOnHide
                  optionLabel="name"
                  optionValue="id"
                  options={jurisdictionList}
                  value={institutionChange && institutionChange?.jurisdiction?.id}
                  onChange={(e) => {
                    setInstitutionChange({ ...institutionChange!, jurisdiction: jurisdictionList!.find((x) => x.id === e.target.value)! });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>{Labels.LABEL_DESCRIPTION}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputTextarea
                  rows={3}
                  cols={5}
                  disabled={isDisabled}
                  value={institutionChange && institutionChange.description}
                  onChange={(e: any) => {
                    setInstitutionChange({
                      ...institutionChange!,
                      description: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>{Labels.LABEL_NOTE}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputTextarea
                  rows={3}
                  cols={5}
                  disabled={isDisabled}
                  value={institutionChange && institutionChange.note}
                  onChange={(e: any) => {
                    setInstitutionChange({
                      ...institutionChange!,
                      note: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>{Labels.INSTITUTION_GPS_LOCATION}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputText
                  disabled={isDisabled}
                  value={institutionChange && institutionChange.gpsLocation}
                  onChange={(e: any) => {
                    setInstitutionChange({
                      ...institutionChange!,
                      gpsLocation: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>{Labels.INSTITUTION_WS_USERNAME}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputText
                  disabled={isDisabled}
                  value={institutionChange && institutionChange.wsUsername}
                  onChange={(e: any) => {
                    setInstitutionChange({
                      ...institutionChange!,
                      wsUsername: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>{Labels.INSTITUTION_WS_PASSWORD}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputText
                  disabled={isDisabled}
                  value={institutionChange && institutionChange.wsPassword}
                  onChange={(e: any) => {
                    setInstitutionChange({
                      ...institutionChange!,
                      wsPassword: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header={Labels.USER_LIST} disabled={institutionOperation === EnumEntityOperation.CREATE}>
          <Toolbar left={leftContentButton} right={rightContentButton} />
          <DataTable
            paginator
            value={institution?.userInstitutionList}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            selectionMode="single"
            first={institutionUserTableFirst}
            rowsPerPageOptions={[5, 10, 20]}
            onPage={(e) => {
              onPageInstitutionUser(e.rows, e.first);
            }}
            rows={institutionUserTableRows}
            selection={selectedInstitutionUserRow}
            onSelectionChange={(e) => setSelectedInstitutionUserRow(e.value)}
            onRowSelect={(e) => setSelectedInstitutionUserRow(e.data)}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          >
            <Column field={"user.firstname"} header={Labels.USER_FIRST_NAME} />
            <Column field={"user.lastname"} header={Labels.USER_LAST_NAME} />
            <Column field={"user.username"} header={Labels.USER_USER_NAME} />
          </DataTable>
          <Dialog visible={displayDialogCreateUserInstitution} onHide={closeDialogCreateUserInstitution} style={{ width: "1000px" }}>
            <div>
              <Toolbar left={leftButtonCreate} right={rightSearchContent} />
              <DataTable
                paginator
                first={userDataTableFirst}
                value={usersNotInInstitution}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[5, 10, 20]}
                selectionMode="single"
                onPage={(e) => {
                  onPageUserTable(e.rows, e.first);
                }}
                rows={userTableRows}
                selection={selectedUserRow}
                onSelectionChange={(e) => setSelectedUserRow(e.value)}
                onRowSelect={(e) => setSelectedUserRow(e.data)}
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
              >
                <Column field={"user.firstname"} header={Labels.USER_FIRST_NAME} />
                <Column field={"user.lastname"} header={Labels.USER_LAST_NAME} />
              </DataTable>
            </div>
          </Dialog>
        </TabPanel>
      </TabView>
    </div>
  );
}
