import { useContext } from "react";
import { useLocation } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/login/LoginPageView";
import { isPublicRoute } from "./infrastructure/system/Utils";
import { AppContext } from "./Store";
import PublicContent from "./PublicContent";

export default function App() {
  const { authData } = useContext(AppContext);

  const location = useLocation();

  if (isPublicRoute(location.pathname)) {
    return <PublicContent />;
  }

  return authData && authData.token ? <Home /> : <Login />;
}
