import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useImperativeHandle } from "react";
import LabelsContext from "../../../../infrastructure/system/LabelsContext";
import { parameterType } from "../../../../infrastructure/system/Utils";
import ReportParameterReadDto from "../../../../model/ReportParameterReadDto";
import { InputTextarea } from "primereact/inputtextarea";
import EnumReportParameterType from "../../../../infrastructure/system/EnumReportParameterType";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import CustomQueryDto from "../../../../model/CustomQueryDto";
import { ScrollPanel } from "primereact/scrollpanel";
import { Fieldset } from "primereact/fieldset";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";

interface CrudReportParameterType {
  dialogRef: any;
  isDisabled: boolean;
  reportParameterToChange: ReportParameterReadDto;
  setReportParameterToChange: any;
  setDisplayDialog: any;
  onCreate: any;
  onUpdate: any;
  onDelete: any;
  getResultSet?: any;
  resultList?: any;
  entityOperation?: any;
}

export default function CrudReportParameter(props: CrudReportParameterType) {
  const { Labels } = LabelsContext();

  const { dialogRef, reportParameterToChange, isDisabled, setReportParameterToChange, onCreate, onUpdate, onDelete, getResultSet, resultList, entityOperation } = props;
  useImperativeHandle(dialogRef, () => ({
    onCreate: onCreate,
    onUpdate: onUpdate,
    onDelete: onDelete,
  }));

  return (
    <div className="layout-form-semantic-validation">
      <div className="p-col-12 p-xl-8 p-lg-8 p-md-12 p-sm-12">
        <div className="p-grid p-fluid p-nogutter">
          <div className="p-col-12">
            <div className="p-grid p-align-top">
              <div className="p-col-4">
                <div>{Labels.REPORT_PARAMETER_TYPE}</div>
              </div>
              <div className="p-col-8">
                <Dropdown
                  disabled={isDisabled}
                  filter
                  placeholder={Labels.PLACEHOLDER_CHOOSE_PARAMETAR_TYPE}
                  resetFilterOnHide
                  optionLabel="label"
                  optionValue="value"
                  options={parameterType(Labels)}
                  value={reportParameterToChange && reportParameterToChange?.reportParameterType}
                  onChange={(e) =>
                    setReportParameterToChange({
                      ...reportParameterToChange!,
                      reportParameterType: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-grid p-align-top">
              <div className="p-col-4">
                <div>
                  {Labels.LABEL_NAME}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8">
                <InputText
                  disabled={isDisabled}
                  value={reportParameterToChange && reportParameterToChange.name}
                  onChange={(e: any) => {
                    setReportParameterToChange({
                      ...reportParameterToChange!,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-grid p-align-top">
              <div className="p-col-4">
                <div>
                  {Labels.LABEL_REQUEST_NAME}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8">
                <InputText
                  disabled={isDisabled}
                  value={reportParameterToChange && reportParameterToChange.requestName}
                  onChange={(e: any) => {
                    setReportParameterToChange({
                      ...reportParameterToChange!,
                      requestName: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-grid p-align-top">
              <div className="p-col-4">
                <div>
                  {Labels.LABEL_DISPLAY_ORDER}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8">
                <InputNumber
                  disabled={isDisabled}
                  value={reportParameterToChange && reportParameterToChange.displayOrder}
                  onChange={(e: any) => {
                    setReportParameterToChange({
                      ...reportParameterToChange!,
                      displayOrder: e.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          {(reportParameterToChange?.reportParameterType === EnumReportParameterType.SET || reportParameterToChange?.reportParameterType === EnumReportParameterType.MULTISELECT) && (
            <>
              <div className="p-col-12">
                <div className="p-grid p-align-top">
                  <div className="p-col-4">
                    <div>{Labels.LABEL_CUSTOM_QUERY}</div>
                  </div>
                  <div className="p-col-8 p-fluid">
                    <InputTextarea
                      className="text-area-tooltip"
                      rows={5}
                      cols={30}
                      disabled={isDisabled}
                      autoResize
                      value={reportParameterToChange && reportParameterToChange.customQuery}
                      onChange={(e: any) => {
                        setReportParameterToChange({
                          ...reportParameterToChange!,
                          customQuery: e.target.value,
                        });
                      }}
                    />
                    <Tooltip target=".text-area-tooltip" position="right">
                      {Labels.CUSTOM_QUERY_EXAMPLE}
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="p-col-12">
                <div className="p-grid p-align-top">
                  <div className="p-col-6"></div>
                  <div className="p-col-4">
                    <Button className="p-button-success" onClick={(e: any) => getResultSet(reportParameterToChange.customQuery)} label={Labels.LABEL_TEST_QUERY}></Button>
                  </div>
                  <div className="p-col-2"></div>
                </div>
              </div>
              <div className="p-col-12">
                <div className="p-grid p-align-top">
                  <div className="p-col-4"></div>
                  {resultList?.length > 0 && entityOperation === EntityOperation.CREATE && (
                    <div className="p-col-8">
                      <Fieldset>
                        <ScrollPanel style={{ width: "100%", height: "100px", marginLeft: "10px" }}>
                          {resultList.map((element: CustomQueryDto) => {
                            return <p>{element.name}</p>;
                          })}
                        </ScrollPanel>
                      </Fieldset>
                    </div>
                  )}
                  {reportParameterToChange.resultList?.length > 0 && entityOperation !== EntityOperation.CREATE && (
                    <div className="p-col-8">
                      <Fieldset>
                        <ScrollPanel style={{ width: "100%", height: "100px", marginLeft: "10px" }}>
                          {reportParameterToChange.resultList.map((element: CustomQueryDto) => {
                            return <p>{element.name}</p>;
                          })}
                        </ScrollPanel>
                      </Fieldset>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
