import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface CommentControllerType {
  axiosGetCommentList: any;
  axiosCreateComment: any;
}

export function CommentController(): CommentControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetCommentList = (formFilledID: number) => {
    return axios.get(`${Endpoint.COMMENT_LIST}/` + formFilledID, axiosConfig(authData!.token));
  };
  const axiosCreateComment = (comment: any) => {
    return axios.post(`${Endpoint.COMMENT_LIST}`, comment, axiosConfig(authData!.token));
  };

  return { axiosGetCommentList, axiosCreateComment };
}

export type { CommentControllerType };
