import { useContext, useRef, useState } from "react";
import { JurisdictionController, JurisdictionControllerType } from "../../../controllers/jurisdiction/JurisdictionController";
import { RoleController, RoleControllerType } from "../../../controllers/role/RoleController";
import { UserGroupController, UserGroupControllerType } from "../../../controllers/user-group/UserGroupController";
import { UserController, UserControllerType } from "../../../controllers/user/UserController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import UserGroupReadDto from "../../../model/UserGroupReadDto";
import { AppContext } from "../../../Store";

interface UserGroupListLogicalType {
  userGroupList: Array<UserGroupReadDto>;
  first: number;
  tableRows: number;
  selectedRow: any;
  setSelectedRow: any;
  selectedUserGroupUserRow: any;
  setSelectedUserGroupUserRow: any;
  setSelectedUserGroup: any;
  dialogHeader: any;
  entityOperation: any;
  displayDialog: boolean;
  openDialog: any;
  selectedUserGroup: any;
  closeDialog: any;
  userGroupToChange: any;
  createUserGroup: any;
  dialogRef: any;
  updateUserGroup: any;
  deleteUserGroup: any;
  index: any;
  setIndex: any;
  userGroupRoleList: any;
  roleList: any;
  onChangeRole: any;
  userGroupJurisdictionList: any;
  jurisdictionList: any;
  onChangeJurisdiction: any;
  deleteUserGroupUser: any;
  openDialogCreateUserGroupUser: any;
  displayDialogCreateUserUserGroup: any;
  closeDialogCreateUserUserGroup: any;
  usersNotInUserGroup: any;
  createUserGroupUser: any;
  selectedUserRow: any;
  setSelectedUserRow: any;
  userDataTableFirst: number;
  userTableRows: number;
  userGroupUserTableFirst: number;
  userGroupUserTableRows: number;
  onPageUserGroupList: any;
  onPageUserGroupUser: any;
  onPageUserTable: any;
  breadCrumbItems: any;
  exportData: any;
  onSearchValueChange: any;
  searchUserData: any;
  setSearchUserData: any;
  searchUserList: any;
}

export default function UserGroupListLogical(): UserGroupListLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const { Labels } = LabelsContext();
  const [userGroupList, setUserGroupList] = useState<Array<UserGroupReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [userDataTableFirst, setUserDataTableFirst] = useState(0);
  const [userTableRows, setUserTableRows] = useState(10);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedUserGroupUserRow, setSelectedUserGroupUserRow] = useState<any>(null);
  const [selectedUserRow, setSelectedUserRow] = useState<any>(null);
  const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroupReadDto | null>();
  const [entityOperation, setEntityOperation] = useState<any>();
  const [userGroupToChange, setUserGroupToChange] = useState<UserGroupReadDto>();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [displayDialogCreateUserUserGroup, setDisplayDialogCreateUserUserGroup] = useState(false);
  const dialogRef = useRef<any>();
  const [index, setIndex] = useState<number>(0);
  const [userGroupRoleList, setUserGroupRoleList] = useState<any>([]);
  const [roleList, setRoleList] = useState<any>([]);
  const [usersNotInUserGroup, setUsersNotInUserGroup] = useState<any>([]);
  const [userGroupJurisdictionList, setUserGroupJurisdictionList] = useState<any>([]);
  const [jurisdictionList, setJurisdictionList] = useState<any>([]);
  const {
    axiosCreateUserGroup,
    axiosDeleteUserGroup,
    axiosCreateUserGroupJurisdiction,
    axiosCreateUserGroupUser,
    axiosGetUserGroupList,
    axiosUpdateUserGroup,
    axiosDeleteUserGroupUser,
    axiosDeleteUserGroupJurisdiction,
    axiosCreateUserGroupRole,
    axiosDeleteUserGroupRole,
  }: UserGroupControllerType = UserGroupController();
  const { axiosGetJurisdictionList }: JurisdictionControllerType = JurisdictionController();
  const { axiosGetRoleList }: RoleControllerType = RoleController();
  const { axiosGetUserList, axiosGetSearchUserList }: UserControllerType = UserController();
  const [userGroupUserTableFirst, setUserGroupUserTableFirst] = useState(0);
  const [userGroupUserTableRows, setUserGroupUserTableRows] = useState(10);
  const [searchUserData, setSearchUserData] = useState<any>();
  const breadCrumbItems = [
    {
      label: Labels.USER_GROUP_LIST,
      command: () => {
        window.location.reload();
      },
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (idSelectedUserGroup?: number) => {
    setShowBlockUI(true);
    axiosGetUserGroupList()
      .then((res: any) => {
        setUserGroupList(res.data.data);
        let exportList = new Array<any>();
        res.data.data.forEach((userGroup: any) => {
          exportList.push({ [Labels.LABEL_CODE]: userGroup.code, [Labels.LABEL_NAME]: userGroup.name, [Labels.LABEL_DESCRIPTION]: userGroup.description, [Labels.LABEL_NOTE]: userGroup.note });
        });
        setExportData(exportList);
        setShowBlockUI(false);
        if (idSelectedUserGroup) {
          res.data.data.forEach((userGroup: any) => {
            if (idSelectedUserGroup === userGroup.id) {
              setSelectedRow(userGroup);
              setSelectedUserGroup(userGroup);
            }
          });
        }
        getUsersNotExistsInUserGroup();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const getUserGroupRoles = () => {
    if (selectedUserGroup !== null && selectedUserGroup !== undefined) {
      var list: any = [];
      const result = selectedUserGroup!.userGroupRoleList;
      result.map((a: any) => {
        return list.push({
          id: a.id,
          role: a.role,
        });
      });
      setUserGroupRoleList(list);
      getRoles(list);
    }
  };

  const getUserGroupJurisdictions = () => {
    if (selectedUserGroup !== null && selectedUserGroup !== undefined) {
      var list: any = [];
      const result = selectedUserGroup!.userGroupJurisdictionList;
      result.map((a: any) => {
        return list.push({
          id: a.id,
          jurisdiction: a.jurisdiction,
        });
      });
      setUserGroupJurisdictionList(list);
      getJurisdictions(list);
    }
  };

  const getJurisdictions = (hasJurisdictionsList: any) => {
    setShowBlockUI(true);
    axiosGetJurisdictionList()
      .then((res: any) => {
        var listNotHave: any = [];
        const allJurisdiction = res.data.data;
        allJurisdiction.forEach((jurisdiction: any) => {
          var isNotInUserGroupJurisdiction = false;
          hasJurisdictionsList.forEach((jurisdictionHave: any) => {
            if (jurisdictionHave.jurisdiction.id === jurisdiction.id) {
              isNotInUserGroupJurisdiction = true;
            }
          });
          if (isNotInUserGroupJurisdiction === false) {
            listNotHave.push({ id: null, jurisdiction: jurisdiction });
          }
        });
        setJurisdictionList(listNotHave);
        setShowBlockUI(false);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };
  const getRoles = (listRolesHave: any) => {
    setShowBlockUI(true);
    axiosGetRoleList()
      .then((res: any) => {
        var listNotHave: any = [];
        const allRoles = res.data;
        allRoles.forEach((role: any) => {
          var isNotInUserGroupRoles = false;
          listRolesHave.forEach((roleHave: any) => {
            if (roleHave.role === role.code) {
              isNotInUserGroupRoles = true;
            }
          });
          if (isNotInUserGroupRoles === false) {
            listNotHave.push({ role: role.code, id: null });
          }
        });
        setRoleList(listNotHave);
        setShowBlockUI(false);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const dialogHeader = (entityOperation: string) => {
    const display = selectedUserGroup ? selectedUserGroup.name : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.USER_GROUP_TITLE_DIALOG_CREATE;
      case EntityOperation.DELETE:
        return Labels.USER_GROUP_TITLE_DIALOG_DELETE + display;
      case EntityOperation.UPDATE:
        return Labels.USER_GROUP_TITLE_DIALOG_UPDATE + display;
      case EntityOperation.READ:
        return Labels.USER_GROUP_TITLE_DIALOG_DETAILS + display;
      default:
        return "";
    }
  };

  const openDialog = (entityOperation: String, selectedUserGroup?: UserGroupReadDto) => {
    let userGroup = undefined;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        userGroup = selectedUserGroup;
        break;
    }

    setEntityOperation(entityOperation);
    setUserGroupToChange(userGroup);
    setDisplayDialog(true);
    getUserGroupRoles();
    getUserGroupJurisdictions();
  };

  const openDialogCreateUserGroupUser = () => {
    getUsersNotExistsInUserGroup();
    setDisplayDialogCreateUserUserGroup(true);
  };

  const closeDialog = () => {
    setIndex(0);
    setDisplayDialog(false);
  };

  const closeDialogCreateUserUserGroup = () => {
    setIndex(3);
    setDisplayDialogCreateUserUserGroup(false);
  };

  const validateInput = (userGroup: UserGroupReadDto) => {
    if (!userGroup || userGroup.code === undefined || userGroup?.code === "") {
      showMessage(MessageType.ERROR, Labels.MESSAGES_CODE_REQUIRED, "");
      return false;
    }
    if (userGroup.name === undefined || userGroup?.name === "") {
      showMessage(MessageType.ERROR, Labels.MESSAGES_NAME_REQUIRED, "");
      return false;
    }
    return true;
  };

  const createUserGroup = (userGroup: UserGroupReadDto) => {
    setUserGroupRoleList([]);
    getRoles([]);
    setUserGroupJurisdictionList([]);
    getJurisdictions([]);
    return new Promise((resolve, reject) => {
      if (!validateInput(userGroup)) {
        return;
      }
      axiosCreateUserGroup(userGroup)
        .then((response: any) => {
          setEntityOperation(EntityOperation.UPDATE);
          setSelectedUserGroup(response.data.data);
          setIndex(1);
          showMessage(MessageType.SUCCESS, Labels.USER_GROUP_TITLE_MESSAGE_CREATE_USER_GROUP_SUCCESS, "");
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const updateUserGroup = (userGroup: UserGroupReadDto) => {
    return new Promise((resolve, reject) => {
      if (!validateInput(userGroup)) {
        return;
      }
      if (userGroup.id === undefined) {
        userGroup = { ...userGroup, id: selectedUserGroup!.id };
      }
      axiosUpdateUserGroup(userGroup)
        .then((response: any) => {
          closeDialog();
          showMessage(MessageType.SUCCESS, Labels.USER_GROUP_TITLE_MESSAGE_UPDATE_USER_GROUP_SUCCESS, "");
          fetchData(response.data.data.id);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const deleteUserGroup = (userGroupId: number) => {
    return new Promise((resolve, reject) => {
      axiosDeleteUserGroup(userGroupId)
        .then(() => {
          closeDialog();
          setSelectedRow(undefined);
          setSelectedUserGroup(undefined);
          showMessage(MessageType.SUCCESS, Labels.USER_GROUP_TITLE_MESSAGE_DELETE_USER_GROUP_SUCCESS, "");
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const deleteUserGroupUser = (userGroupUserId: number) => {
    return new Promise((resolve, reject) => {
      axiosDeleteUserGroupUser(selectedUserGroup!.id, userGroupUserId)
        .then((res: any) => {
          setIndex(3);
          setUserGroupToChange(res.data.data);
          showMessage(MessageType.SUCCESS, Labels.USER_GROUP_TITLE_MESSAGE_DELETE_USER_GROUP_USER_SUCCESS, "");
          fetchData(selectedUserGroup!.id);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const getUsersNotExistsInUserGroup = () => {
    axiosGetUserList()
      .then((res: any) => {
        var listNotHave: any = [];
        const allUsers = res.data.data;
        allUsers.forEach((user: any) => {
          var isNotInUserGroupUsers = false;
          var listUsersHave = selectedUserGroup?.userGroupUserList;
          listUsersHave?.forEach((userHave: any) => {
            if (userHave.user.id === user.id) {
              isNotInUserGroupUsers = true;
            }
          });
          if (isNotInUserGroupUsers === false) {
            listNotHave.push({ user: user, id: null });
          }
        });
        setUsersNotInUserGroup(listNotHave);
        setShowBlockUI(false);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const createUserGroupUser = (userGroupUser: any) => {
    return new Promise((resolve, reject) => {
      axiosCreateUserGroupUser(selectedUserGroup!.id, userGroupUser)
        .then((res: any) => {
          setSelectedUserGroup(res.data.data);
          setUserGroupToChange(res.data.data);
          fetchData();
          showMessage(MessageType.SUCCESS, Labels.USER_GROUP_TITLE_MESSAGE_CREATE_USER_GROUP_USER_SUCCESS, "");
          closeDialogCreateUserUserGroup();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const onChangeJurisdiction = async (event: any) => {
    setShowBlockUI(true);
    if (userGroupJurisdictionList.length < event.target.length) {
      var userGroupJurisdictionArray: any = [];
      await Promise.all(
        event.target.map(async (userGroupJurisdiction: any) => {
          if (!userGroupJurisdictionList.includes(userGroupJurisdiction)) {
            const newUserGroupJurisdiction = {
              jurisdiction: { id: userGroupJurisdiction.jurisdiction.id },
              userGroup: { id: selectedUserGroup!.id },
            };
            await axiosCreateUserGroupJurisdiction(selectedUserGroup!.id, newUserGroupJurisdiction)
              .then(async (res: any) => {
                userGroupJurisdictionArray = res.data.data.userGroupJurisdictionList;
              })
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
                setShowBlockUI(false);
              });
          }
        })
      );
      setUserGroupJurisdictionList(userGroupJurisdictionArray);
      setJurisdictionList(event.source);
      fetchData();
    } else if (jurisdictionList.length < event.source.length) {
      event.source.forEach((jurisdiction: any) => {
        if (!jurisdictionList.includes(jurisdiction)) {
          axiosDeleteUserGroupJurisdiction(selectedUserGroup!.id, jurisdiction.id)
            .then((res: any) => {
              setUserGroupJurisdictionList(event.target);
              setJurisdictionList(event.source);
            })
            .catch((error: any) => {
              handleAxiosCallError(showMessage, error);
              setShowBlockUI(true);
            });
        }
      });
      fetchData();
    }
  };

  const onChangeRole = async (event: any) => {
    setShowBlockUI(true);
    if (userGroupRoleList.length < event.target.length) {
      var arrayUserGroupRole: any = [];
      await Promise.all(
        event.target.map(async (userGroupRole: any) => {
          if (!userGroupRoleList.includes(userGroupRole)) {
            const role = {
              role: userGroupRole.role,
            };
            await axiosCreateUserGroupRole(selectedUserGroup!.id, role)
              .then(async (res: any) => {
                arrayUserGroupRole = res.data.data.userGroupRoleList;
              })
              .catch((error: any) => {
                handleAxiosCallError(showMessage, error);
                setShowBlockUI(false);
              });
          }
        })
      );
      setUserGroupRoleList(arrayUserGroupRole);
      setRoleList(event.source);
      fetchData();
    } else if (roleList.length < event.source.length) {
      event.source.forEach((role: any) => {
        if (!roleList.includes(role)) {
          axiosDeleteUserGroupRole(selectedUserGroup!.id, role.id)
            .then((res: any) => {
              setUserGroupRoleList(event.target);
              setRoleList(event.source);
            })
            .catch((error: any) => {
              handleAxiosCallError(showMessage, error);
              setShowBlockUI(false);
            });
        }
      });
      fetchData();
    }
  };

  const onPageUserGroupList = (rows: any, first: any) => {
    setTableRows(rows);
    setFirst(first);
  };

  const onPageUserGroupUser = (rows: any, first: any) => {
    setUserGroupUserTableRows(rows);
    setUserGroupUserTableFirst(first);
  };

  const onPageUserTable = (rows: any, first: any) => {
    setUserTableRows(rows);
    setUserDataTableFirst(first);
  };

  const onSearchValueChange = (sortedData: Array<any>) => {
    const exportList = new Array<any>();
    sortedData?.forEach((userGroup: any) => {
      exportList.push({ [Labels.LABEL_CODE]: userGroup.code, [Labels.LABEL_NAME]: userGroup.name, [Labels.LABEL_DESCRIPTION]: userGroup.description, [Labels.LABEL_NOTE]: userGroup.note });
    });
    setExportData(exportList);
  };

  const searchUserList = (event: any) => {
    const params = {
      param: searchUserData,
    };
    if (event.charCode === 13) {
      axiosGetSearchUserList(params)
        .then((res: any) => {
          var listNotHave: any = [];
          const allUsers = res.data.data;
          allUsers.forEach((user: any) => {
            var isNotInUserGroupUsers = false;
            var listUsersHave = selectedUserGroup?.userGroupUserList;
            listUsersHave?.forEach((userHave: any) => {
              if (userHave.user.id === user.id) {
                isNotInUserGroupUsers = true;
              }
            });
            if (isNotInUserGroupUsers === false) {
              listNotHave.push({ user: user, id: null });
            }
          });
          setUsersNotInUserGroup(listNotHave);
          setShowBlockUI(false);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        });
    }
  };

  return {
    userGroupList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    selectedUserGroupUserRow,
    setSelectedUserGroupUserRow,
    setSelectedUserGroup,
    dialogHeader,
    entityOperation,
    displayDialog,
    openDialog,
    selectedUserGroup,
    closeDialog,
    userGroupToChange,
    createUserGroup,
    dialogRef,
    updateUserGroup,
    deleteUserGroup,
    index,
    setIndex,
    userGroupRoleList,
    roleList,
    userGroupJurisdictionList,
    jurisdictionList,
    onChangeRole,
    onChangeJurisdiction,
    deleteUserGroupUser,
    openDialogCreateUserGroupUser,
    displayDialogCreateUserUserGroup,
    closeDialogCreateUserUserGroup,
    usersNotInUserGroup,
    createUserGroupUser,
    selectedUserRow,
    setSelectedUserRow,
    userDataTableFirst,
    userTableRows,
    userGroupUserTableFirst,
    userGroupUserTableRows,
    onPageUserGroupList,
    onPageUserGroupUser,
    onPageUserTable,
    breadCrumbItems,
    exportData,
    onSearchValueChange,
    searchUserData,
    setSearchUserData,
    searchUserList,
  };
}

export type { UserGroupListLogicalType };
