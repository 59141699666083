import React from "react";
import { InputNumber } from "primereact/inputnumber";
import FieldReadDto from "../../../../model/FieldReadDto";

interface NumberIntegerType {
  field: FieldReadDto;
  onChange?: any;
  value: any;
  isDisabled?: boolean;
  onBlur?: any;
  errorMessage?: any;
}

export default function NumberInteger(props: NumberIntegerType) {
  const { field, onChange, value, onBlur, errorMessage, isDisabled } = props;
  return (
    <div>
      <InputNumber
        disabled={isDisabled}
        onBlur={() => {
          if (onBlur) onBlur({ field: field, value: value });
        }}
        value={value ? value : null}
        onChange={(e: any) => {
          onChange({ field: field, value: e.value });
        }}
        className={errorMessage && errorMessage !== "" ? "p-invalid" : field.isDisabled === true ? "p-disabled-component" : ""}
        aria-describedby="number-integer-required-min-max-help"
        useGrouping={false}
      />

      <small id="number-integer-required-min-max-help" className="p-error">
        {errorMessage && errorMessage !== "" ? errorMessage : ""}
      </small>
    </div>
  );
}
