import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface LogActionControllerType {
  axiosSearchLogActionList: any;
  axiosCountSearchLogActionList: any;
}

export function LogActionController(): LogActionControllerType {
  const { authData } = useContext(AppContext);

  const axiosSearchLogActionList = (params: any) => {
    return axios.get(Endpoint.LOG_ACTION_LIST + "/search", axiosConfig(authData!.token, params));
  };

  const axiosCountSearchLogActionList = (params: any) => {
    return axios.get(Endpoint.LOG_ACTION_LIST + "/countSearch", axiosConfig(authData!.token, params));
  };

  return { axiosSearchLogActionList, axiosCountSearchLogActionList };
}

export type { LogActionControllerType };
