import { confirmDialog } from "primereact/confirmdialog";

export default function UserLeaveConfirmation(message: string, callback: any, confirmOpen: any, setConfirmOpen: any) {
  const handleConfirm = (callbackState?: any) => {
    callback(callbackState);
    setConfirmOpen(false);
  };
  const handleCancel = () => {
    callback();
    setConfirmOpen(false);
  };

  const obj = JSON.parse(message);
  return confirmDialog({
    visible: confirmOpen,
    acceptLabel: obj.confirmText,
    rejectLabel: obj.cancelText,
    message: obj.messageText,
    accept: () => handleConfirm(callback),
    reject: () => handleCancel(),
  });
}
