import EnumComponentType from "../../../infrastructure/system/EnumComponentType";
import Boolean from "./boolean/Boolean";
import Datetime from "./datetime/Datetime";
import LongText from "./long-text/LongText";
import NumberDecimal from "./number-decimal/NumberDecimal";
import NumberInteger from "./number-integer/NumberInteger";
import Set from "./set/Set";
import Text from "./text/Text";
import Codebook from "./codebook/Codebook";
import CodebookAutocomplete from "./codebook-autocomplete/CodebookAutocomplete";
import PanelField from "./panel/PanelField";

interface FieldType {
  fieldList?: any;
  field: any;
  value: any;
  onChange?: any;
  onBlur?: any;
  errorMessage?: any;
  isDisabled?: boolean;
  isSearch?: boolean;
  codebookLogicalControlValidation?: any;
  setFieldCodebookListValidation?: any;
  validate?: any;
  showField?: any;
}

export default function Field(props: FieldType) {
  const { field }: FieldType = props;

  if (!field) {
    return null;
  }

  return (
    <>
      {field.fieldType?.componentEnumType === EnumComponentType.TEXT && <Text {...props} />}
      {field.fieldType?.componentEnumType === EnumComponentType.LONG_TEXT && <LongText {...props} />}
      {field.fieldType?.componentEnumType === EnumComponentType.NUMBER_INTEGER && <NumberInteger {...props} />}
      {field.fieldType?.componentEnumType === EnumComponentType.NUMBER_DECIMAL && <NumberDecimal {...props} />}
      {field.fieldType?.componentEnumType === EnumComponentType.DATE_TIME && <Datetime {...props} />}
      {field.fieldType?.componentEnumType === EnumComponentType.SET && <Set {...props} />}
      {field.fieldType?.componentEnumType === EnumComponentType.BOOLEAN && <Boolean {...props} />}
      {field.fieldType?.componentEnumType === EnumComponentType.CODEBOOK && <Codebook {...props} />}
      {field.fieldType?.componentEnumType === EnumComponentType.CODEBOOK_AUTOCOMPLETE && <CodebookAutocomplete {...props} />}
      {field.fieldType?.componentEnumType === EnumComponentType.PANEL && <PanelField {...props} />}
    </>
  );
}
