import axios from "axios";
import { useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ReportController, ReportControllerType } from "../../../../controllers/report/ReportController";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../../infrastructure/system/LabelsContext";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, useEffectOnce } from "../../../../infrastructure/system/Utils";
import ReportParameterReadDto from "../../../../model/ReportParameterReadDto";
import { AppContext } from "../../../../Store";

interface ReportLogicalType {
  reportParameterList: any;
  first: number;
  tableRows: number;
  selectedRow: any;
  setSelectedRow: any;
  selectedParameter: any;
  exportData: any;
  onPage: any;
  setSelectedParameter: any;
  openDialog: any;
  closeDialog: any;
  dialogHeader: any;
  onCreate: any;
  onDelete: any;
  onUpdate: any;
  entityOperation: any;
  dialogRef: any;
  displayDialog: any;
  setDisplayDialog: any;
  selectedParameterToChange: any;
  setSelectedParameterToChange: any;
  index: any;
  getResultSet: any;
  resultList: any;
  deleteReportParameter: any;
}
interface UseParamsType {
  reportID?: string;
}
export default function ReportParameterLogical() {
  const { Labels } = LabelsContext();
  const { reportID } = useParams<UseParamsType>();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const [reportParameterList, setReportParameterList] = useState<any>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [selectedParameter, setSelectedParameter] = useState<ReportParameterReadDto>();
  const [selectedParameterToChange, setSelectedParameterToChange] = useState<any>();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const [entityOperation, setEntityOperation] = useState<any>();
  const [resultList, setResultList] = useState<any>([]);
  const dialogRef = useRef<any>();
  const {
    axiosGetReportParameterListByReportId,
    axiosCreateReportParameter,
    axiosUpdateReportParameter,
    axiosDeleteReportParameter,
    axiosGetReportCategoryList,
    axiosGetCustomQueryResult,
  }: ReportControllerType = ReportController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    if (reportID) {
      setShowBlockUI(true);
      axios
        .all([axiosGetReportParameterListByReportId(reportID), axiosGetReportCategoryList()])
        .then(
          axios.spread((responseReportParameterList: any) => {
            const exportList = new Array<any>();
            responseReportParameterList.data.data.forEach((reportParameter: ReportParameterReadDto) => {
              exportList.push({
                [Labels.LABEL_NAME]: reportParameter.name,
                [Labels.LABEL_DISPLAY_ORDER]: reportParameter.displayOrder,
                [Labels.LABEL_REQUEST_NAME]: reportParameter.requestName,
              });
            });
            setExportData(exportList);
            setReportParameterList(responseReportParameterList.data.data);
            setShowBlockUI(false);
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        });
    }
  };

  const onPage = (rows: any, first: any) => {
    setTableRows(rows);
    setFirst(first);
  };

  const validateInput = (reportParametar: ReportParameterReadDto) => {
    if (reportParametar?.name === "") {
      showMessage(MessageType.ERROR, Labels.FIELD_TYPE_ENUMERATOR_ITEM_NAME_IS_REQUIRED, "");
      return false;
    }
    if (!reportParametar?.displayOrder) {
      showMessage(MessageType.ERROR, Labels.DISPLAY_ORDER_REQUIRED, "");
      return false;
    }

    return true;
  };

  const onCreate = () => {
    if (!validateInput(selectedParameterToChange)) {
      return;
    }
    selectedParameterToChange.reportId = reportID;
    axiosCreateReportParameter(selectedParameterToChange)
      .then((response: any) => {
        setDisplayDialog(false);
        showMessage(MessageType.SUCCESS, Labels.MESSAGE_CREATE_REPORT_PARAMETER_SUCCESS, "");
        fetchData();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onUpdate = () => {
    if (!validateInput(selectedParameterToChange)) {
      return;
    }

    selectedParameterToChange.reportId = reportID;
    axiosUpdateReportParameter(selectedParameterToChange.id, selectedParameterToChange)
      .then((response: any) => {
        setDisplayDialog(false);
        showMessage(MessageType.SUCCESS, Labels.MESSAGE_UPDATE_REPORT_PARAMETER_SUCCESS, "");
        fetchData();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onDelete = () => {
    return new Promise((resolve, reject) => {
      axiosDeleteReportParameter(selectedParameterToChange.id)
        .then(() => {
          setDisplayDialog(false);
          setSelectedParameter(undefined);
          setSelectedRow(undefined);
          showMessage(MessageType.SUCCESS, Labels.MESSAGE_DELETE_REPORT_PARAMETER_SUCCESS, "");
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const dialogHeader = (entityOperation: string) => {
    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.LABEL_CREATE_REPORT_PARAMETER;
      case EntityOperation.DELETE:
        return Labels.LABEL_DELETE_REPORT_PARAMETER;
      case EntityOperation.UPDATE:
        return Labels.LABEL_UPDATE_REPORT_PARAMETER;
      case EntityOperation.READ:
        return Labels.LABEL_DETAILS_REPORT_PARAMETER;
      default:
        return "";
    }
  };
  const closeDialog = () => {
    setIndex(0);
    setDisplayDialog(false);
  };

  const openDialog = (entityOperation: String) => {
    let reportParameter: any = undefined;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        reportParameter = selectedParameter;
        break;
    }

    setEntityOperation(entityOperation);

    if (reportParameter) {
      if (reportParameter.customQuery !== "" && reportParameter.customQuery !== undefined && reportParameter.customQuery !== null) {
        axiosGetCustomQueryResult(reportParameter.customQuery)
          .then((response: any) => {
            setSelectedParameterToChange({
              id: reportParameter.id,
              reportId: reportID,
              name: reportParameter.name,
              displayOrder: reportParameter.displayOrder,
              requestName: reportParameter.requestName,
              reportParameterType: reportParameter.reportParameterType,
              customQuery: reportParameter.customQuery,
              resultList: response.data.data,
            });
            setShowBlockUI(false);
          })
          .catch((error: any) => {
            handleAxiosCallError(showMessage, error);
            setShowBlockUI(false);
          });
      } else {
        setSelectedParameterToChange({
          id: reportParameter.id,
          reportId: reportID,
          name: reportParameter.name,
          displayOrder: reportParameter.displayOrder,
          requestName: reportParameter.requestName,
          reportParameterType: reportParameter.reportParameterType,
          customQuery: reportParameter.customQuery,
        });
      }
    } else {
      setSelectedParameterToChange(undefined);
    }
    setDisplayDialog(true);
  };

  const getResultSet = (query: any) => {
    if (query !== "" && query !== undefined) {
      setShowBlockUI(true);
      axiosGetCustomQueryResult(query)
        .then((response: any) => {
          setResultList(response.data.data);
          setShowBlockUI(false);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
          setShowBlockUI(false);
        });
    } else {
      showMessage(MessageType.WARNING, Labels.LABEL_ADD_QUERY, "");
    }
  };

  const deleteReportParameter = (selectedParameterId: any) => {
    axiosDeleteReportParameter(selectedParameterId)
      .then(() => {
        setDisplayDialog(false);
        setSelectedParameter(undefined);
        setSelectedRow(undefined);
        showMessage(MessageType.SUCCESS, Labels.MESSAGE_DELETE_REPORT_PARAMETER_SUCCESS, "");
        fetchData();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });

  };

  return {
    reportParameterList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    selectedParameter,
    setSelectedParameter,
    exportData,
    onPage,
    openDialog,
    closeDialog,
    dialogHeader,
    onCreate,
    onDelete,
    onUpdate,
    entityOperation,
    dialogRef,
    displayDialog,
    setDisplayDialog,
    selectedParameterToChange,
    setSelectedParameterToChange,
    index,
    getResultSet,
    resultList,
    deleteReportParameter
  };
}

export type { ReportLogicalType };
