import { Button } from "primereact/button";
import EntityOperation from "../../../../infrastructure/system/EnumEntityOperation";
import CrudUserRightLogical, { CrudUserRightLogicalType } from "./CrudUserRightLogical";

interface CrudUserRightType {
  formOperation: any;
}

export default function CrudUserRight(props: CrudUserRightType) {
  const { roleList, userRightList, hasRight, onCreate, onDelete, userRightLabel }: CrudUserRightLogicalType = CrudUserRightLogical();

  const { formOperation } = props;

  return (
    <div className="layout-form-user-right">
      <table className="table">
        <tr>
          <td />
          {userRightList.map((u: any) => (
            <td align="center">{userRightLabel(u.code)}</td>
          ))}
        </tr>
        {roleList.map((role: any) => (
          <tr>
            <td>{role.name}</td>
            {userRightList.map((right: any) => (
              <td align="center" className="button">
                {hasRight(right.code, role.code) ? (
                  <Button
                    icon="pi pi-check"
                    className="p-button-rounded p-button-outlined p-button-success"
                    onClick={() => onDelete(right.code, role.code)}
                    disabled={formOperation !== EntityOperation.UPDATE}
                  />
                ) : (
                  <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-outlined p-button-danger"
                    onClick={() => onCreate(right.code, role.code)}
                    disabled={formOperation !== EntityOperation.UPDATE}
                  />
                )}
              </td>
            ))}
          </tr>
        ))}
      </table>
    </div>
  );
}
