import { useRef } from "react";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import ExportDataLogical, { ExportDataLogicalType } from "./ExportDataLogical";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

interface ExportDataType {
  data: any;
  name: any;
  dt: any;
}

export default function ExportData(props: ExportDataType) {
  const { exportPdf, exportXls, exportCsv, exportJson }: ExportDataLogicalType = ExportDataLogical();
  const { data, name, dt } = props;
  const { Labels } = LabelsContext();
  const menu = useRef<any>(null);
  const items = [
    {
      label: Labels.LABEL_PDF,
      icon: "pi pi-file-pdf",
      command: (e: any) => {
        exportPdf(data, name);
      },
    },
    {
      label: Labels.LABEL_CSV,
      icon: "pi pi-file-o",
      command: (e: any) => {
        exportCsv(data, dt, name);
      },
    },
    {
      label: Labels.LABEL_XLS,
      icon: "pi pi-file-excel",
      command: (e: any) => {
        exportXls(data, name);
      },
    },
    {
      label: Labels.LABEL_JSON,
      icon: "pi pi-copy",
      command: (e: any) => {
        exportJson(data, name);
      },
    },
  ];

  return (
    <div>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <Button
        label={Labels.LABEL_EXPORT}
        icon="pi pi-file"
        tooltip={Labels.DOWNLOAD_DATA_TABLE}
        tooltipOptions={{ position: "bottom" }}
        onClick={(event: any) => menu.current.toggle(event)}
        className="btn-margin-right"
        aria-controls="popup_menu"
        aria-haspopup
      />
    </div>
  );
}
