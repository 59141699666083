import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import { getOperatorName, operatorCodebookLogicalControl } from "../../../infrastructure/system/Utils";
import ExportData from "../../app/export/ExportData";
import CrudCodebookLogicalControlListLogical, { CrudCodebookLogicalControlListLogicalType } from "./CrudCodebookLogicalControlListLogical";
import CrudCodebookLogicalControlView from "./CrudCodebookLogicalControlView";
import { Dropdown } from "primereact/dropdown";
import { confirmDialog } from "primereact/confirmdialog";

export default function CrudCodebookLogicalControlList() {
  const {
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    breadCrumbItems,
    codebookLogicalControlList,
    selectedCodebookLogicalControl,
    setSelectedCodebookLogicalControl,
    dialogHeader,
    entityOperation,
    openDialog,
    closeDialog,
    displayDialog,
    dialogRef,
    index,
    codebookLogicalControlToChange,
    deleteCodebookLogicalControl,
    createCodebookLogicalControl,
    updateCodebookLogicalControl,
    onPageCodebookLogicalControl,
    codebookList,
    codebookOptionListPrimary,
    codebookOptionListSecondary,
    setCodebookLogicalControlToChange,
    exportData,
    filterOperatorFirst,
    onOperatorFilterChangeFirst,
    filterOperatorSecond,
    onOperatorFilterChangeSecond,
    onSearchValueChange,
  }: CrudCodebookLogicalControlListLogicalType = CrudCodebookLogicalControlListLogical();

  const { Labels } = LabelsContext();
  const dt = useRef<any>(null);

  const renderSourceFilterFirst = () => {
    return (
      <Dropdown
        value={filterOperatorFirst}
        options={operatorCodebookLogicalControl(Labels)}
        onChange={(e) => onOperatorFilterChangeFirst(e, dt)}
        showClear
        optionValue="code"
        optionLabel="name"
        placeholder={Labels.PLACEHOLDER_CHOOSE_OPERATOR}
        className="p-column-filter"
      />
    );
  };

  const sourceFilterFirst = renderSourceFilterFirst();

  const renderSourceFilterSecond = () => {
    return (
      <Dropdown
        value={filterOperatorSecond}
        options={operatorCodebookLogicalControl(Labels)}
        onChange={(e) => onOperatorFilterChangeSecond(e, dt)}
        showClear
        optionValue="code"
        optionLabel="name"
        placeholder={Labels.PLACEHOLDER_CHOOSE_OPERATOR}
        className="p-column-filter"
      />
    );
  };

  const sourceFilterSecond = renderSourceFilterSecond();

  const leftContents = () => (
    <>
      <Button
        icon="pi pi-plus"
        className="p-button-success btn-margin-right"
        label={Labels.BUTTON_CREATE}
        tooltip={Labels.CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_CREATE}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          openDialog(EntityOperation.CREATE);
        }}
      />

      <Button
        icon="pi pi-pencil"
        className="p-button-warning btn-margin-right"
        disabled={!selectedCodebookLogicalControl}
        label={Labels.BUTTON_EDIT}
        tooltip={Labels.CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_UPDATE}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          selectedCodebookLogicalControl && openDialog(EntityOperation.UPDATE, selectedCodebookLogicalControl);
        }}
      />
      <Button
        icon="pi pi-info-circle"
        label={Labels.BUTTON_DETAILS}
        tooltip={Labels.CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DETAILS}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedCodebookLogicalControl}
        onClick={() => {
          selectedCodebookLogicalControl && openDialog(EntityOperation.READ, selectedCodebookLogicalControl);
        }}
      />
    </>
  );

  const rightContents = () => (
    <>
      {<ExportData data={exportData} name={Labels.LOGICAL_CONTROL_TITLE} dt={dt} />}
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        label={Labels.BUTTON_DELETE}
        tooltip={Labels.CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DELETE}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedCodebookLogicalControl}
        onClick={() => {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_TYPE_LOGICAL_CONTROL,
            icon: "pi pi-power-off",
            accept: () => {
              deleteCodebookLogicalControl(selectedCodebookLogicalControl.id);
            },
          });
        }}
      />
    </>
  );

  const getFirstOperator = (column: any) => {
    return getOperatorName(column.primaryEnumOperator, Labels);
  };
  const getSecondOperator = (column: any) => {
    return getOperatorName(column.secondaryEnumOperator, Labels);
  };

  const getFirstCodebookTypeName = (column: any) => {
    return codebookList!.find((x: any) => x.code === column.primaryCodebookTypeEnum)?.name!;
  };
  const getSecondCodebookTypeName = (column: any) => {
    return codebookList!.find((x: any) => x.code === column.secondaryCodebookTypeEnum)?.name!;
  };

  const leftContentsDialog = () => (
    <>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          tooltip={Labels.CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_CREATE}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          tooltip={Labels.CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_UPDATE}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            dialogRef.current.onUpdate();
          }}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          tooltip={Labels.CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DELETE}
          tooltipOptions={{ position: "bottom" }}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </>
  );

  const rightContentsDialog = () => (
    <>
      <Button label={index === 0 ? Labels.BUTTON_CANCEL : Labels.BUTTON_CLOSE} icon="pi pi-times" onClick={closeDialog} />
    </>
  );
  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };
  return (
    <div className="layout-institution-list">
      <Panel>
        <div className="p-grid">
          <div className="p-col-12">
            <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
            <Toolbar left={leftContents} right={rightContents} />
            <div>
              <DataTable
                ref={dt}
                value={codebookLogicalControlList}
                paginator
                first={first}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rows={tableRows}
                rowsPerPageOptions={[5, 10, 20]}
                onPage={(e) => {
                  onPageCodebookLogicalControl(e.rows, e.first);
                }}
                selectionMode="single"
                selection={selectedRow}
                onSelectionChange={(e) => setSelectedRow(e.value)}
                onRowSelect={(e) => setSelectedCodebookLogicalControl(e.data)}
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                exportFilename={Labels.LOGICAL_CONTROL_TITLE}
                onValueChange={onSearchValueChange}
              >
                <Column
                  field={"primaryCodebookTypeEnum"}
                  className="column-width-percent-20"
                  header={Labels.CODEBOOK_TYPE}
                  filter
                  filterMatchMode="contains"
                  sortable
                  body={getFirstCodebookTypeName}
                />
                <Column
                  field={"primaryEnumOperator"}
                  className="column-width-percent-10"
                  header={Labels.HEADER_OPERATOR}
                  filter
                  filterElement={sourceFilterFirst}
                  filterMatchMode="contains"
                  sortable
                  body={getFirstOperator}
                />
                <Column field={"primaryFirstValue.name"} className="column-width-percent-10" header={Labels.HEADER_FIRST_VALUE} filter filterMatchMode="contains" sortable />
                <Column field={"primarySecondValue.name"} className="column-width-percent-10" header={Labels.HEADER_SECOND_VALUE} filter filterMatchMode="contains" sortable />
                <Column field={"secondaryCodebookTypeEnum"} header={Labels.CODEBOOK_TYPE} filter filterMatchMode="contains" sortable body={getSecondCodebookTypeName} />
                <Column
                  field={"secondaryEnumOperator"}
                  className="column-width-percent-10"
                  header={Labels.HEADER_OPERATOR}
                  filter
                  filterElement={sourceFilterSecond}
                  filterMatchMode="contains"
                  sortable
                  body={getSecondOperator}
                />
                <Column field={"secondaryFirstValue.name"} className="column-width-percent-10" header={Labels.HEADER_FIRST_VALUE} filter filterMatchMode="contains" sortable />
                <Column field={"secondarySecondValue.name"} className="column-width-percent-10" header={Labels.HEADER_SECOND_VALUE} filter filterMatchMode="contains" sortable />
              </DataTable>
            </div>
            <Dialog header={dialogHeader(entityOperation)} visible={displayDialog} onHide={closeDialog} style={{ width: "1000px" }} footer={dialogFooter()}>
              <CrudCodebookLogicalControlView
                codebookList={codebookList}
                codebookLogicalControlOperation={entityOperation}
                dialogRef={dialogRef}
                onDeleteCodebookLogicalControl={deleteCodebookLogicalControl}
                onCreateCodebookLogicalControl={createCodebookLogicalControl}
                onUpdateCodebookLogicalControl={updateCodebookLogicalControl}
                codebookOptionListPrimary={codebookOptionListPrimary}
                codebookOptionListSecondary={codebookOptionListSecondary}
                codebookLogicalControlToChange={codebookLogicalControlToChange}
                setCodebookLogicalControlToChange={setCodebookLogicalControlToChange}
              />
            </Dialog>
          </div>
        </div>
      </Panel>
    </div>
  );
}
