import axios from "axios";
import { useContext, useRef, useState } from "react";
import { CodebookController, CodebookControllerType } from "../../../controllers/codebook/CodebookController";
import { JurisdictionController, JurisdictionControllerType } from "../../../controllers/jurisdiction/JurisdictionController";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { DISTRICT, handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import JurisdictionReadDto from "../../../model/JurisdictionReadDto";
import { AppContext } from "../../../Store";

interface JurisdictionListLogicalType {
  jurisdictionList: Array<JurisdictionReadDto>;
  first: number;
  tableRows: number;
  selectedRow: any;
  setSelectedRow: any;
  setSelectedJurisdiction: any;
  selectedJurisdiction: any;
  dialogHeader: any;
  entityOperation: any;
  openDialog: any;
  displayDialog: boolean;
  closeDialog: any;
  dialogRef: any;
  createJurisdiction: any;
  updateJurisdiction: any;
  deleteJurisdiction: any;
  jurisdictionToChange: any;
  onPageJurisdiction: any;
  breadCrumbItems: any;
  exportData: any;
  onSearchValueChange: any;
  districtList: any;
  selectedDistrict: any;
  setSelectedDistrict: any;
}

export default function JurisdictionListLogical(): JurisdictionListLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const { Labels } = LabelsContext();
  const [jurisdictionList, setJurisdictionList] = useState<Array<JurisdictionReadDto>>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedJurisdiction, setSelectedJurisdiction] = useState<JurisdictionReadDto | null>();
  const [entityOperation, setEntityOperation] = useState<any>();
  const [jurisdictionToChange, setJurisdictionToChange] = useState<JurisdictionReadDto>();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [districtList, setDistrictList] = useState<Array<any>>([]);
  const [selectedDistrict, setSelectedDistrict] = useState<any>(null);
  const dialogRef = useRef<any>();
  const breadCrumbItems = [
    {
      label: Labels.JURISDICTIONS,
      command: () => {
        window.location.reload();
      },
    },
  ];
  const { axiosGetCodebookListWithCode }: CodebookControllerType = CodebookController();
  const { axiosGetJurisdictionList, axiosCreateJurisdiction, axiosUpdateJurisdiction, axiosDeleteJurisdiction }: JurisdictionControllerType = JurisdictionController();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (idSelectedJurisdiction?: number) => {
    setShowBlockUI(true);
    const requestJurisdictionList = axiosGetJurisdictionList();
    const requestDistrictList = axiosGetCodebookListWithCode(DISTRICT);
    axios.all([requestJurisdictionList, requestDistrictList])
      .then(axios.spread((res: any, responseDistrictList: any) => {
        setJurisdictionList(res.data.data);
        let exportList = new Array<any>();
        res.data.data.forEach((jurisdiction: any) => {
          exportList.push({
            [Labels.LABEL_CODE]: jurisdiction.code,
            [Labels.LABEL_NAME]: jurisdiction.name,
            [Labels.LABEL_DESCRIPTION]: jurisdiction.description,
            [Labels.LABEL_NOTE]: jurisdiction.note,
          });
        });
        setDistrictList(responseDistrictList.data.data);
        setExportData(exportList);
        setShowBlockUI(false);
        if (idSelectedJurisdiction) {
          res.data.data.forEach((jurisdiction: any) => {
            if (idSelectedJurisdiction === jurisdiction.id) {
              setSelectedRow(jurisdiction);
              setSelectedJurisdiction(jurisdiction);
            }
          });
        }
      }))
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const dialogHeader = (entityOperation: string) => {
    const display = selectedJurisdiction ? selectedJurisdiction.name : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.JURISDICTION_TITLE_DIALOG_CREATE;
      case EntityOperation.DELETE:
        return Labels.JURISDICTION_TITLE_DIALOG_DELETE + display;
      case EntityOperation.UPDATE:
        return Labels.JURISDICTION_TITLE_DIALOG_UPDATE + display;
      case EntityOperation.READ:
        return Labels.JURISDICTION_TITLE_DIALOG_DETAILS + display;
      default:
        return "";
    }
  };

  const openDialog = (entityOperation: String, selectedJurisdiction?: JurisdictionReadDto) => {
    let jurisdiction = undefined;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        jurisdiction = selectedJurisdiction;
        break;
    }
    districtList.forEach((element: any) => {
      if (element.code === selectedJurisdiction?.code) {
        setSelectedDistrict(element);
      }
    });
    setEntityOperation(entityOperation);
    setJurisdictionToChange(jurisdiction);
    setDisplayDialog(true);
  };

  const closeDialog = () => {
    setSelectedDistrict({});
    setDisplayDialog(false);
  };

  const validateInput = (jurisdiction: JurisdictionReadDto) => {
    if (jurisdiction === undefined || jurisdiction?.code === "") {
      showMessage(MessageType.ERROR, Labels.JURISDICTION_CODE_IS_REQUIRED, "");
      return false;
    }
    if (jurisdiction?.name === "") {
      showMessage(MessageType.ERROR, Labels.JURISDICTION_NAME_IS_REQUIRED, "");
      return false;
    }
    if (!jurisdiction?.displayOrder) {
      showMessage(MessageType.ERROR, Labels.DISPLAY_ORDER_REQUIRED, "");
      return false;
    }

    return true;
  };

  const createJurisdiction = (jurisdiction: JurisdictionReadDto) => {
    return new Promise((resolve, reject) => {
      if (!validateInput(jurisdiction)) {
        return;
      }
      axiosCreateJurisdiction(jurisdiction)
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.JURISDICTION_TITLE_MESSAGE_CREATE_USER_GROUP_SUCCESS, "");
          fetchData(response.data.data.id);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const updateJurisdiction = (jurisdiction: JurisdictionReadDto) => {
    return new Promise((resolve, reject) => {
      if (!validateInput(jurisdiction)) {
        return;
      }
      axiosUpdateJurisdiction(jurisdiction)
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.JURISDICTION_TITLE_MESSAGE_UPDATE_USER_GROUP_SUCCESS, "");
          fetchData(response.data.data.id);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const deleteJurisdiction = (jurisdictionId: number) => {
    return new Promise((resolve, reject) => {
      axiosDeleteJurisdiction(jurisdictionId)
        .then(() => {
          setDisplayDialog(false);
          setSelectedRow(undefined);
          setSelectedJurisdiction(undefined);
          showMessage(MessageType.SUCCESS, Labels.JURISDICTION_TITLE_MESSAGE_DELETE_USER_GROUP_SUCCESS, "");
          fetchData();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    });
  };

  const onSearchValueChange = (sortedData: Array<any>) => {
    const exportList = new Array<any>();
    sortedData?.forEach((jurisdiction: any) => {
      exportList.push({ [Labels.LABEL_CODE]: jurisdiction.code, [Labels.LABEL_NAME]: jurisdiction.name, [Labels.LABEL_DESCRIPTION]: jurisdiction.description, [Labels.LABEL_NOTE]: jurisdiction.note });
    });
    setExportData(exportList);
  };

  const onPageJurisdiction = (rows: any, first: any) => {
    setTableRows(rows);
    setFirst(first);
  };

  return {
    jurisdictionList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    setSelectedJurisdiction,
    selectedJurisdiction,
    dialogHeader,
    entityOperation,
    openDialog,
    displayDialog,
    closeDialog,
    dialogRef,
    createJurisdiction,
    updateJurisdiction,
    deleteJurisdiction,
    jurisdictionToChange,
    onPageJurisdiction,
    breadCrumbItems,
    exportData,
    onSearchValueChange,
    districtList,
    selectedDistrict,
    setSelectedDistrict
  };
}

export type { JurisdictionListLogicalType };
