const Labels = {
  LATIN: "Латиница",
  CYRILLIC: "Ћирилица",
  NEW_LINE: "",
  TAB: "",
  SPACE: "",
  DASH: "-",
  SLASH: "/",
  SPECIAL_CHAR_REQUIRED: "*",
  APPLICATION_NAME: "Национални регистар малигних тумора Србије",
  COPYRIGHT: "Девелопед бy",
  HELIANT: " HELIANT",
  MEMBERSHIP: "Чланство",
  LOGIN: "Пријава",
  FORGOTTEN_PASSWORD_OR_USERNAME: "Заборављена лозинка или корисничко име?",
  TITLE_LOGIN: "Унесите Ваше приступне параметре",
  TITLE_RESET_PASSWORD: "Постављање нове лозинке",
  MENU_FORMS: "Формулари",
  MENU_ADMIN: "Администрација",
  MENU_REPORT: "Извештаји",
  MENU_REPORT_PARAMETER: "Параметри извештаја",
  BUTTON_CREATE: "Креирај",
  BUTTON_UPDATE: "Измени",
  BUTTON_DETAILS: "Детаљи",
  BUTTON_LOGIN: "Пријава",
  BUTTON_LOGOUT: "Одјава",
  BUTTON_SAVE: "Сачувај",
  BUTTON_CANCEL: "Одустани",
  BUTTON_DELETE: "Обриши",
  BUTTON_UPLOAD_IMAGE: "Учитај слику",
  BUTTON_RESET_PASSWORD: "Постави лозинку",
  BUTTON_NEW_FORM: "Нова пријава",
  BUTTON_EDIT_DRAFT: "Измени нацрт",
  BUTTON_EDIT: "Измени",
  BUTTON_BACK: "Назад",
  BUTTON_ADD_USER: "Додај корисника",
  BUTTON_ADD: "Додај",
  BUTTON_YES: "Да",
  BUTTON_NO: "Не",
  BUTTON_CLOSE: "Затвори",
  BUTTON_APPROVE: "Одобри",
  BUTTON_REVISION: "Ревизија",
  BUTTON_MERGE_CCF: "Спајање пријава",
  BUTTON_GENERATE_REPORT: "Генериши извештај",
  TOOLTIP_MERGE_CCF: "Спајање две пријаве",
  WORNING_PASSWORD: "Лозинка мора садржати најмање 8 алфа-нумеричких карактера",
  PLACEHOLDER_FIRST_NAME_REQUIRED: "Име *",
  PLACEHOLDER_LAST_NAME_REQUIRED: "Презиме *",
  PLACEHOLDER_CHOSEN_NAME_REQUIRED: "Изабрано име *",
  PLACEHOLDER_USERNAME: "Корисничко име",
  PLACEHOLDER_PASSWORD: "Лозинка",
  PLACEHOLDER_EMAIL_REQUIRED: "Емаил (корисничко име) *",
  PLACEHOLDER_PASSWORD_REQUIRED: "Лозинка *",
  PLACEHOLDER_PASSWORD_CONFIRM_REQUIRED: "Потврда лозинке *",
  PLACEHOLDER_POST_COMMENT: "Додај коментар",
  PLACEHOLDER_SEARCH_BY_NAME: "Претрага по имену",
  CREATE_REPORT_OF_DEATH: "Креирање пријаве о смрти",
  BADGE_TOOLTIP: "Пристигле пријаве",
  PLACEHOLDER_NEW_PASSWORD: "Нова лозинка",
  PLACEHOLDER_NEW_PASSWORD_CONFIRM: "Потврда нове лозинке",
  PLACEHOLDER_SEARCH_BY_STATUS: "Претрага по статусу",
  PLACEHOLDER_INSTITUTION_TYPE: "Тип установе",
  PLACEHOLDER_CHOOSE_STATUS: "Изаберите статус",
  PLACEHOLDER_CHOOSE_FORM_TYPE: "Изаберите тип формулара",
  PLACEHOLDER_CHOOSE_OPERATOR: "Изаберите оператор",
  PLACEHOLDER_SEARCH_BY_INSTITUTION: "Претрага по имену пацијента",
  PLACEHOLDER_PATIENT_INSTITUTION: "Претрага по имену установе",
  PLACEHOLDER_PATIENT_LAST_NAME: "Претрага по презимену пацијента",
  PLACEHOLDER_INSERT_DATE: "Претрага по датуму уноса",
  PLACEHOLDER_INSERT_CODE_OR_NAME: "Унесите део шифре или назива",
  BUTTON_REPORT_OF_DEATH: "Пријава смрти",
  CHECKBOX_REMEMBER_PASSWORD: "запамти лозинку",
  LABEL_MUNICIPALITY: "Општина",
  LABEL_DESCRIPTION: "Опис",
  LABEL_NOTE: "Напомена",
  LABEL_EMAIL: "Емаил",
  LABEL_FIRSTNAME: "Име",
  LABEL_LASTNAME: "Презиме",
  LABEL_CHOSEN_NAME: "Изабрано име",
  LABEL_DELETE: "Обриши",
  LABEL_POST: "Постави",
  LABEL_BEFORE: "пре",
  LABEL_MINUTES: "минута",
  LABEL_HOURS: "часова",
  LABEL_EDIT: "Измени",
  LABEL_USER_PROFILE: "Кориснички профил",
  LABEL_DISPLAY_ORDER: "Приказни редослед",
  LABEL_BACK_ON_LOGIN: "Назад на страну",
  LABEL_TABLE_OF_FORMS: "Табела формулара",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED: "Да ли сте сигурни да желите да се одјавите?",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_BACK: "Да ли сте сигурни да желите назад?",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_GIVE_UP: "Да ли сте сигурни да желите да одустанете?",
  LABEL_FORM_LIST: "Формулари",
  LABEL_CODE: "Шифра",
  LABEL_NAME: "Назив",
  LABEL_UPDATE_FORM: "Измена формулара ",
  LABEL_DELETE_FORM: "Брисање фромулара ",
  LABEL_DETAILS_FORM: "Детаљи формулара ",
  LABEL_CREATE_FORM: "Креирање формулара ",
  LABEL_UPDATE_FORM_FIELD: "Измена поља формулара ",
  LABEL_DELETE_FORM_FIELD: "Брисање поља формулара ",
  LABEL_DETAILS_FORM_FIELD: "Детаљи поља формулара ",
  LABEL_CREATE_FORM_FIELD: "Креирање поља формулара ",
  LABEL_UPDATE_FORM_SEMANTIC_VALIDATION: "Измена семантичке валидације ",
  LABEL_DELETE_FORM_SEMANTIC_VALIDATION: "Брисање семантичке валидације ",
  LABEL_DETAILS_FORM_SEMANTIC_VALIDATION: "Детаљи семантичке валидације ",
  LABEL_CREATE_FORM_SEMANTIC_VALIDATION: "Креирање семантичке валидације ",
  LABEL_DELETE_REPORT_ACCESS: "Брисање приступа извештају ",
  LABEL_ADD_REPORT_ACCESS: "Додавање приступа извештају",
  LABEL_UPDATE_FIELD_TYPE: "Измена типа поља ",
  LABEL_DELETE_FIELD_TYPE: "Брисање типа поља ",
  LABEL_DETAILS_FIELD_TYPE: "Детаљи типа поља ",
  LABEL_CREATE_FIELD_TYPE: "Креирање типа поља ",
  LABEL_CREATE_REPORT: "Креирање извештаја ",
  LABEL_UPDATE_REPORT: "Измена извештаја ",
  LABEL_DELETE_REPORT: "Брисање извештаја ",
  LABEL_DETAILS_REPORT: "Детаљи извештаја ",
  LABEL_CREATE_REPORT_PARAMETER: "Креирање параметра извештаја ",
  LABEL_UPDATE_REPORT_PARAMETER: "Измена параметра извештаја ",
  LABEL_DELETE_REPORT_PARAMETER: "Брисање параметра извештаја ",
  LABEL_DETAILS_REPORT_PARAMETER: "Детаљи параметра извештаја ",
  LABEL_FIELD_TYPE: "Типови поља",
  LABEL_CREATE: "Креирај",
  LABEL_DETAILS: "Детаљи",
  LABEL_NAME_SHORT: "Скраћени назив",
  LABEL_DISTRICT: "Округ",
  LABEL_JURISDICTION: "Јурисдикција",
  LABEL_ADDRESS: "Адреса",
  LABEL_UNIT: "Јединица",
  LABEL_USER: "Корисник",
  LABEL_INSTITUTION_LIST: "Установе",
  LABEL_USER_INSTITUTION_LIST: "Установе корисника",
  LABEL_USER_GROUP_USER_LIST: "Корисничке групе корисника",
  LABEL_TEXT: "Текстуални",
  LABEL_LONG_TEXT: "Дугачак текст",
  LABEL_INTEGER: "Целобројни",
  LABEL_NUMBER: "Бројни",
  LABEL_FLOAT: "Децимални",
  LABEL_DATE_TIME: "Датумски",
  LABEL_SET: "Скуповни",
  LABEL_LABEL: "Текст",
  LABEL_BOOLEAN: "Изборни",
  LABEL_PANEL: "Панел",
  LABEL_CODEBOOK: "Шифарнички",
  LABEL_COODEBOOK_TYPE_LIST: "Шифарници",
  LABEL_COODEBOOK_LIST: "Шифарник",
  LABEL_VALUE_NUMBER: "Бројна вредност",
  LABEL_UPLOADED_ATTACHMENTS: "Приложена документација",
  LABEL_NO_UPLOADED_ATTACHMENTS: "Нема приложене документације",
  LABEL_UPLOADED: "Отпремљено",
  LABEL_ATTACHMENTS: "Документација",
  LABEL_UPLOAD: "Отпреми",
  LABEL_CHOOSE: "Припреми",
  LABEL_REMOVE_DOCUMENT: "Да ли сте сигурни да желите да уклоните документ?",
  LABEL_EXPORT: "Преузми",
  LABEL_PDF: "PDF",
  LABEL_XLS: "XLS",
  LABEL_CSV: "CSV",
  LABEL_JSON: "JSON",
  LABEL_STATUS: "Статус формулара",
  LABEL_FORM_TYPE: "Тип формулара",
  LABEL_MERGE_FORMS: "Спајање пријава",
  LABEL_APPROVE: "Прихвати",
  LABEL_APPROVE_FIRST_CCF: "Прихвати први формулар",
  LABEL_APPROVE_SECOND_CCF: "Прихвати други формулар",
  LABEL_CHOOSE_TWO_CCF: "Одаберите две пријаве које желите да спојите",
  LABEL_REQUEST_NAME: "Request name",
  LABEL_REPORT_GENERATED: "Генерисани извештаји",
  LABEL_GENERATE_REPORT: "Генерисање извештаја",
  LABEL_CHOOSE_REPORT: "Изаберите извештај",
  LABEL_PARAMETER: "Параметри",
  LABEL_CUSTOM_QUERY: "SQL упит",
  LABEL_REPORT_PARAMETER_TYPE: "Тип параметра",
  LABEL_ACTIONS: "Акције",
  LABEL_CREATE_LABEL: "Снимање лабеле",
  LABEL_TRANSLATE_LABEL: "Преводи лабеле",
  LABEL: "Лабела",
  LABEL_PARENT_FIELD: "Парент поље",
  HOME_PAGE: "Почетна страна",

  LABEL_DOWNLOAD_IN_FORMAT: "Преузмите извештај у одабраном формату",
  LABEL_PUBLIC_REPORTS: "Јавни извештаји",
  REPORT_PARAMETERS: "Параметри извештаја",
  MESSAGE_RESULT_LIST_IS_EMPTY: "Нема пронађених резултата.",
  LABEL_FILE_FORMAT: "Формат фајла",
  DOWNLOAD_DATA_TABLE: "Преузмите садржај табеле",
  FORM_STATUS: "Статус",
  LABLE_COMMENTS: "Коментари",
  LOGICAL_CONTROL: "Логичка правила",
  COMMENT_IS_REQUIRED: "Обавезено је унети коментар.",
  TAB_ARTICLE_SUMMARY: "Основни подаци",
  TAB_ARTICLE_CONTENT: "Садржај",
  TAB_ARTICLE_PREVIEW_SUMMARY: "Кратак преглед",
  TAB_ARTICLE_PREVIEW_CONTENT: "Преглед садржаја",
  TABLE_EMPTY_MESSAGE: "Нема података",
  COLUMN_TITLE_DISTANCE: "Пређено",
  COLUMN_HEADER_NAME: "Име",
  COLUMN_HEADER_DESCRIPTION: "Опис",
  HEADER_CATEGORY: "Категорија",
  HEADER_JASPER_LOCATION: "Локација извештаја",
  HEADER_FILE_FORMAT: "Формат фајла",
  COLUMN_HEADER_STATUS: "Статус",
  HEADER_NAME: "Назив",
  HEADER_CODE: "Шифра",
  HEADER_OPERATOR: "Оператор",
  HEADER_FIRST_VALUE: "Прва вредност",
  HEADER_SECOND_VALUE: "Друга вредност",
  HEADER_DESCRIPTION: "Опис",
  HEADER_DISPLAY_ORDER: "Приказни редослед",
  HEADER_NOTE: "Напомена",
  HEADER_FIELD: "Поље",
  HEADER_CUSTOM_FORMULA: "Формула",
  HEADER_FIRST_COODEBOOK: "Први шифарник",
  HEADER_SECOND_COODEBOOK: "Други шифарник",
  MESSAGES_FIRSTNAME_FIELD: "Поље 'Име' је обавезно",
  MESSAGES_LASTNAME_FIELD: "Поље 'Презиме' је обавезно",
  MESSAGES_CHOSEN_NAME_FIELD: "Поље 'Изабрано име' је обавезно",
  MESSAGES_EMAIL_FIELD: "Поље 'Емаил' је обавезно",
  MESSAGES_USERNAME_FIELD: "Поље 'Корисничко име' је обавезно",
  MESSAGES_PASSWORD_FIELD: "Поље 'Лозинка' је обавезно",
  MESSAGES_PASSWORD_CONFIRM_FIELD: "Поље 'Потврда лозинке' је обавезно",
  MESSAGES_PASSWORD_LENGTH: "Лозинка мора садржати најмање 8 алфа-нумеричких карактера",
  MESSAGES_NOT_EQUAL_PASSWORD: "Лозинка и потврда лозинке морају бити исте",
  MESSAGES_UPLOAD_SIZE_50KB_ERROR: "Датотека мора бити мања од 50 Кб.",
  MESSAGES_CHOOSE_IMAGE: "Изаберите",
  MESSAGES_FILE_SIZE_ERROR: "Максимална величина слике је 5МБ",
  MESSAGES_UPLOAD_IMAGE_ERROR: "Неуспешно учитвање слике",
  MESSAGES_REGISTRATION_RESEND: "Уколико Вам није стигао мејл, кликните на дугме Пошаљи опет мејл",
  MESSAGES_NO_RESULT_FOUND: "Нема резултата",
  MESSAGES_NEW_PASSWORD_FIELD: "Поље 'Нова лозинка' је обавезно!",
  MESSAGES_NEW_PASSWORD_CONFIRM_FIELD: "Поље 'Потврда нове лозинке' је обавезно!",
  MESSAGES_CODE_REQUIRED: "Шифра је обавезан податак!",
  MESSAGES_NAME_REQUIRED: "Назив је обавезан податак!",
  MESSAGES_INSTITUTION_TYPE_REQUIRED: "Тип установе је обавезан податак!",
  MESSAGES_MUNICIPALITY_REQUIRED: "Општина је обавезан податак!",
  MESSAGES_DISTRICT_REQUIRED: "Округ је обавезан податак!",
  MESSAGES_JURISDICTION_REQUIRED: "Јурисдикција је обавезан податак!",
  MESSAGES_DELETE_DOCUMENT_SUCCESS: "Успешно сте обрисали документ.",
  MESSAGES_APPROVE_DOCUMENT_SUCCESS: "Пријава је одобрена и послата Батуту.",
  MESSAGES_FOR_REVISION_DOCUMENT_SUCCESS: "Пријава је послата лекару на ревизију.",
  MESSAGES_INVALID_FILE_SIZE: "Максимлана величина датотеке је 5 МБ.",
  MESSAGE_SEND_BACK_TO_REVISION: "Да ли сте сигурни да желите да вратите формулар на ревизију?",
  MESSAGE_APPROVE_FORM: "Да ли сте сигурни да желите да одобрите формулар?",
  MESSAGES_MERGE_DIFFERENT_FORMS: "Није могуће спајати пријаве различитог типа.",
  MESSAGES_MERGE_SUCCESS: "Успешно сте спојили пријаве.",
  MESSAGES_ARE_YOU_SURE_YOU_WANT_TO_MERGE: "Да ли сте сигурни да желите да спојите пријаве?",
  MESSAGES_DELETE_REPORT_GENERATED: "Да ли сте сигурни да желите да обришете генерисани извештај?",
  MESSAGES_REPORT_GENERATE_GENERATE_SUCCESS: "Извештај се генерише.",
  MESSAGES_REPORT_GENERATE_DELETE_SUCCESS: "Успешно сте обрисали генерисани извештај.",
  MESSAGE_CREATE_LABEL_SUCCESS: "Успешно креирана лабела.",
  MESSAGE_UPDATE_LABEL_SUCCESS: "Успешно измењена лабела.",
  USER_PROFILE_CHANGE: "Измена корисничког профила",
  USER_PROFILE_CHANGE_EMAIL: "Емаил",
  USER_PROFILE_CHANGE_NAME: "Име",
  USER_PROFILE_CHANGE_SURNAME: "Презиме",
  USER_PROFILE_CHANGE_LANGUANGE: "Писмо",
  USER_PROFILE_BUTTON_SAVE: "Сачувај",
  USER_PROFILE_BUTTON_CHANGE_PASSWORD: "Промена лозинке",
  USER_PROFILE_CHANGE_IMAGE: "Промени слику",
  USER_PROFILE_BUTTON_CANCEL: "Поништи",
  USER_PROFILE_CHANGE_PASSWORD: "Промена лозинке",
  USER_PROFILE_PASSWORD_CHANGE_ERROR: "Грешка приликом промене лозинке.",
  USER_PROFILE_MESSAGES_NEW_PASSWORD: "Морате унети нову лозинку.",
  USER_PROFILE_MESSAGES_OLD_PASSWORD: "Морате унети стару лозинку.",
  USER_PROFILE_MESSAGES_PASSWORD_CONFIRMATION: "Морате унети потврду нове лозинке.",
  USER_PROFILE_MESSAGES_PASSWORDS_NOT_SAME: "Нова лозинка и потврда лозинке нису исте.",
  USER_PROFILE_BUTTON_SAVE_NEW_PASSWORD: "Сачувај нову лозинку",
  USER_PROFILE_OLD_PASSWORD: "Стара лозинка",
  USER_PROFILE_NEW_PASSWORD: "Нова лозинка",
  USER_PROFILE_CONFIRM_NEW_PASSWORD: "Потврда нове лозинке",
  USER_PROFILE_EDIT_SUCCESS: "Успешно сачуване измене.",
  USER_PROFILE_CHANGE_PASSWORD_SUCCESS: "Успешно промењена лозинка.",
  SAVE_USER_DATA: "Сачувај податке о кориснику",
  CANCEL_USER_DATA: "Поништи промене",
  PAGINATION_SHOWING_TO_OF_ENTRIES: "Приказано {first} до {last} од {totalRecords} резултата",
  NEW_FORM_HEALTH_INSTITUTION: "Здравствена установа",
  NEW_FORM_TITLE_PANEL_CREATE_FORM: "Попуњавање формулара:",
  NEW_FORM_SAVE: "Сачувај",
  NEW_FORM_SAVE_AS_DRAFT: "Сачувај нацрт",
  NEW_FORM_CANCEL: "Поништи",
  FORM_SUCCESS_SAVE: "Пријава је прослеђена регионалном заводу.",
  FORM_FILLED_SUCCESS_DELETED: "Попуњен формулар је успешно обрисан.",
  FORM_FAILED_SAVE: "Формулар није сачуван. Исправите грешке на формулару!",
  FORM: "Формулар",
  FORM_FIELDS: "Поља формулара",
  SEMANTIC_VALIDATION: "Семантичка валидација",
  USER_RIGHT: "Права приступа",
  FIELD_DROPDOWN_CHOOSE: "Изаберите поље...",
  OPERATOR_FIELD_DROPDOWN_CHOOSE: "Изаберите оператор...",
  INSTITUTION_DROPDOWN_CHOOSE: "Изаберите установу...",
  CODEBOOK_OPTION_DROPDOWN_CHOOSE: "Изаберите ставку шифарника...",
  FILE_FORMAT_DROPDOWN_CHOOSE: "Изаберите формат фајла...",
  PLACEHOLDER_CHOOSEN_FORM_TYPE_LIST: "Изабран(о) je {0} формат(а)",
  CATEGORY_DROPDOWN_CHOOSE: "Изаберите категорију...",
  PLACEHOLDER_CHOOSE_PARAMETAR_TYPE: "Изаберите тип параметра...",
  CONTAIS: "садржи",
  NOT_CONTAIS: "не садржи",
  START_WITH: "почиње са",
  END_WITH: "завршава се",
  CHOOSE: "изабрано",
  NOT_CHOOSE: "није изабрано",
  ERROR_PAGE_404: "404",
  ERROR_PAGE_PAGE_NOT_FOUND: "Страница није пронађена.",
  ERROR_PAGE_HOME_BUTTON: "Назад на почетну страну",
  USER_GROUP: "Корисничка група",
  USER_GROUP_ROLES: "Улоге корисничке групе",
  USER_GROUP_LIST: "Корисничке групе",
  USER_GROUP_LIST_CODE: "Шифра",
  USER_GROUP_LIST_NAME: "Назив",
  USER_GROUP_LIST_DESCRIPTION: "Опис",
  USER_GROUP_LIST_NOTE: "Напомена",
  USER_GROUP_LIST_CREATE: "Креирај",
  USER_GROUP_LIST_EDIT: "Измени",
  USER_GROUP_LIST_DETAILS: "Детаљи",
  USER_GROUP_LIST_DELETE: "Обриши",
  USER_GROUP_TITLE_DIALOG_CREATE: "Креирање корисничке групе ",
  USER_GROUP_TITLE_DIALOG_UPDATE: "Измена корисничке групе ",
  USER_GROUP_TITLE_DIALOG_DELETE: "Брисање корисничке групе ",
  USER_GROUP_TITLE_DIALOG_DETAILS: "Детаљи корисничке групе ",
  USER_GROUP_TITLE_MESSAGE_CREATE_USER_GROUP_SUCCESS: "Корисничка група је успешно креирана.",
  USER_GROUP_TITLE_MESSAGE_UPDATE_USER_GROUP_SUCCESS: "Корисничка група је успешно измењена.",
  USER_GROUP_TITLE_MESSAGE_DELETE_USER_GROUP_SUCCESS: "Корисничка група је успешно обрисана.",
  USER_GROUP_TITLE_MESSAGE_DELETE_USER_GROUP_USER_SUCCESS: "Корисник је успешно обрисан из корисничке групе.",
  USER_GROUP_TITLE_MESSAGE_CREATE_USER_GROUP_USER_SUCCESS: "Корисник је успешно додат корисничкој групи.",
  FORM_LIST: "Формулари",
  FORM_TITLE_DIALOG_CREATE: "Креирање формулара",
  FORM_TITLE_DIALOG_UPDATE: "Измена формулара:",
  FORM_TITLE_DIALOG_DELETE: "Брисање формулара:",
  FORM_TITLE_DIALOG_DETAILS: "Детаљи формулара:",
  TITLE_MESSAGE_CREATE_FORM_SUCCESS: "Формулар је успешно креиран.",
  TITLE_MESSAGE_UPDATE_FORM_SUCCESS: "Формулар је успешно измењен.",
  TITLE_MESSAGE_DELETE_FORM_SUCCESS: "Формулар је успешно обрисан.",
  JURISDICTIONS: "Јурисдикције",
  USER_GROUP_JURISDICTION: "Јурисдикције корисничке групе",
  JURISDICTION: "Јурисдикција",
  JURISDICTION_CODE: "Шифра",
  JURISDICTION_NAME: "Назив",
  JURISDICTION_DESCRIPTION: "Опис",
  JURISDICTION_NOTE: "Напомена",
  JURISDICTION_LIST_CREATE: "Креирај",
  JURISDICTION_LIST_EDIT: "Измени",
  JURISDICTION_LIST_DETAILS: "Детаљи",
  JURISDICTION_LIST_DELETE: "Обриши",
  JURISDICTION_TITLE_DIALOG_CREATE: "Креирање јурисдикције ",
  JURISDICTION_TITLE_DIALOG_UPDATE: "Измена јурисдикције ",
  JURISDICTION_TITLE_DIALOG_DELETE: "Брисање јурисдикције ",
  JURISDICTION_TITLE_DIALOG_DETAILS: "Детаљи јурисдикције ",
  JURISDICTION_DISPLAY_ORDER: "Приказни редослед",
  JURISDICTION_TITLE_MESSAGE_CREATE_USER_GROUP_SUCCESS: "Јурисдикција је успешно креирана.",
  JURISDICTION_TITLE_MESSAGE_UPDATE_USER_GROUP_SUCCESS: "Јурисдикција је успешно измењена.",
  JURISDICTION_TITLE_MESSAGE_DELETE_USER_GROUP_SUCCESS: "Јурисдикација је успешно обрисана.",
  JURISDICTION_CODE_IS_REQUIRED: "Обавезно је унети шифру јурисдикције.",
  JURISDICTION_NAME_IS_REQUIRED: "Обавезно је унети назив јурисдикције.",
  USER_LIST: "Корисници",
  USER_FIRST_NAME: "Име",
  USER_CODE: "Шифра",
  USER_LAST_NAME: "Презиме",
  USER_USER_NAME: "Корисничко име",
  USER_EMAIL: "Емаил",
  USER_INSTITUTION: "Установа",
  USER_PASSWORD: "Лозинка",
  USER_PASSWORD_CONFIRM: "Потврда лозинке",
  USER_SEARCH: "Претрага",
  ADD_SEARCH_PARAMETER: "Додај параметар претраге",
  USER_LIST_CREATE: "Креирај",
  USER_LIST_EDIT: "Измени",
  USER_LIST_DETAILS: "Детаљи",
  USER_LIST_DELETE: "Обриши",
  USER_TITLE_DIALOG_CREATE: "Креирање корисника",
  USER_TITLE_DIALOG_UPDATE: "Измена корисника ",
  USER_TITLE_DIALOG_DELETE: "Брисање корисника ",
  USER_TITLE_DIALOG_DETAILS: "Детаљи о кориснику ",
  USER_PICK_INSTITUTION: "Одаберите установу",
  USER_TITLE_MESSAGE_DELETE_USER_SUCCESS: "Корисник је успешно обрисан.",
  USER_TITLE_MESSAGE_CREATE_USER_SUCCESS: "Корисник је успешно креиран.",
  USER_TITLE_MESSAGE_UPDATE_USER_SUCCESS: "Корисник је успешно измењен.",
  USER_USERNAME_IS_REQUIRED: "Обавезно је унети корисничко име.",
  USER_EMAIL_IS_REQUIRED: "Обавезно је унети емаил.",
  USER_PASSWORD_DEFAULT: "УПроцесуКреирања",
  INSTITUTION_LIST: "Установе",
  INSTITUTION_TITLE_DIALOG_CREATE: "Креирање установе ",
  INSTITUTION_TITLE_DIALOG_UPDATE: "Измена установе ",
  INSTITUTION_TITLE_DIALOG_DETAILS: "Детаљи о установи ",
  INSTITUTION_TITLE_DIALOG_DELETE: "Брисање установе ",
  INSTITUTION_TITLE_MESSAGE_DELETE_INSTITUTION_SUCCESS: "Установа је успешно обрисана.",
  INSTITUTION_TITLE_MESSAGE_CREATE_INSTITUTION_SUCCESS: "Установа је успешно креирана.",
  INSTITUTION_TITLE_MESSAGE_UPDATE_INSTITUTION_SUCCESS: "Установа је успешно измењена.",
  INSTITUTION_NAME_IS_REQUIRED: "Обавезно је унети назив установе.",
  INSTITUTION_CODE_IS_REQUIRED: "Обавезно је унети шифру установе.",
  INSTITUTION_LIST_CREATE: "Креирај",
  INSTITUTION_LIST_EDIT: "Измени",
  INSTITUTION_LIST_DETAILS: "Детаљи",
  INSTITUTION_LIST_DELETE: "Обриши",
  INSTITUTION_SEARCH: "Претрага",
  INSTITUTION_TYPE: "Тип установе",
  INSTITUTION_WS_USERNAME: "BC корисничко име",
  INSTITUTION_WS_PASSWORD: "ВС  шифра",
  INSTITUTION_GPS_LOCATION: "ГПС локација",
  FIELD_TITLE_DIALOG_CREATE: "Креирање поља формулара ",
  FIELD_TITLE_DIALOG_UPDATE: "Измена поља формулара ",
  FIELD_TITLE_DIALOG_DELETE: "Брисање поља формулара ",
  FIELD_TITLE_DIALOG_DETAILS: "Детаљи о пољу формулара ",
  CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_CREATE: "Креирање логичке контроле",
  CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_UPDATE: "Измена логичке контроле",
  CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DETAILS: "Детаљи логичке контроле",
  CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DELETE: "Брисање логичке контроле",

  IS_REQUIRED_FIELD: "Обавезно поље",
  IS_RELEVANT_FIELD: "Релевантно поље",
  IS_MULTISELECT_FIELD: "Више-опционо поље",
  IS_DISABLED_FIELD: "Онеспособљено поље",
  MULTISELECT: "Више-опциони",
  EQUATION_SHOW_LABEL_ON_FORM: "Формула за приказ лабеле на формулару",
  EQUATION_SHOW_FIELD_ON_FORM: "Формула за приказ поља на формулару",
  EQUATION_CALCULATED_VALUE: "Формула за рачунање вредности поља",
  HTML_INFO: "ХТМЛ информације",
  TITLE_MESSAGE_DELETE_FIELD_SUCCESS: "Успешно избрисано поље",
  TITLE_MESSAGE_UPDATE_FIELD_SUCCESS: "Успешно измењено поље",
  TITLE_MESSAGE_CREATE_FIELD_SUCCESS: "Успешно креирано поље",
  FIELD_CODE_IS_REQUIRED: "Обавезно је унети шифру поља.",
  FIELD_NAME_IS_REQUIRED: "Обавезно је унети назив поља.",
  INITIAL_VALUE: "Подразумевана вредност",
  FORM_FILLED_EDIT_DRAFT: "Измена нацрта формулара",
  FORM_FILLED_SAVE: "Сачувај",
  FORM_FILLED_SAVE_AS_DRAFT: "Сачувај нацрт",
  APPROVE_FORM: "Одобри формулар",
  REVISION_FORM: "Врати на ревизију формулар",
  CANCEL_FORM: "Одустани од чувања формулара",
  FORM_FILLED_CANCEL: "Одустани",
  ROLES: "Улоге",
  FIELD_IS_REQUIRED: "Поље је обавезно",
  VALUE_SHOULD_BE_BETWEEN: "Вредност треба да буде између",
  VALUE_SHOULD_BE_GREATER_THEN: "Вредност треба да буде веца",
  VALUE_SHOULD_BE_LESS_THEN: "Вредност треба да буде мања",
  IT_SHOULD_BE_MORE_THEN: "Треба да буде више од",
  FIELD_SHOULD_HAVE: "Поље треба да има",
  IT_SHOULD_BE_LESS_THEN: "Треба да буде мање од",
  IT_SHOULD_BE_BETWEEN: "Треба да буде између",
  CHARACTERS: "карактера",
  FIELD_TYPES: "Типови поља",
  FIELD_TYPE_MIN: "Минимална вредност",
  FIELD_TYPE_MAX: "Максимална вредност",
  FIELD_TYPE_FORMAT: "Формат",
  FIELD_TYPE_CREATE: "Креирање типа поља",
  TITLE_MESSAGE_CREATE_FIELD_TYPE_SUCCESS: "Тип поља је успешно креиран.",
  TITLE_MESSAGE_UPDATE_FIELD_TYPE_SUCCESS: "Тип поља је успешно измењен.",
  TITLE_MESSAGE_DELETE_FIELD_TYPE_SUCCESS: "Тип поља је успешно обрисан.",
  FIELD_TYPE: "Тип поља",
  FIELD_TYPE_DECIMALS: "Број децимала",
  FIELD_TYPE_ENUM: "Скуповне ставке",
  FIELD_TYPE_COMPONENT_TYPE: "Тип компоненте",
  FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_CREATE: "Креирање скуповне ставке ",
  FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_UPDATE: "Измена скуповне ставке ",
  FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_DELETE: "Брисање скуповне ставке ",
  FIELD_TYPE_ENUMERATOR_ITEM_TITLE_DIALOG_DETAILS: "Детаљи скуповне ставке ",
  FIELD_TYPE_ENUMERATOR_ITEM_CODE_IS_REQUIRED: "Обавезно је унети шифру скуповне ставке.",
  FIELD_TYPE_ENUMERATOR_ITEM_NAME_IS_REQUIRED: "Обавезно је унети назив скуповне ставке.",
  FIELD_TYPE_ENUMERATOR_ITEM_TITLE_MESSAGE_CREATE_USER_GROUP_SUCCESS: "Скуповна ставка је успешно креирана.",
  FIELD_TYPE_ENUMERATOR_ITEM_TITLE_MESSAGE_UPDATE_USER_GROUP_SUCCESS: "Скуповна ставка је успешно измењена.",
  FIELD_TYPE_ENUMERATOR_ITEM_TITLE_MESSAGE_DELETE_USER_GROUP_SUCCESS: "Скуповна ставка је успешно обрисана.",
  INSTITUTION: "Установа",
  INSTITUTION_TITLE_MESSAGE_DELETE_USER_INSTITUTION_SUCCESS: "Корисник је успешно обрисан.",
  INSTITUTION_TITLE_MESSAGE_CREATE_USER_INSTITUTION_SUCCESS: "Корисник је успешно додат установи.",
  CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_DELETE_SUCCESS: "Логичка контрола је успешно обрисана.",
  CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_CREATE_SUCCESS: "Логичка контрола је успешно креирана.",
  CODEBOOK_LOGICAL_CONTROL_TITLE_DIALOG_UPDATE_SUCCESS: "Логичка контрола је успешно измењена.",
  HEADER_CREATE_FORM: "Креирање формулара",
  HEADER_UPDATE_FORM_FILLED: "Измена формулара",
  HEADER_DELETE_FORM_FILLED: "Брисање формулара",
  HEADER_READ_FORM_FILLED: "Детаљи формулара",
  HEADER_FIELD_TARGET: "Циљано поље",
  HEADER_PUBLIC: "Јаван",
  SEARCH_FORM: "Претрага формулара",
  SEARCH_USER: "Претрага корисника",
  SEARCH_LOGS: "Претрага логова",
  SAVE_FORM: "Сачувај формулар",
  SAVE_FORM_AS_DRAFT: "Сачувај нацрт формулара",
  LABEL_DIALOG_MESSAGE_DELETE_USER_FROM_USER_GROUP: "Да ли сте сигурни да желите да обришете корисника из корисничке групе?",
  LABEL_DIALOG_MESSAGE_DELETE_USER_FROM_INSTITUTION: "Да ли сте сигурни да желите да обришете корисника из установе?",
  LABEL_DIALOG_MESSAGE_LEAVE_PAGE: "Да ли сте сигурни да желите да напустите страницу?",
  CODEBOOK_TITLE_CREATE: "Креирање шифарника",
  CODEBOOK_TITLE_UPDATE: "Измена шифарника",
  CODEBOOK_TITLE_DETAILS: "Детаљи шифарника",
  LOGICAL_CONTROL_TITLE: "Логичка контрола шифарника",
  CODEBOOK_TITLE_DELETE: "Брисање шифарника",
  CODEBOOK_TYPE: "Тип шифарника",
  CODEBOOK_TYPES: "Типови шифарника",
  LOGICAL_CONTROLS: "Логичке контроле",
  CODEBOOK_OPTION: "Ставка шифарника",
  CODEBOOK_AUTOCOMPLETEČ: "Шифарнички са претрагом",
  LABEL_LOG_LIST: "Лог",
  INSERT_TIME_FROM: "Време уноса од",
  INSERT_TIME_TO: "Време уноса до",
  INSERT_TIME: "Време уноса",
  OLD_VALUE: "Стара вредност",
  NEW_VALUE: "Нова вредност",
  ACTION_TYPE: "Тип акције",
  IP_ADDRESS: "ИП адреса",
  CLASS: "Класа",
  OBJECT: "Објекат",
  MESSAGE_CREATE_SEMANTIC_VALIDATION_SUCCESS: "Семантичка валидација је успешно креирана.",
  MESSAGE_UPDATE_SEMANTIC_VALIDATION_SUCCESS: "Семантичка валидација је успешно измењена.",
  MESSAGE_DELETE_SEMANTIC_VALIDATION_SUCCESS: "Семантичка валидација је успешно обрисана.",
  MESSAGE_CREATE_REPORT_PARAMETER_SUCCESS: "Параметар извештаја је успешно креиран.",
  MESSAGE_UPDATE_REPORT_PARAMETER_SUCCESS: "Параметар извештаја је успешно измењен.",
  MESSAGE_DELETE_REPORT_PARAMETER_SUCCESS: "Параметар извештаја је успешно обрисан.",
  MESSAGE_CREATE_REPORT_ACCESS_SUCCESS: "Приступ извештају је успешно креиран.",
  MESSAGE_DELETE_REPORT_ACCESS_SUCCESS: "Приступ извештају је успешно обрисан.",

  //operator
  GREATER: "веће",
  LESS: "мање",
  EQUAL: "једнако",
  NOT_EQUAL: "није једнако",
  RANGE: "опсег",
  CODEBOOKS: "Шифарници",
  FORM_SEMANTIC_VALIDATION_LIST: "Семантичке валидације",

  // form user right
  USER_RIGHT_CREATE: "Креирање",
  USER_RIGHT_READ: "Читање",
  USER_RIGHT_UPDATE: "Измена",
  USER_RIGHT_DELETE: "Брисање",
  USER_RIGHT_VISIBILITY: "Видљивост",
  USER_RIGHT_OVERVIEW: "Преглед",

  REPORT: "Извештај",
  REPORT_LIST: "Извештаји",
  REPORT_TITLE_DIALOG_CREATE: "Креирање извештаја",
  REPORT_TITLE_DIALOG_UPDATE: "Измена извештаја: ",
  REPORT_TITLE_DIALOG_DELETE: "Брисање извештаја: ",
  REPORT_TITLE_DIALOG_DETAILS: "Детаљи извештаја: ",
  TITLE_MESSAGE_CREATE_REPORT_SUCCESS: "Извештај je успешно креиран.",
  TITLE_MESSAGE_UPDATE_REPORT_SUCCESS: "Извештај je успешно измењен.",
  TITLE_MESSAGE_DELETE_REPORT_SUCCESS: "Извештај je успешно обрисан.",
  REPORT_PARAMETER: "Параметри извештаја",
  REPORT_ACCESS: "Приступ извештају",
  REPORT_PARAMETER_TYPE: "Тип параметра",
  PATIENT_FIRST_NAME: "Име пацијента",
  PATIENT_LAST_NAME: "Презиме пацијента",
  INSERT_DATE: "Датум уноса",

  DISPLAY_ORDER_REQUIRED: "Приказни редослед је обавезно поље.",
  CUSTOM_QUERY_EXAMPLE: "Пример SQL упита: select u.id as id, u.email as name form User u",
  LABEL_TEST_QUERY: "Тест упита",
  LABEL_ADD_QUERY: "Унесите упит у одговарајуће поље",
  LABEL_SUBMITTED: "Поднет",
  LABEL_APPROVED: "Одобрен",
  LABEL_FOR_REVISION: "За ревизију",
  LABEL_UNFINISHED: "Незавршен",
  TOOLTIP_RETURN: "Повратак на претходну страну",
  TOOLTIP_ADD_COMMENT: "Додајте коментар или одобрите формулар",
  HEADER_FORM_REVISION: "Ревизија формулара",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_USERGROUP: "Да ли сте сигурни да желите да обришете корисничку групу?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_JURISDICTION: "Да ли сте сигурни да желите да обришете јурисдикцију?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_USER: "Да ли сте сигурни да желите да обришете корисника?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_INSTITUTION: "Да ли сте сигурни да желите да обришете установу?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FORM: "Да ли сте сигурни да желите да обришете формулар?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_SEMANTIC_VALIDATION: "Да ли сте сигурни да желите да обришете семантичку валидацију?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FORM_FIELD: "Да ли сте сигурни да желите да обришете поље формулара?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_TYPE: "Да ли сте сигурни да желите да обришете тип поља?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_TYPE_ENUMERATOR_ITEM: "Да ли сте сигурни да желите да обришете скуповну ставку?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_TYPE_LOGICAL_CONTROL: "Да ли сте сигурни да желите да обришете логичко правило?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPORT: "Да ли сте сигурни да желите да обришете извештај?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPORT_PARAMETER: "Да ли сте сигурни да желите да обришете параметар извештаја?",
  MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPORT_ACCESS: "Да ли сте сигурни да желите да обришете приступ извештајy?",
  LABEL_IMPORT_CODEBOOK: "Ажурирај",
  TOOLTIP_IMPORT_CODEBOOK: "Ажурирање шифарника",
  LABEL_M1_PATH: "m1",
  REPORT_GENERATE_TIME: "Време генерисања",
  CANCER_REGISTRY: "Регистар малигних тумора",
  INSTITUTE_OF_PUBLIC_HEALTH: "Институт за јавно здравље Србије",
  BATUT_LABEL: "Др Милан Јовановић Батут",
  DELIVERED_BY: "delivered by",
  HELIANT_LABEL: "HELIANT",
  MESSAGES_REPORT_GENERATED_SUCCESS: "Извештај је успешно генерисан."
};

export default Labels;
