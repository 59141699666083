import { Accordion, AccordionTab } from "primereact/accordion";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import Endpoint from "../../../../infrastructure/system/Endpoint";
import EnumCategory from "../../../../infrastructure/system/EnumCategory";
import EnumLanguage from "../../../../infrastructure/system/EnumLanguage";
import EnumReportParameterType from "../../../../infrastructure/system/EnumReportParameterType";
import LabelsContext from "../../../../infrastructure/system/LabelsContext";
import { categoryList } from "../../../../infrastructure/system/Utils";
import ReportParameterReadDto from "../../../../model/ReportParameterReadDto";
import { AppContext } from "../../../../Store";
import ReportTableView from "../public-report/ReportTableView";
import NewReportLogical, { NewReportLogicalType } from "./NewReportLogical";
const Websocket = require("react-websocket");

export default function NewReport() {
  const {
    reportsByCategory,
    selectedReport,
    onReportChange,
    parameterList,
    generateReport,
    addNewParameter,
    paramsList,
    selectedValue,
    setSelectedValue,
    reportGenerateList,
    setReportGenerateList,
    first,
    tableRows,
    onPageGeneratedReport,
    breadCrumbItems,
    handleWebSocketResponse,
    labelList,
    locale,
  }: NewReportLogicalType = NewReportLogical();
  const { Labels } = LabelsContext();
  const { authData } = useContext(AppContext);
  const leftContents = <Button label={Labels.BUTTON_GENERATE_REPORT} onClick={(e: any) => generateReport(e)} />;
  let idUser = authData!.currentUser.id;
  let urlWebSocket = Endpoint.WS_URL + "/reportWebSocket/websocket?userID=" + idUser;
  const multiSelectItemTemplate = (option: any) => {
    return <div className="p-clearfix">{option.name}</div>;
  };
  const getName = (fieldName: any) => {
    const elementName = labelList.find((label: any) => label.original === fieldName && label.language === locale);
    return elementName !== undefined ? elementName.translate : fieldName;
  };

  const getCategoryName = (reportCategory: any) => {
    if (locale === EnumLanguage.LATIN) {
      return reportCategory.key === EnumCategory.INDIVIDUAL ? categoryList[0].label : categoryList[1].label;
    } else {
      return reportCategory.key === EnumCategory.INDIVIDUAL ? categoryList[0].labelCyrillic : categoryList[1].labelCyrillic;
    }
  };

  return (
    <div className="layout-report">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
        <Websocket url={urlWebSocket} onMessage={(e: any) => handleWebSocketResponse(e)} reconnect={true} />
        <div className="p-grid">
          <div className="p-col-3">
            <Card title={Labels.LABEL_CHOOSE_REPORT}>
              <Accordion multiple>
                {reportsByCategory.map((reportCategory: any) => (
                  <AccordionTab key={reportCategory.key} header={getCategoryName(reportCategory)} disabled={reportCategory.children.length === 0}>
                    {reportCategory.children?.map((children: any) => (
                      <Button
                        label={getName(children.label)}
                        key={children.key}
                        onClick={(e: any) => onReportChange(children.key)}
                        className={`category p-button-text w-full p-text-left ${selectedReport !== children.key && "p-button-plain"}`}
                      />
                    ))}
                  </AccordionTab>
                ))}
              </Accordion>
            </Card>
          </div>
          <div className="p-col-9">
            {selectedReport && (
              <TabView>
                <TabPanel header={Labels.LABEL_PARAMETER}>
                  {parameterList &&
                    parameterList.map((parameter: ReportParameterReadDto) => {
                      return (
                        <div key={parameter.name} className="p-grid p-fluid p-align-center p-justify-between">
                          <div className="p-col-12">
                            {parameter.reportParameterType === EnumReportParameterType.TEXT && (
                              <div className="p-grid p-justify-between p-align-center">
                                <div className="p-col-4">
                                  <label>{parameter.name}</label>
                                </div>
                                <div className="p-col-8">
                                  <InputText onChange={(e) => addNewParameter(parameter.id, e.target.value)} value={paramsList[parameter.id]} />
                                </div>
                              </div>
                            )}
                            {parameter.reportParameterType === EnumReportParameterType.NUMBER && (
                              <div className="p-grid p-justify-between p-align-center">
                                <div className="p-col-4">
                                  <label htmlFor="input-number">{parameter.name}</label>
                                </div>
                                <div className="p-col-8">
                                  <InputNumber inputId="input-number" onValueChange={(e) => addNewParameter(parameter.id, e.value)} value={paramsList[parameter.id]} />
                                </div>
                              </div>
                            )}
                            {parameter.reportParameterType === EnumReportParameterType.DATE && (
                              <div className="p-grid p-justify-between p-align-center">
                                <div className="p-col-4">
                                  <label htmlFor="input-switch">{parameter.name}</label>
                                </div>
                                <div className="p-col-8">
                                  <Calendar dateFormat="dd/mm/yy" onChange={(e) => addNewParameter(parameter.id, e.value)} value={paramsList[parameter.id]}></Calendar>
                                </div>
                              </div>
                            )}
                            {parameter.reportParameterType === EnumReportParameterType.SET && (
                              <div className="p-grid p-justify-between p-align-center">
                                <div className="p-col-4">
                                  <label htmlFor="input-switch">{parameter.name}</label>
                                </div>
                                <div className="p-col-8">
                                  {
                                    <Dropdown
                                      value={selectedValue[parameter.name]}
                                      options={parameter.resultList}
                                      optionLabel="name"
                                      optionValue="id"
                                      onChange={(e) => {
                                        addNewParameter(parameter.id, [e.value]);
                                        setSelectedValue({
                                          ...selectedValue,
                                          [parameter.name]: e.value,
                                        });
                                      }}
                                    />
                                  }
                                </div>
                              </div>
                            )}
                            {parameter.reportParameterType === EnumReportParameterType.MULTISELECT && (
                              <div className="p-grid p-justify-between p-align-center">
                                <div className="p-col-4">
                                  <label htmlFor="input-switch">{parameter.name}</label>
                                </div>
                                <div className="p-col-8">
                                  {
                                    <MultiSelect
                                      optionLabel="name"
                                      optionValue="id"
                                      dataKey="id"
                                      value={selectedValue[parameter.name] ? selectedValue[parameter.name] : undefined}
                                      options={parameter.resultList ? parameter.resultList : []}
                                      itemTemplate={multiSelectItemTemplate}
                                      onChange={(e: any) => {
                                        addNewParameter(parameter.id, e.value);
                                        setSelectedValue({
                                          ...selectedValue,
                                          [parameter.name]: e.value,
                                        });
                                      }}
                                    />
                                  }
                                </div>
                              </div>
                            )}
                            {parameter.reportParameterType === EnumReportParameterType.BOOLEAN && (
                              <div className="p-grid p-justify-between p-align-center">
                                <div className="p-col-4">
                                  <label htmlFor="input-switch">{parameter.name}</label>
                                </div>
                                <div className="p-col-8">
                                  <InputSwitch inputId="input-switch" onChange={(e) => addNewParameter(parameter.id, e.value)} checked={paramsList[parameter.id]} />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {parameterList.length !== 0 && <Toolbar left={leftContents} />}
                </TabPanel>
                <TabPanel header={Labels.LABEL_REPORT_GENERATED}>
                  <ReportTableView
                    setReportGenerateList={setReportGenerateList}
                    reportGeneratedList={reportGenerateList}
                    first={first}
                    tableRows={tableRows}
                    onPageGeneratedReport={onPageGeneratedReport}
                    isPublic={false}
                  ></ReportTableView>
                </TabPanel>
              </TabView>
            )}
          </div>
        </div>
      </Panel>
    </div>
  );
}
