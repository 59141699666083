import { useContext } from "react";
import EnumRole from "../../infrastructure/system/EnumRole";
import { hasRole } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface RoleServiceType {
  isSuperAdmin: () => boolean;
  isAdmin: () => boolean;
  isMd: () => boolean;
  isBed: () => boolean;
  isEd: () => boolean;
  isUser: () => boolean;
}

export function RoleService(): RoleServiceType {
  const { authData } = useContext(AppContext);
  const { roleList } = authData!.currentUser;

  const isSuperAdmin = (): boolean => {
    return hasRole(roleList, EnumRole.SUPERADMIN);
  };

  const isAdmin = (): boolean => {
    return hasRole(roleList, EnumRole.ADMIN);
  };

  const isMd = (): boolean => {
    return hasRole(roleList, EnumRole.MD);
  };

  const isBed = (): boolean => {
    return hasRole(roleList, EnumRole.BED);
  };

  const isEd = (): boolean => {
    return hasRole(roleList, EnumRole.ED);
  };

  const isUser = (): boolean => {
    return hasRole(roleList, EnumRole.USER);
  };

  return { isAdmin, isSuperAdmin, isMd, isBed, isEd, isUser };
}

export type { RoleServiceType };
