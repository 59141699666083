import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { FormController, FormControllerType } from "../../../controllers/form/FormController";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import FormReadDto from "../../../model/FormReadDto";
import { AppContext } from "../../../Store";

interface FormListLogicalType {
  formList: Array<FormReadDto>;
  first: number;
  tableRows: number;
  selectedRow: any;
  setSelectedRow: any;
  setSelectedForm: any;
  selectedForm: any;
  history: any;
  breadCrumbItems: any;
  onPageForm: any;
  exportData: any;
  onSearchValueChange: any;
  deleteForm: any;
}

export default function FormListLogical(): FormListLogicalType {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const { Labels } = LabelsContext();
  const [formList, setFormList] = useState<Array<FormReadDto>>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedForm, setSelectedForm] = useState<FormReadDto | null>();
  const history = useHistory();
  const [exportData, setExportData] = useState<Array<any>>([]);
  const { axiosGetFormList, axiosDeleteForm }: FormControllerType = FormController();

  const breadCrumbItems = [
    {
      label: Labels.FORM_LIST,
      command: () => {
        window.location.reload();
      },
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (codeSelectedForm?: number) => {
    setShowBlockUI(true);
    axiosGetFormList()
      .then((res: any) => {
        setFormList(res.data.data);
        let exportList = new Array<any>();
        res.data.data.forEach((form: any) => {
          exportList.push({ [Labels.LABEL_CODE]: form.code, [Labels.LABEL_NAME]: form.name, [Labels.LABEL_DESCRIPTION]: form.description, [Labels.LABEL_NOTE]: form.note });
        });
        setExportData(exportList);
        setShowBlockUI(false);
        if (codeSelectedForm) {
          res.data.data.forEach((form: any) => {
            if (codeSelectedForm === form.code) {
              setSelectedRow(form);
              setSelectedForm(form);
            }
          });
        }
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const onPageForm = (rows: any, first: any) => {
    setTableRows(rows);
    setFirst(first);
  };

  const deleteForm = (formCode: string) => {
    axiosDeleteForm(formCode)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_DELETE_FORM_SUCCESS, "");
        fetchData();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onSearchValueChange = (sortedData: Array<any>) => {
    const exportList = new Array<any>();
    sortedData?.forEach((form: any) => {
      exportList.push({ [Labels.LABEL_CODE]: form.code, [Labels.LABEL_NAME]: form.name, [Labels.LABEL_DESCRIPTION]: form.description, [Labels.LABEL_NOTE]: form.note });
    });
    setExportData(exportList);
  }

  return {
    formList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    setSelectedForm,
    selectedForm,
    history,
    breadCrumbItems,
    onPageForm,
    exportData,
    onSearchValueChange,
    deleteForm
  };
}

export type { FormListLogicalType };
