import axios from "axios";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReportController, ReportControllerType } from "../../../controllers/report/ReportController";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { categoryList, handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import { AppContext } from "../../../Store";

interface ReportLogicalType {
  reportList: any;
  first: number;
  tableRows: number;
  selectedRow: any;
  setSelectedRow: any;
  selectedReport: any;
  breadCrumbItems: any;
  exportData: any;
  onPage: any;
  setSelectedReport: any;
  history: any;
  getCategoryName: any;
  getFileFormatList: any;
  deleteReport: any;
}

export default function ReportLogical() {
  const { Labels } = LabelsContext();
  const history = useHistory();
  const { showMessage, setShowBlockUI } = useContext(AppContext);

  const [reportList, setReportList] = useState<any>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [selectedReport, setSelectedReport] = useState<any>();

  const { axiosGetReportList, axiosDeleteReport }: ReportControllerType = ReportController();

  const breadCrumbItems = [
    {
      label: Labels.REPORT_LIST,
      command: () => {
        window.location.reload();
      },
    },
  ];

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    setShowBlockUI(true);
    axios
      .all([axiosGetReportList()])
      .then(
        axios.spread((responseReport: any) => {
          const exportList = new Array<any>();
          responseReport.data.data.forEach((report: any) => {
            exportList.push({
              [Labels.HEADER_CATEGORY]: getCategoryName(report),
              [Labels.LABEL_NAME]: report.name,
              [Labels.HEADER_JASPER_LOCATION]: report.jasperLocation,
              [Labels.HEADER_FILE_FORMAT]: report.reportFileFormatList,
            });
          });
          setExportData(exportList);
          setReportList(responseReport.data.data);
          setShowBlockUI(false);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const onPage = (rows: any, first: any) => {
    setTableRows(rows);
    setFirst(first);
  };

  const getCategoryName = (column: any) => {
    return categoryList!.find((x: any) => x.value === column.category)?.label!;
  };
  const getFileFormatList = (column: any) => {
    let ffList: Array<string> = [];
    column.reportFileFormatList &&
      column.reportFileFormatList.forEach((ff: any) => {
        ffList.push(ff.fileFormat);
      });
    return ffList.toString();
  };

  const deleteReport = (reportID: any) => {
    axiosDeleteReport(reportID)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_DELETE_REPORT_SUCCESS, "");
        fetchData();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  return {
    reportList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    selectedReport,
    setSelectedReport,
    breadCrumbItems,
    exportData,
    onPage,
    history,
    getCategoryName,
    getFileFormatList,
    deleteReport,
  };
}

export type { ReportLogicalType };
