import { useContext, useEffect, useState } from "react";
import { LabelController, LabelControllerType } from "../../controllers/label/LabelController";
import LabelsContext from "../../infrastructure/system/LabelsContext";
import MessageType from "../../infrastructure/system/MessageType";
import { handleAxiosCallError } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";
interface CrudLabelLogicalType {
  onSaveLabel: any;
  labelListChange: any;
  onChangeLabel: any;
}

interface PropsType {
  original: string | undefined;
}

export default function CrudLabelLogical(props: PropsType) {
  const { original } = props;
  const { axiosCreateLabel, axiosUpdateLabel }: LabelControllerType = LabelController();
  const { showMessage, labelList, setLabelList } = useContext(AppContext);
  const [labelListChange, setLabelListChange] = useState<any>([]);
  const { Labels } = LabelsContext();

  useEffect(() => {
    setLabelListChange(
      labelList.filter((label: any) => {
        return label.original === original;
      })
    );
    // eslint-disable-next-line
  }, [labelList]);

  const onChangeLabel = (value: any, labelCode: string) => {
    if (
      labelListChange.filter((label: any) => {
        return label.original === original && label.language === labelCode;
      }).length !== 0
    ) {
      setLabelListChange(
        labelListChange.map((label: any) => {
          if (label.language === labelCode) {
            return { ...label, translate: value };
          } else {
            return label;
          }
        })
      );
    } else {
      setLabelListChange([...labelListChange, { original: original, language: labelCode, translate: value }]);
    }
  };

  const onSaveLabel = (body: any, labelCode: string) => {
    if (body.id !== null && body.id !== undefined) {
      axiosUpdateLabel(body)
        .then((res: any) => {
          showMessage(MessageType.SUCCESS, Labels.MESSAGE_UPDATE_LABEL_SUCCESS, "");
          setLabelList(
            labelList.map((label: any) => {
              if (label.original === original && label.language === labelCode) {
                return res.data.data;
              } else {
                return label;
              }
            })
          );
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    } else {
      axiosCreateLabel(body)
        .then((res: any) => {
          setLabelList([...labelList, res.data.data]);
          showMessage(MessageType.SUCCESS, Labels.MESSAGE_CREATE_LABEL_SUCCESS, "");
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  return {
    onSaveLabel,
    labelListChange,
    onChangeLabel,
  };
}
export type { CrudLabelLogicalType };
