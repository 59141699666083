import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface RoleControllerType {
  axiosGetRoleList: any;
}

export function RoleController(): RoleControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetRoleList = () => {
    return axios.get(Endpoint.ROLE_LIST, axiosConfig(authData!.token));
  };

  return { axiosGetRoleList };
}

export type { RoleControllerType };
