import axios from "axios";
import { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FieldTypeController, FieldTypeControllerType } from "../../../controllers/field-type/FieldTypeController";
import { FormController, FormControllerType } from "../../../controllers/form/FormController";
import EnumComponentType from "../../../infrastructure/system/EnumComponentType";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import MessageType from "../../../infrastructure/system/MessageType";
import { handleAxiosCallError, isFormDisabled, useEffectOnce } from "../../../infrastructure/system/Utils";
import FieldReadDto from "../../../model/FieldReadDto";
import FieldTypeEnumeratorItemReadDto from "../../../model/FieldTypeEnumeratorItemReadDto";
import { AppContext } from "../../../Store";

interface CrudFormFieldLogicalType {
  formFieldOperation: any;
  selectedFormField: any;
  setSelectedFormField: any;
  isFieldDisabled: any;
  fieldTypeList: any;
  getFieldType: any;
  enumeratorItemList: any;
  fieldType: any;
  onCancel: any;
  onDeleteField: any;
  onUpdateField: any;
  onCreateField: any;
  breadCrumbItems: any;
  displayDialog: boolean;
  setDisplayDialog: any;
  fieldTypePanelList: any;
}

interface LocationType {
  formFieldOperation: string;
  selectedFormField?: any;
  formCode: any;
  formOperation: any;
}

export default function CrudFormFieldLogical() {
  const { Labels } = LabelsContext();
  const history = useHistory();
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const location = useLocation<LocationType>();
  const [formFieldOperation] = useState(location.state ? location.state.formFieldOperation : EntityOperation.UPDATE);
  const [selectedFormField, setSelectedFormField] = useState(
    location.state && location.state.selectedFormField !== undefined ? location.state.selectedFormField : {}
  );
  const [formCode] = useState(location.state ? location.state.formCode : "");
  const [formOperation] = useState(location.state ? location.state.formOperation : "");
  const [isFieldDisabled] = useState(isFormDisabled(formFieldOperation));
  const [fieldTypeList, setFieldTypeList] = useState<Array<any>>([]);
  const [fieldTypePanelList, setFieldTypePanelList] = useState<any>([]);
  const { axiosGetFieldTypeList, axiosGetFieldTypeEnumeratorItemList, axiosGetFieldType }: FieldTypeControllerType =
    FieldTypeController();
  const {
    axiosDeleteFormField,
    axiosUpdateFormField,
    axiosCreateFormField,
    axiosGetForm,
    axiosGetFormFieldsList,
  }: FormControllerType = FormController();
  const [fieldType, setFieldType] = useState<FieldReadDto | undefined>();
  const [enumeratorItemList, setEnumeratorItemList] = useState<Array<FieldTypeEnumeratorItemReadDto>>([]);
  const componentTypeOptions = [
    { label: Labels.LABEL_TEXT, value: EnumComponentType.TEXT },
    { label: Labels.LABEL_LONG_TEXT, value: EnumComponentType.LONG_TEXT },
    { label: Labels.LABEL_LABEL, value: EnumComponentType.LABEL },
    { label: Labels.LABEL_BOOLEAN, value: EnumComponentType.BOOLEAN },
    { label: Labels.LABEL_INTEGER, value: EnumComponentType.NUMBER_INTEGER },
    { label: Labels.LABEL_FLOAT, value: EnumComponentType.NUMBER_DECIMAL },
    { label: Labels.LABEL_SET, value: EnumComponentType.SET },
    { label: Labels.LABEL_DATE_TIME, value: EnumComponentType.DATE_TIME },
    { label: Labels.LABEL_PANEL, value: EnumComponentType.PANEL },
    { label: Labels.LABEL_CODEBOOK, value: EnumComponentType.CODEBOOK },
    { label: Labels.CODEBOOK_AUTOCOMPLETE, value: EnumComponentType.CODEBOOK_AUTOCOMPLETE },
  ];
  const [breadCrumbItems, setBreadCrumbItems] = useState<any>([
    {
      label: Labels.FORM_LIST,
      command: () => {
        history.push("/crudFormList");
      },
    },
  ]);
  const [displayDialog, setDisplayDialog] = useState(false);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    setShowBlockUI(true);
    const requestFieldList = axiosGetFormFieldsList(formCode);
    const requestForm = axiosGetForm(formCode);
    const requestFieldTypes = axiosGetFieldTypeList();
    axios
      .all([requestForm, requestFieldTypes, requestFieldList])
      .then(
        axios.spread((responseForm: any, responseFieldTypes: any, responseFieldList: any) => {
          let fieldTypesList = responseFieldTypes.data.data;
          let panelFieldTypeList = [] as any;
          responseFieldList.data.data.forEach((field: any) => {
            if (field.fieldType.componentEnumType === EnumComponentType.PANEL) {
              panelFieldTypeList.push(field);
            }
          });
          setFieldTypePanelList(panelFieldTypeList);
          let groupList = [] as any;
          componentTypeOptions.forEach((componentTypeOption: any) => {
            const fieldTypeGroupedList = fieldTypesList.filter(
              (x: any) => x.componentEnumType === componentTypeOption.value
            );
            if (fieldTypeGroupedList?.length !== 0) {
              groupList.push({
                label: componentTypeOption.label,
                list: fieldTypeGroupedList,
              });
            }
            setFieldTypeList(groupList);
            setShowBlockUI(false);
            if (breadCrumbItems.length < 2) {
              setBreadCrumbItems([
                ...breadCrumbItems,
                {
                  label:
                    formOperation === EntityOperation.UPDATE
                      ? Labels.LABEL_UPDATE_FORM + responseForm.data.data.name
                      : formOperation === EntityOperation.READ
                      ? Labels.LABEL_DETAILS_FORM + responseForm.data.data.name
                      : Labels.LABEL_DELETE_FORM + responseForm.data.data.name,
                  command: () => {
                    history.push({
                      pathname: `/crudForm/${formCode}`,
                      state: { tabIndex: 1, formOperation: formOperation },
                    });
                  },
                },
                {
                  label:
                    formFieldOperation === EntityOperation.UPDATE
                      ? Labels.LABEL_UPDATE_FORM_FIELD + selectedFormField.name
                      : formFieldOperation === EntityOperation.READ
                      ? Labels.LABEL_DETAILS_FORM_FIELD + selectedFormField.name
                      : formFieldOperation === EntityOperation.CREATE
                      ? Labels.LABEL_CREATE_FORM_FIELD
                      : Labels.LABEL_DELETE_FORM_FIELD + selectedFormField.name,
                },
              ]);
            }
          });
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const getFieldType = (fieldTypeId: any) => {
    if (fieldTypeId !== undefined) {
      const fieldTypeRequest = axiosGetFieldType(fieldTypeId);
      const enumeratorItemListRequest = axiosGetFieldTypeEnumeratorItemList(fieldTypeId);
      axios
        .all([fieldTypeRequest, enumeratorItemListRequest])
        .then(
          axios.spread((fieldTypeResponse: any, enumeratorItemListResponse: any) => {
            setFieldType(fieldTypeResponse.data.data);
            setEnumeratorItemList(enumeratorItemListResponse.data.data);
          })
        )
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const onCancel = () => {
    history.push({
      pathname: `/crudForm/${formCode}`,
      state: { tabIndex: 1, formOperation: formOperation },
    });
  };

  const onDeleteField = () => {
    if (selectedFormField !== null && selectedFormField !== undefined) {
      axiosDeleteFormField(formCode, selectedFormField?.id)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_DELETE_FIELD_SUCCESS, "");
          setSelectedFormField(undefined);
          onCancel();
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const validateField = (field?: FieldReadDto) => {
    if (field === undefined || field?.code === "") {
      showMessage(MessageType.ERROR, Labels.FIELD_CODE_IS_REQUIRED, "");
      return false;
    }
    if (field?.name === "") {
      showMessage(MessageType.ERROR, Labels.FIELD_NAME_IS_REQUIRED, "");
      return false;
    }
    if (!field?.displayOrder) {
      showMessage(MessageType.ERROR, Labels.DISPLAY_ORDER_REQUIRED, "");
      return false;
    }

    return true;
  };

  const onUpdateField = () => {
    if (!validateField(selectedFormField)) {
      return;
    }
    if (selectedFormField !== null && selectedFormField !== undefined) {
      axiosUpdateFormField(selectedFormField, formCode, selectedFormField?.id)
        .then((response: any) => {
          setSelectedFormField(response.data.data);
          onCancel();
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_UPDATE_FIELD_SUCCESS, "");
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const onCreateField = () => {
    if (!validateField(selectedFormField)) {
      return;
    }
    let newFieldToChange = selectedFormField;
    if (newFieldToChange && !newFieldToChange?.hasOwnProperty("isRequired")) {
      newFieldToChange = { ...newFieldToChange, isRequired: false };
    }
    if (newFieldToChange && !newFieldToChange?.hasOwnProperty("isDisabled")) {
      newFieldToChange = { ...newFieldToChange, isDisabled: false };
    }
    if (newFieldToChange && !newFieldToChange?.hasOwnProperty("isMultiselect")) {
      newFieldToChange = { ...newFieldToChange, isMultiselect: false };
    }
    if (newFieldToChange && !newFieldToChange?.hasOwnProperty("isRelevant")) {
      newFieldToChange = { ...newFieldToChange, isRelevant: false };
    }
    axiosCreateFormField(newFieldToChange, formCode)
      .then((response: any) => {
        showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGE_CREATE_FIELD_SUCCESS, "");
        setSelectedFormField(response.data.data);
        onCancel();
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  return {
    formFieldOperation,
    selectedFormField,
    setSelectedFormField,
    isFieldDisabled,
    fieldTypeList,
    getFieldType,
    enumeratorItemList,
    fieldType,
    onCancel,
    onDeleteField,
    onUpdateField,
    onCreateField,
    breadCrumbItems,
    displayDialog,
    setDisplayDialog,
    fieldTypePanelList,
  };
}

export type { CrudFormFieldLogicalType };
