import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import { getComponentTypeName } from "../../../infrastructure/system/Utils";
import ExportData from "../../app/export/ExportData";
import FieldTypeLogical, { FieldTypeLogicalType } from "./FieldTypeLogical";

export default function FieldTypeViewPage() {
  const {
    fieldTypeList,
    first,
    tableRows,
    selectedRow,
    setSelectedRow,
    setSelectedFieldType,
    selectedFieldType,
    history,
    breadCrumbItems,
    onPageFieldType,
    exportData,
    onSearchValueChange,
    deleteFieldType,
  }: FieldTypeLogicalType = FieldTypeLogical();

  const { Labels } = LabelsContext();
  const dt = useRef<any>(null);

  const openFieldType = (entityOperation: string) => {
    const idFieldType = entityOperation !== EntityOperation.CREATE ? selectedFieldType.id : "";
    history.push({
      pathname: `/crudFieldType/${idFieldType}`,
      state: { fieldTypeOperation: entityOperation },
    });
  };

  const getComponentType = (column: any) => {
    return getComponentTypeName(column.componentEnumType, Labels);
  };

  const leftContents = () => (
    <>
      <Button
        icon="pi pi-plus"
        className="p-button-success btn-margin-right"
        label={Labels.LABEL_CREATE}
        tooltip={Labels.LABEL_CREATE_FIELD_TYPE}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          openFieldType(EntityOperation.CREATE);
        }}
      />

      <Button
        icon="pi pi-pencil"
        className="p-button-warning btn-margin-right"
        disabled={!selectedFieldType}
        label={Labels.LABEL_EDIT}
        tooltipOptions={{ position: "bottom" }}
        tooltip={Labels.LABEL_UPDATE_FIELD_TYPE}
        onClick={() => {
          selectedFieldType && openFieldType(EntityOperation.UPDATE);
        }}
      />
      <Button
        icon="pi pi-info-circle"
        label={Labels.LABEL_DETAILS}
        tooltip={Labels.LABEL_DETAILS_FIELD_TYPE}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedFieldType}
        onClick={() => {
          selectedFieldType && openFieldType(EntityOperation.READ);
        }}
      />
    </>
  );

  const rightContents = () => (
    <>
      <ExportData data={exportData} name={Labels.FIELD_TYPES} dt={dt} />
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        label={Labels.LABEL_DELETE}
        tooltipOptions={{ position: "bottom" }}
        tooltip={Labels.LABEL_DELETE_FIELD_TYPE}
        disabled={!selectedFieldType}
        onClick={() => {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_TYPE,
            icon: "pi pi-power-off",
            accept: () => {
              deleteFieldType(selectedFieldType.id);
            },
          });
        }}
      />
    </>
  );

  return (
    <div className="layout-field-type">
      <Panel>
        <BreadCrumb model={breadCrumbItems} className="margin-bottom-10px" />
        <Toolbar left={leftContents} right={rightContents} />
        <div>
          <DataTable
            ref={dt}
            value={fieldTypeList}
            paginator
            first={first}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            rows={tableRows}
            rowsPerPageOptions={[5, 10, 20]}
            onPage={(e) => {
              onPageFieldType(e.rows, e.first);
            }}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedFieldType(e.data)}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            exportFilename={Labels.FIELD_TYPES}
            onValueChange={onSearchValueChange}
          >
            <Column field={"name"} header={Labels.HEADER_NAME} filter filterMatchMode="contains" sortable />
            <Column field={"code"} header={Labels.HEADER_CODE} filter filterMatchMode="contains" sortable />
            <Column field={"componentEnumType"} header={Labels.FIELD_TYPE_COMPONENT_TYPE} filter filterMatchMode="contains" body={getComponentType} sortable />
            <Column field={"format"} header={Labels.FIELD_TYPE_FORMAT} filter filterMatchMode="contains" />
          </DataTable>
        </div>
      </Panel>
    </div>
  );
}
