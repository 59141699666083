import axios from "axios";
import { useContext, useState } from "react";
import { ReportController, ReportControllerType } from "../../../../controllers/report/ReportController";
import EnumReportParameterType from "../../../../infrastructure/system/EnumReportParameterType";
import LabelsContext from "../../../../infrastructure/system/LabelsContext";
import MessageType from "../../../../infrastructure/system/MessageType";
import { handleAxiosCallError, useEffectOnce } from "../../../../infrastructure/system/Utils";
import { AppContext } from "../../../../Store";
import { confirmPopup } from "primereact/confirmpopup";
import ReportParameterReadDto from "../../../../model/ReportParameterReadDto";
import ReportReadDto from "../../../../model/ReportReadDto";

interface NewReportLogicalType {
  reportCategoryList: any;
  reportList: any;
  reportsByCategory: any;
  selectedReport: any;
  onReportChange: any;
  parameterList: any;
  generateReport: any;
  addNewParameter: any;
  paramsList: any;
  selectedValue: any;
  setSelectedValue: any;
  reportGenerateList: any;
  setReportGenerateList: any;
  first: any;
  tableRows: any;
  onPageGeneratedReport: any;
  breadCrumbItems: any;
  handleWebSocketResponse: any;
  labelList: any;
  locale: any;
}

export default function NewReportLogical(): NewReportLogicalType {
  const { showMessage, setShowBlockUI, labelList, locale } = useContext(AppContext);
  const [reportCategoryList, setReportCategoryList] = useState<any>([]);
  const [reportList, setReportList] = useState<any>([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [reportGenerateList, setReportGenerateList] = useState<any>([]);
  const [parameterList, setParameterList] = useState<any>([]);
  const [reportsByCategory, setReportsByCategory] = useState<any>([]);
  const [selectedReport, setSelectedReport] = useState<any>(null);
  const [selectedValue, setSelectedValue] = useState([]);
  const [paramsList, setParamsList] = useState<any>({});
  const [selectedCategoryCode, setSelectedCategory] = useState("");
  const { Labels } = LabelsContext();
  const {
    axiosGetReportCategoryList,
    axiosGetReportListByUser,
    axiosGetReportParameterListByReportId,
    axiosDeleteGenerateReport,
    axiosGenerateReport,
    axiosGetReportParameterCustomQueryResultList,
    axiosGetReportGeneratedListByReportId,
    axiosGetReport,
  }: ReportControllerType = ReportController();
  const breadCrumbItems = [
    {
      label: Labels.LABEL_GENERATE_REPORT,
      command: () => {
        window.location.reload();
      },
    },
  ];
  let isMessageShown = false;

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    setShowBlockUI(true);
    const requestReportCategoryList = axiosGetReportCategoryList();
    const requestReportList = axiosGetReportListByUser();
    axios
      .all([requestReportCategoryList, requestReportList])
      .then(
        axios.spread((responseReportCategoryList, responseReportList) => {
          setReportCategoryList(responseReportCategoryList.data);
          setReportList(responseReportList.data.data);
          let groupedReports = [] as any;
          responseReportCategoryList.data.forEach((category: any) => {
            let reportsByCategory = [] as any;
            responseReportList.data.data.forEach((report: ReportReadDto) => {
              if (report.category === category.code) {
                reportsByCategory.push({ label: report.name, key: report.id });
              }
            });
            groupedReports.push({ label: category.name, key: category.code, children: reportsByCategory });
          });
          setReportsByCategory(groupedReports);
          setShowBlockUI(false);
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const onReportChange = (reportId: number) => {
    setSelectedReport(reportId);
    setParameterList([]);
    setParamsList({});
    axios
      .all([axiosGetReportParameterListByReportId(reportId), axiosGetReportGeneratedListByReportId(reportId), axiosGetReport(reportId)])
      .then(
        axios.spread((responseParameterList: any, responseReportGenerateList: any, responseReport: any) => {
          setSelectedCategory(responseReport.data.data.category);
          let paramList: any = [];
          responseParameterList.data.data.forEach((parameter: ReportParameterReadDto) => {
            if (parameter.reportParameterType === EnumReportParameterType.SET || parameter.reportParameterType === EnumReportParameterType.MULTISELECT) {
              axiosGetReportParameterCustomQueryResultList(parameter.id)
                .then((result: any) => {
                  parameter.resultList = result.data.data;
                  paramList.push(parameter);
                  setParameterList(paramList);
                })
                .catch((error: any) => {
                  handleAxiosCallError(showMessage, error);
                });
            } else {
              paramList.push(parameter);
              setParameterList(paramList);
            }
            if (parameter.reportParameterType === EnumReportParameterType.BOOLEAN) {
              addNewParameter(parameter.id, false);
            }
          });
          setReportGenerateList(responseReportGenerateList.data.data);
        })
      )
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const addNewParameter = (parameterID: any, value: any) => {
    setParamsList({
      ...paramsList,
      [parameterID]: value,
    });
  };

  const generateReport = (e: any) => {
    const params = {
      reportID: selectedReport,
      params: paramsList,
    };
    setShowBlockUI(true);
    axiosGenerateReport(params)
      .then((response: any) => {
        showMessage(MessageType.SUCCESS, Labels.MESSAGES_REPORT_GENERATE_GENERATE_SUCCESS, "");
        setShowBlockUI(false);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const fetchGeneratedReport = (id: any) => {
    setShowBlockUI(true);
    axiosGetReportGeneratedListByReportId(selectedReport)
      .then((response: any) => {
        setReportGenerateList(response.data.data);
        setShowBlockUI(false);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  const onPageGeneratedReport = (rows: any, first: any) => {
    setTableRows(rows);
    setFirst(first);
  };

  const handleWebSocketResponse = (data: any) => {
    const results = JSON.parse(data);
    const jsonResponseQ = results.jsonResponseQ;
    const jsonCurrentQ = results.jsonCurrentQ;
    var dataGenerated = [] as any;
    if (jsonCurrentQ?.currentQ) {
      jsonCurrentQ.currentQ.forEach((currentQ: any) => {
        if (selectedCategoryCode === currentQ.categoryCode) {
          const object = {
            title: currentQ.name,
            description: currentQ.parameters,
            time: currentQ.time,
            isPublic: false,
            reportID: currentQ.reportID,
            report: { reportFileFormatList: [{ isGenerated: false }] },
          };
          dataGenerated.push(object);
        }
      });
    }

    jsonResponseQ.responseQ.forEach((responseQ: any) => {
      let ksi = responseQ.ksi;
      if (ksi && ksi.item) {
        let itemList = ksi.item;
        itemList.forEach((item: any) => {
          if (selectedCategoryCode === item.categoryCode && item.kategorijaId === selectedReport) {
            const object = {
              title: item.naziv,
              description: item.parametri,
              isPublic: false,
              time: item.vremeGenerisanja,
              id: parseInt(item.reportID),
              reportID: item.id,
              insertTimestamp: item.insertTimestamp,
              report: { reportFileFormatList: item.format },
            };
            if (isGeneratedReport(dataGenerated, object) === true) {
              dataGenerated.push(object);
            }
          }
        });
        setReportGenerateList(dataGenerated);
        if (reportGenerateList.length === dataGenerated.length && !isMessageShown) {
          showMessage(MessageType.SUCCESS, Labels.MESSAGES_REPORT_GENERATED_SUCCESS, "");
          isMessageShown = true;
        }
      }
    });
  };
  const isGeneratedReport = (objectList: any, object: any) => {
    var result = true;
    objectList.forEach((report: any) => {
      if (object.id === report.id) {
        result = false;
      }
    });
    return result;
  };

  return {
    reportCategoryList,
    reportList,
    reportsByCategory,
    selectedReport,
    onReportChange,
    parameterList,
    generateReport,
    addNewParameter,
    paramsList,
    selectedValue,
    setSelectedValue,
    reportGenerateList,
    first,
    setReportGenerateList,
    tableRows,
    onPageGeneratedReport,
    breadCrumbItems,
    handleWebSocketResponse,
    labelList,
    locale
  };
}

export type { NewReportLogicalType };
