import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { useRef } from "react";
import { Redirect } from "react-router-dom";
import EntityOperation from "../../../infrastructure/system/EnumEntityOperation";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import ExportData from "../../app/export/ExportData";
import CrudLabel from "../../label/CrudLabel";
import CrudFormLogical, { CrudFormLogicalType } from "./CrudFormLogical";
import FormSematicValidationList from "./sematic-validation/FormSematicValidationList";
import CrudUserRight from "./user-right/CrudUserRight";

export default function CrudFormGroup() {
  const {
    formOperation,
    onCancel,
    onCreate,
    onDelete,
    onUpdate,
    isDisabled,
    formChange,
    location,
    formCode,
    setFormChange,
    breadCrumbItems,
    formFieldList,
    first,
    tableRows,
    setSelectedFormField,
    selectedFormField,
    selectedRow,
    setSelectedRow,
    index,
    setIndex,
    onPageFormTable,
    exportData,
    history,
    deleteFormField,
    displayDialog,
    setDisplayDialog,
    selectedForm,
  }: CrudFormLogicalType = CrudFormLogical();

  const { Labels } = LabelsContext();
  const dt = useRef<any>(null);
  const openFormField = (entityOperation: string) => {
    const formFieldId = entityOperation !== EntityOperation.CREATE ? selectedFormField.id : "";
    history.push({
      pathname: `/crudFormField/${formFieldId}`,
      state: {
        formFieldOperation: entityOperation,
        selectedFormField: selectedFormField,
        formCode: formCode,
        formOperation: formOperation,
      },
    });
  };

  if (!formCode && !location.state) {
    return <Redirect to="/crudFormList" />;
  }
  const leftContentsToolbar = () => (
    <>
      {formOperation === EntityOperation.CREATE && (
        <Button label={Labels.BUTTON_CREATE} tooltip={Labels.LABEL_CREATE_FORM} tooltipOptions={{ position: "bottom" }} icon="pi pi-plus" className="p-button-success" onClick={onCreate} />
      )}
      {formOperation === EntityOperation.UPDATE && (
        <Button label={Labels.BUTTON_UPDATE} icon="pi pi-pencil" tooltip={Labels.LABEL_UPDATE_FORM} tooltipOptions={{ position: "bottom" }} className="p-button-warning" onClick={onUpdate} />
      )}
      {formOperation === EntityOperation.DELETE && (
        <Button label={Labels.BUTTON_DELETE} icon="pi pi-trash" tooltip={Labels.LABEL_DELETE_FORM} tooltipOptions={{ position: "bottom" }} className="p-button-danger" onClick={onDelete} />
      )}
    </>
  );

  const rightContentsToolbar = () => (
    <>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-danger" onClick={onCancel} />
    </>
  );
  const leftContents = () => (
    <>
      <Button
        icon="pi pi-plus"
        className="p-button-success btn-margin-right"
        label={Labels.BUTTON_CREATE}
        tooltip={Labels.FIELD_TITLE_DIALOG_CREATE}
        tooltipOptions={{ position: "bottom" }}
        disabled={formOperation !== EntityOperation.UPDATE}
        onClick={() => {
          openFormField(EntityOperation.CREATE);
        }}
      />
      <Button
        icon="pi pi-pencil"
        className="p-button-warning btn-margin-right"
        disabled={!selectedFormField || formOperation !== EntityOperation.UPDATE}
        label={Labels.BUTTON_UPDATE}
        tooltip={Labels.FIELD_TITLE_DIALOG_UPDATE}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          openFormField(EntityOperation.UPDATE);
        }}
      />
      <Button
        icon="pi pi-info-circle"
        label={Labels.BUTTON_DETAILS}
        tooltip={Labels.FIELD_TITLE_DIALOG_DETAILS}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedFormField}
        onClick={() => {
          openFormField(EntityOperation.READ);
        }}
      />
    </>
  );

  const rightContents = () => (
    <>
      <ExportData data={exportData} name={Labels.FORM_FIELDS} dt={dt} />
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        label={Labels.BUTTON_DELETE}
        tooltip={Labels.FIELD_TITLE_DIALOG_DELETE}
        tooltipOptions={{ position: "bottom" }}
        disabled={!selectedFormField || formOperation !== EntityOperation.UPDATE}
        onClick={() => {
          confirmDialog({
            acceptLabel: Labels.BUTTON_YES,
            rejectLabel: Labels.BUTTON_NO,
            message: Labels.MESSAGE_ARE_YOU_SURE_YOU_WANT_TO_DELETE_FORM_FIELD,
            icon: "pi pi-power-off",
            accept: () => {
              deleteFormField(selectedFormField.id);
            },
          });
        }}
      />
    </>
  );
  return (
    <div className="layout-crud-form">
      <BreadCrumb model={breadCrumbItems} />
      <CrudLabel
        nameOriginal={selectedForm?.name}
        displayDialog={displayDialog}
        dialogClose={() => {
          setDisplayDialog(false);
        }}
        isDisabled={isDisabled}
        entityOperation={formOperation}
      />
      <TabView
        className="tabview-margin-top"
        renderActiveOnly={false}
        activeIndex={index}
        onTabChange={(e) => {
          setIndex(e.index);
        }}
      >
        <TabPanel header={Labels.FORM}>
          <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
            <div className="p-grid p-align-center p-nogutter">
              <div className="p-col-4">
                <div>
                  {Labels.LABEL_CODE}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-8 p-fluid">
                <InputText
                  disabled={isDisabled}
                  value={formChange && formChange.code}
                  onChange={(e: any) => {
                    setFormChange({
                      ...formChange!,
                      code: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-4">
                <div>
                  {Labels.LABEL_NAME}
                  {Labels.SPECIAL_CHAR_REQUIRED}
                </div>
              </div>
              <div className="p-col-7 p-fluid margin-top-bottom-5">
                <InputText
                  disabled={isDisabled}
                  value={formChange && formChange.name}
                  onChange={(e: any) => {
                    setFormChange({
                      ...formChange!,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-1 justify-items-end">
                <Button
                  icon="pi pi-external-link"
                  onClick={() => {
                    setDisplayDialog(true);
                  }}
                  className="margin-top-bottom-5 margin-left-10"
                  disabled={formOperation === EntityOperation.READ}
                  tooltip={Labels.LABEL_TRANSLATE_LABEL}
                  tooltipOptions={{ position: "bottom" }}
                />
              </div>
              <div className="p-col-4">
                <div>{Labels.LABEL_DESCRIPTION}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputTextarea
                  rows={3}
                  cols={5}
                  disabled={isDisabled}
                  value={formChange && formChange.description}
                  onChange={(e: any) => {
                    setFormChange({
                      ...formChange!,
                      description: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="p-col-4">
                <div>{Labels.LABEL_NOTE}</div>
              </div>
              <div className="p-col-8 p-fluid margin-top-bottom-5">
                <InputTextarea
                  rows={3}
                  cols={5}
                  disabled={isDisabled}
                  value={formChange && formChange.note}
                  onChange={(e: any) => {
                    setFormChange({
                      ...formChange!,
                      note: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <Toolbar left={leftContentsToolbar} right={rightContentsToolbar}></Toolbar>
        </TabPanel>
        <TabPanel header={Labels.FORM_FIELDS} disabled={formOperation === EntityOperation.CREATE}>
          <Toolbar left={leftContents} right={rightContents} />
          <div>
            <DataTable
              ref={dt}
              value={formFieldList}
              paginator
              first={first}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              rows={tableRows}
              rowsPerPageOptions={[5, 10, 20]}
              onPage={(e) => {
                onPageFormTable(e.rows, e.first);
              }}
              selectionMode="single"
              selection={selectedRow}
              onSelectionChange={(e) => setSelectedRow(e.value)}
              onRowSelect={(e) => setSelectedFormField(e.data)}
              emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
              exportFilename={Labels.FORM_FIELDS}
            >
              <Column field={"displayOrder"} header={Labels.HEADER_DISPLAY_ORDER} filter filterMatchMode="contains" sortable />
              <Column field={"name"} header={Labels.HEADER_NAME} filter filterMatchMode="contains" sortable />
              <Column field={"code"} header={Labels.HEADER_CODE} filter filterMatchMode="contains" sortable />
              <Column field={"description"} header={Labels.HEADER_DESCRIPTION} filter filterMatchMode="contains" sortable />
            </DataTable>
          </div>
        </TabPanel>
        <TabPanel header={Labels.SEMANTIC_VALIDATION} disabled={formOperation === EntityOperation.CREATE}>
          <FormSematicValidationList formOperation={formOperation} />
        </TabPanel>
        <TabPanel header={Labels.USER_RIGHT} disabled={formOperation === EntityOperation.CREATE}>
          <CrudUserRight formOperation={formOperation} />
        </TabPanel>
      </TabView>
    </div>
  );
}
