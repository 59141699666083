import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { CodebookController, CodebookControllerType } from "../../../controllers/codebook/CodebookController";
import LabelsContext from "../../../infrastructure/system/LabelsContext";
import { handleAxiosCallError, useEffectOnce } from "../../../infrastructure/system/Utils";
import { AppContext } from "../../../Store";

interface CodebookListTypeLogicalType {
  codebookTypeList: any;
  first: number;
  tableRows: number;
  selectedRow: any;
  selectedCodebook: any;
  onPage: any;
  setSelectedRow: any;
  setSelectedCodebook: any;
  breadCrumbItems: any;
  exportData: any;
  openCodebook: any;
  onSearchValueChange: any;
  getAllCodeBook: any;
}

export default function CodebookTypeListLogical() {
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const { axiosGetCodebookTypeList, axiosGetCodebookListWithCode }: CodebookControllerType = CodebookController();
  const [exportData, setExportData] = useState<Array<any>>([]);
  const [codebookTypeList, setCodebookTypeList] = useState([]);
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);
  const [selectedRow, setSelectedRow] = useState<boolean>(false);
  const [selectedCodebook, setSelectedCodebook] = useState<any>();
  const { Labels } = LabelsContext();
  const history = useHistory();
  const breadCrumbItems = [
    {
      label: Labels.LABEL_COODEBOOK_TYPE_LIST,
      command: () => {
        window.location.reload();
      },
    },
  ];

  const fetchData = () => {
    setShowBlockUI(true);
    axiosGetCodebookTypeList()
      .then((res: any) => {
        setCodebookTypeList(res.data.data);
        const exportList = new Array<any>();
        res.data.data.forEach((codebook: any) => {
          exportList.push({ [Labels.LABEL_CODE]: codebook.code, [Labels.LABEL_NAME]: codebook.name });
        });
        setExportData(exportList);
        setShowBlockUI(false);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  useEffectOnce(() => {
    fetchData();
  });

  const onPage = (e: any) => {
    setTableRows(e.rows);
    setFirst(e.first);
  };

  const onSearchValueChange = (sortedData: Array<any>) => {
    const exportList = new Array<any>();
    sortedData?.forEach((codebook: any) => {
      exportList.push({ [Labels.LABEL_CODE]: codebook.code, [Labels.LABEL_NAME]: codebook.name });
    });
    setExportData(exportList);
  };

  const openCodebook = () => {
    history.push("/codebookList/" + selectedCodebook.path);
  };

  const getAllCodeBook = () => {
    setShowBlockUI(true);
    return axiosGetCodebookListWithCode(selectedCodebook.code)
      .then((response: any) => {
        const exportList = new Array<any>();
        response.data.data.forEach((codebook: any) => {
          exportList.push({ [Labels.LABEL_CODE]: codebook.code, [Labels.LABEL_NAME]: codebook.name });
        });
        setExportData(exportList);
        setShowBlockUI(false);
        return exportList;
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  };

  return {
    codebookTypeList,
    first,
    tableRows,
    selectedRow,
    selectedCodebook,
    onPage,
    setSelectedRow,
    setSelectedCodebook,
    breadCrumbItems,
    exportData,
    openCodebook,
    onSearchValueChange,
    getAllCodeBook,
  };
}

export type { CodebookListTypeLogicalType };
