import { useContext, useEffect, useState } from "react";
import { axiosConfig, handleAxiosCallError } from "../../../infrastructure/system/Utils";
import { AppContext } from "../../../Store";
import axios from "axios";
import Endpoint from "../../../infrastructure/system/Endpoint";
import InstitutionReadDto from "../../../model/InstitutionReadDto";
import { UserController, UserControllerType } from "../../../controllers/user/UserController";
import { FormController, FormControllerType } from "../../../controllers/form/FormController";
import { RoleService, RoleServiceType } from "../../../controllers/role/RoleService";

interface AppTopbarLogicalType {
  institutionList: Array<InstitutionReadDto>;
  onChangeIntitution: any;
  getCurrentUser: any;
  badgeValue: any;
}

export default function AppTopbarLogical(): AppTopbarLogicalType {
  const { authData, updateAuthData, showMessage } = useContext(AppContext);
  const [institutionList, setInstitutionList] = useState<Array<InstitutionReadDto>>([]);
  const { axiosGetUser }: UserControllerType = UserController();
  const [badgeValue, setBadgeValue] = useState<any>();
  const { axiosCountForRevisionFormList, axiosCountSubmittedFormList }: FormControllerType = FormController();
  const { isBed, isEd, isMd }: RoleServiceType = RoleService();
  useEffect(() => {
    getBadgeValue();
    const institutionUser = authData!.currentUser.userInstitutionList?.map((x) => x.institution);
    if (institutionUser) {
      setInstitutionList(institutionUser);
    }
    // eslint-disable-next-line
  }, [authData]);

  const getBadgeValue = () => {
    if (isEd() || isBed()) {
      axiosCountSubmittedFormList()
        .then((res: any) => {
          setBadgeValue(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    } else if (isMd()) {
      axiosCountForRevisionFormList()
        .then((res: any) => {
          setBadgeValue(res.data.data);
        })
        .catch((error: any) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const getCurrentUser = () => {
    axiosGetUser(authData?.currentUser.id, authData?.token)
      .then((res: any) => {
        updateAuthData(
          JSON.stringify({
            ...authData,
            currentUser: res.data.data,
          })
        );
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const onChangeIntitution = (id: number) => {
    axios
      .put(`${Endpoint.USER_LIST}/${authData?.currentUser.id}/updateInstutution/${id}`, {}, axiosConfig(authData!.token))
      .then((response) => {
        updateAuthData(
          JSON.stringify({
            token: authData?.token,
            currentUser: response.data.data,
          })
        );
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  return { onChangeIntitution, institutionList, getCurrentUser, badgeValue };
}

export type { AppTopbarLogicalType };
