import axios from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/system/Endpoint";
import { axiosConfig } from "../../infrastructure/system/Utils";
import { AppContext } from "../../Store";

interface UserControllerType {
  axiosGetUserList: any;
  axiosCreateUser: any;
  axiosUpdateUser: any;
  axiosUpdateUserProfile: any;
  axiosDeleteUser: any;
  axiosGetUser: any;
  axiosCreateUserInstitution: any;
  axiosDeleteUserInstitution: any;
  axiosCreateUserGroupUser: any;
  axiosDeleteUserGroupUser: any;
  axiosGetSearchUserList: any;
}

export function UserController(): UserControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetUserList = (searchData: any) => {
    return axios.get(Endpoint.USER_LIST, axiosConfig(authData!.token, searchData));
  };

  const axiosGetUser = (userId: string, token: string) => {
    return axios.get(`${Endpoint.USER_LIST}/${userId}`, axiosConfig(token));
  };

  const axiosCreateUser = (userChange: any) => {
    return axios.post(Endpoint.USER_LIST, userChange ? userChange : {}, axiosConfig(authData!.token));
  };

  const axiosUpdateUser = (userChange: any) => {
    return axios.put(`${Endpoint.USER_LIST}/${userChange.id}`, userChange, axiosConfig(authData!.token));
  };

  const axiosUpdateUserProfile = (userChange: any) => {
    return axios.put(`${Endpoint.USER_LIST}/profile`, userChange, axiosConfig(authData!.token));
  };

  const axiosDeleteUser = (userId: string) => {
    return axios.delete(`${Endpoint.USER_LIST}/${userId}`, axiosConfig(authData!.token));
  };

  const axiosCreateUserInstitution = (userId: string, institution: any) => {
    return axios.post(`${Endpoint.USER_LIST}/${userId}/institutionList`, institution, axiosConfig(authData!.token));
  };

  const axiosDeleteUserInstitution = (userId: string, userInstitutionId: string) => {
    return axios.delete(`${Endpoint.USER_LIST}/${userId}/institutionList/${userInstitutionId}`, axiosConfig(authData!.token));
  };
  const axiosCreateUserGroupUser = (userId: string, userGroup: any) => {
    return axios.post(`${Endpoint.USER_LIST}/${userId}/userGroupList`, userGroup, axiosConfig(authData!.token));
  };

  const axiosDeleteUserGroupUser = (userId: string, userGroupUserId: string) => {
    return axios.delete(`${Endpoint.USER_LIST}/${userId}/userGroupList/${userGroupUserId}`, axiosConfig(authData!.token));
  };

  const axiosGetSearchUserList = (params: any) => {
    return axios.get(Endpoint.USER + "/searchUserList", axiosConfig(authData!.token, params));
  };

  return {
    axiosGetUserList,
    axiosCreateUser,
    axiosUpdateUser,
    axiosUpdateUserProfile,
    axiosDeleteUser,
    axiosCreateUserInstitution,
    axiosDeleteUserInstitution,
    axiosGetUser,
    axiosCreateUserGroupUser,
    axiosDeleteUserGroupUser,
    axiosGetSearchUserList,
  };
}

export type { UserControllerType };
