const EnumComponentType = {
  BOOLEAN: "BOOLEAN",
  NUMBER_INTEGER: "NUMBER_INTEGER",
  NUMBER_DECIMAL: "NUMBER_DECIMAL",
  TEXT: "TEXT",
  LONG_TEXT: "LONG_TEXT",
  DATE_TIME: "DATE_TIME",
  LABEL: "LABEL",
  PANEL: "PANEL",
  SET: "SET",
  CODEBOOK: "CODEBOOK",
  CODEBOOK_AUTOCOMPLETE: "CODEBOOK_AUTOCOMPLETE"
};

export default EnumComponentType;
